import { NavigationItem } from '@wla/app/(cms)/navigation-drawer';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';
import { MouseEvent, Ref, RefObject, forwardRef, useState } from 'react';

type ItemProps = {
  item: NavigationItem;
  onClick?(item: NavigationItem): void;
  onClose?(): void;
  className?: string;
  ref?: RefObject<HTMLAnchorElement>;
};

export const Item = forwardRef((props: ItemProps, ref: Ref<HTMLAnchorElement>) => {
  const { item, onClick, onClose, className } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    if (item.children) {
      e.preventDefault();
      setIsExpanded(!isExpanded);
      onClick?.(item);
    } else {
      onClose?.();
    }
  }

  return (
    <Link
      ref={ref}
      {...(item.children && { 'aria-expanded': isExpanded, 'aria-controls': item.title })}
      href={item.url}
      data-disable-nprogress={item.children ? 'true' : 'false'}
      prefetch={true}
      className="group flex cursor-pointer flex-row items-center justify-between"
      onClick={handleClick}
      tabIndex={0}
    >
      <span className={cn('text-lg group-hover:underline', className)}>{item.title}</span>
      {item.children && <Icon src="w3-arrow-right" size="20" className="font-bold" />}
    </Link>
  );
});

Item.displayName = 'Item';
