import { CarrierType, OrderType, OrderValidityStatus } from '@wla/types/orders';
import pickBy from 'lodash/pickBy';
import { CarrierOption, CheckoutState, Order, ShoppingSession } from '@jysk/api-types/orderManagementApi/model';

export type ValidityCheckType =
  | 'ZIP_CODE_ENTERED'
  | 'ADDRESS_ENTERED'
  | 'SALES_TERMS_APPROVED'
  | 'DELIVERY_INFO_APPROVED'
  | 'PAYMENT_METHOD_SELECTED'
  | 'ORDER_APPROVED'
  | 'PAYMENT_REQUESTED'
  | 'PAYMENT_VALIDATED';

// @ts-expect-error Type are not declared properly in the OM API
export type ValidityCheckState = CheckoutState['validityChecks']['ADDRESS_ENTERED'];

export type ValidityChecks = Record<ValidityCheckType, ValidityCheckState>;

export type RelevantValidityChecks = Partial<Record<ValidityCheckType, Omit<ValidityCheckState, 'NOT_RELEVANT'>>>;

export type BasketType = OrderType | 'MIXED_BASKET';

export type OrderValidityChecks = {
  ADDRESS_ENTERED: OrderValidityStatus;
  DELIVERY_INFO_APPROVED: OrderValidityStatus;
  ORDER_APPROVED: OrderValidityStatus;
  PAYMENT_METHOD_SELECTED: OrderValidityStatus;
  PAYMENT_REQUESTED: OrderValidityStatus;
  PAYMENT_VALIDATED: OrderValidityStatus;
  SALES_TERMS_APPROVED: OrderValidityStatus;
  ZIP_CODE_ENTERED: OrderValidityStatus;
};

type FindProduct = {
  orders: Order[];
  type: OrderType;
  productId: string;
};

function getOrderByOrderType(orderType: string) {
  return function (orders: Order[]) {
    return orders.find((order) => order.orderType === orderType);
  };
}
export const getOnlineOrder = getOrderByOrderType(OrderType.OnlineSales);
export const getClickCollectOrder = getOrderByOrderType(OrderType.ClickCollect);
export const getGiftcardOrder = getOrderByOrderType(OrderType.GiftCard);

function isOneOrderOnlyOfOrderType(orderType: string) {
  return function (orders: Order[]) {
    return orders.length === 1 && orders.at(0)?.orderType === orderType;
  };
}

function hasOrderOfOrderType(orderType: string) {
  return function (orders: Order[]) {
    return Boolean(orders.find((order) => order.orderType === orderType));
  };
}

export const hasClickAndCollectOrder = hasOrderOfOrderType(OrderType.ClickCollect);
export const hasGiftCardOrder = hasOrderOfOrderType(OrderType.GiftCard);
export const hasOnlineOrder = hasOrderOfOrderType(OrderType.OnlineSales);

export const isOnlineOnlyBasket = isOneOrderOnlyOfOrderType(OrderType.OnlineSales);
export const isClickAndCollectOnlyBasket = isOneOrderOnlyOfOrderType(OrderType.ClickCollect);
export const isGiftcardOnlyBasket = isOneOrderOnlyOfOrderType(OrderType.GiftCard);

export function isMixedBasket(orders: Order[]) {
  return hasOnlineOrder(orders) && hasClickAndCollectOrder(orders);
}

export function isMixedBasketWithGiftcard(orders: Order[]) {
  return hasGiftCardOrder(orders) && (hasOnlineOrder(orders) || hasClickAndCollectOrder(orders));
}

export function isMixedBasketGiftcardClickCollectOnly(orders: Order[]) {
  return orders.length === 2 && hasGiftCardOrder(orders) && hasClickAndCollectOrder(orders);
}

function validityCheckValidator(validityCheck: keyof OrderValidityChecks) {
  return function (orders: Order[]) {
    return Boolean(orders.find((order) => order.checkoutState?.validityChecks?.[validityCheck] === 'OK'));
  };
}

export const isDeliveryValidated = validityCheckValidator('ADDRESS_ENTERED');
export const isPaymentValidated = validityCheckValidator('PAYMENT_VALIDATED');

export function isCarrierValidated(orders: Order[]) {
  const onlineOrder = getOnlineOrder(orders);
  if (onlineOrder) {
    return onlineOrder.checkoutState?.validityChecks?.DELIVERY_INFO_APPROVED === 'OK';
  }
  return true;
}

export function getZipCodeFromOrder(orders: Order[]) {
  return (
    orders.find((order) => order.zipCode)?.zipCode ||
    orders.find((order) => order.billingAddress?.zipCode)?.billingAddress?.zipCode
  );
}

export function getStoreIdFromOrder(orders: Order[]) {
  return getClickCollectOrder(orders)?.siteId;
}

export function findProduct({ orders, type, productId }: FindProduct) {
  const order = orders.find((order) => order.orderType === type);

  const product = order?.deliveryGroups
    ?.map((group) => group?.orderLines?.filter((line) => line?.article === productId))
    .flat();

  return product;
}

export function getProductIdsFromOrder(order?: Order) {
  return order?.deliveryGroups?.flatMap(
    (group) => group.orderLines?.map((line) => line?.article).filter(Boolean),
    2,
  ) as string[] | undefined;
}

export function getSelectedCarrier(order: Order | undefined) {
  return order?.deliveryGroups?.at(0)?.selectedCarrier;
}

export function isBusinessCarrier(selectedCarrier: CarrierOption | undefined) {
  return selectedCarrier && (Boolean(selectedCarrier.businessCarrier) || selectedCarrier.carrierId === 'glsbusiness');
}

export const CARRIERS_WITH_OVERRIDDEN_DELIVERY_ADDRESS = [CarrierType.OopusDelivery, CarrierType.DropPointDelivery];

export function alternativeDeliveryIsOverridden(carrierType: CarrierType | string) {
  return CARRIERS_WITH_OVERRIDDEN_DELIVERY_ADDRESS.includes(carrierType as CarrierType);
}

export function selectActiveOrders(shoppingSession: ShoppingSession | undefined) {
  return shoppingSession?.active?.orders?.filter((order) => !order.isEmpty) ?? [];
}

/**
 * Essentially the same as ShoppingManager.activeBaskets.
 */
export function selectOrderTypes(shoppingSession: ShoppingSession | undefined) {
  return selectActiveOrders(shoppingSession).map((order) => order.orderType);
}

export function selectBasketType(shoppingSession: ShoppingSession | undefined): BasketType | undefined {
  const orders = selectActiveOrders(shoppingSession);
  const orderSize = orders.length;

  if (orderSize === 1) return orders[0].orderType as OrderType;
  if (orderSize > 1) return 'MIXED_BASKET';

  return undefined;
}

export function selectOrderByType(shoppingSession: ShoppingSession | undefined, orderType: OrderType) {
  return selectActiveOrders(shoppingSession).find((order) => order.orderType === orderType);
}

const ORDER_TYPE_EXECUTE_PRIORITY = ['ONLINE_SALES', 'CLICK_COLLECT', 'GIFT_CARD'];

export function selectOrderState(shoppingSession: ShoppingSession | undefined): RelevantValidityChecks {
  if (!shoppingSession) return {};

  const sortedOrders = [...selectActiveOrders(shoppingSession)].sort(
    (a, b) => ORDER_TYPE_EXECUTE_PRIORITY.indexOf(a.orderType) - ORDER_TYPE_EXECUTE_PRIORITY.indexOf(b.orderType),
  );

  const orderState = (sortedOrders[0]?.checkoutState?.validityChecks ?? {}) as ValidityChecks;

  return pickBy(orderState, (value) => value !== 'NOT_RELEVANT');
}

export function selectBasketById(shoppingSession: ShoppingSession | undefined, basketId: string) {
  return shoppingSession?.active?.orders?.find((order) => order.uuid === basketId);
}

export function selectOrderLineInBasketById(basket: Order | undefined, orderLineId: string) {
  const allOrderLines = basket?.deliveryGroups?.flatMap((deliveryGroup) => deliveryGroup.orderLines);
  return allOrderLines?.find((orderLine) => orderLine?.lineId === orderLineId);
}

export function selectOrderLineInBasketByArticle(basket: Order, article: string) {
  const allOrderLines = basket.deliveryGroups?.flatMap((deliveryGroup) => deliveryGroup.orderLines);
  return allOrderLines?.find((orderLine) => orderLine?.article === article);
}

/**
 * Essentially the same as ShoppingManager.baskets
 * @param shoppingSession
 * @returns baskets A mapping of basket types to their respective basket id
 */
export function selectBaskets(shoppingSession: ShoppingSession | undefined) {
  if (!shoppingSession) return null;

  return Object.fromEntries(
    selectActiveOrders(shoppingSession).map(({ orderType, uuid }) => [orderType, uuid]),
  ) as Record<OrderType, string>;
}

export function selectCustomerData(shoppingSession: ShoppingSession | undefined) {
  return shoppingSession?.checkoutSessionData?.customerData;
}

export function selectActiveCarrier(shoppingSession: ShoppingSession | undefined) {
  return selectOrderByType(shoppingSession, OrderType.OnlineSales)?.deliveryGroups?.[0].selectedCarrier;
}
