import { FilterAxises, GetFilterAxis200 } from '@jysk/api-types/wssFilterAxisApi/model';
import { Button } from '@wla/components/ui/button/button';
import { Drawer } from '@wla/components/ui/drawer';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { Filters } from './filters';
import { ResetFiltersButton } from './reset-filters-button';

type FilterDrawerProps = {
  filters: GetFilterAxis200 | null;
  toggleFilterDrawer: boolean;
  isLoading?: boolean;
  preOpenFilter?: FilterAxises | null;
  onFilterDrawerToggle: () => void;
  showCount?: boolean;
};

export function FilterDrawer({
  filters,
  isLoading,
  toggleFilterDrawer,
  preOpenFilter,
  onFilterDrawerToggle,
  showCount,
}: FilterDrawerProps) {
  const t = useTranslations();
  const [numResults] = useState(0);
  const productCount = filters?.filteredarticles.length || 0;

  return (
    <Drawer open={toggleFilterDrawer} onClose={onFilterDrawerToggle}>
      <Drawer.Header title={t('common.filter')} />
      <Drawer.Body>
        <Filters filters={filters} preOpenFilter={preOpenFilter} isLoading={isLoading} />
      </Drawer.Body>
      <Drawer.Footer className="mt-auto flex gap-2 border-none">
        <ResetFiltersButton className="min-w-28" />
        <Button onClick={onFilterDrawerToggle} variant="primary" className="flex w-full justify-center">
          {showCount
            ? t('plp.show-filters-results', { results: filters ? productCount : numResults })
            : t('common.apply')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
}
