import { SeparatorParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';

type ParagraphSeparatorProps = {
  content: SeparatorParagraph;
};

export function ParagraphSeparator({ content }: ParagraphSeparatorProps) {
  const { config } = content;
  return (
    <ParagraphConfig config={config}>
      <hr className="h-0 w-full border-gray-300" />
    </ParagraphConfig>
  );
}
