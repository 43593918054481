'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { formatTime } from '@wla/app/(cms)/(favorite-store)/get-store-opening-data';
import { Drawer } from '@wla/components/ui/drawer';
import { GoogleMaps } from '@wla/components/ui/maps/google-maps';
import { usePublicConfig } from '@wla/lib/configs';
import { useTranslations } from 'next-intl';
import { StoreFull } from '@jysk/api-types/drupalApi';

type AddressClickCollectDetailsDrawerProps = { store: StoreFull };

export const AddressClickCollectDetailsDrawer = NiceModal.create(({ store }: AddressClickCollectDetailsDrawerProps) => {
  const t = useTranslations();
  const modal = useModal();
  const { googleMaps } = usePublicConfig();

  const now = new Date();
  const currentWeekday = now.getDay();

  let days = [
    t('common.days.sunday'),
    t('common.days.monday'),
    t('common.days.tuesday'),
    t('common.days.wednesday'),
    t('common.days.thursday'),
    t('common.days.friday'),
    t('common.days.saturday'),
  ];

  days = [...days.slice(currentWeekday), ...days.slice(0, currentWeekday)];

  const storeInfo = {
    id: store.id,
    url: store.url,
    name: store.name,
    address: `${store.street} ${store.number}, ${store.zipCode} ${store.city}`,
    lat: store.latitude,
    lng: store.longitude,
    openingHours: store.openingHours,
  };

  return (
    <Drawer open={modal.visible} onClose={modal.hide} position="right">
      <Drawer.Header title={t('checkout.click-collect.selected-store')} />
      <Drawer.Body>
        <div className="flex flex-col gap-4 rounded border border-gray-400 px-5 py-6">
          <h3 className="text-lg font-semibold">{t('common.jysk-store', { storeName: store.name })}</h3>
          <div className="h-48 w-full bg-blue-200">
            <GoogleMaps
              storeInfo={[storeInfo]}
              title={store.name}
              apiKey={googleMaps.key}
              mapId="click-collect-store-details-map"
            />
          </div>
          <div className="flex flex-col gap-2">
            <h4 className="font-bold">{t('common.store-information')}</h4>
            <div className="grid grid-cols-5 gap-1">
              <p className="col-span-2">{t('forms.address')}</p>
              <p className="col-span-3 text-start">
                {t('common.address-format', {
                  street: store.street,
                  number: store.number,
                  zipCode: store.zipCode,
                  city: store.city,
                })}
              </p>
            </div>
          </div>
          <div>
            <p className="mb-2 font-bold">{t('common.opening-hours')}</p>
            {store.openingHours?.map((openingHour, i) => (
              <div key={i} className="grid grid-cols-5 gap-1">
                <p className="col-span-2">{days[i % days.length]}</p>

                <p className="col-span-3 text-start">
                  {openingHour
                    ? `${formatTime(openingHour.startHours)} - ${formatTime(openingHour.endHours)}`
                    : t('common.closed')}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
});
