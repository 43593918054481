'use client';
import { ProductBrandCarouselParagraph } from '@jysk/api-types/drupalApi';
import { Carousel, CarouselItem } from '@wla/components/ui/carousel/carousel';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { useTranslations } from 'next-intl';
import { BrandCard } from '../../brand-card';

type IParagraphBrandSliderProps = {
  content: ProductBrandCarouselParagraph;
};

export function ParagraphBrandSlider({ content }: IParagraphBrandSliderProps) {
  const t = useTranslations('common');
  const { config, brands } = content;
  if (!brands) return null;

  return (
    <ParagraphConfig config={config} className="my-8">
      <h3 className="mb-8 text-5xl font-semibold">{t('brands')}</h3>
      <Carousel visibleItems={5}>
        {brands.map((brand) => (
          <CarouselItem key={brand.id}>
            <BrandCard brandLogoImage={brand.brandLogoImage} name={brand.name} url={brand.url} />
          </CarouselItem>
        ))}
      </Carousel>
    </ParagraphConfig>
  );
}
