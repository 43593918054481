import { Link } from '@wla/components/ui/link';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import Image from 'next/image';
import type { HeroBannerSecondary } from '@jysk/api-types/drupalApi';

type ParagraphHeroBannerSecondary = {
  content: HeroBannerSecondary;
};

export function ParagraphHeroBannerSecondary({ content }: ParagraphHeroBannerSecondary) {
  const { config, items } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config}>
      <div className="mb-4 grid auto-cols-fr grid-flow-row grid-cols-2 gap-4 gap-y-4 md:mb-8 md:mt-4 md:grid-flow-row md:grid-cols-2 lg:grid-flow-col lg:grid-rows-1 lg:gap-8">
        {items.map((item, index) => {
          const HeroItem: React.ElementType = item?.link ? Link : 'div';
          return (
            <HeroItem
              key={`item?.title-${index}`}
              {...(item?.link && { href: item.link.url, target: item.link.target })}
            >
              <div className="aspect-[3/2] overflow-hidden">
                {item?.image && (
                  <Image
                    sizes="(max-width: 359px) 165px, (max-width: 430px) 268px, (max-width: 768px) 322px, (max-width: 992px) 436px, (max-width: 1200px) 322px, 322px"
                    src={item.image.url}
                    alt={item.image.alt}
                    width="319"
                    height="213"
                    className="w-full object-cover"
                  />
                )}
              </div>
              {item?.title && <h3 className="mt-2 text-base font-semibold md:mt-4 md:text-2xl">{item.title}</h3>}
            </HeroItem>
          );
        })}
      </div>
    </ParagraphConfig>
  );
}
