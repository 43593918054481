import { StoreFullAllOfOpeningHours } from '@jysk/api-types/drupalApi';

export type OpeningInfo = {
  open: boolean;
  closesToday: string;
  opensAgain: string;
};

export function formatTime(time: number): string {
  const timeString = time.toString().padStart(4, '0');
  const hours = parseInt(timeString.slice(0, 2));
  const minutes = timeString.slice(2);
  return `${hours}:${minutes}`;
}

export function getStoreOpeningData(openingHours: StoreFullAllOfOpeningHours): OpeningInfo | null {
  const now = new Date();
  const currentWeekday = now.getDay();
  const openingTimes = openingHours?.filter((opening) => opening?.day === currentWeekday)[0];

  if (!openingTimes?.startHours || !openingTimes?.endHours) {
    return { open: false, closesToday: '', opensAgain: '' };
  }

  const todayOpeningTime = formatTime(openingTimes.startHours);
  const todayClosingTime = formatTime(openingTimes.endHours);

  const currentTimeInt = parseInt(
    `${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}`,
    10,
  );

  return {
    open: currentTimeInt >= openingTimes.startHours && currentTimeInt <= openingTimes.endHours,
    closesToday: todayClosingTime,
    opensAgain: todayOpeningTime,
  };
}
