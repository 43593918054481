import { zodResolver } from '@hookform/resolvers/zod';
import {
  CustomerB2bRequestResetPasswordFormData,
  b2bRequestResetPasswordFormSchema,
} from '@wla/app/(cms)/(customer)/b2b/login/b2b-auth-schemas';
import { Alert } from '@wla/components/ui/alert';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export function B2bRequestResetPasswordForm() {
  const t = useTranslations();
  const [statusCode, setStatusCode] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<CustomerB2bRequestResetPasswordFormData>({
    mode: 'all',
    resolver: zodResolver(b2bRequestResetPasswordFormSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  async function onSubmit(data: CustomerB2bRequestResetPasswordFormData) {
    setIsLoading(true);
    const response = await fetch('/b2b/api/request-reset-b2b-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    setStatusCode(result.status);
    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup
        label={t('forms.customer-id')}
        inputType={InputTypes.Input}
        required
        validationError={errors.customerNo?.message ? t(errors.customerNo?.message) : ''}
      >
        <Input {...register('customerNo')} className="mb-4" />
      </FormGroup>
      {statusCode === 200 && (
        <Alert type="success" className="mb-4">
          {t('customer.login.we-have-sent-you-an-email')}
        </Alert>
      )}
      {statusCode && statusCode >= 400 && (
        <Alert className="my-3 lg:my-4" type="error">
          {t('forms.errors.something-went-wrong')}
        </Alert>
      )}

      <SubmitButton className="w-full" loading={isLoading}>
        {t('customer.reset-password.reset-password')}
      </SubmitButton>
    </form>
  );
}
