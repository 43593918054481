import { MostPopularFaqQuestionParagraph } from '@jysk/api-types/drupalApi';
import { Icon } from '@wla/components/ui/icon';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { useTranslations } from 'next-intl';
import { Link } from '../link';

type FaqSectionProps = {
  content: MostPopularFaqQuestionParagraph;
};

export function ParagraphFaqSection({ content }: FaqSectionProps) {
  const t = useTranslations('customer-service');
  const { config, items } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config} className="mt-8 flex flex-col">
      <h2 className="pb-4 text-3xl font-semibold lg:text-5xl">{t('faq-headline')}</h2>
      {items.map((item) => (
        <Link
          key={item.title}
          href={item.url}
          className="flex w-full items-center justify-between border-b py-5 text-left text-base font-normal no-underline first-of-type:border-t lg:py-8 lg:text-lg"
        >
          <span className="text-lg text-black">{item.title}</span>
          <Icon src="w3-arrow-down" size="24" className="w-fit" />
        </Link>
      ))}
    </ParagraphConfig>
  );
}
