import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import Link from 'next/dist/client/link';
import { JSXElementConstructor, ReactElement, isValidElement, useMemo } from 'react';
import { Layout, TableOfContentsParagraph, TextParagraph } from '@jysk/api-types/drupalApi';

type TableOfContentsParagraphProps = {
  content: TableOfContentsParagraph;
  fullContent: Layout;
};

export function ParagraphTableOfContent({ content, fullContent }: TableOfContentsParagraphProps) {
  const { config } = content;

  const headings = useMemo(() => {
    const everyParagraph = fullContent.map((item) => item.regions).flat(2);
    const textParagraphs = everyParagraph.filter((paragraph) => paragraph?.paragraphType === 'Text') as TextParagraph[];

    return textParagraphs
      .map((x) => {
        const elements = convertHtmlToReact(x.value) as ReactElement[];
        return getH2Heading(elements);
      })
      .flat();
  }, [fullContent]);

  return (
    <ParagraphConfig config={config}>
      <div className="divide-y border-y">
        {headings?.map((head) => (
          <div key={head?.title} className="px-8 py-4">
            <Link href={`#${head?.id}`}>{head?.title}</Link>
          </div>
        ))}
      </div>
    </ParagraphConfig>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getH2Heading(elements: ReactElement<any, string | JSXElementConstructor<any>>[]) {
  const h2Elements = elements.filter((x) => x.type === 'h2');

  if (!h2Elements) return undefined;

  const titles = h2Elements?.map((element) => extractText(element));

  return titles.map((title) => ({
    title,
    id: convertTitleToId(title),
  }));
}

export function convertTitleToId(title?: string) {
  if (!title) return undefined;
  return title?.replaceAll(' ', '-').toLowerCase();
}

export function extractText(element: ReactElement): string {
  const childNodes = (element as ReactElement)?.props?.children;
  if (typeof childNodes === 'string' && Boolean(childNodes)) return childNodes;

  if (!Array.isArray(childNodes) || childNodes.length === 0) return '';

  const nodes = childNodes.map((node) => (isValidElement(node) ? extractText(node) : node));

  return nodes.join('');
}
