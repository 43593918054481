'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@wla/components/ui/alert';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { useTranslations } from 'next-intl';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import { isValid } from 'zod';
import { reguestResetPassword } from './request-reset-password-actions';
import { RequestResetPasswordFormData, requestResetPasswordSchema } from './request-reset-password-schema';

const initialState = {
  email: '',
  success: false,
  message: '',
};

export function RequestResetPasswordForm() {
  const t = useTranslations();
  const [state, formAction] = useFormState(reguestResetPassword, initialState);

  const form = useForm<RequestResetPasswordFormData>({
    mode: 'onBlur',
    resolver: zodResolver(requestResetPasswordSchema),
  });

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Form form={form} action={formAction} className="grid gap-4">
      <FormGroup
        label={t('forms.email')}
        inputType={InputTypes.Input}
        required
        validationError={errors.email?.message && t('forms.errors.not-valid.email-not-valid')}
      >
        <Input {...register('email')} />
      </FormGroup>
      <SubmitButton disabled={!isValid} loadingMessage={t('forms.submitting')} className="w-full">
        {t('customer.reset-password.reset-password')}
      </SubmitButton>
      {state.success && <Alert type="success">{state.message}</Alert>}
    </Form>
  );
}
