'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from '@wla/components/ui/button/button';
import { Dialog } from '@wla/components/ui/dialog';
import { Icon } from '@wla/components/ui/icon';
import { useTranslations } from 'next-intl';

type OnlineMinimumQuantityModalProps = {
  onlineMinimumQuantity: number;
  productName: string;
  selectedQuantity: number;
  leftQuantity: number; // stock minus already in the basket
  onClick?: (value: number) => void;
};

export const OnlineMinimumQuantityModal = NiceModal.create(
  ({
    onlineMinimumQuantity,
    productName,
    selectedQuantity,
    leftQuantity,
    onClick,
  }: OnlineMinimumQuantityModalProps) => {
    const t = useTranslations('pdp');
    const modal = useModal();

    const nextAllowedQuantity = Math.min(
      Math.ceil((selectedQuantity + 1) / onlineMinimumQuantity) * onlineMinimumQuantity,
      Math.floor(leftQuantity / onlineMinimumQuantity) * onlineMinimumQuantity,
    );

    function handleClose() {
      modal.resolve({ takeMinQuantity: false, quantity: undefined });
      modal.hide();
    }

    return (
      <Dialog open={modal.visible} onClose={handleClose} isModal>
        <Dialog.Panel>
          <div className="relative mb-1 h-5">
            <Dialog.Close />
          </div>
          <Dialog.Header>
            <Dialog.Title>
              <div className="top-8 mb-4 flex justify-center">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
                  <Icon size="20" src="w3-error" className="font-normal" />
                </div>
              </div>
            </Dialog.Title>
          </Dialog.Header>
          <Dialog.Body className="flex flex-col items-center gap-4">
            <div className="flex flex-col items-center gap-2">
              <div className="text-2xl font-semibold">
                {t('quantity-warning-sold-in-pieces', { onlineMinimumQuantity })}
              </div>
              <div className="text-sm">
                {t('quantity-warning-product-is-sold-in-pieces', { onlineMinimumQuantity, productName })}
              </div>
            </div>
            {nextAllowedQuantity !== 0 && (
              <Button
                size="medium"
                onClick={() => {
                  onClick?.(nextAllowedQuantity);
                  modal.resolve({ takeMinQuantity: true, quantity: nextAllowedQuantity });
                  modal.hide();
                }}
              >
                {t('quantity-warning-add-button', { nextAllowedQuantity })}
              </Button>
            )}
          </Dialog.Body>
        </Dialog.Panel>
      </Dialog>
    );
  },
);
