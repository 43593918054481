import { TooltipParagraphAllOfTooltipsOneOfItemPosition } from '@jysk/api-types/drupalApi';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';

type TooltipDirectLinkProps = {
  position: TooltipParagraphAllOfTooltipsOneOfItemPosition;
  url: string;
};

export function TooltipDirectLink({ url, position }: TooltipDirectLinkProps) {
  const x = position.x !== undefined ? Math.round(position.x * 100) : null;
  const y = position.y !== undefined ? Math.round(position.y * 100) : null;
  return (
    <div
      className="z-9 absolute flex h-10 w-10 -translate-x-5 translate-y-2 scale-100 cursor-pointer items-center justify-center rounded-full bg-white bg-opacity-80 shadow-[0_0_0_5px_rgba(255,255,255,.33)] hover:scale-110"
      style={{
        left: `${x}%`,
        top: `${y}%`,
      }}
    >
      <Link className="m-0 h-10 w-10 rounded-full p-3 shadow-[0_0_20px_rgba(0,0,0,0.3)]" href={url}>
        <Icon src="add" size="16" />
      </Link>
    </div>
  );
}
