import { Button, ButtonProps, ButtonVariantProps } from './button';

type DropDownButtonProps = Pick<ButtonVariantProps, 'size'> & ButtonProps & { title: string; className?: string };

export function DropDownButton({ size, title, className, ...rest }: DropDownButtonProps) {
  return (
    <Button
      size={size}
      customIcon="w3-arrow-down"
      variant="secondary"
      {...rest}
      iconPosition="right"
      className={className}
    >
      <span>{title}</span>
    </Button>
  );
}
