import {
  StatusIndicator,
  StatusIndicatorStatus,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { useTranslations } from 'next-intl';

export enum AvailabilityStatus {
  InStock = 'InStock',
  Extended = 'Extended',
  OutOfStock = 'OutOfStockInStores',
}

type FavoriteStoreStockProps = {
  availability: AvailabilityStatus;
};

export function FavoriteStoreStockBasket({ availability }: FavoriteStoreStockProps) {
  const t = useTranslations('favorite-store');

  if (availability === AvailabilityStatus.InStock) return null;

  return (
    <>
      <div className="my-3 border-b" />
      <div className="flex items-start">
        <StatusIndicator
          status={
            availability === AvailabilityStatus.OutOfStock ? StatusIndicatorStatus.Error : StatusIndicatorStatus.Warning
          }
        />
        <div className="pl-2.5 pt-0.5 text-sm">
          {availability === AvailabilityStatus.OutOfStock
            ? t('no-products-are-available')
            : t('some-products-are-unavailable')}
        </div>
      </div>
    </>
  );
}
