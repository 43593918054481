'use client';

import { usePublicConfig } from '@wla/lib/configs';
import { defaultTranslationValues } from '@wla/lib/helpers/default-translation-values';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { ReactNode } from 'react';

type IntlProviderProps = {
  messages: AbstractIntlMessages | undefined;
  children: ReactNode;
};

export default function IntlProvider({ messages, children }: IntlProviderProps) {
  const { defaultLocale } = usePublicConfig();
  return (
    <NextIntlClientProvider
      timeZone="Europe/Copenhagen" //  We are not using timeZones in translations. So for now its hardcoded
      messages={messages}
      locale={defaultLocale}
      defaultTranslationValues={defaultTranslationValues}
    >
      {children}
    </NextIntlClientProvider>
  );
}
