export function scrollToElement(ref: HTMLElement, options?: ScrollIntoViewOptions) {
  ref.scrollIntoView({ behavior: 'smooth', ...options });
}

export function scrollToOffset(offset: number, elm?: Element | null) {
  (elm ?? window).scrollTo({ top: offset, left: 0, behavior: 'smooth' });
}

export function scrollToTop(elm?: Element | null) {
  (elm ?? window).scroll({ top: 0, left: 0, behavior: 'smooth' });
}
