import { Link } from '@wla/components/ui/link';
import { ContactHeader } from '@wla/components/ui/paragraph/paragraph-footer-contact-information/contact-header';
import { ContactLine } from '@wla/components/ui/paragraph/paragraph-footer-contact-information/contact-line';
import { ContactLink } from '@wla/components/ui/paragraph/paragraph-footer-contact-information/contact-link';
import { useTranslations } from 'next-intl';
import { Fragment } from 'react';
import { FooterContactInformationParagraph } from '@jysk/api-types/drupalApi';

type ParagraphFooterContactInformationProps = {
  content: FooterContactInformationParagraph;
};

export function ParagraphFooterContactInformation({ content }: ParagraphFooterContactInformationProps) {
  const t = useTranslations();
  const { b2b, company, customerService } = content;

  return (
    <section className="flex flex-1 flex-col text-left">
      {company && (
        <div className="mb-4">
          {company.address && (
            <ContactLine>
              <div dangerouslySetInnerHTML={{ __html: company.address }} />
            </ContactLine>
          )}
          {company.phone && (
            <ContactLine>
              {t.rich('footer.contact-information.telephone', {
                link: () => <ContactLink href={'tel:' + company.phone}>{company.phone}</ContactLink>,
              })}
            </ContactLine>
          )}
          {company.fax && <ContactLine>{t('footer.contact-information.fax', { fax: company.fax })}</ContactLine>}
          {company.reg && (
            <ContactLine>
              {t('footer.contact-information.company-reg', {
                reg: company.reg,
              })}
            </ContactLine>
          )}
          {company.vatNo && (
            <ContactLine>
              {t('footer.contact-information.vat-no', {
                vat: company.vatNo,
              })}
            </ContactLine>
          )}
        </div>
      )}

      {b2b && (
        <Fragment>
          {(b2b.phone || b2b.email || b2b.contactPageUrl) && (
            <ContactHeader>{t('footer.contact-information.jysk-b2b')}</ContactHeader>
          )}
          <div className="mb-4">
            {b2b.phone && (
              <ContactLine>
                {t.rich('footer.contact-information.telephone', {
                  link: () => <ContactLink href={'tel:' + b2b.phone}>{b2b.phone}</ContactLink>,
                })}
              </ContactLine>
            )}
            {b2b.email && (
              <ContactLine>
                {t.rich('footer.contact-information.email', {
                  link: () => <ContactLink href={'mailto:' + b2b.email}> {b2b.email}</ContactLink>,
                })}
              </ContactLine>
            )}
            {b2b.contactPageUrl && (
              <ContactLink href={b2b.contactPageUrl}>{t('footer.contact-information.contact-us')}</ContactLink>
            )}
          </div>
        </Fragment>
      )}

      {customerService && (
        <Fragment>
          {(customerService.contactPageUrl || customerService.phone) && (
            <ContactHeader>{t('footer.contact-information.jysk-customer-service')}</ContactHeader>
          )}
          <div className="mb-4">
            {customerService.contactPageUrl && (
              <Link className="text-base text-black no-underline" href={customerService.contactPageUrl}>
                {t('common.contact-customer-service')}
              </Link>
            )}
            {customerService.phone && (
              <ContactLine>
                {t.rich('footer.contact-information.telephone', {
                  link: () => <ContactLink href={'tel:' + customerService.phone}>{customerService.phone}</ContactLink>,
                })}
              </ContactLine>
            )}
          </div>
        </Fragment>
      )}
    </section>
  );
}
