'use client';
import { EmbeddedContentType } from '@jysk/api-types/drupalApi';
import { B2bLoginForm } from '@wla/app/(cms)/(customer)/b2b/login/b2b-login-form';
import { LoginForm } from '@wla/app/(cms)/(customer)/customer/login/login-form';
import { Button } from '@wla/components/ui/button/button';
import { EmbeddedContent } from '@wla/components/ui/embedded-content/embedded-content';
import { Tab } from '@wla/components/ui/tabs/tab';
import { Tabs } from '@wla/components/ui/tabs/tabs';
import { TabList } from '@wla/components/ui/tabs/tabs-list';
import { TabPanel } from '@wla/components/ui/tabs/tabs-panel';
import { usePublicConfig } from '@wla/lib/configs';
import { AccountType } from '@wla/types/custom/authentication';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

type LoginTabProps = {
  onSubmitSuccess?: () => void;
  navigateToB2c?: string;
  navigateToB2b?: string;
  defaultTab?: 'B2B' | 'B2C';
  forgotPassword?: boolean;
};

export function LoginTab({
  onSubmitSuccess,
  navigateToB2c,
  navigateToB2b,
  defaultTab = AccountType.B2C,
  forgotPassword,
}: LoginTabProps) {
  const t = useTranslations();
  const [selectedTab, setSelectedTab] = useState<keyof typeof AccountType>(defaultTab);

  const { featureFlags } = usePublicConfig();
  const isB2bLoginEnabled = featureFlags.enableB2BCustomerLoginAndCheckout;

  return (
    <>
      {isB2bLoginEnabled ? (
        <Tabs defaultTab={AccountType.B2C} selectedTab={selectedTab} onTabChange={setSelectedTab}>
          <TabList>
            <Tab value={AccountType.B2C}>{t('customer.login.b2c-customer')}</Tab>
            <Tab value={AccountType.B2B}>{t('customer.login.b2b-customer')}</Tab>
          </TabList>
          <TabPanel value={AccountType.B2C}>
            <div className="my-4">
              <LoginForm
                onSubmitSuccess={onSubmitSuccess}
                navigateToB2c={navigateToB2c}
                forgotPassword={forgotPassword}
              />
            </div>
            <h1 className="text-lg font-semibold">{t('customer.create.create-a-new-account')}</h1>
            <EmbeddedContent contentTypes={EmbeddedContentType.CustomerLoginCreateAccountTextB2C} excludeWrapper />
            <Button as="a" href="/customer/create" variant="secondary">
              {t('common.create-account')}
            </Button>
          </TabPanel>
          <TabPanel value={AccountType.B2B}>
            <B2bLoginForm onSubmitSuccess={onSubmitSuccess} navigateToB2b={navigateToB2b} />
          </TabPanel>
        </Tabs>
      ) : (
        <>
          <LoginForm onSubmitSuccess={onSubmitSuccess} navigateToB2c={navigateToB2c} />
          <h1 className="text-lg font-semibold">{t('customer.create.create-a-new-account')}</h1>
          <EmbeddedContent contentTypes={EmbeddedContentType.CustomerLoginCreateAccountTextB2C} excludeWrapper />
          <Button as="a" href="/customer/create" variant="secondary">
            {t('common.create-account')}
          </Button>
        </>
      )}
    </>
  );
}
