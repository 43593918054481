import { cn } from '@wla/lib/helpers/cn';
import { ProductLabelsItem } from '@jysk/api-types/drupalApi';

export type ProductStickerSize = 'small' | 'large' | 'xl';

type ProductStickerProps = {
  text: string;
  sticker: ProductLabelsItem;
  className?: string;
  size?: ProductStickerSize;
  isPdp?: boolean;
};

// Determine which StickerVariants should be rendered with black text
function getStickerTextColor(variant: ProductLabelsItem) {
  return ['completed', 'cancelled'].includes(variant) ? 'text-black' : 'text-white';
}

function getQualityStickerColor(variant: ProductLabelsItem) {
  switch (variant) {
    case 'gold':
      return 'text-yellow-300';
    case 'plus':
      return 'text-black';
    case 'basic':
      return 'text-blue-500';
    default:
      return 'text-white';
  }
}

// Map StickerVariant to Tailwind class
const StickerColorMap: Record<ProductLabelsItem, string> = {
  basic: 'bg-basic',
  campaign: 'bg-campaign',
  coupon: 'bg-coupon',
  discount: 'bg-discount',
  everyDayLowPrice: 'bg-everyDayLowPrice',
  gold: 'bg-gold',
  greatOffer: 'bg-greatOffer',
  new: 'bg-new',
  plus: 'bg-plus',
  specialSale: 'bg-specialSale',
  whileStockLasts: 'bg-whileStockLasts',
  blackFriday: 'bg-blackFriday',
};

export function ProductSticker({ sticker, text, size = 'small', isPdp = false, className }: ProductStickerProps) {
  return (
    <span
      className={cn(
        `inline-flex h-[1.1875rem] w-fit items-center justify-center rounded-sm px-1 py-0.5 text-xs font-semibold`,
        { 'h-[1.625rem] px-1.5 py-1 text-sm': size === 'large' },
        { 'h-[1.625rem] rounded-[0.171rem] px-2 py-1 text-xl font-extrabold': size === 'xl' },
        { 'md:hidden': sticker.includes(ProductLabelsItem.discount) && isPdp },
        className,
        getStickerTextColor(sticker),
        StickerColorMap[sticker],
        getQualityStickerColor(sticker),
      )}
    >
      {text}
    </span>
  );
}
