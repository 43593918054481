'use client';
import { Link } from '@wla/components/ui/link';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { JyskLogo } from '../icons/jysk-logo-icon';

export function Home() {
  const { b2bCaption } = usePublicConfig();
  const { isB2B } = useCustomerSession();

  return (
    <div className="flex min-w-max pl-0">
      <Link href="/" title="Home">
        <figure className="w-20 xl:w-[97px]">
          <JyskLogo />
          {isB2B && (
            <figcaption
              translate="no"
              className={cn('center h-0 whitespace-nowrap text-center text-[7px] font-bold uppercase xl:text-[8.4px]', {
                'lg:mt-0': isB2B,
              })}
            >
              {b2bCaption}
            </figcaption>
          )}
        </figure>
      </Link>
    </div>
  );
}
