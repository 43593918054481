import { ReactNode, createContext, useContext } from 'react';

type TabsContextProps<T> = {
  selectedTab: T;
  selectTab: (value: T) => void;
};

const TabsContext = createContext<TabsContextProps<any> | undefined>(undefined);

export const useTabsContext = <T,>(): TabsContextProps<T> => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabsContext must be used within a TabsProvider');
  }
  return context;
};

type TabsProviderProps<T> = {
  children: ReactNode;
  defaultTab: T;
  selectedTab: T;
  onTabChange: (value: T) => void;
};

export function TabsProvider({ children, defaultTab, selectedTab, onTabChange }: TabsProviderProps<any>) {
  function selectTab(value: typeof defaultTab) {
    onTabChange(value);
  }

  return <TabsContext.Provider value={{ selectedTab, selectTab }}>{children}</TabsContext.Provider>;
}
