'use client';

import { SearchProductListing } from '@wla/app/(cms)/search/search-product-listing';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { DEFAULT_SEARCH_QUERY } from '@wla/lib/constants';
import type { ProductListWithFiltersParagraph } from '@jysk/api-types/drupalApi';
import { PostSearchBodyStaticFiltersItem } from '@jysk/api-types/searchApi/model';

type ParagraphProductListWithFiltersProps = {
  content: ProductListWithFiltersParagraph;
};

function convertFilterDataStructure(filter: ProductListWithFiltersParagraph): PostSearchBodyStaticFiltersItem[] {
  return Object.entries(filter).reduce<PostSearchBodyStaticFiltersItem[]>((acc, [key, value]) => {
    if (value === null || key === 'paragraphType' || key === 'config') {
      return acc;
    }
    if (key === 'brands' || key === 'categories' || key === 'statusCode' || key === 'productSkus') {
      acc.push({
        name: key,
        values: value as string[],
      });
    } else if (key === 'price') {
      const { min, max } = value as PostSearchBodyStaticFiltersItem;
      acc.push({
        name: key,
        min: Number(min) || 0,
        max: Number(max) || 0,
      });
    } else {
      acc.push({
        name: key,
      });
    }

    return acc;
  }, []);
}

export function ParagraphProductListWithFilters({ content }: ParagraphProductListWithFiltersProps) {
  const { config } = content;
  const staticFilters = convertFilterDataStructure(content);
  const searchQuery = staticFilters.length === 0 ? DEFAULT_SEARCH_QUERY : '';

  return (
    <ParagraphConfig config={config}>
      {
        <SearchProductListing
          query={searchQuery}
          initialLoadOccurred={true}
          showProductListLoading={true}
          products={[]}
          filters={{}}
          staticFilters={staticFilters}
        />
      }
    </ParagraphConfig>
  );
}
