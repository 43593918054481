import { FilterAxises } from '@jysk/api-types/wssFilterAxisApi/model';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { filterActions, handleChangeFilter } from '@wla/lib/helpers/filter-change-handler';
import { formatFiltersToObject } from './filters-helpers';

type FilterSectionTypeProps = {
  filterAxises: FilterAxises;
  id: string;
  caption?: string;
  checked?: boolean;
  queryFilters: string | null;
  pathname: string;
  searchParams: URLSearchParams;
};

export function FilterTypeBool({ filterAxises, id, pathname, searchParams, queryFilters }: FilterSectionTypeProps) {
  const formattedFilters = formatFiltersToObject(queryFilters || '');

  return filterAxises.filteraxisitems?.map(
    (filteraxisitem) =>
      !filteraxisitem.missing && (
        <Checkbox
          id={filteraxisitem.handle}
          key={filteraxisitem.value}
          name={filteraxisitem.handle}
          value={filteraxisitem.value}
          checked={formattedFilters?.[id]?.includes(filteraxisitem.value) || false}
          className="flex cursor-pointer flex-row-reverse justify-between py-2"
          size="lg"
          disabled={!filteraxisitem.countRemaining && !formattedFilters?.[id]?.includes(filteraxisitem.value)}
          onCheckedChange={() =>
            handleChangeFilter(
              {
                id,
                value: filteraxisitem.value,
                handle: formattedFilters?.[id]?.includes(filteraxisitem.value)
                  ? filterActions.REMOVE
                  : filterActions.ADD,
                caption: filteraxisitem.caption,
              },
              { pathname, searchParams, queryFilters },
            )
          }
        >
          <span className="mr-auto">{filteraxisitem.caption}</span>
          <span className="float-right pr-3 text-sm">{filteraxisitem.countRemaining}</span>
        </Checkbox>
      ),
  );
}
