import type { TableParagraph } from '@jysk/api-types/drupalApi';
import { TableRow } from './paragraph-table-row';

type ParagraphTableProps = {
  content: TableParagraph;
};

export function ParagraphTable({ content }: ParagraphTableProps) {
  const { rows, firstColumnSticky, alternateRowColors } = content;

  return (
    <div className="relative overflow-x-auto scroll-smooth border-l border-t border-gray-300">
      <table className="w-full">
        <tbody>
          {rows?.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              row={row}
              firstColumnSticky={firstColumnSticky}
              isOddRow={alternateRowColors && rowIndex % 2 === 0}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
