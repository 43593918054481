type ClusterMarkerProps = {
  count: number;
};

export function ClusterMarker({ count }: ClusterMarkerProps) {
  return (
    <div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-blue-500 text-xs font-medium text-white">
      {count}
    </div>
  );
}
