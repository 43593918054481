import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import { useEffect, useState } from 'react';

export function useChatAvailability() {
  const hasConsent = useCookieConsent(CookieTypesEnum.FUNCTIONAL);
  const [isChatAvailable, setIsChatAvailable] = useState(false);

  useEffect(() => {
    if (!hasConsent || typeof window === 'undefined') {
      setIsChatAvailable(false);
      return;
    }

    const checkLiveAgentInitialization = setInterval(() => {
      if (window.embedded_svc && window.embedded_svc.settings) {
        clearInterval(checkLiveAgentInitialization);

        setIsChatAvailable(window.embedded_svc.settings.agentAvailableOnButtonClick);

        const settings = window.embedded_svc.settings;
        const proxy = new Proxy(settings, {
          set(target, prop, value) {
            if (prop === 'agentAvailableOnButtonClick') {
              setIsChatAvailable(value);
            }
            target[prop] = value;
            return true;
          },
        });
        window.embedded_svc.settings = proxy;
      }
    }, 100);

    return () => clearInterval(checkLiveAgentInitialization);
  }, [hasConsent]);

  return isChatAvailable;
}
