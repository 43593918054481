import { ProductTeaser } from '@jysk/api-types/drupalApi';
import { FilterAxises, GetFilterAxis200 } from '@jysk/api-types/wssFilterAxisApi/model';
import { FilterDrawer } from '@wla/app/(cms)/[...slug]/(filters)/filter-drawer';
import { findPromotionAxis } from '@wla/app/(cms)/[...slug]/(filters)/filters-plp';
import { FilterProductCount } from '@wla/app/(cms)/[...slug]/(product-listing-page)/filter-product-count';
import { ProductListSorting, SortOptions } from '@wla/app/(cms)/[...slug]/(product-listing-page)/product-list-sorting';
import { AllFiltersButton } from '@wla/components/ui/button/all-filters-button';
import { DropDownButton } from '@wla/components/ui/button/dropdown-button';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

type FilterBarProps = {
  filters: GetFilterAxis200 | null;
  groupedProducts: Map<string, ProductTeaser[]>;
  onSortChange: (sort: SortOptions) => void;
  isLoading?: boolean;
};

export function FilterBar({ filters, groupedProducts, onSortChange, isLoading }: FilterBarProps) {
  const t = useTranslations();
  const [toggleFilterDrawer, setToggleFilterDrawer] = useState(false);
  const [preOpenFilter, setPreOpenFilter] = useState<FilterAxises | null>(null);

  const productCount = Array.from(groupedProducts).reduce((acc, [_, products]) => acc + products.length, 0);
  const { promotedFilter, filterOptions } = findPromotionAxis(filters);

  function handleFilterDrawerToggle(filter?: FilterAxises) {
    const filterButtonsElement = document.getElementById('filter-buttons');
    const filterProductCountElement = document.getElementById('filter-product-count');
    const selectedFiltersElement = document.getElementById('selected-filters');
    if (filterButtonsElement && filterProductCountElement && selectedFiltersElement) {
      const top =
        selectedFiltersElement.offsetTop - filterButtonsElement?.offsetHeight - filterProductCountElement.offsetHeight;
      window.scroll({ top, behavior: 'smooth' });
    }
    setPreOpenFilter(filter || null);
    setToggleFilterDrawer(!toggleFilterDrawer);
  }

  return (
    <>
      <FilterDrawer
        toggleFilterDrawer={toggleFilterDrawer}
        onFilterDrawerToggle={handleFilterDrawerToggle}
        filters={filters}
        preOpenFilter={preOpenFilter}
        isLoading={isLoading}
        showCount
      />
      <div
        className="sticky -top-[1px] z-20 justify-between bg-white py-4 md:mt-11 lg:flex lg:flex-row"
        id="filter-buttons"
      >
        <div className="flex items-center gap-2.5">
          {filterOptions
            .slice(0, 3)
            ?.map((filter) => (
              <DropDownButton
                key={filter.caption}
                onClick={() => handleFilterDrawerToggle(filter)}
                aria-label={`Filter - ${filter.caption}`}
                aria-haspopup="listbox"
                className="hidden h-14 rounded border-gray-300 py-2.5 pl-4.5 pr-5 md:inline-flex"
                title={filter.caption}
              />
            ))}
          <div className="flex w-full justify-end gap-3 md:w-auto md:flex-row-reverse md:gap-2.5">
            <AllFiltersButton
              title={t('plp.all-filters')}
              aria-label={`Filter - ${t('plp.all-filters')}`}
              aria-haspopup="listbox"
              className="h-14 w-full whitespace-nowrap rounded border-gray-300 py-2.5 pl-4.5 pr-5 lg:w-auto"
              onClick={() => handleFilterDrawerToggle()}
            />
            <ProductListSorting onSortChange={onSortChange} />
          </div>
        </div>
        <FilterProductCount
          productCount={productCount}
          promotedFilter={promotedFilter}
          className="my-4 hidden lg:flex"
        />
      </div>
      <FilterProductCount
        productCount={productCount}
        promotedFilter={promotedFilter}
        className="flex pb-5 lg:hidden"
        id="filter-product-count"
      />
    </>
  );
}
