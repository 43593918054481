import { RatingCount } from '@jysk/api-types/wssReviewsApi/model';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { useState } from 'react';

type RatingListProps = {
  summary?: RatingCount;
  onRatingClick: (rating: number | null) => void;
};

type RatingSummaryKey = keyof RatingCount;

export function RatingList({ summary, onRatingClick }: RatingListProps) {
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  if (!summary) {
    return null;
  }

  const availableKeys: RatingSummaryKey[] = ['1', '2', '3', '4', '5'];
  const totalRatings = availableKeys.reduce((p, c) => p + (summary[c as RatingSummaryKey] ?? 0), 0);

  function handleRatingClick(rating: number) {
    if (selectedRating === rating) {
      // If the same rating is clicked, unselect and show all reviews
      setSelectedRating(null);
      onRatingClick(null);
    } else {
      setSelectedRating(rating);
      onRatingClick(rating);
    }
  }

  return (
    <div className="flex flex-col-reverse gap-1">
      {availableKeys.map((key, index) => {
        const keySummary = summary[key as RatingSummaryKey];
        if (keySummary === undefined) {
          return null;
        }

        const isSelected = selectedRating === index + 1;

        return (
          <div
            className={cn('flex cursor-pointer flex-row items-center gap-2 rounded p-2', {
              'bg-gray-100': isSelected,
            })}
            key={`rating-summary-${key}`}
            onClick={() => handleRatingClick(index + 1)}
          >
            <Icon src="w3-star-filled" className="text-black" />
            <p>{index + 1}</p>
            <div className="relative mx-1 flex flex-grow items-center">
              <div className="absolute flex h-2 w-full rounded bg-gray-200" />
              <div
                className="absolute flex h-2 rounded bg-brand-500"
                style={{ width: (100 / totalRatings) * keySummary + '%' }}
              />
            </div>
            <p className="min-w-5 text-center">{keySummary}</p>
          </div>
        );
      })}
    </div>
  );
}
