'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ProductFullAllOfRelationsOneOfComplementary, ProductTeaser } from '@jysk/api-types/drupalApi';
import { CarrierOption, OrderLine } from '@jysk/api-types/orderManagementApi/model';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';
import { Button } from '@wla/components/ui/button/button';
import { Drawer } from '@wla/components/ui/drawer';
import { Icon } from '@wla/components/ui/icon';
import { AddedRecommendedProduct, ProductCard } from '@wla/components/ui/product-card/product-card';
import { Spinner } from '@wla/components/ui/spinner';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { OrderType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { CarrierSavings } from './carrier-savings';
import { FreeFreight } from './free-freight';
import { ProductDetails } from './product-details';

export type PowerStepDrawerProps = {
  quantity: number;
  totalInBasket: number;
  orderType: OrderType;
  totalPriceAmount: number;
  totalPriceText?: string;
  line?: OrderLine;
  imageSrc?: string | null;
  relations?: ProductFullAllOfRelationsOneOfComplementary;
  carriers?: CarrierOption[];
  onAddToBasket?: (addedRecommendedProduct: Omit<AddedRecommendedProduct, 'success'>) => void;
  addedRecommendedProduct?: AddedRecommendedProduct;
};

export const PowerStepDrawer = NiceModal.create(
  ({
    quantity,
    totalInBasket,
    orderType,
    totalPriceAmount,
    totalPriceText,
    line,
    imageSrc,
    relations,
    carriers,
    onAddToBasket,
    addedRecommendedProduct,
  }: PowerStepDrawerProps) => {
    const t = useTranslations();
    const modal = useModal();
    const router = useRouter();
    const { fetchOnlineWithStoreStatusAtp } = useAtp();
    const [recommendedProducts, setRecommendedProducts] = useState<ProductTeaser[]>([]);
    const [productsAtp, setProductsAtp] = useState<OnlineAtpMap | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const isGiftCard = orderType === OrderType.GiftCard;
    const isOnlineSales = orderType === OrderType.OnlineSales;
    const { favoriteStore } = useFavoriteStore();

    function handleCheckOut() {
      router.push('/checkout');
      modal.hide();
    }

    async function getRecommendedProducts() {
      setIsLoading(true);
      const response = await fetch('/api/get-recommended-products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ relations, orderType, storeId: favoriteStore?.id }),
      });
      const data = await response.json();

      const recommendedProductsIds = data.recommendedProducts.map((product: ProductTeaser) => product.id);
      const stockData = await fetchOnlineWithStoreStatusAtp(recommendedProductsIds);

      setProductsAtp(stockData);
      setRecommendedProducts(data.recommendedProducts);
      setIsLoading(false);
    }

    useEffect(() => {
      getRecommendedProducts();
    }, [relations, orderType, favoriteStore?.id]);

    return (
      <Drawer open={modal.visible} onClose={modal.hide}>
        <Drawer.Header
          title={t('pdp.power-step.product-added-to-basket')}
          headerIcon="w3-success"
          iconClassName="text-green-500"
        />
        <Drawer.Body className="mt-0">
          {!isGiftCard && (
            <Drawer.BodySection border className="flex items-center gap-2.5">
              <Icon src={isOnlineSales ? 'w3-delivery' : 'w3-click-and-collect'} size="24" />
              {isOnlineSales ? (
                <p className="font-bold">{t('common.delivery')}</p>
              ) : (
                <div>
                  <p className="font-bold">
                    {t('pdp.power-step.click-collect-at-jysk-store', {
                      shop: favoriteStore?.name,
                    })}
                  </p>
                  <p className="text-sm text-gray-500">{t('pdp.pick-up-in-30-min')}</p>
                </div>
              )}
            </Drawer.BodySection>
          )}
          <ProductDetails
            isGiftCard={isGiftCard}
            quantity={quantity}
            totalInBasket={totalInBasket}
            totalPriceText={totalPriceText}
            line={line}
            imageSrc={imageSrc}
          />
          {isOnlineSales && <CarrierSavings carriers={carriers} />}
          {isOnlineSales && <FreeFreight totalPriceAmount={totalPriceAmount} carriers={carriers} />}
          <Drawer.BodySection>
            <div className="mb-4 flex flex-col gap-3">
              <Button data-testid="checkout-button" onClick={handleCheckOut} variant="primary">
                {t('common.checkout')}
              </Button>
              <Button onClick={modal.hide} variant="secondary">
                {t('common.continue-shopping')}
              </Button>
            </div>
            {isLoading ? (
              <div className="mt-6 flex justify-center">
                <Spinner />
              </div>
            ) : (
              recommendedProducts.length > 0 && (
                <>
                  <h2 className="mb-4 text-2xl font-medium">{t('pdp.remember-accessories')}</h2>
                  <div className="grid grid-cols-2 gap-x-3.5 gap-y-8">
                    {recommendedProducts?.map((product) => (
                      <ProductCard
                        key={product.id}
                        product={product}
                        refId="UpsellingCaroussel"
                        onClick={modal.hide}
                        showAddToBasketBtn
                        onAddToBasket={onAddToBasket}
                        addedRecommendedProduct={addedRecommendedProduct}
                        onProductCardClick={modal.hide}
                        stockStatus={productsAtp?.[product.id]}
                        isCompact
                      />
                    ))}
                  </div>
                </>
              )
            )}
          </Drawer.BodySection>
        </Drawer.Body>
      </Drawer>
    );
  },
);
