type MenuLinksProps = {
  items: {
    title: string;
    url: string;
    target?: string | null;
  }[];
};

export function MenuLinks({ items }: MenuLinksProps) {
  return (
    <div className="flex w-full justify-center overflow-x-auto before:absolute before:left-3.5 before:h-6 before:w-5 before:bg-gradient-to-r before:from-gray-100 after:absolute after:right-3.5 after:h-6 after:w-5 after:bg-gradient-to-l after:from-gray-100">
      {items.map((item, i) => (
        // Using https://www.toptal.com/designers/htmlarrows/punctuation/bullet/ to make bullet points
        // eslint-disable-next-line no-octal-escape
        <div key={i} className="whitespace-nowrap before:px-2.5 [&:not(:first-child)]:before:content-['\2022']">
          <a className="pt-1 text-lg font-bold" href={item.url} {...(item.target ? { target: item.target } : {})}>
            {item.title}
          </a>
        </div>
      ))}
    </div>
  );
}
