'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { LoginTab } from '@wla/app/(cms)/(customer)/customer/login-tab';
import { Drawer } from '@wla/components/ui/drawer';
import { useTranslations } from 'next-intl';

type LoginNavigationDrawerProps = {
  defaultTab?: 'B2B' | 'B2C';
  forgotPassword?: boolean;
  isCheckout?: boolean;
};

export const LoginNavigationDrawer = NiceModal.create(
  ({ defaultTab, forgotPassword, isCheckout }: LoginNavigationDrawerProps) => {
    const t = useTranslations('forms');
    const modal = useModal();

    return (
      <Drawer open={modal.visible} onClose={modal.hide} position="right">
        <div>
          <Drawer.Header title={t('login')} className="mb-10 justify-between" />
          <Drawer.Body className="mt-0">
            <LoginTab
              onSubmitSuccess={modal.hide}
              defaultTab={defaultTab}
              forgotPassword={forgotPassword}
              navigateToB2b={!isCheckout ? '/b2b/update' : undefined}
            />
          </Drawer.Body>
        </div>
      </Drawer>
    );
  },
);
