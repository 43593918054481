import { Button } from '@wla/components/ui/button/button';
import { filterActions, handleChangeFilter } from '@wla/lib/helpers/filter-change-handler';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';

type ResetFiltersButtonProps = {
  className?: string;
};

export function ResetFiltersButton({ className }: ResetFiltersButtonProps) {
  const t = useTranslations('plp');
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryFilters = searchParams.get('filters');
  const hasSelectedFilters = Boolean(queryFilters);

  return (
    <Button
      onClick={() =>
        handleChangeFilter(
          { id: '', value: '', handle: filterActions.RESET },
          { pathname, searchParams, queryFilters: searchParams.get('filters') },
        )
      }
      disabled={!hasSelectedFilters}
      variant="secondary"
      className={className}
    >
      {t('clear-all')}
    </Button>
  );
}
