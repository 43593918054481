import { Button, ButtonProps, ButtonVariantProps } from './button';

type AllFiltersButtonProps = Pick<ButtonVariantProps, 'size'> & ButtonProps & { title: string; className?: string };

export function AllFiltersButton({ size, title, className, ...rest }: AllFiltersButtonProps) {
  return (
    <Button size={size} variant="secondary" customIcon="w3-filter" {...rest} iconPosition="left" className={className}>
      <span>{title}</span>
    </Button>
  );
}
