import Image from '@wla/components/ui/image';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';

//TODO: Modify image to ImageType when drupal team have implemented the new data contract
type ContentCardProps = {
  href: string;
  title: string;
  image?: {
    url: string;
    width?: number;
    height?: number;
    className?: string;
  };
};

export function ContentCard({ href, title, image }: ContentCardProps) {
  return (
    <Link href={href} className="group">
      <div className="flex flex-col gap-2 self-center lg:gap-4">
        <div className="flex h-28 w-full self-center bg-gray-100 md:h-auto md:max-h-52 md:max-w-80">
          {image && (
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
              alt={title}
              title={title}
              className={cn(
                'object-contain p-2.5 mix-blend-darken transition-transform sm:group-hover:scale-110',
                image.className,
              )}
              priority
              data-org={image.url}
            />
          )}
        </div>
        <h3 className="text-base font-semibold md:text-2xl">{title}</h3>
      </div>
    </Link>
  );
}
