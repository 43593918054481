import { StatusIndicator } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { StockStatus } from '@wla/lib/hooks/product-atp/types';

type ProductCardStockStatusProps = {
  onlineStockStatus: StockStatus;
  storeStockStatus: StockStatus;
  showOnlineStock: boolean;
  showStoreStock: boolean;
  isLoading?: boolean;
};

export function ProductCardStockStatus({
  onlineStockStatus,
  storeStockStatus,
  showOnlineStock,
  showStoreStock,
  isLoading,
}: ProductCardStockStatusProps) {
  return (
    <>
      {isLoading && (
        <div className="flex animate-skeleton flex-col gap-2">
          {Array.from({ length: 2 }).map((_, i) => (
            <div key={i} className="flex items-start gap-1.5 md:gap-x-2.5">
              <div className="h-3 w-3 rounded-full bg-gray-200" />
              <div className="h-3.5 w-full rounded bg-gray-200" />
            </div>
          ))}
        </div>
      )}

      {!isLoading && (
        <div className="flex flex-col gap-0.5 md:gap-0">
          <StatusIndicator
            showStatusAndText={showOnlineStock}
            status={onlineStockStatus.status}
            className="gap-x-1 md:gap-x-2"
            isProductCard
          >
            <StatusIndicator.Text className="text-xs md:text-sm">{onlineStockStatus.text}</StatusIndicator.Text>
          </StatusIndicator>

          <StatusIndicator
            showStatusAndText={showStoreStock}
            status={storeStockStatus.status}
            className="gap-x-1 md:gap-x-2"
            isProductCard
          >
            <StatusIndicator.Text className="text-xs md:text-sm">{storeStockStatus.text}</StatusIndicator.Text>
          </StatusIndicator>
        </div>
      )}
    </>
  );
}
