import { NiceModalHandler } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@wla/components/ui/forms/form';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { ZipCodeDrawerType } from '@wla/lib/configs/schemas/da-DK/checkout-schema';
import { FormFieldsGenerator, FormSchema } from '@wla/lib/forms/formfields-generator';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ZodType } from 'zod';

type ZipCodeDrawerFormProps = {
  modal: NiceModalHandler<Record<string, unknown>>;
  schema: ZodType;
  fields: FormSchema<ZipCodeDrawerType>;
};

export function ZipcodeDrawerForm({ modal, schema, fields }: ZipCodeDrawerFormProps) {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { orders, updateAreacode } = useShoppingSession();

  async function onSubmit(data: ZipCodeDrawerType) {
    setIsLoading(true);
    await updateAreacode({ areaCode: data.zipCode });
    setIsLoading(false);
    modal.hide();
  }

  const form = useForm<ZipCodeDrawerType>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: {
      zipCode: orders?.length > 0 ? orders[0].zipCode : undefined,
    },
  });

  return (
    <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
      <div className="mb-4">{t('checkout.areacode-drawer.subtitle')}</div>
      <div className="flex w-full gap-2.5">
        <FormFieldsGenerator form={form} fieldslist={fields} />
        <SubmitButton loadingMessage={t('common.loading')} loading={isLoading}>
          {t('common.save')}
        </SubmitButton>
      </div>
    </Form>
  );
}
