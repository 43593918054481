import { useTabsContext } from '@wla/components/ui/tabs/tabs-context';
import { ReactNode } from 'react';

type TabPanelProps<T> = {
  value: T;
  children: ReactNode;
};

export function TabPanel({ value, children }: TabPanelProps<any>) {
  const { selectedTab } = useTabsContext<typeof value>();

  if (selectedTab !== value) return null;

  return <div>{children}</div>;
}
