'use client';

import { ContentLayout } from '@wla/components/ui/content-layout';
import { useEffect, useState } from 'react';
import { EmbeddedContentFull, EmbeddedContentType } from '@jysk/api-types/drupalApi';

type EmbeddedContentProps = {
  contentTypes: EmbeddedContentType[] | EmbeddedContentType;
  excludeWrapper?: boolean;
};

export function EmbeddedContent({ contentTypes, excludeWrapper = false }: EmbeddedContentProps) {
  const [embeddedContents, setEmbeddedContents] = useState<EmbeddedContentFull[]>([]);

  useEffect(() => {
    async function getEmbeddedContent() {
      const response = await fetch(`/content/get-embedded-content?types=${contentTypes}`);
      const { embeddedContent } = await response.json();
      setEmbeddedContents(embeddedContent);
    }

    getEmbeddedContent();
  }, []);

  return embeddedContents?.map((embeddedContent, index) => (
    <ContentLayout
      key={`${embeddedContent.type}-${index}`}
      content={embeddedContent.content}
      excludeWrapper={excludeWrapper}
      type={embeddedContent.type}
    />
  ));
}
