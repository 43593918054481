import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { OnlineAtpMap, StoreArticleAtp } from '@jysk/api-types/webSapApiV2/model';

export function useAtp() {
  const { favoriteStore } = useFavoriteStore();

  async function fetchStoresAtp(productId: string) {
    try {
      const response = await fetch('/api/get-store-atp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ article: productId }),
      });

      const data = (await response.json()) as {
        totalNumberStoresWithAtp: number;
        totalNumberStores: number;
        status: number;
        [key: string]: any;
      };

      const storeKeys = Object.keys(data).filter(
        (key) => !['totalNumberStoresWithAtp', 'totalNumberStores', 'status'].includes(key),
      );
      const atLeastOneOnDisplay = storeKeys.some((storeKey) => (data[storeKey] as StoreArticleAtp).onDisplay);

      return {
        totalNumberStoresWithAtp: data.totalNumberStoresWithAtp,
        totalNumberStores: data.totalNumberStores,
        selectedStoreUnit: !favoriteStore ? 0 : data[favoriteStore.id]?.units ?? 0,
        atLeastOneOnDisplay,
      };
    } catch (error) {
      trackError(error, { productId });
      return null;
    }
  }

  async function fetchOnlineAtp(productId: string, salesOrganisation: string) {
    try {
      const response = await fetch('/api/get-online-atp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ article: productId, sorg: salesOrganisation }),
      });

      const json = (await response.json()).response as Record<
        string,
        {
          nextAtpDate: string;
          qty: number;
          nextAtpQty: number;
          nextAtpDC: string;
        }
      >;

      return json[productId];
    } catch (error) {
      trackError(error, { productId, salesOrganisation });
      return null;
    }
  }

  async function fetchOnlineWithStoreStatusAtp(articles: string[]) {
    if (!articles.length) {
      return null;
    }
    try {
      const response = await fetch('/api/get-online-with-store-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ articles, storeId: favoriteStore?.id }),
      });

      const json = (await response.json()).response as OnlineAtpMap;
      return json;
    } catch (error) {
      trackError(error, { articles, storeId: favoriteStore?.id });
      return null;
    }
  }

  return { fetchStoresAtp, fetchOnlineAtp, fetchOnlineWithStoreStatusAtp };
}
