import { z } from 'zod';

export const ParagraphContactFormB2BContactSchema = z.object({
  contact: z
    .string()
    .min(1, 'forms.errors.required.contact-is-required')
    .max(300, 'forms.errors.required.contact-is-required'),
  companyName: z
    .string()
    .min(1, 'forms.errors.company-name-is-required')
    .max(300, 'forms.errors.company-name-is-required'),
  firstName: z
    .string()
    .min(1, 'forms.errors.required.contact-is-required')
    .max(300, 'forms.errors.required.contact-is-required'),
  lastName: z
    .string()
    .min(1, 'forms.errors.required.contact-is-required')
    .max(300, 'forms.errors.required.contact-is-required'),
  email: z.string().email('forms.errors.not-valid.email-not-valid'),
  phoneNumber: z
    .string()
    .regex(new RegExp('^(|\\d{8,20})$'), 'forms.errors.not-valid.phone-number-not-valid')
    .optional(),
  b2bCustomerNumber: z.string().optional(),
  message: z
    .string()
    .min(1, 'forms.errors.required.message-is-required')
    .max(1000, 'forms.errors.required.message-is-required'),
});

export type ParagraphContactFormB2BContactData = z.infer<typeof ParagraphContactFormB2BContactSchema>;
