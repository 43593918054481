import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

export enum StatusIndicatorStatus {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type StatusIndicatorProps = {
  status: StatusIndicatorStatus;
  title?: string;
  className?: string;
  isProductCard?: boolean;
  children?: ReactNode;
  showStatusAndText?: boolean;
  isLoading?: boolean;
};

export function StatusIndicator({
  status,
  title,
  className,
  isProductCard = false,
  children,
  showStatusAndText = true,
  isLoading,
}: StatusIndicatorProps) {
  const t = useTranslations();
  return (
    <>
      {title && <div className="font-bold">{title}</div>}
      {showStatusAndText && (
        <div className={cn('flex items-start gap-x-2.5', className)}>
          {isLoading && (
            <div className={cn('animate-skeleton', { 'pt-1.5': !isProductCard, 'pt-0.5 md:pt-1': isProductCard })}>
              <div className="h-3 w-3 rounded-full bg-gray-200" />
            </div>
          )}
          {!isLoading && (
            <div className={cn({ 'pt-1.5': !isProductCard, 'pt-0.5 md:pt-1': isProductCard })}>
              <div
                aria-label={cn(
                  status === StatusIndicatorStatus.Error && t('pdp.product-atp.online-messages.not-available'),
                  status === StatusIndicatorStatus.Warning &&
                    `${t('pdp.product-atp.online-messages.few-left')} / ${t('pdp.product-atp.online-messages.temporary-out-of-stock')}`,
                  status === StatusIndicatorStatus.Success && t('common.in-stock'),
                )}
                role="status"
                className={cn('h-2.5 w-2.5 rounded-full', {
                  'bg-red-600': status === StatusIndicatorStatus.Error,
                  'bg-yellow-400': status === StatusIndicatorStatus.Warning,
                  'bg-green-600': status === StatusIndicatorStatus.Success,
                })}
              />
            </div>
          )}
          {children}
        </div>
      )}
    </>
  );
}

type StatusIndicatorTextProps = {
  className?: string;
  children: ReactNode;
};

function StatusIndicatorText({ className, children }: StatusIndicatorTextProps) {
  return <div className={className}>{children}</div>;
}

type StatusIndicatorNotificationProps = {
  children: string;
};

function StatusIndicatorSignup({ children }: StatusIndicatorNotificationProps) {
  return <input defaultValue={children} />;
}

StatusIndicator.Text = StatusIndicatorText;
StatusIndicator.NotificationSignup = StatusIndicatorSignup;
