import { ImageMedia, ProductCategoryTeaserImage } from '@jysk/api-types/drupalApi';
import Image from '@wla/components/ui/image';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';

type ContentCardRoundedProps = {
  href: string;
  title: string;
  className?: string;
  isSelected?: boolean;
  image?: (ImageMedia & { className?: string }) | ProductCategoryTeaserImage;
};

export function ContentCardRounded({ href, title, image, className, isSelected }: ContentCardRoundedProps) {
  return (
    <>
      <Link href={href} className={cn('mt-2 flex flex-col items-center justify-start no-underline', className)}>
        <div
          className={cn(
            'flex h-20 w-20 flex-nowrap items-center justify-center rounded-full bg-gray-100 md:h-[100px] md:w-[100px]',
            {
              'shadow-[inset_0_0_0_3px_var(--shadow-white),_0_0_0_1px_var(--shadow-blue-500)]': isSelected,
            },
          )}
        >
          {image && (
            <Image
              src={image.url}
              width="100"
              height="100"
              alt={`Category - ${title}`}
              title={title}
              className="h-[64%] w-[64%] object-contain mix-blend-darken"
            />
          )}
        </div>
        <h3
          className={cn(
            'mt-2 w-full max-w-48 overflow-hidden break-all text-center text-sm text-black hover:underline md:text-base',
            {
              'font-semibold': isSelected,
            },
          )}
        >
          {title}
        </h3>
      </Link>
    </>
  );
}
