'use client';
import { Review as ReviewType } from '@jysk/api-types/wssReviewsApi/model';
import { Button } from '@wla/components/ui/button/button';
import { Rating } from '@wla/components/ui/rating/rating';
import { usePublicConfig } from '@wla/lib/configs';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

type ReviewsProps = {
  productId: string;
  review: ReviewType;
};

export function Review({ review, productId }: ReviewsProps) {
  const t = useTranslations();
  const { defaultLocale, catalog } = usePublicConfig();
  const { id: reviewId, author, body, title, rating, date } = review;
  const timestamp = new Date(date).toISOString();
  const [isError, setIsError] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [translation, setTranslation] = useState<{ title: string; body: string }>();
  const showOriginalText = !isTranslated || !translation || isError;

  const domain = `JYSK.${review.catalog.slice(-2).toLowerCase()}`;

  async function toggleReview() {
    if (!isTranslated) {
      if (!translation) {
        const response = await fetch('/api/get-review-translated', {
          method: 'POST',
          body: JSON.stringify({ productId, reviewId }),
        });
        const data = await response.json();

        if (!data.success) {
          setIsError(true);
          setIsTranslated(false);
          return;
        }

        setTranslation(data.review);
        setIsError(false);
      }
      !isError && setIsTranslated(true);
    } else {
      setIsTranslated(false);
    }
  }

  return (
    <article className="border-t py-4">
      {/* Top */}
      <div className="flex items-center gap-4">
        <Rating rating={rating} size="sm" />
        <time dateTime={timestamp}>{new Intl.DateTimeFormat(defaultLocale).format(new Date(timestamp))}</time>
      </div>

      {/* Content */}
      <div className="pt-2">
        <div className="font-bold">{showOriginalText ? title : translation?.title}</div>
        {/* Author */}
        <p>{showOriginalText ? body : translation?.body}</p>
        <div className="pb-2.5 pt-2">
          {t('forms.name')}: {author}
        </div>
        {catalog !== review.catalog && (
          <div className="my-2 flex items-center justify-between bg-gray-100 p-3">
            <span>
              {t('pdp.review.written-on', {
                domain: domain,
              })}
            </span>
            <Button className="bg-gray-100 font-medium" size="medium" variant="secondary" onClick={toggleReview}>
              {showOriginalText ? t('pdp.review.translate') : t('pdp.review.original')}
            </Button>
          </div>
        )}
      </div>
    </article>
  );
}
