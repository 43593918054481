import { ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { usePublicConfig } from '@wla/lib/configs';
import { trackViewedItemList } from '@wla/lib/tracking/google-analytics-tracking';
import debounce from 'lodash/debounce';
import { useState } from 'react';

export type ProductsVisibility = ((PostSearch200ProductsHitsItem | ProductTeaser) & { index?: number })[];

export function useProductVisibility(pageType?: string) {
  const { currency } = usePublicConfig();
  const [, setVisibleProducts] = useState<(ProductTeaser | PostSearch200ProductsHitsItem)[]>([]);

  const debouncedTrackViewedItemList = debounce((products: ProductsVisibility) => {
    products.sort((a, b) => (a.index || 0) - (b.index || 0));
    trackViewedItemList({ products, currency, pageType });
    setVisibleProducts([]); // Empty the array after tracking to avoid duplicates in the next tracking event
  }, 1000);

  function handleOnProductVisible(product: ProductTeaser | PostSearch200ProductsHitsItem, index?: number) {
    const addIndexToProduct = { ...product, index: index ?? 0 };
    setVisibleProducts((prevVisibleProducts) => {
      if (prevVisibleProducts.includes(product)) return prevVisibleProducts;
      const newVisibleProducts = [...prevVisibleProducts, addIndexToProduct];
      debouncedTrackViewedItemList(newVisibleProducts);
      return newVisibleProducts;
    });
  }

  return handleOnProductVisible;
}
