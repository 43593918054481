'use client';
import { CarrierOption } from '@jysk/api-types/orderManagementApi/model';
import { Accordion } from '@wla/components/ui/accordion';
import { Alert } from '@wla/components/ui/alert';
import { Drawer } from '@wla/components/ui/drawer';
import { cn } from '@wla/lib/helpers/cn';
import { CarrierType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';

type CarrierSavingsProps = {
  carriers?: CarrierOption[];
};

export function CarrierSavings({ carriers }: CarrierSavingsProps) {
  const t = useTranslations();

  // If there are more than one carrier with the same carrierId then the price of the cheapest one should be shown
  const existingCarrierIds = Array.from(new Set(carriers?.map((carrier) => carrier.carrierId)));

  const groupedArray = existingCarrierIds.map(
    (carrierId) => carriers?.filter((carrier) => carrier.carrierId === carrierId) || [],
  );

  const filteredCarriers = groupedArray.map((array) =>
    array.reduce(
      (acc, cur) =>
        acc.minimumPrice?.amount && cur.minimumPrice?.amount && acc.minimumPrice.amount < cur.minimumPrice.amount
          ? acc
          : cur,
      array[0],
    ),
  );

  return (
    <Drawer.BodySection border>
      <Accordion title={t('common.delivery')} titleClassName="sm:text-base my-0" bodyClassName="pb-4 sm:pb-4" noBorder>
        {!carriers ? (
          <Alert type="error" className="mt-4">
            {t('pdp.errors.power-step-no-carriers')}
          </Alert>
        ) : (
          filteredCarriers.map((carrier) => (
            <div key={carrier.carrierId} className="mt-4 flex justify-between">
              <div>
                <p>{carrier.name}</p>
                {carrier.carrierType === CarrierType.OopusDelivery && carrier.nextDiscount && (
                  <p className="text-sm text-gray-500">
                    {t('pdp.power-step.at-least', {
                      amount: carrier.minimumSubtotalText,
                    })}
                  </p>
                )}
              </div>
              <div className="flex gap-x-1.5">
                {carrier.originalPrice && (
                  <p className="text-gray-500 line-through decoration-gray-500">{carrier.originalPrice.amountText}</p>
                )}
                <p
                  className={cn({
                    'text-red-500': carrier.originalPrice,
                  })}
                >
                  {carrier.minimumPrice?.amountText}
                </p>
              </div>
            </div>
          ))
        )}
      </Accordion>
    </Drawer.BodySection>
  );
}
