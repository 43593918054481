export function getMapPointsDistance(p1: google.maps.LatLngLiteral, p2: google.maps.LatLngLiteral) {
  function rad(x: number) {
    return (x * Math.PI) / 180;
  }

  const earthMeanRadius = 6378137; // Earth’s mean radius in meter
  const distanceLat = rad(p2.lat - p1.lat);
  const distanceLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(distanceLong / 2) * Math.sin(distanceLong / 2);

  return earthMeanRadius * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}
