import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { Text } from '@wla/components/ui/text';
import Image from 'next/image';
import Link from 'next/link';
import { CategoriesParagraph } from '@jysk/api-types/drupalApi';

type ParagraphCategoryProps = {
  content: CategoriesParagraph;
};

export function ParagraphCategory({ content }: ParagraphCategoryProps) {
  const { config, items, backgroundColor } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config} backgroundColor={backgroundColor}>
      <ul className="grid w-full grid-cols-2 justify-stretch gap-x-3 gap-y-4 md:grid-cols-3 md:gap-8">
        {items.map(({ image, name, url }) => (
          <li key={name}>
            <Link href={url} className="group relative flex flex-col gap-2 lg:gap-4">
              {image && (
                <div className="relative aspect-[1/1] h-full w-full overflow-hidden">
                  <Image
                    className="relative bg-contain object-cover transition-transform duration-300 group-hover:scale-110"
                    src={image.url}
                    title={image.title || undefined}
                    fill
                    alt={image.alt}
                  />
                </div>
              )}
              <Text value={name} className="text-base font-semibold md:text-lg lg:text-2xl" />
            </Link>
          </li>
        ))}
      </ul>
    </ParagraphConfig>
  );
}
