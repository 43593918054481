'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@wla/components/ui/alert';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { TextArea } from '@wla/components/ui/forms/textArea';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useRef, useState } from 'react';
import { useFormState } from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import {
  ParagraphContactFormCustomerAssortmentSuggestionsData,
  ParagraphContactFormCustomerAssortmentSuggestionsSchema,
} from './paragraph-customer-assortment-suggestions-schema';

const initialState = {
  success: false,
  message: '',
};

export function ParagraphContactFormCustomerAssortmentSuggestions() {
  const t = useTranslations();
  const { recaptchaKey, defaultLocale } = usePublicConfig();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const language = defaultLocale.substring(3, 5);
  const [messageLength, setMessageLength] = useState(0);
  const [result, formAction] = useFormState(async (prevState: any, formData: FormData) => {
    const data = {
      toAddress: `cs_${language}_product@jysk.com`,
      email: formData.get('email') || 'info@jysk.com',
      firstName: 'Product', // Mandatory, therefor hardcoded.
      lastName: 'Suggestion', // Mandatory, therefor hardcoded.
      phone: formData.get('phoneNumber'),
      message: formData.get('message'),
      subject: 'Product Suggestions', // Mandatory - constant value required to determine whether an auto-reply will be send - not for product suggestions
      orderNumber: '',
      store: '',
    };
    const result = ParagraphContactFormCustomerAssortmentSuggestionsSchema.safeParse(data);

    if (result.success) {
      try {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        const response = await fetch('/api/post-sales-force-form', {
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          return { success: true, message: t('customer-service.email-success') };
        }
      } catch (error) {
        return { success: false, message: t('forms.errors.something-went-wrong') };
      }
    }
    return { success: false, message: t('forms.errors.something-went-wrong') };
  }, initialState);

  const form = useForm<ParagraphContactFormCustomerAssortmentSuggestionsData>({
    mode: 'all',
    resolver: zodResolver(ParagraphContactFormCustomerAssortmentSuggestionsSchema),
  });
  const {
    register,
    formState: { errors },
  } = form;

  function handleOnMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setMessageLength(event.target.value.length);
  }

  return (
    <Form form={form} action={formAction} className={cn('mt-4 flex h-auto flex-col gap-3')}>
      <FormGroup
        label={t('forms.message')}
        inputType={InputTypes.TextArea}
        required
        validationError={errors.message?.message ? t('forms.errors.required.message-is-required') : ''}
      >
        <TextArea
          {...register('message')}
          counter={messageLength}
          onChange={handleOnMessageChange}
          maxLength={1000}
          className="h-40"
          resize="none"
        />
      </FormGroup>
      <FormGroup
        label={t('forms.email')}
        required
        inputType={InputTypes.Email}
        validationError={errors.email?.message ? t('forms.errors.not-valid.email-not-valid') : ''}
        className="flex-1"
      >
        <Input {...register('email')} />
      </FormGroup>

      <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} size="normal" badge="inline" />
      <SubmitButton translate="no" variant="primary">
        {t('customer-service.send-email-submit')}
      </SubmitButton>
      {result.message && (
        <Alert className="mt-4" type={result.success ? 'success' : 'warning'}>
          {result.message}
        </Alert>
      )}
    </Form>
  );
}
