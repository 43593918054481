'use client';
import {
  Dialog as HeadlessDialog,
  DialogPanel as HeadlessDialogPanel,
  DialogTitle as HeadlessDialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';
import { cn } from '@wla/lib/helpers/cn';
import { Fragment, MouseEventHandler, ReactNode, createContext, forwardRef, useContext, useEffect } from 'react';
import { Icon } from './icon';

const DrawerContext = createContext<{
  open: boolean;
  onClose: (value: boolean) => void;
}>({
  open: false,
  onClose: () => undefined,
});

function useDrawer() {
  const drawer = useContext(DrawerContext);
  if (!drawer) throw new Error('useDrawer should only be called from inside a drawer');
  return drawer;
}

type DrawerProps = Parameters<typeof HeadlessDialog>[0] & {
  position?: 'left' | 'right' | 'fullscreen';
};

export function Drawer({ children, onClose, open: show, position = 'right', ...rest }: DrawerProps) {
  const drawerAnimation = {
    enter: 'transition ease-in-out duration-300 transform',
    enterFrom: position === 'left' ? '-translate-x-full' : 'translate-x-full',
    enterTo: 'translate-x-0',
    leave: 'transition ease-in-out duration-200 transform',
    leaveFrom: 'translate-x-0 opacity-100',
    leaveTo: `${position === 'left' ? '-translate-x-full' : 'translate-x-full'} opacity-0`,
  };

  useEffect(() => {
    if (show) {
      window.addEventListener('popstate', () => onClose(false));
      return () => window.removeEventListener('popstate', () => onClose(false));
    }
  }, [show]);

  return (
    <Transition appear show={show} as={Fragment}>
      <HeadlessDialog className="relative z-modal" onClose={onClose} as="div" {...rest}>
        {({ open }) => (
          <DrawerContext.Provider value={{ onClose, open }}>
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
            </TransitionChild>
            <TransitionChild
              as={DrawerPanel}
              position={position}
              {...(position !== 'fullscreen' ? drawerAnimation : undefined)}
            >
              {typeof children === 'function' ? (
                children({ open })
              ) : (
                <div data-testid="drawer" className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
                  {children}
                </div>
              )}
            </TransitionChild>
          </DrawerContext.Provider>
        )}
      </HeadlessDialog>
    </Transition>
  );
}

type DrawerPanelProps = Parameters<typeof HeadlessDialogPanel>[0] & {
  position?: 'left' | 'right' | 'fullscreen';
};

const DrawerPanel = forwardRef<HTMLDivElement, DrawerPanelProps>(function DrawerPanel(
  { className, children, position = 'right', ...rest },
  forwardedRef,
) {
  return (
    <HeadlessDialogPanel
      ref={forwardedRef}
      className={cn(
        `fixed bottom-0 top-0 flex w-full flex-col bg-white s:w-[500px]`,
        { 'left-0': position === 'left', 'right-0': position === 'right' },
        { 's:w-full': position === 'fullscreen' },
        className,
      )}
      {...rest}
    >
      {children}
    </HeadlessDialogPanel>
  );
});

type DrawerHeaderProps = {
  title: string;
  headerIcon?: JyskIconsId;
  iconClassName?: string;
  headerSlot?: ReactNode;
  className?: string;
};

function DrawerHeader({ title, className, headerIcon, iconClassName, headerSlot }: DrawerHeaderProps) {
  const { onClose } = useDrawer();

  const closeDrawer: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onClose?.(false);
  };

  return (
    <div
      data-drawerheader
      className={cn(
        "after:border-gray-350 sticky top-0 z-drawerHeader flex min-h-[6.25rem] items-end bg-white px-5 pb-4 after:absolute after:bottom-0 after:w-[calc(100%-2.5rem)] after:border-b after:content-[''] s:px-[1.875rem] s:after:w-[calc(100%-3.75rem)] sm:pb-5",
        className,
      )}
    >
      {!headerSlot && (
        <div className="flex flex-1 items-center justify-center gap-2">
          {headerIcon && <Icon src={headerIcon} size="20" className={iconClassName} />}
          <HeadlessDialogTitle className="flex flex-col items-center text-2xl font-semibold">
            {title}
          </HeadlessDialogTitle>
        </div>
      )}

      {headerSlot}
      <button onClick={closeDrawer} aria-label="Close">
        <Icon src="w3-close" size="20" aria-label="Close" />
      </button>
    </div>
  );
}

function DrawerHeaderTitle({ title, className }: { title: string; className?: string }) {
  return (
    <HeadlessDialogTitle className={cn('flex flex-col items-center text-2xl font-medium', className)}>
      {title}
    </HeadlessDialogTitle>
  );
}

type DrawerBodyProps = {
  className?: string;
  children?: ReactNode;
};

function DrawerBody({ className, children }: DrawerBodyProps) {
  return (
    <div data-drawerbody className={cn('mt-8 overflow-y-auto overflow-x-hidden px-4 s:px-4 lg:px-8', className)}>
      {children}
    </div>
  );
}

type DrawerBodySectionProps = {
  border?: boolean;
  className?: string;
  children?: ReactNode;
};

export function DrawerBodySection({ border = false, className, children }: DrawerBodySectionProps) {
  return <section className={cn('py-4', { 'border-gray-350 border-b': border }, className)}>{children}</section>;
}

type DrawerFooterProps = {
  children?: ReactNode;
  className?: string;
};

function DrawerFooter({ children, className }: DrawerFooterProps) {
  return (
    <div className={cn('border-gray-350 flex justify-between gap-4 border-t px-5 pb-8 pt-4 s:px-4 lg:px-8', className)}>
      {children}
    </div>
  );
}
Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.BodySection = DrawerBodySection;
Drawer.Footer = DrawerFooter;
Drawer.HeaderTitle = DrawerHeaderTitle;
