'use client';
import { OrderLine } from '@jysk/api-types/orderManagementApi/model';
import { Drawer } from '@wla/components/ui/drawer';
import { usePublicConfig } from '@wla/lib/configs';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

type ProductDetailsProps = {
  isGiftCard: boolean;
  quantity: number;
  totalInBasket: number;
  totalPriceText?: string;
  line?: OrderLine;
  imageSrc?: string | null;
};

// TODO: add pricing (UI) if the product is on sale

export function ProductDetails({
  isGiftCard,
  quantity,
  totalInBasket,
  totalPriceText,
  line,
  imageSrc,
}: ProductDetailsProps) {
  const t = useTranslations();
  const { imageBaseUrl } = usePublicConfig();

  return (
    <Drawer.BodySection border>
      <div className="mb-4 flex gap-3" data-testid="powerstep-product-details" data-product-id={line?.article}>
        <div className="relative aspect-[3/4] overflow-hidden bg-gray-100">
          <Image
            src={isGiftCard ? `${imageBaseUrl}getimage/wd3.small/${line?.pictureId}` : imageSrc || ''}
            alt={line?.receiptText || ''}
            width={80}
            height={106}
            className="h-[106px] w-20 object-contain p-3"
          />
        </div>
        <div>
          {!isGiftCard && <p className="mb-2 text-sm text-gray-700">{line?.receiptText}</p>}
          <p className="mb-2 text-base font-bold">{line?.unitPriceText}</p>
          <p className="text-xs">
            {t('common.amout-quantity', {
              quantity: quantity,
            })}
          </p>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-base">
          {totalInBasket === 1
            ? t('pdp.power-step.total-one-product')
            : t('pdp.power-step.total-multiple-products', {
                quantity: totalInBasket,
              })}
        </p>
        <p className="text-base">{totalPriceText}</p>
      </div>
    </Drawer.BodySection>
  );
}
