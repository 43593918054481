'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { BackInStockForm } from '@wla/app/(cms)/(back-in-stock-drawer)/back-in-stock-form';
import { NotifyFormData } from '@wla/app/(cms)/(back-in-stock-drawer)/back-in-stock-schema';
import { CreateNotificationAction } from '@wla/app/(cms)/(back-in-stock-drawer)/create-notification-action';
import { Alert, AlertTypes } from '@wla/components/ui/alert';
import { Drawer } from '@wla/components/ui/drawer';
import { trackBackInStock } from '@wla/lib/tracking/google-analytics-tracking';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';

type BackInStockDrawerProps = {
  articleId: string;
};

export const BackInStockDrawer = NiceModal.create(({ articleId }: BackInStockDrawerProps) => {
  const t = useTranslations('pdp');
  const { visible, hide } = useModal();
  const [submitState, setSubmitState] = useState<{ success: AlertTypes; message: string } | undefined>(undefined);

  const handleSubmit = useCallback(
    async (data: NotifyFormData) => {
      const res = await CreateNotificationAction(data);
      setSubmitState(res);
      trackBackInStock(data.articleId, res.success);
    },
    [setSubmitState],
  );

  useEffect(() => {
    return () => {
      setSubmitState(undefined);
    };
  }, [articleId]);

  const handleClose = useCallback(() => {
    if (submitState?.success !== 'success') setSubmitState(undefined);
    hide();
  }, [submitState?.success]);

  return (
    <Drawer open={visible} onClose={handleClose} position="right">
      <Drawer.Header title={t('back-in-stock.stock-notification')} />
      <Drawer.Body className="flex flex-col gap-y-4">
        {(!submitState || submitState.success !== 'success') && (
          <>
            <p>{t('back-in-stock.be-notified-when-the-product-is-back')}</p>
            <BackInStockForm articleId={articleId} onSubmit={handleSubmit} />
            {submitState && <Alert type={submitState.success}>{submitState.message}</Alert>}
          </>
        )}

        {submitState && submitState.success === 'success' && (
          <Alert type={submitState.success}>{submitState.message}</Alert>
        )}
      </Drawer.Body>
    </Drawer>
  );
});
