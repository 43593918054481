import { ProductBrandOverviewParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { ContentCard } from '../content/content-card';

type BrandOverviewProps = {
  content: ProductBrandOverviewParagraph;
};

export function ParagraphBrandOverview({ content }: BrandOverviewProps) {
  const { brands, config } = content;

  if (!brands?.length) return null;

  return (
    <ParagraphConfig config={config}>
      <div className="grid grid-cols-2 items-center gap-x-3 gap-y-4 pt-12 sm:grid-cols-3 lg:grid-cols-4 lg:gap-8">
        {brands.map((brand) => (
          <ContentCard
            key={brand.id}
            href={brand.url}
            title={brand.name}
            image={
              brand.brandLogoImage?.url
                ? {
                    url: brand.brandLogoImage?.url,
                    width: 370,
                    height: 217,
                    className: 'sm:aspect-[3/2] p-2.5 lg:p-8 h-full',
                  }
                : undefined
            }
          />
        ))}
      </div>
    </ParagraphConfig>
  );
}
