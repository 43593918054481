'use client';

import { FilterSearchDrawer } from '@wla/app/(cms)/[...slug]/(filters)/filter-search-drawer';
import {
  formatQueryToPostSearchBodyFilters,
  hasSelectedFilter,
} from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { RemoveFilterButtons } from '@wla/app/(cms)/[...slug]/(filters)/remove-filter-buttons';
import { ProductList } from '@wla/app/(cms)/[...slug]/(product-listing-page)/product-list';
import { ProductListSorting, SortOptions } from '@wla/app/(cms)/[...slug]/(product-listing-page)/product-list-sorting';
import { getProductStockData } from '@wla/app/(cms)/[...slug]/(product-listing-page)/product-listing-data';
import { AllFiltersButton } from '@wla/components/ui/button/all-filters-button';
import { DropDownButton } from '@wla/components/ui/button/dropdown-button';
import { PaginationProgressbar } from '@wla/components/ui/pagination-progressbar';
import { Spinner } from '@wla/components/ui/spinner';
import { Switch } from '@wla/components/ui/switch';
import { SEARCH_ITEMS_PR_PAGE } from '@wla/lib/constants';
import { handleChangeSeachFilter } from '@wla/lib/helpers/filter-change-handler';
import { useSearch } from '@wla/lib/hooks/search/use-search';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import {
  PostSearch200ProductsFacets,
  PostSearch200ProductsFacetsSortedItem,
  PostSearch200ProductsHitsItem,
  PostSearchBodySort,
  PostSearchBodyStaticFiltersItem,
} from '@jysk/api-types/searchApi/model';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';

type SearchProductListingProps = {
  query: string;
  products: PostSearch200ProductsHitsItem[];
  filters: PostSearch200ProductsFacets;
  staticFilters?: PostSearchBodyStaticFiltersItem[];
  amount?: number;
  initialLoadOccurred?: boolean;
  showProductListLoading?: boolean;
  onAmountChange?: (amount: number) => void;
};

export function SearchProductListing({
  query,
  products,
  filters: initialFilters,
  initialLoadOccurred = false,
  amount,
  staticFilters,
  showProductListLoading,
  onAmountChange,
}: SearchProductListingProps) {
  const t = useTranslations();
  const { postFullSearch } = useSearch();

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryFilters = searchParams.get('filters');
  const querySort = searchParams.get('sort') as SortOptions;

  const [isLoading, setIsLoading] = useState(false);
  const [hasInitialLoadOccurred, setHasInitialLoadOccurred] = useState(initialLoadOccurred);
  const [isProductListLoading, setIsProductListLoading] = useState(showProductListLoading);
  const [data, setData] = useState(products);
  const [filters, setFilters] = useState<PostSearch200ProductsFacets>(initialFilters);
  const [toggleFilterDrawer, setToggleFilterDrawer] = useState(false);
  const [preOpenFilter, setPreOpenFilter] = useState<PostSearch200ProductsFacetsSortedItem | null>(null);

  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const [stockData, setStockData] = useState<OnlineAtpMap | null>();
  const [isLoadingStock, setIsLoadinStock] = useState(hasInitialLoadOccurred);
  const [searchQuantity, setSearchQuantity] = useState(amount || 0);

  async function getFilters(from?: number) {
    setIsLoading(true);

    const response = await postFullSearch({
      q: query,
      type: 'products',
      from,
      staticFilters,
      ...(queryFilters && { filters: formatQueryToPostSearchBodyFilters(queryFilters) }),
      ...(querySort && { sort: querySort as PostSearchBodySort }),
    });

    setIsLoading(false);
    setIsProductListLoading(false);

    const dataSet = !from ? response?.products?.hits ?? [] : [...data, ...(response?.products?.hits ?? [])];
    setData(dataSet);

    const amount = response?.products?.totalHits || 0;
    setSearchQuantity(amount);
    onAmountChange?.(amount);
    setFilters(response?.products?.facets ?? {});

    const articleIds = dataSet.map((product) => product.id);
    const atpData = await fetchOnlineWithStoreStatusAtp(articleIds);
    setStockData(atpData);
    setIsLoadinStock(false);

    return response?.products?.hits;
  }

  function handleFilterDrawerToggle(filter?: PostSearch200ProductsFacetsSortedItem) {
    setPreOpenFilter(filter || null);
    setToggleFilterDrawer(!toggleFilterDrawer);
  }

  function handleLoadMore(size: number) {
    getFilters(size - SEARCH_ITEMS_PR_PAGE);
  }

  useEffect(() => {
    hasInitialLoadOccurred && getFilters();
    setHasInitialLoadOccurred(true);
  }, [queryFilters, querySort]);

  useEffect(() => {
    setData(products);
    setSearchQuantity(amount || 0);
    setFilters(initialFilters);
  }, [products, initialFilters, amount]);

  if (isProductListLoading) {
    return (
      <div className="flex w-full justify-center pt-10">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <FilterSearchDrawer
        toggleFilterDrawer={toggleFilterDrawer}
        onFilterDrawerToggle={handleFilterDrawerToggle}
        filters={filters}
        productCount={searchQuantity}
        preOpenFilter={preOpenFilter}
        isLoading={isLoading}
        showCount
      />
      <div className="sticky z-20 mt-2 bg-white py-6 sm:mt-6 lg:flex lg:flex-row">
        <div className="flex items-center gap-2.5">
          {filters?.sorted
            ?.filter((filter) => filter.type === 'keyword' || filter.type === 'number')
            .slice(0, 3)
            ?.map((filter) => (
              <DropDownButton
                key={filter.name}
                onClick={() => handleFilterDrawerToggle(filter)}
                className="hidden h-14 rounded border-gray-300 py-2.5 pl-4.5 pr-5 md:inline-flex"
                title={filter.name}
              />
            ))}
          <div className="flex w-full justify-end gap-3 md:w-auto md:flex-row-reverse md:gap-2.5">
            <AllFiltersButton
              title={t('plp.all-filters')}
              className="h-14 w-full whitespace-nowrap rounded border-gray-300 py-2.5 pl-4.5 pr-5 lg:w-auto"
              onClick={() => handleFilterDrawerToggle()}
            />
            <ProductListSorting />
          </div>
        </div>
        <div className="my-4 ml-auto flex w-full flex-wrap justify-between lg:w-auto">
          <div>
            {searchQuantity} {t('common.products')}
          </div>
          {filters?.promotion && (
            <>
              <div className="hidden px-4 text-gray-300 lg:block">|</div>
              <div className="flex items-center">
                <Switch
                  isLeftLabel
                  labelClassName="text-md"
                  label={filters.promotion.name}
                  isChecked={hasSelectedFilter(filters.promotion.name, queryFilters)}
                  onChange={() =>
                    handleChangeSeachFilter(
                      {
                        key: filters?.promotion?.name,
                        value: filters?.promotion?.name,
                      },
                      { pathname, searchParams, queryFilters },
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-full">
        <RemoveFilterButtons />
        <ProductList
          isFirst={true}
          products={data}
          isLoadingStock={isLoadingStock}
          stockStatus={getProductStockData({ products: data, stockData })}
          onProductClickTrackingUserAction={'Search product click'}
        />
      </div>
      <PaginationProgressbar
        className="flex w-full items-center justify-center"
        onPageChange={handleLoadMore}
        totalElements={searchQuantity}
        pageSize={SEARCH_ITEMS_PR_PAGE}
        currentSize={data.length}
        isLoading={isLoading}
      />
    </>
  );
}
