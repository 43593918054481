import { z } from 'zod';

export const ParagraphContactFormOrderReturnsAndClaimsSchema = z.object({
  receivedMyOrder: z
    .string()
    .min(1, 'forms.errors.received-my-order-is-required')
    .max(300, 'forms.errors.received-my-order-is-required'),
  orderNumber: z
    .string()
    .min(1, 'forms.errors.required.order-number-is-required')
    .max(10, 'forms.errors.required.order-number-is-required'),
  reasonForReturn: z
    .string()
    .min(1, 'forms.errors.required.reason-for-return-is-required')
    .max(300, 'forms.errors.required.reason-for-return-is-required'),
  cancelMyOrder: z
    .string()
    .min(1, 'forms.errors.required.reason-for-return-is-required')
    .max(300, 'forms.errors.required.reason-for-return-is-required')
    .nullable(),
  orderOn: z
    .string()
    .min(1, 'forms.errors.required.order-on-is-required')
    .max(15, 'forms.errors.required.order-on-is-required'),
  receivedOn: z
    .string()
    .min(1, 'forms.errors.required.order-on-is-required')
    .max(15, 'forms.errors.required.order-on-is-required')
    .nullable(),
  customerName: z
    .string()
    .min(1, 'forms.errors.required.first-name-is-required')
    .max(45, 'forms.errors.required.first-name-is-required'),
  customerAddress: z
    .string()
    .min(1, 'forms.errors.required.last-name-is-required')
    .max(300, 'forms.errors.required.last-name-is-required'),
  date: z
    .string()
    .min(1, 'forms.errors.required.last-name-is-required')
    .max(15, 'forms.errors.required.last-name-is-required'),
  email: z.string().email('forms.errors.not-valid.email-not-valid'),
  phoneNumber: z
    .string()
    .regex(new RegExp('^(|\\d{8,20})$'), 'forms.errors.not-valid.phone-number-not-valid')
    .optional(),
  message: z
    .string()
    .min(1, 'forms.errors.required.message-is-required')
    .max(1000, 'forms.errors.required.message-is-required'),
});

export type ParagraphReturnsAndClaimsData = z.infer<typeof ParagraphContactFormOrderReturnsAndClaimsSchema>;
