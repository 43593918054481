import { JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';
import { Input } from '@wla/components/ui/forms/input';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';

type InputProps = ComponentPropsWithoutRef<'input'>;

export const Password = forwardRef<HTMLInputElement, InputProps>(function PasswordField(
  { className, ...rest }: InputProps,
  ref,
) {
  const [showPassword, setShowPassword] = useState(false);
  const iconId: { icon?: JyskIconsId } = { icon: showPassword ? 'w3-hide' : 'w3-show' };

  return (
    <>
      <Input ref={ref} className={cn(className, 'pr-16')} {...rest} type={showPassword ? 'text' : 'password'} />
      <button
        type="button"
        tabIndex={rest?.readOnly || rest?.disabled ? -1 : undefined}
        onClick={() => setShowPassword(!showPassword)}
        className={cn('absolute right-0 top-2 px-5 font-semibold text-gray-500', {
          'pointer-events-none text-gray-400': rest?.disabled || rest?.readOnly,
        })}
      >
        <Icon src={iconId.icon} size="24" />
      </button>
    </>
  );
});
