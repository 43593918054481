import { FilterAxises } from '@jysk/api-types/wssFilterAxisApi/model';
import { formatFiltersToObject } from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { ResetFiltersButton } from '@wla/app/(cms)/[...slug]/(filters)/reset-filters-button';
import { Icon } from '@wla/components/ui/icon';
import { filterActions, handleChangeFilter } from '@wla/lib/helpers/filter-change-handler';
import { usePathname, useSearchParams } from 'next/navigation';

type RemoveFilterButtonsProps = {
  filterAxises?: FilterAxises[];
};

export function RemoveFilterButtons({ filterAxises }: RemoveFilterButtonsProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const filters = searchParams.get('filters');
  const formattedFilters = formatFiltersToObject(filters);
  const filterCount = Object.entries(formattedFilters).reduce((acc, [, values]) => acc + values.length, 0);

  if (!filterCount) return;
  return (
    <section className="mt-5 flex w-full flex-wrap gap-2 md:mt-6" id="selected-filters">
      {Object.entries(formattedFilters).map(([key, values]) =>
        values.map((value) => (
          <button
            type="button"
            onClick={() =>
              handleChangeFilter(
                { id: key, value, handle: filterActions.REMOVE },
                { pathname, searchParams, queryFilters: filters },
              )
            }
            key={key + value}
            className="align-center flex gap-3 bg-gray-100 px-4 py-2 text-sm"
          >
            {filterAxises?.flatMap((axis) => axis.filteraxisitems).find((item) => item.value === value)?.caption ||
              value.replace('_', ' - ')}
            <Icon src="close" size="10" className="mt-0.5" />
          </button>
        )),
      )}
      {filterCount > 1 && <ResetFiltersButton className="flex h-9 border-0 bg-gray-100 px-4 py-2 text-sm" />}
    </section>
  );
}
