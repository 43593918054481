export function calculateViewport(latitude: number, longitude: number, radius = 0.1) {
  const earthRadius = 6378.1; // Radius of the Earth in kilometers

  const dLat = radius / earthRadius;
  const dLon = radius / (earthRadius * Math.cos((Math.PI * latitude) / 180));

  const northeastLat = latitude + dLat * (180 / Math.PI);
  const northeastLon = longitude + dLon * (180 / Math.PI);
  const southwestLat = latitude - dLat * (180 / Math.PI);
  const southwestLon = longitude - dLon * (180 / Math.PI);

  return {
    northeast: {
      latitude: northeastLat,
      longitude: northeastLon,
    },
    southwest: {
      latitude: southwestLat,
      longitude: southwestLon,
    },
  };
}
