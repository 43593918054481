import { PageContext } from '@wla/app/(cms)/[...slug]/page-provider';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { PageType } from '@wla/lib/helpers/(analytics)/get-page-type';
import { useContext } from 'react';
import { EmbeddedContentType, type UspParagraph as UspParagraphModel } from '@jysk/api-types/drupalApi';

type ParagraphUspProps = {
  content: UspParagraphModel;
  type?: string;
};

export function ParagraphUspItem({ content, type }: ParagraphUspProps) {
  const { config, items } = content;
  const { pageType } = useContext(PageContext);

  if (!items) return null;

  if (pageType === PageType.PRODUCT_DETAIL_PAGE && type === EmbeddedContentType.PdpUsp) {
    return (
      <ParagraphConfig config={config} className="flex flex-col items-start gap-4 pt-5">
        {items.map((item, index) => (
          <Link key={`${index}${item.title}`} href={item.url} className="group flex gap-2">
            {item.icon && <Icon src={item.icon} size="20" />}
            <div>
              <div className="pt-1.5 font-bold text-gray-800 group-hover:underline">{item.title}</div>
              <div className="text-gray-500">{item.text}</div>
            </div>
          </Link>
        ))}
      </ParagraphConfig>
    );
  }

  if (pageType === PageType.CHECKOUT) {
    return (
      <ParagraphConfig config={config}>
        <div className="grid w-full grid-cols-1 items-start justify-center gap-4 md:grid-cols-3 md:gap-5">
          {items.map((item, index) => (
            <div key={`${index}${item.title}`} className="flex w-full gap-2 md:flex-col md:items-center">
              <Icon src={item.icon} size="20" />
              <div>
                <div className="text-wrap font-bold md:text-center">{item.title}</div>
                <div className="text-gray-500 md:text-center">{item.text}</div>
              </div>
            </div>
          ))}
        </div>
      </ParagraphConfig>
    );
  }

  if (type === EmbeddedContentType.FooterUsp) {
    return (
      <ParagraphConfig config={config} className="flex justify-center">
        <div className="my-8 grid w-64 items-start justify-center gap-8 sm:w-full sm:grid-cols-2 md:my-12 md:grid-cols-4">
          {items.map((item, index) => (
            <Link key={`${index}${item.title}`} href={item.url} className="flex flex-col justify-center">
              <div className="flex items-center justify-center">
                {item.icon && <Icon src={item.icon} className="text-3xl md:text-4xl" />}
              </div>
              <div className="mt-2 text-center text-lg font-semibold md:text-2xl">{item.title}</div>
              <div className="text-center md:text-lg">{item.text}</div>
            </Link>
          ))}
        </div>
      </ParagraphConfig>
    );
  }

  return (
    <ParagraphConfig config={config}>
      <div className="my-8 grid w-full grid-cols-2 items-start justify-center gap-8 md:my-12 md:grid-cols-4">
        {items.map((item, index) => (
          <Link key={`${index}${item.title}`} href={item.url} className="flex flex-col justify-center">
            <div className="flex items-center justify-center">
              {item.icon && <Icon src={item.icon} className="text-3xl md:text-4xl" />}
            </div>
            <div className="mt-2 text-center text-lg font-semibold md:text-2xl">{item.title}</div>
            <div className="text-center md:text-lg">{item.text}</div>
          </Link>
        ))}
      </div>
    </ParagraphConfig>
  );
}
