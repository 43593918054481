'use client';
import { StoreFull } from '@jysk/api-types/drupalApi';
import { setCookie } from '@wla/lib/helpers/setCookie';
import Cookies from 'js-cookie';
import { ReactNode, createContext, useCallback, useState } from 'react';

type FavoriteStoreContextType = {
  favoriteStore: StoreFull | null;
  setFavoriteStore: (store: StoreFull | null) => void;
};

const defaultContextValue: FavoriteStoreContextType = {
  favoriteStore: null,
  setFavoriteStore: () => null,
};

export const FavoriteStoreContext = createContext<FavoriteStoreContextType>(defaultContextValue);

type FavoriteStoreProviderProps = {
  children: ReactNode;
};

export function FavoriteStoreProvider({ children }: FavoriteStoreProviderProps) {
  const [favoriteStore, setFavoriteStoreInternal] = useState<StoreFull | null>(() => {
    const cookie = Cookies.get('favoriteStore');
    return cookie ? JSON.parse(cookie) : null;
  });

  const setFavoriteStore = useCallback((store: StoreFull | null) => {
    setFavoriteStoreInternal(store);
    setCookie('favoriteStore', JSON.stringify(store), 7);
  }, []);

  return (
    <FavoriteStoreContext.Provider value={{ favoriteStore, setFavoriteStore }}>
      {children}
    </FavoriteStoreContext.Provider>
  );
}
