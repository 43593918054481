'use client';

import { NewRelicContext } from '@wla/lib/hooks/tracking/new-relic-context';
import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import { useContext } from 'react';

export type UserAction = 'PLP Product click' | 'Search product click' | 'Search';

type UserActionProps = {
  action: UserAction;
  target: string | number | boolean;
  newContext?: string;
};

function doNothing(_: UserActionProps) {
  return;
}

/***
 Makes it easy to log to newrelic, and ensures consent is given.
 ***/
export function useNewRelic() {
  const consent = useCookieConsent(CookieTypesEnum.STATISTIC);
  const { context, setContext } = useContext(NewRelicContext);

  /***
   * Record user action in New Relic.
   * @param {UserActionProps} properties
   * @param action - User action to record
   * @param target - Target of the action
   * @param newContext - Actions performed within the same context can be grouped and compared in New Relic.
   ***/
  const recordUserAction = ({ action, target, newContext }: UserActionProps) => {
    const canTrack = window.newrelic && window.newrelic.addPageAction;
    if (!canTrack) {
      return;
    }

    if (newContext) {
      setContext(newContext);
    }

    window.newrelic.addPageAction(action, { target, context: newContext ?? context });
  };

  return {
    recordUserAction: consent ? recordUserAction : doNothing,
  };
}
