import { ProductCategoryTeaserExternalId, ProductListPageAllOfCategories } from '@jysk/api-types/drupalApi';
import { ContentCardRounded } from '@wla/components/ui/content/content-card-rounded';
import { scrollToElement } from '@wla/lib/helpers/scroll-to';
import { useEffect, useRef } from 'react';

type CategoryBannersProps = {
  banners: ProductListPageAllOfCategories;
  selectedId: ProductCategoryTeaserExternalId;
};

export function CategoryBanners({ banners, selectedId }: CategoryBannersProps) {
  const selectedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedRef.current) {
      scrollToElement(selectedRef.current, { behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [selectedId]);

  return (
    <div className="grid snap-x snap-mandatory auto-cols-[minmax(100px,100px)] grid-flow-col justify-start gap-2 overflow-x-auto md:auto-cols-[minmax(119px,119px)] md:gap-10">
      {banners?.map((banner) => {
        const isSelected = selectedId === banner.externalId;
        return (
          <div key={banner.externalId} ref={isSelected ? selectedRef : null}>
            <ContentCardRounded href={banner.url} title={banner.name} image={banner.image} isSelected={isSelected} />
          </div>
        );
      })}
    </div>
  );
}
