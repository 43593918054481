export const RECENTLY_VIEWED_COOKIE_KEY = 'JYSK_RECENTLY_VIEWED';

export const CUSTOMER_SESSION_TOKEN_KEY = 'JYSK_SESSION_TOKEN';

export const CUSTOMER_TYPE_KEY = 'JYSK_CUSTOMER_TYPE';

export const SHOPPING_SESSION_STORAGE_KEY = 'JYSK_SHOPPING_SESSION';

export const FAVOURITE_IDS = 'FAVOURITES';

export const GENERAL_NOTICE_ID = 'GENERAL_NOTICE';

export const RECENTLY_SEARCHED_COOKIE_KEY = 'JYSK_RECENTLY_SEARCHED';

export const REVIEWS_PR_PAGE = 5;

export const BLOGS_PR_PAGE = 10;

export const SALESFORCE_CHAT_AVAILABLE = 'CHAT_IS_ONLINE';

export const DEFAULT_SEARCH_QUERY = 'JYSK';

export const SEARCH_ITEMS_PR_PAGE = 32;

export const STORE_FLY_IN_PAGESIZE = 20;

export const COOKIE_INFORMATION = 'CookieInformationConsent';
