'use client';

import { Link } from '@wla/components/ui/link';
import { useTranslations } from 'next-intl';

export function StoreButton() {
  const t = useTranslations('customer-service');

  return (
    <Link href="structural-page/StoresLocator" className="w-fit text-sm text-blue-500 underline md:text-base">
      {t('find-store-opening-hours-here')}
    </Link>
  );
}
