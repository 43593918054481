'use client';
import { CarrierOption } from '@jysk/api-types/orderManagementApi/model';
import * as Progress from '@radix-ui/react-progress';
import { Drawer } from '@wla/components/ui/drawer';
import { Icon } from '@wla/components/ui/icon';
import { usePublicConfig } from '@wla/lib/configs';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

type FreeFreightProps = {
  totalPriceAmount: number;
  carriers?: CarrierOption[];
};

export function FreeFreight({ totalPriceAmount, carriers }: FreeFreightProps) {
  const t = useTranslations('pdp');
  const config = usePublicConfig();
  const [progress, setProgress] = useState(0);

  const freeShippingCarrier = carriers
    ?.filter((carrier) => carrier.nextDiscount?.shippingPrice === 0)
    .reduce(
      (prev, curr) =>
        prev.nextDiscount?.amountThreshold &&
        curr.nextDiscount?.amountThreshold &&
        prev.nextDiscount.amountThreshold <= curr.nextDiscount.amountThreshold
          ? prev
          : curr,
      {},
    );

  const reachedPercent =
    (freeShippingCarrier?.nextDiscount?.amountThreshold &&
      (100 * totalPriceAmount) / freeShippingCarrier.nextDiscount.amountThreshold) ??
    0;

  useEffect(() => {
    const timer = setTimeout(() => setProgress(reachedPercent < 100 ? reachedPercent : 100), 500);
    return () => clearTimeout(timer);
  }, [reachedPercent]);

  return (
    <>
      {freeShippingCarrier?.nextDiscount && (
        <Drawer.BodySection className="flex justify-center gap-1.5 pb-0 pt-6">
          <p className="mb-[3px] self-end whitespace-nowrap text-base">{`0 ${config.currency}`}</p>
          <div>
            {freeShippingCarrier.nextDiscount.amountThreshold &&
            totalPriceAmount < freeShippingCarrier.nextDiscount.amountThreshold ? (
              <div className="flex justify-center">
                <p className="text-base">
                  {t.rich('power-step.free-freight-progress', {
                    amount: () => <>{freeShippingCarrier?.nextDiscount?.priceUntilNextDiscountText}</>,
                  })}
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-center gap-1.5">
                <Icon src="w3-success" size="24" className="text-green-700" />
                <p className="text-base">{t.rich('power-step.free-freight-reached')}</p>
              </div>
            )}
            <Progress.Root
              className="translate-z-0 relative my-2 h-2 w-64 transform self-center overflow-hidden rounded-full bg-gray-200"
              value={progress}
            >
              <Progress.Indicator
                className="ease-[cubic-bezier(0.65, 0, 0.35, 1)] h-full w-full rounded-full bg-blue-500 transition-transform duration-[660ms]"
                style={{ transform: `translateX(-${100 - progress}%)` }}
              />
            </Progress.Root>
          </div>
          <p className="mb-[3px] self-end whitespace-nowrap text-base">
            {freeShippingCarrier.nextDiscount.amountThresholdText}
          </p>
        </Drawer.BodySection>
      )}
    </>
  );
}
