'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { RequestResetPasswordForm } from '@wla/app/(cms)/(customer)/customer/login/(reset-password)/request-reset-password-form';
import { PageContext } from '@wla/app/(cms)/[...slug]/page-provider';
import { Alert } from '@wla/components/ui/alert';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { Password } from '@wla/components/ui/forms/password';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { Link } from '@wla/components/ui/link';
import { errorToast } from '@wla/components/ui/toasters';
import { trackLogin } from '@wla/lib/tracking/google-analytics-tracking';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { login } from '../auth-actions';
import { LoginFormData, loginSchema } from './login-schema';

type LoginFormProps = {
  onSubmitSuccess?: () => void;
  navigateToB2c?: string;
  forgotPassword?: boolean;
};

export function LoginForm({ onSubmitSuccess, navigateToB2c, forgotPassword = false }: LoginFormProps) {
  const t = useTranslations();
  const router = useRouter();
  const [resetPasswordClicked, setResetPasswordClicked] = useState(forgotPassword);
  const { pageType } = useContext(PageContext);
  const form = useForm<LoginFormData>({
    mode: 'onBlur',
    resolver: zodResolver(loginSchema),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  async function onSubmit(data: LoginFormData) {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('message', '');
    const response = await login(formData);

    if (response.success) {
      onSubmitSuccess?.();
      trackLogin(pageType);
      navigateToB2c && router.push(navigateToB2c);
    } else {
      errorToast({ message: response.message });
    }
  }

  return (
    <div className="w-full">
      {!resetPasswordClicked ? (
        <Form form={form} onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3 lg:gap-4">
          <FormGroup
            label={t('forms.email')}
            inputType={InputTypes.Input}
            required
            validationError={errors.email?.message && t('forms.errors.not-valid.email-not-valid')}
          >
            <Input {...register('email')} type="email" />
          </FormGroup>
          <FormGroup
            label={t('forms.password')}
            inputType={InputTypes.Input}
            required
            validationError={errors.password?.message && t('forms.errors.must-be-digits', { number: 8 })}
          >
            <Password {...register('password')} />
          </FormGroup>
          <SubmitButton loadingMessage={t('forms.submitting')}>{t('forms.login')}</SubmitButton>
        </Form>
      ) : (
        <>
          <Alert type="info" className="mb-4">
            {t('customer.login.reset-password-info-text')}
          </Alert>
          <RequestResetPasswordForm />
        </>
      )}
      <Link
        as="button"
        className="mb-6 w-min whitespace-nowrap pt-3 text-left text-base lg:pt-4"
        onClick={() => {
          setResetPasswordClicked(!resetPasswordClicked);
        }}
      >
        {!resetPasswordClicked ? t('common.forgot-password') : t('customer.login.back')}
      </Link>
    </div>
  );
}
