import { PublicationListParagraph } from '@jysk/api-types/drupalApi';
import { Catalog } from '@wla/components/ui/catalog';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { useTranslations } from 'next-intl';

type ParagraphPublicationListProps = {
  content: PublicationListParagraph;
};

export function ParagraphPublicationList({ content }: ParagraphPublicationListProps) {
  const t = useTranslations('campaign');
  const { config, items } = content;

  if (!items) {
    return <ParagraphConfig config={config}>{t('no-active-campaigns')}</ParagraphConfig>;
  }

  return (
    <ParagraphConfig config={config}>
      {items.map((item, index) => (
        <div key={index}>
          <Catalog title={item.title} description={item.description} url={item.url} imageUrl={item.imageUrl} />
        </div>
      ))}
    </ParagraphConfig>
  );
}
