import { FilterAxises } from '@jysk/api-types/wssFilterAxisApi/model';
import { RangeSlider } from '@wla/components/ui/range-slider';
import { filterActions, handleChangeFilter } from '@wla/lib/helpers/filter-change-handler';

type FilterSectionTypeProps = {
  id: string;
  filterAxises: FilterAxises;
  caption?: string;
  pathname: string;
  searchParams: URLSearchParams;
  queryFilters: string | null;
};

export function FilterTypeRange({ id, filterAxises, pathname, searchParams, queryFilters }: FilterSectionTypeProps) {
  const [selectedMin, selectedMax] = filterAxises.selectedValue?.split('_').map(Number) || [0, 0];

  return (
    <RangeSlider
      min={filterAxises.minPrice || 0}
      max={filterAxises.maxPrice || 0}
      thumbMin={selectedMin}
      thumbMax={selectedMax}
      onChange={(range) =>
        handleChangeFilter(
          {
            id,
            value: range.join('_'),
            handle: filterActions.OVERRIDE,
            caption: filterAxises.caption,
          },
          { pathname, searchParams, queryFilters },
        )
      }
    />
  );
}
