'use client';

import { ProductSticker, ProductStickerSize } from '@wla/components/ui/product/product-sticker';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { ProductLabelsItem } from '@jysk/api-types/drupalApi';

// Map StickerVariant to the string/label that will be displayed in the sticker ui
const StickerTranslationKeys = {
  basic: 'pdp.product.sticker-labels.basic',
  campaign: 'pdp.product.sticker-labels.campaign',
  coupon: 'pdp.product.sticker-labels.coupon', // only used for JCH
  discount: 'common.discount',
  everyDayLowPrice: 'pdp.product.sticker-labels.everyday-low-price',
  gold: 'pdp.product.sticker-labels.gold',
  greatOffer: 'pdp.product.sticker-labels.a-great-offer',
  new: 'pdp.product.sticker-labels.new',
  plus: 'pdp.product.sticker-labels.plus',
  specialSale: 'pdp.product.sticker-labels.special-sale', // only used for JFR
  whileStockLasts: 'pdp.product.sticker-labels.limited-stock',
  blackFriday: 'pdp.product.sticker-labels.black-friday',
} as const;

type ProductStickersProps = {
  stickers?: ProductLabelsItem[] | null;
  showBasicStickersOnly?: boolean;
  size?: ProductStickerSize;
  className?: string;
  discount?: number;
  defaultLocale?: string;
  isPdp?: boolean;
};

export function ProductStickers({
  className,
  discount,
  stickers,
  showBasicStickersOnly,
  size,
  isPdp = false,
}: ProductStickersProps) {
  const t = useTranslations();

  if (!stickers) return null;

  return (
    <div className={cn('pointer-events-none flex flex-wrap gap-1 lg:justify-start', className)}>
      {stickers.map((sticker) => {
        const isBasicSticker =
          sticker.includes(ProductLabelsItem.basic) ||
          sticker.includes(ProductLabelsItem.plus) ||
          sticker.includes(ProductLabelsItem.gold);

        if ((showBasicStickersOnly && isBasicSticker) || (!showBasicStickersOnly && !isBasicSticker)) {
          return (
            <ProductSticker
              key={sticker}
              text={
                sticker.includes(ProductLabelsItem.discount)
                  ? t('common.discount', { discount })
                  : t(StickerTranslationKeys[sticker as keyof typeof StickerTranslationKeys])
              }
              sticker={sticker}
              size={size}
              isPdp={isPdp}
            />
          );
        }
      })}
    </div>
  );
}
