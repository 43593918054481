import { Link } from '@wla/components/ui/link';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';
import type { HeroBannerPrimary } from '@jysk/api-types/drupalApi';

type ParagraphHeroPrimaryProps = {
  content: HeroBannerPrimary;
};

export function ParagraphHeroBannerPrimary({ content }: ParagraphHeroPrimaryProps) {
  const { config, image, link } = content;
  const HeroItem: React.ElementType = link ? Link : 'div';
  const imageObj = (isMobile && image?.mobile) || image?.desktop;

  return (
    // The aspect is hardcoded to match the aspect ratio of the image.
    <ParagraphConfig config={config}>
      <div className="w-full overflow-hidden md:aspect-[1372/578]">
        {imageObj && (
          <HeroItem {...(link && { href: link.url, target: link.target })}>
            <Image
              src={imageObj.url}
              alt={imageObj.alt}
              priority
              width={imageObj.dimensions?.width}
              height={imageObj.dimensions?.height}
              className="h-full w-full object-cover"
            />
          </HeroItem>
        )}
      </div>
    </ParagraphConfig>
  );
}
