import { useTabsContext } from '@wla/components/ui/tabs/tabs-context';
import { cn } from '@wla/lib/helpers/cn';
import { ReactNode } from 'react';

type TabProps<T> = {
  value: T;
  children: ReactNode;
};

export function Tab({ value, children }: TabProps<any>) {
  const { selectedTab, selectTab } = useTabsContext<typeof value>();

  return (
    <button
      data-value={value}
      className={cn('relative z-10 -mb-1 border-b-4 px-4 pb-3 font-semibold text-gray-500', {
        'border-blue-500 text-blue-500': selectedTab === value,
      })}
      onClick={() => selectTab(value)}
    >
      {children}
    </button>
  );
}
