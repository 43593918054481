'use client';

import { RemoveFilterButtons } from '@wla/app/(cms)/[...slug]/(filters)/remove-filter-buttons';
import { CategoryBanners } from '@wla/app/(cms)/[...slug]/(product-listing-page)/category-banners';
import { FilterBar } from '@wla/app/(cms)/[...slug]/(product-listing-page)/filter-bar';
import { SortOptions, sortProducts } from '@wla/app/(cms)/[...slug]/(product-listing-page)/product-list-sorting';
import { Text } from '@wla/components/ui/text';
import { isProductFamily } from '@wla/lib/helpers/product';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { initFilters, useFilter } from '@wla/lib/hooks/use-filter';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { ProductListPage, ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';
import { ProductList } from './product-list';

type ProductListingDataProps = {
  productsByCategory: Map<string, ProductTeaser[]>;
  page: ProductListPage;
  filters: initFilters;
};

type ProductStockDataProps = {
  products: (ProductTeaser | PostSearch200ProductsHitsItem)[];
  stockData?: OnlineAtpMap | null;
};

export function getProductStockData({ products, stockData }: ProductStockDataProps) {
  return products.reduce((acc, product) => {
    return { ...acc, [product.id]: stockData?.[product.id] };
  }, {});
}

export function ProductListingData({ productsByCategory, page, filters }: ProductListingDataProps) {
  const searchParams = useSearchParams();
  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const queryFilters = searchParams.get('filters');
  const hasSelectedFilters = Boolean(queryFilters);
  const querySort = searchParams.get('sort') as SortOptions;
  const [sort, setSort] = useState<SortOptions>(querySort);
  const categories = page.categories;
  const { data, loading } = useFilter({
    url: '/api/get-product-filters',
    externalId: page.externalId,
    initialFilters: filters,
  });

  const [stockData, setStockData] = useState<OnlineAtpMap | null>(null);
  const [isLoadingStock, setIsLoadingStock] = useState(true);

  const groupedProducts = new Map<string, ProductTeaser[]>();
  productsByCategory.forEach((products, category) => {
    const filteredProducts = products?.filter((product) => {
      const isFilteredProduct = data?.filteredarticles?.includes(product.id);
      const isGroupedProduct =
        (isProductFamily(product) && product.relations?.family?.defaultProductId === product.id) ||
        !isProductFamily(product);

      return hasSelectedFilters ? isFilteredProduct : isGroupedProduct;
    });
    filteredProducts?.length > 0 && groupedProducts.set(category, filteredProducts);
  });

  useEffect(() => {
    async function fetchStockStatus() {
      setIsLoadingStock(true);

      const articleIds = Array.from(productsByCategory)
        .flatMap(([_, products]) => products)
        .map((product) => product?.id)
        .filter(Boolean);

      const stockData = await fetchOnlineWithStoreStatusAtp(articleIds);

      setStockData(stockData);
      setIsLoadingStock(false);
    }

    fetchStockStatus();
  }, [productsByCategory]);

  return (
    <>
      <div className="mb-3 w-full gap-2 md:w-7/12 lg:mb-8">
        <h1 className="hyphens-auto text-pretty text-6xl font-semibold">{page.title}</h1>
        {page.description && (
          <div className="text-base">
            <Text value={page.description} />
          </div>
        )}
      </div>
      <CategoryBanners banners={categories} selectedId={page.externalId} />
      <FilterBar filters={data} groupedProducts={groupedProducts} onSortChange={setSort} isLoading={loading} />
      <div className="mb-4 w-full">
        <RemoveFilterButtons filterAxises={data?.filteraxises} />
        {Array.from(groupedProducts).map(([category, products], index) => (
          <ProductList
            key={`${category}-${index}`}
            title={category}
            isFirst={index === 0}
            products={sortProducts(products, sort)}
            stockStatus={getProductStockData({ products, stockData })}
            isLoadingStock={isLoadingStock}
            onProductClickTrackingUserAction={'PLP Product click'}
          />
        ))}
        {page.footerDescription && (
          <div className="mt-20 text-base lg:mt-32">
            <Text value={page.footerDescription} />
          </div>
        )}
      </div>
    </>
  );
}
