import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { PostSearch200ProductsFacetsSortedItemOneOf } from '@jysk/api-types/searchApi/model';
import { hasSelectedFilter } from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { FacetProps } from '@wla/app/(cms)/[...slug]/(filters)/filters-search';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';

type CheckboxFacetProps = FacetProps & {
  facet: PostSearch200ProductsFacetsSortedItemOneOf;
};

export function CheckboxFacet({
  facet,
  queryFilters,
  handleChangeSeachFilter,
  isOpen,
  pathname,
  searchParams,
}: CheckboxFacetProps) {
  return (
    <Disclosure as="div" key={facet.name} className="border-b" defaultOpen={isOpen}>
      {({ open }) => (
        <div className="flow-root">
          <DisclosureButton
            id={facet.name}
            as="button"
            className="my-5 flex h-12 w-full items-center justify-between text-gray-800 hover:text-black"
          >
            <div className="flex w-full items-center justify-between">
              <span className="text-md font-semibold text-gray-1000">{facet.name}</span>
            </div>
            <span className="ml-6 flex items-center">
              <Icon
                src="w3-arrow-down"
                size="20"
                className={cn('transform transition-transform', { 'rotate-180': open })}
              />
            </span>
          </DisclosureButton>

          <DisclosurePanel className="pb-6">
            <div className="space-y-3">
              {facet.values.map((checkbox) => (
                <Checkbox
                  id={checkbox.value}
                  key={checkbox.value}
                  name={checkbox.value}
                  value={checkbox.value}
                  checked={hasSelectedFilter(checkbox.value, queryFilters)}
                  className="flex cursor-pointer flex-row-reverse justify-between py-2"
                  disabled={checkbox.numberOfDocuments === 0}
                  size="lg"
                  onCheckedChange={() =>
                    handleChangeSeachFilter(
                      {
                        key: facet.name,
                        value: checkbox.value,
                      },
                      { pathname, searchParams, queryFilters },
                    )
                  }
                >
                  <span className="mr-auto">{checkbox.value}</span>
                  <span className="float-right pr-3 text-sm">{checkbox.numberOfDocuments}</span>
                </Checkbox>
              ))}
            </div>
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
}
