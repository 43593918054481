'use client';

import { MediaGroupName } from '@wla/app/(cms)/[...slug]/(product-detail-page)/helpers/product-images-mapping';
import { FullImageType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-image';
import { MappedMediaItem } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-media';
import { FullVideoType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-video';
import { Button } from '@wla/components/ui/button/button';
import { Carousel, CarouselItem } from '@wla/components/ui/carousel/carousel';
import { cn } from '@wla/lib/helpers/cn';
import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

type ProductGalleryDrawerProps = {
  medias: MappedMediaItem[];
  is360Image?: boolean;
  initialSlide?: number;
};

export function isVideo(media: FullVideoType | FullImageType): media is FullVideoType {
  return (media as FullVideoType).groupName === MediaGroupName.Video;
}

export function ProductGallerySwiper({ medias, is360Image = false, initialSlide }: ProductGalleryDrawerProps) {
  const t = useTranslations();
  const hasConsent = useCookieConsent(CookieTypesEnum.STATISTIC);
  const spriteRef = useRef<HTMLDivElement | null>(null);
  const mouseDownXClientRef = useRef<number>(0);
  const currBackgroundPositionXRef = useRef<number>(0);
  const [isMouseMoving, setIsMouseMoving] = useState(false);

  function handleOnButtonClick() {
    try {
      (window as Window).CookieConsent?.show();
    } catch (error) {
      trackError(error, { medias, is360Image, initialSlide });
    }
  }

  const rotate = useCallback((event: globalThis.MouseEvent) => {
    const totalNumberImages = 36;
    const imageWidth = 500;
    const animationHasBeenStopped = currBackgroundPositionXRef.current !== 0;

    const currImageStep = animationHasBeenStopped ? currBackgroundPositionXRef.current / imageWidth : 0;
    const stepCount = Math.floor(
      ((event.clientX - mouseDownXClientRef.current) / 10 + currImageStep) % totalNumberImages,
    );

    const coordinate100 = stepCount * imageWidth;
    const coordinate0 = animationHasBeenStopped ? coordinate100 : 18000;

    const keyframes = `@keyframes sprite-spin { 0%  {background-position: ${coordinate0}px}  100%  {background-position: ${coordinate100}px}`;

    const style = document.createElement('style');
    style.innerHTML = keyframes;
    spriteRef.current?.appendChild(style);
  }, []);

  function handleMouseDown(event: MouseEvent<HTMLElement>) {
    if (!spriteRef.current) return;

    setIsMouseMoving(true);
    spriteRef.current.style.animationPlayState = 'paused';
    mouseDownXClientRef.current = event.clientX;
    const currBackgroundPosition = getComputedStyle(spriteRef.current).getPropertyValue('background-position');
    currBackgroundPositionXRef.current = Number(currBackgroundPosition.split(' ')[0].slice(0, -2));
    document.addEventListener('mousemove', rotate);
    document.addEventListener('pointermove', rotate);
  }

  useEffect(() => {
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', rotate);
      setIsMouseMoving(false);
    });
    document.addEventListener('pointerup', () => {
      document.removeEventListener('pointermove', rotate);
    });
  }, []);

  return (
    <Carousel
      parentClass="h-full w-full"
      visibleItems={1}
      visibleItemsSm={1}
      visibleItemsMd={1}
      previewNextSlide={false}
      showPagination
      buttonOverflow={false}
      paginationClass="justify-center bottom-4 right-0"
      className={cn('h-full w-full', { 'cursor-grabbing': isMouseMoving })}
      initialSlide={initialSlide}
    >
      {medias.map((media, index) => (
        <CarouselItem
          key={isVideo(media) ? media.externalId : `${media.alt}-${index}`}
          className={cn(
            'flex h-full min-h-full w-full justify-center pr-0 md:px-16 md:py-10 [&_iframe]:h-full [&_iframe]:w-full',
            {
              'bg-gray-100': !is360Image,
            },
          )}
        >
          {isVideo(media) &&
            (hasConsent ? (
              <ReactPlayer controls width="100%" height="100%" url={`https://vimeo.com/${media.externalId}`} />
            ) : (
              <div className="m-auto text-center">
                <p className="pb-2">{t('pdp.please-accept-statistical-cookies')}</p>
                <Button variant="primary" onClick={handleOnButtonClick}>
                  {t('common.update-consent')}
                </Button>
              </div>
            ))}
          {!isVideo(media) &&
            (is360Image ? (
              <div className="flex w-full items-center justify-center">
                <div
                  id="spin-sprite"
                  ref={spriteRef}
                  onMouseDown={handleMouseDown}
                  onPointerDown={handleMouseDown}
                  className={cn('h-[500px] w-[500px] min-w-[500px] animate-sprite-spin cursor-pointer', {
                    'cursor-grabbing': isMouseMoving,
                  })}
                  style={{ backgroundImage: `url(${media.url})` }}
                />
              </div>
            ) : (
              <Image
                className="h-full object-contain object-center p-[4vh] mix-blend-darken"
                alt={media.alt}
                src={media.url}
                width={media.dimensions?.width ?? 1024}
                height={media.dimensions?.height ?? 705}
              />
            ))}
        </CarouselItem>
      ))}
    </Carousel>
  );
}
