import { NavigationItem } from '@wla/app/(cms)/navigation-drawer';
import { Drawer } from '@wla/components/ui/drawer';
import { Home } from '@wla/components/ui/home';
import { Icon } from '@wla/components/ui/icon';

export function Header({ title, stack, onClick }: { title: string; stack: NavigationItem[]; onClick(): void }) {
  return (
    <>
      {!stack.length && <Home />}
      {stack.length > 0 && (
        <>
          <button onClick={onClick} className="cursor-pointer">
            <Icon src="w3-arrow-left" size="20" className="font-bold" />
          </button>
          <Drawer.HeaderTitle title={title} />
        </>
      )}
    </>
  );
}
