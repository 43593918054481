'use client';

import { useEffect, useRef, useState } from 'react';

export function useKeyPress(targetKey: string, callback?: () => void) {
  // State for keeping track of whether key is pressed

  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    if (key === targetKey) {
      setKeyPressed(false);

      if (callback) {
        callback();
      }
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // eslint-disable-line
  return keyPressed;
}

export function useModalEscapePress(callback?: () => void) {
  // Ref for the close button inside a Modal. To check if this is the last opened modal
  const buttonRef = useRef<HTMLButtonElement>(null);

  // If released key is the Escape then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    if (key === 'Escape' && buttonRef.current && callback) {
      const headlessUiRoot = document.querySelector('#headlessui-portal-root');
      if (headlessUiRoot) {
        const lastOpenedModal = headlessUiRoot.querySelector('[data-headlessui-portal]:last-child');
        const modalElement = buttonRef.current.closest('[data-headlessui-portal]');

        if (modalElement === lastOpenedModal) {
          callback();
        }
      }
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // eslint-disable-line
  return buttonRef;
}
