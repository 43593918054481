import { z } from 'zod';

export const createReviewSchema = z.object({
  rating: z.coerce.number().gt(1, 'forms.errors.required.rating-is-required'),
  title: z.string().min(1, 'forms.errors.required.headline-is-required'),
  description: z.string().min(1, 'forms.errors.required.review-is-required'),
  name: z.string().min(1, 'forms.errors.required.name-is-required'),
  email: z.string().email('forms.errors.not-valid.email-not-valid'),
  acceptTermsAndConditions: z
    .any()
    .refine(
      (value) => value === true || value === 'true' || value === 'on',
      'common.please-accept-guidelines-and-terms-and-conditions',
    ),
});

export type CreateReviewFormData = z.infer<typeof createReviewSchema>;
