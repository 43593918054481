'use client';
import {
  Dialog as HeadlessDialog,
  DialogPanel as HeadlessDialogPanel,
  DialogTitle as HeadlessDialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { cn } from '@wla/lib/helpers/cn';
import { useModalEscapePress } from '@wla/lib/hooks/use-key-press';
import { Fragment, MouseEventHandler, ReactNode, createContext, forwardRef, useContext } from 'react';
import { Icon } from './icon';

const DialogContext = createContext<{
  open?: boolean | undefined;
  onClose: (value: boolean) => void;
} | null>(null);

function useDialog() {
  const dialog = useContext(DialogContext);

  if (!dialog) throw new Error('useDialog should only be called from inside a dialog');

  return dialog;
}

type DialogProps = Parameters<typeof HeadlessDialog>[0] & {
  isModal?: boolean;
};

export function Dialog({ className, children, onClose, open: show, isModal = false, ...rest }: DialogProps) {
  return (
    <Transition appear show={show} as={Fragment}>
      <HeadlessDialog className="relative z-modal" onClose={onClose} as="div" {...(rest as any)}>
        {({ open }) => {
          return (
            <DialogContext.Provider value={{ onClose, open }}>
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
              </TransitionChild>
              <div className="fixed inset-x-4 inset-y-8">
                <div
                  className={cn('flex min-h-full min-w-full justify-center', { 'items-center': isModal }, className)}
                >
                  <TransitionChild
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-y-full opacity-0"
                    enterTo="translate-y-0 opacity-100"
                    leave="transition ease-in-out duration-200 transform"
                    leaveFrom="translate-y-0 opacity-100"
                    leaveTo="-translate-y-full opacity-0"
                  >
                    {typeof children === 'function' ? children({ open }) : children}
                  </TransitionChild>
                </div>
              </div>
            </DialogContext.Provider>
          );
        }}
      </HeadlessDialog>
    </Transition>
  );
}

type DialogPanelProps = Parameters<typeof HeadlessDialogPanel>[0];

const DialogPanel = forwardRef<HTMLDivElement, DialogPanelProps>(({ className, children, ...rest }, forwardedRef) => {
  return (
    <HeadlessDialogPanel
      ref={forwardedRef}
      className={cn('absolute flex max-h-full w-full max-w-[400px] flex-col rounded bg-white p-6 shadow-lg', className)}
      {...(rest as any)}
    >
      {children}
    </HeadlessDialogPanel>
  );
});
DialogPanel.displayName = 'DialogPanel';

type DialogHeaderProps = {
  className?: string;
  children?: ReactNode;
};

const DialogHeader = forwardRef<HTMLDivElement, DialogHeaderProps>(({ className, children }, forwardedRef) => {
  return (
    <div className={cn('border-black/12.5 relative', className)} ref={forwardedRef}>
      {children}
    </div>
  );
});
DialogHeader.displayName = 'DialogHeader';

Dialog.Panel = DialogPanel;
Dialog.Title = DialogTitle;
Dialog.Header = DialogHeader;
Dialog.Close = DialogClose;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

type DialogTitleProps = Parameters<typeof HeadlessDialogTitle>[0];

function DialogTitle({ className, ...rest }: DialogTitleProps) {
  return <HeadlessDialogTitle className={cn('text-2xl font-bold', className)} {...(rest as any)} />;
}

function DialogClose() {
  const { onClose } = useDialog();

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClose?.(false);
    e.preventDefault();
  };

  const ref = useModalEscapePress(() => {
    onClose?.(false);
  });
  return (
    <button
      className="absolute right-0 top-0 cursor-pointer rounded-full text-lg leading-none"
      aria-label="close"
      onClick={onClick}
      ref={ref}
    >
      <div className="-translate-y-[1px]">
        <Icon size="20" src="w3-close" />
      </div>
    </button>
  );
}

type DialogBodyProps = {
  className?: string;
  children?: ReactNode;
};

function DialogBody({ className, children }: DialogBodyProps) {
  return <div className={cn('overflow-y-auto overflow-x-hidden', className)}>{children}</div>;
}

type DialogFooterProps = {
  className?: string;
  children?: ReactNode;
};

function DialogFooter({ className, children }: DialogFooterProps) {
  return (
    <div className={cn('border-black/12.5 flex flex-row-reverse justify-between gap-4 border-t p-4', className)}>
      {children}
    </div>
  );
}
