'use client';

import { PageContext } from '@wla/app/(cms)/[...slug]/page-provider';
import { usePublicConfig } from '@wla/lib/configs';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { trackPage } from '@wla/lib/tracking/google-analytics-tracking';
import { useContext, useEffect } from 'react';

type PageAnalyticsProps = {
  pageType: string;
};

export function PageAnalytics({ pageType }: PageAnalyticsProps) {
  const config = usePublicConfig();
  const { setPageType } = useContext(PageContext);

  // TODO: Make sure that customer is loaded here before sending gtm twice
  const { customerSession } = useCustomerSession();

  const language = config.defaultLocale.substring(0, 2) || '';
  const country = config.countryISOCode;

  useEffect(() => {
    setPageType(pageType);
    trackPage({ pageType, customerSession, language, country });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
