'use client';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { cn } from '@wla/lib/helpers/cn';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

type RadiobuttonProps = {
  value: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  id?: string;
  children?: ReactNode;
  labelStyling?: string;
};

export const Radiobutton = forwardRef(function Radiobutton(
  { value, children, labelStyling, className, disabled, required, id, isInvalid }: RadiobuttonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <>
      <RadioGroup.Item
        ref={ref}
        value={value}
        id={id}
        aria-invalid={isInvalid}
        aria-label={`${id} - ${value}`}
        disabled={disabled}
        className={cn(
          'flex h-5 w-5 min-w-5 items-center justify-center rounded-full border border-gray-400 hover:border-gray-500',
          'data-[state=checked]:border-blue-500 data-[state=checked]:bg-blue-500',
          'cursor-pointer data-[disabled]:border-gray-200 data-[disabled]:bg-gray-100',
          {
            'cursor-not-allowed': disabled,
          },
          className,
        )}
      >
        <RadioGroup.Indicator className="h-2.5 w-2.5 rounded-full bg-white" />
      </RadioGroup.Item>
      {children && (
        <label htmlFor={id} className={cn('ml-5', labelStyling)}>
          {children}
          {required && <span className="pl-1 text-sm">*</span>}
        </label>
      )}
    </>
  );
});
