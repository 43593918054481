'use client';
import { CSCContactInformationParagraph } from '@jysk/api-types/drupalApi';
import { LiveChatButton } from '@wla/app/(cms)/[...slug]/(faq-page)/live-chat-button';
import Image from '@wla/components/ui/image';
import { useTranslations } from 'next-intl';
import { ContactLine } from './contact-line';
import { InfoWarning } from './info-warning';
import { StoreButton } from './store-button';

type ParagraphContactInfoProps = {
  content: CSCContactInformationParagraph;
};

export function ParagraphContactInfo({ content }: ParagraphContactInfoProps) {
  const t = useTranslations();
  const { cscImage } = content;

  return (
    <div className="relative mt-16 w-full bg-blue-200 md:mt-0">
      {cscImage && (
        <Image
          alt={cscImage.alt}
          src={cscImage.url}
          width={cscImage.dimensions?.width}
          height={cscImage.dimensions?.height}
          className="absolute top-0 -translate-y-1/2 translate-x-8 rounded-full"
        />
      )}
      <div className="mx-6 mb-6 mt-20 flex flex-col gap-3">
        <h3 className="text-2xl font-semibold md:text-3xl">{t('customer-service.contact-customer-care')}</h3>
        {content.yellowNoticeMessage && <InfoWarning text={content.yellowNoticeMessage} />}

        <div className="my-4 flex flex-col">
          <LiveChatButton
            styleAsButton={false}
            onLineContent={
              <ContactLine
                icon="w3-chat"
                header={t('customer-service.live-chat-online')}
                subText={content.chatResponseTime ?? ''}
              />
            }
            offLineContent={
              <ContactLine
                icon="w3-chat"
                header={t('customer-service.live-chat-offline')}
                subText={content.chatResponseTime ?? ''}
              />
            }
          />
          {content.messengerChatEnabled && (
            <ContactLine
              icon="messenger"
              header={t('customer-service.chat-with-us-on-messenger')}
              subText={content.messengerChatResponseTime ?? ''}
              href={content.messengerChatLink ?? ''}
            />
          )}
          {content.whatsAppChatEnabled && (
            <ContactLine
              icon="whatsapp"
              header={t('customer-service.chat-with-us-on-whats-app')}
              subText={content.whatsAppChatResponseTime ?? ''}
              href={content.whatsAppChatLink ?? ''}
            />
          )}
          {content.cscPhoneNumber && (
            <ContactLine
              icon="w3-phone"
              header={content.cscPhoneNumber}
              subText={content.phoneResponseTime ?? ''}
              href={`tel:${content.cscPhoneNumber}`}
            />
          )}
          {/**TODO: This should open a fly in formula when we integrate with Salesforce */}
          {content.emailFormEnabled && (
            <ContactLine
              icon="w3-email"
              header={t('forms.email')}
              subText={content.emailFormResponseTime ?? ''}
              //TODO: The email comes from salesforce
              href={`mailto:${''}`}
            />
          )}
          <hr className="h-0 w-full border-gray-300" />
        </div>

        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-semibold md:text-2xl">
            {t('customer-service.opening-hours-for-customer-service')}
          </h4>
          <div dangerouslySetInnerHTML={{ __html: content.openingHours || '' }} />
          <StoreButton />
        </div>
      </div>
    </div>
  );
}
