import { GetFilterAxis200 } from '@jysk/api-types/wssFilterAxisApi/model';
import { formatFiltersToObject } from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

type UseFilterResult = {
  data: GetFilterAxis200 | null;
  loading: boolean;
  error: Error | null;
};

export type initFilters = GetFilterAxis200 | null;

type FiltersProps = {
  initialFilters: initFilters;
  url?: string;
  externalId?: string | null;
};

export function useFilter({ initialFilters, url, externalId }: FiltersProps): UseFilterResult {
  const searchParams = useSearchParams();
  const filters = searchParams.get('filters');
  const [data, setData] = useState<initFilters>(initialFilters);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchFilteredData() {
      setLoading(true);
      setError(null);
      if (!url) return;

      try {
        const formattedFilters = formatFiltersToObject(filters);
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({ externalId: externalId, filters: formattedFilters }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const { data } = await response.json();
        setData(data);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }

      setLoading(false);
    }

    fetchFilteredData();
  }, [searchParams, url, filters, externalId]);

  return { data, loading, error };
}
