import { FavouriteButton } from '@wla/components/ui/favourites/favourite-button';
import Image from '@wla/components/ui/image';
import { ProductSticker } from '@wla/components/ui/product/product-sticker';
import { ProductStickers } from '@wla/components/ui/product/product-stickers';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { ProductImageFull, ProductLabelsItem, ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';

type ProductCardHeaderProps = {
  product: ProductTeaser | PostSearch200ProductsHitsItem;
  url?: string;
  image?: ProductImageFull;
  isCompact?: boolean;
  isAboveTheFold?: boolean;
  stickers?: ProductLabelsItem[];
  onClick?(e: React.MouseEvent): void;
};

export function ProductCardHeader({
  product,
  image,
  isAboveTheFold = false,
  url,
  isCompact,
  onClick,
}: ProductCardHeaderProps) {
  const { labels, price } = product as ProductTeaser;
  const discountPercentage = price?.discount?.percentage ?? undefined;
  const hasBlackFridaySticker = labels?.includes(ProductLabelsItem.blackFriday);
  const t = useTranslations();
  const excludedStickers: ProductLabelsItem[] = [ProductLabelsItem.blackFriday];
  const stickers = labels?.filter((label: ProductLabelsItem) => !excludedStickers.includes(label));

  return (
    <div className={cn('group relative mb-4 flex w-full flex-col items-center', { 'mb-2': isCompact })}>
      <FavouriteButton product={product} className="absolute right-0 top-0 z-10 flex items-baseline p-2 md:p-3" />
      <a href={url} className="w-full" onClick={onClick} aria-label="Product link" role="link">
        <div className="relative aspect-[3/4] overflow-hidden bg-gray-100">
          <Image
            src={image?.url || ''}
            sizes="(max-width: 359px) 265px, (max-width: 430px) 158px, (max-width: 768px) 383px, (max-width: 992px) 240px, (max-width: 1200px) 255px, 255px"
            width={image?.dimensions?.width || 279}
            priority={isAboveTheFold}
            height={image?.dimensions?.height || 383}
            placeholder="empty"
            alt={image?.alt || ''}
            className="mx-auto h-full w-full object-contain object-center px-[10%] py-[20%] transition-transform group-hover:scale-110"
          />
          {hasBlackFridaySticker && (
            <div className="absolute top-5">
              <ProductSticker
                sticker={ProductLabelsItem.blackFriday}
                className="h-auto bg-[linear-gradient(25deg,_rgba(65,_100,_150,_1)_17%,_rgba(11,_15,_22,_1)_100%)] py-0 text-base uppercase"
                text={t('pdp.product.sticker-labels.black-friday')}
                size="xl"
              />
            </div>
          )}
          <ProductStickers
            discount={discountPercentage}
            stickers={stickers}
            className="absolute bottom-0 left-0 my-2.5 flex-col items-start lg:my-5"
          />
        </div>
      </a>
    </div>
  );
}
