import { Button } from '@wla/components/ui/button/button';
import { Spinner } from '@wla/components/ui/spinner';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';

type PaginationProgressbarProps = {
  pageSize: number;
  totalElements: number;
  onPageChange: (nextSizeOrPage: number) => void;
  isLoading?: boolean;
  currentPage?: number;
  currentSize?: number;
  className?: string;
};

export function PaginationProgressbar({
  currentPage = 1,
  pageSize,
  totalElements,
  onPageChange,
  isLoading,
  currentSize,
  className,
}: PaginationProgressbarProps) {
  const t = useTranslations('common');
  const numberOfElementsShowing = currentSize ?? Math.min(pageSize * currentPage, totalElements);

  function handleOnPageChange() {
    const next = currentSize !== undefined ? currentSize + pageSize : (currentPage + 1) * pageSize;
    numberOfElementsShowing !== totalElements && onPageChange(next);
  }

  return (
    <div className={cn('mt-8 flex flex-col justify-center', className)}>
      <div className="mt-10 flex gap-1">
        <p>{t('showing-x-of-y', { from: numberOfElementsShowing, to: totalElements })}</p>
      </div>
      <div className="my-4 h-1 w-80 rounded-full bg-gray-200">
        <div
          className="h-1 rounded-full bg-blue-500"
          style={{
            width: `${(100 / totalElements) * numberOfElementsShowing}%`,
          }}
        />
      </div>
      {numberOfElementsShowing !== totalElements && (
        <Button disabled={isLoading} variant="secondary" className="lg:w-fit" onClick={handleOnPageChange}>
          {isLoading ? <Spinner size="md" /> : t('show-more')}
        </Button>
      )}
    </div>
  );
}
