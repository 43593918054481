'use client';

import { ReactNode, createContext, useState } from 'react';

type NewRelicContextType = {
  context: string | null;
  setContext: (context: string) => void;
};

export const NewRelicContext = createContext<NewRelicContextType>({
  context: null,
  setContext: () => {
    return;
  },
});

type NewRelicProviderProps = {
  children: ReactNode;
};

export function NewRelicProvider({ children }: NewRelicProviderProps) {
  const [newRelicContext, setNewRelicContextInternal] = useState<string | null>(null);

  return (
    <NewRelicContext.Provider value={{ context: newRelicContext, setContext: setNewRelicContextInternal }}>
      {children}
    </NewRelicContext.Provider>
  );
}
