'use client';
import type { drupalApi } from '@jysk/api-client';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';
import { Carousel, CarouselItem } from '@wla/components/ui/carousel/carousel';
import { ProductCard } from '@wla/components/ui/product-card/product-card';
import { useProductVisibility } from '@wla/lib/hooks/use-product-visibility';

export type ProductCardCarousel = {
  products: drupalApi.GetProductsResult['response']['data'];
  stockData?: OnlineAtpMap | null;
  refId?: string;
};

export function ProductCardCarousel({ products, stockData, refId }: ProductCardCarousel) {
  const handleOnProductVisible = useProductVisibility(refId);

  return (
    <Carousel>
      {products?.map((product) => (
        <CarouselItem key={product.id}>
          <ProductCard
            product={product}
            onProductVisible={handleOnProductVisible}
            refId={refId}
            stockStatus={stockData?.[product.id]}
          />
        </CarouselItem>
      ))}
    </Carousel>
  );
}
