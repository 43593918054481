import { PostSearchBodyFiltersItem } from '@jysk/api-types/searchApi/model';
import { FilterAxises } from '@jysk/api-types/wssFilterAxisApi/model';

export function decodeURIComponentSafely(uri: string) {
  try {
    return decodeURIComponent(uri);
  } catch (e) {
    return uri;
  }
}

/*
 * Format query string to filters for post search body
 * @param filterString - string of filters from URL
 * @returns array of filters
 */
export function formatQueryToPostSearchBodyFilters(filterString: string): PostSearchBodyFiltersItem[] {
  const filters = decodeURIComponentSafely(filterString)?.split(',');
  const getQuery = filters?.map((filter) => {
    const [name, values] = filter?.split(':') ?? [];
    if (values?.includes('-')) {
      const [min, max] = values.split('-');
      if (parseInt(min, 10) && parseInt(max, 10)) {
        return { name, min: parseInt(min, 10), max: parseInt(max, 10) };
      }
    }
    return { name, values: values?.split('|') };
  });
  return getQuery;
}

export function hasSelectedFilter(value: string, query: string | null): boolean {
  if (!query) return false;
  const filters = decodeURIComponentSafely(query)?.split(',');
  return filters.some((filter) => {
    const [names, values] = filter?.split(':') ?? [];
    return (values || names)?.split(/[|,]/).some((val) => val === value);
  });
}

/*
 * Format filters to URL string ({"f_68": "Andedunsdyne", "f_43": ["Ja", "Øvrige"]} -> f_68:Andedunsdyne,f_43:Ja|Øvrige)
 * @param filters - object of filters from WSS
 * @returns string of filters
 */
export function formatFiltersToString(filters: Record<string, string[]> | undefined) {
  if (!filters) return '';
  return Object.entries(filters)
    .map(([id, value]) => {
      if (id === value[0]) {
        return value[0];
      }

      return `${id}:${value.join('|')}`;
    })
    .join(',');
}

/*
 * Format filters to WSS format (f_68:Andedunsdyne,f_43:Ja|Øvrige -> {"f_68": "Andedunsdyne", "f_43": ["Ja", "Øvrige"]} )
 * @param filters - string of filters from URL
 * @returns object of filters
 */
export function formatFiltersToObject(query: string | null) {
  if (!query) return {};
  const filters = decodeURIComponentSafely(query).split(',');
  return Object.fromEntries(
    filters.map((filter) => {
      if (filter.includes(':') || filter.includes('|')) {
        const [id, value] = filter.split(':');
        return [id, value.split(/[|,]/)];
      }
      return [filter, [filter]];
    }),
  );
}

/*
 * Get the parent id of a filterAxis
 * @param filterAxisId - id of filterAxis
 * @returns id of parent filterAxis eg. f_123_1 -> f_123
 */
export function getParentId(filterAxisId: string) {
  const id = filterAxisId.split('_')[1];
  return `f_${id}`;
}

/*
 * Get the type of filterAxis
 * @param filterAxis - filterAxis object
 * @returns type of filterAxis
 */
export function getFilterAxisType(filterAxis: FilterAxises): 'range' | 'bool' {
  return filterAxis.minPrice && filterAxis.maxPrice ? 'range' : 'bool';
}
