'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { LoginTab } from '@wla/app/(cms)/(customer)/customer/login-tab';
import { ZipcodeDrawerForm } from '@wla/app/checkout/zipcode-drawer-form';
import { Drawer } from '@wla/components/ui/drawer';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { SchemaFormType, useSchemas } from '@wla/lib/configs';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

export const CheckoutZipDrawer = NiceModal.create(() => {
  const t = useTranslations();
  const modal = useModal();
  const [toggleUseAccountInfo, setToggleUseAccountInfo] = useState(false);
  const { customerSession } = useCustomerSession();
  const { schema, fields } = useSchemas(SchemaFormType.CheckoutZipDrawer);

  return (
    <Drawer open={modal.visible} onClose={modal.hide}>
      <Drawer.Header title={t('checkout.areacode-drawer.title')} />
      <Drawer.Body className="flex flex-col gap-4">
        {schema && fields && <ZipcodeDrawerForm modal={modal} schema={schema} fields={fields} />}

        {!customerSession && (
          <>
            <Checkbox
              checked={toggleUseAccountInfo}
              onCheckedChange={(checked) => {
                setToggleUseAccountInfo(checked);
              }}
              className="pb-4"
            >
              {t('checkout.areacode-drawer.use-account-label')}
            </Checkbox>

            {toggleUseAccountInfo && <LoginTab onSubmitSuccess={modal.hide} />}
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
});
