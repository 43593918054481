'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@wla/components/ui/dialog';
import { useTranslations } from 'next-intl';

export enum FavouritesAction {
  Add = 'Add',
  Delete = 'Delete',
}

type FavouritesErrorModalType = {
  productId?: string;
  productIds?: string[];
  actionType: FavouritesAction;
};

export const FavouritesErrorModal = NiceModal.create(
  ({ productId, productIds, actionType }: FavouritesErrorModalType) => {
    const t = useTranslations('customer');
    const modal = useModal();

    return (
      <Dialog open={modal.visible} onClose={modal.hide} isModal>
        <Dialog.Panel>
          <Dialog.Header>
            <Dialog.Title>
              <div className="text-xl normal-case">{t('errors.favourites-list-error')}</div>
            </Dialog.Title>
            <Dialog.Close />
          </Dialog.Header>
          <Dialog.Body className="space-y-5">
            {actionType === FavouritesAction.Add
              ? t('errors.error-modal-body-add', {
                  productIds: productIds?.join(', ') || '',
                })
              : t('errors.error-modal-body-delete', {
                  productId: productId,
                })}
          </Dialog.Body>
        </Dialog.Panel>
      </Dialog>
    );
  },
);
