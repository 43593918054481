import { ProductCardStockStatus } from '@wla/components/ui/product-card/product-card-stock-status';
import { DeliveryTermsButton } from '@wla/components/ui/product/delivery-terms-button';
import { ProductPrice } from '@wla/components/ui/product/product-price';
import { ProductStickers } from '@wla/components/ui/product/product-stickers';
import { Rating } from '@wla/components/ui/rating/rating';
import { usePublicConfig } from '@wla/lib/configs';
import { PageType } from '@wla/lib/helpers/(analytics)/get-page-type';
import { useProductAtp } from '@wla/lib/hooks/product-atp/use-product-atp';
import { ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { OnlineAtpWithStoreStatusMap } from '@jysk/api-types/webSapApiV2/model/onlineAtpWithStoreStatusMap';

type ProductCardInfoProps = {
  product: ProductTeaser | PostSearch200ProductsHitsItem;
  url?: string;
  stockStatus?: OnlineAtpWithStoreStatusMap;
  isLoadingStock?: boolean;
  onClick?(e: React.MouseEvent): void;
};

export function ProductCardInfo({ product, stockStatus, isLoadingStock, url, onClick }: ProductCardInfoProps) {
  const { labels, rating, title, price, isOnlineSales, relations, shouldShowCheapestPriceNotice } =
    product as ProductTeaser;

  const { getOnlineStockStatus, getStoreStockStatus } = useProductAtp();
  const { featureFlags } = usePublicConfig();

  const onlineStatus = getOnlineStockStatus({
    productId: product.id,
    onlineQuantity: stockStatus?.onlineAtp?.dcAtp?.quantity ?? 0,
    nextAtpDate: stockStatus?.onlineAtp?.nextAtp?.[0]?.availabilityDate,
    isOnlineSales,
    type: PageType.PRODUCT_LIST_PAGE,
  });

  const storeStatus = getStoreStockStatus({
    product: product as ProductTeaser,
    type: PageType.PRODUCT_LIST_PAGE,
    storesWithAtp: stockStatus?.store?.totalNumberStoresWithATP ?? 0,
    onlineAtp: stockStatus?.onlineAtp?.dcAtp?.quantity ?? 0,
    selectedStoreUnit: Number(stockStatus?.store?.inStockInSpecifedStore),
  });

  return (
    <div className="flex h-full flex-col">
      <div className="flex justify-between">
        <a href={url} onClick={onClick} aria-label="Product link" role="link" className="text-left">
          <h4 className="block text-sm font-bold uppercase hover:underline md:text-base">
            {relations?.series?.seriesId || relations?.brand?.name}
          </h4>
          <h3 className="my-1 block text-sm hover:underline md:text-base">{title}</h3>
        </a>
        <ProductStickers stickers={labels} showBasicStickersOnly className="flex-col items-end gap-2" />
      </div>
      <Rating rating={rating} iconSize="text-lg md:text-xl" />
      {price?.formatted && (
        <ProductPrice
          className="text-2xl md:text-3xl"
          price={price.formatted?.gross ?? price.formatted?.grossWithUnit}
          unit={price.formatted.unit}
          additionalPriceInfo={price.additionalPriceInfo}
          shouldShowCheapestPriceNotice={shouldShowCheapestPriceNotice}
          beforePriceSecondary={price.formatted.beforePriceSecondary}
          beforePrice={price.formatted.beforePrice}
          discount={price.discount}
          discountSecondary={price.discountSecondary}
          isProductCard
          netWithUnit={price.formatted?.netWithUnit}
        />
      )}
      {isOnlineSales && <DeliveryTermsButton className="text-xs md:text-sm" />}
      {featureFlags.showStockStatusOnProductCards && (
        <ProductCardStockStatus
          isLoading={isLoadingStock}
          showOnlineStock={featureFlags.showOnlineStock}
          onlineStockStatus={onlineStatus}
          showStoreStock={featureFlags.showStoreStock}
          storeStockStatus={storeStatus}
        />
      )}
    </div>
  );
}
