import type { Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme';

export const breakpoints = {
  xs: 360,
  s: 500,
  sm: 640,
  md: 768,
  lg: 992,
  xl: 1280,
  '2xl': 1536,
} as const;

export type Breakpoints = typeof breakpoints;

const ramps = {
  blue: {
    50: '#58CDF4',
    100: '#dbedf9',
    200: '#F5F7FA',
    300: '#E4ECF3',
    400: '#C7D1DE',
    500: '#143C8A',
    600: '#1F326C',
  },
  brand: {
    400: '#E4ECF3',
    500: '#034795',
  },
  gradient: {
    300: '#E4ECF3',
    500: '#034795',
  },
  gray: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#E5E5E5',
    300: '#D4D4D4',
    400: '#A3A3A3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
    1000: '#000000',
  },
  green: {
    100: '#E8F7DE',
    500: '#239343',
  },
  yellow: {
    100: '#FFEDB8',
    200: '#F7F4E6',
    300: '#7E743C',
    500: '#F5C740',
  },
  red: {
    100: '#F8DDDD',
    500: '#C02931',
  },
};

const config: Config = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
    './lib/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    screens: Object.entries(breakpoints).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: `${value}px`,
      }),
      {},
    ),

    extend: {
      fontFamily: {
        sans: ['var(--font-open-sans)', 'Open Sans', ...defaultTheme.fontFamily.sans],
        italic: ['var(--font-open-sans-italic)', 'Open Sans', ...defaultTheme.fontFamily.sans],
        fox: ['var(--font-fox-in-the-snow)', 'Fox in the snow'],
        icon: ['var(--font-jysk-icons)'],
      },
      padding: {
        4.5: '1.125rem',
      },
      zIndex: {
        spinner: '10000',
        modal: '1000',
        drawerHeader: '1001',
        basket: '100',
      },
      colors: {
        // Exports semantic color names for use in the UI.
        brand: ramps.brand,

        red: ramps.red,
        green: ramps.green,
        blue: ramps.blue,
        yellow: ramps.yellow,
        gray: ramps.gray,

        section: ramps.blue['600'],
        // border: ramps.gray['300'],

        success: ramps.green['500'],
        danger: ramps.red['500'],
        warning: ramps.yellow['500'],
        info: ramps.blue['400'],

        // Sticker variants
        basic: ramps.blue['300'],
        plus: ramps.gray['200'],
        gold: ramps.yellow['200'],
        campaign: ramps.red['500'],
        coupon: ramps.red['500'],
        specialSale: ramps.red['500'],
        new: ramps.green['500'],
        discount: ramps.red['500'],
        whileStockLasts: ramps.red['500'],
        blackFriday: ramps.gray['1000'],
        everyDayLowPrice: ramps.gray['1000'],
        greatOffer: ramps.blue['500'],
      },
      animation: {
        spinner: 'fade-out 1.2s linear infinite;',
        'fade-in': 'fade-in 3s;',
        'slide-in-from-right': 'slide-in-from-right .3s ease-out forwards',
        'slide-in-from-left': 'slide-in-from-left .3s ease-out forwards',
        'sprite-spin': 'sprite-spin 3.5s steps(36) forwards',
        skeleton: 'skeleton 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      },
      keyframes: {
        'fade-out': {
          '0%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
        'fade-in': {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
        'slide-in-from-right': {
          '0%': {
            transform: 'translateX(100%)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
        'slide-in-from-left': {
          '0%': {
            transform: 'translateX(-100%)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
        'sprite-spin': {
          '0%': {
            'background-position': '18000px',
          },
          '100%': {
            'background-position': '0',
          },
        },
        skeleton: {
          '0%, 100%': {
            opacity: '1',
          },
          '50%': {
            opacity: '.5',
          },
        },
      },
    },
    fontSize: {
      xs: ['0.625rem', { lineHeight: '1.5' }], //  10px
      sm: ['0.75rem', { lineHeight: '1.5' }], //  12px
      base: ['0.875rem', { lineHeight: '1.5' }], //  14px
      lg: ['1rem', { lineHeight: '1.5' }], //  16px
      xl: ['1.125rem', { lineHeight: '1.5' }], //  18px
      '2xl': ['1.25rem', { lineHeight: '1.5' }], //  20px
      '3xl': ['1.5rem', { lineHeight: '1.5' }], //  24px
      '4xl': ['2rem', { lineHeight: '1.5' }], //  32px
      '5xl': ['2.5rem', { lineHeight: '1.5' }], //  40px
      '6xl': ['3rem', { lineHeight: '1.5' }], //  48px
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/container-queries'),
    require('tailwindcss-animation-delay'),
  ],
};
export default config;
