import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { cn } from '@wla/lib/helpers/cn';
import { BackgroundColorWithOverlayParagraph } from '@jysk/api-types/drupalApi';
import { Button } from '../button/button';
import { Text } from '../text';

type ParagraphBackgroundColorWithOverlayProps = {
  content: BackgroundColorWithOverlayParagraph;
};

export function ParagraphBackgroundColorWithOverlay({ content }: ParagraphBackgroundColorWithOverlayProps) {
  const { config, backgroundColor } = content;
  const overlay = content.overlay;
  const contentPosition = overlay.position;
  const contentHref = overlay.cta?.url;

  return (
    <ParagraphConfig className="h-auto" config={config} backgroundColor={backgroundColor}>
      <div
        // min height is agreed with Michael
        className="relative min-h-[240px] p-4"
        style={{
          backgroundColor: `${content.backgroundColor}`,
        }}
      >
        {/* Overlay */}
        <div
          // max and min widths are to make sure the overlay look optimal
          className={cn('absolute min-w-[70%] max-w-[90%] p-4 text-center', {
            'left-4 top-4': contentPosition === 'top_left',
            'left-2/4 top-4 -translate-x-2/4': contentPosition === 'top_center',
            'right-4 top-4': contentPosition === 'top_right',
            'left-4 top-2/4 -translate-y-2/4': contentPosition === 'center_left',
            'left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4': contentPosition === 'center',
            'right-4 top-2/4 -translate-y-2/4': contentPosition === 'center_right',
            'bottom-4 left-4': contentPosition === 'bottom_left',
            'bottom-4 left-2/4 -translate-x-2/4': contentPosition === 'bottom_center',
            'bottom-4 right-4': contentPosition === 'bottom_right',
          })}
          style={{
            backgroundColor: `${overlay.backgroundColor}`,
          }}
        >
          {overlay.text && <Text value={overlay.text} />}
          {overlay.cta && (
            <>
              {overlay.ctaStyle.type === 'primary' && (
                <Button className="mt-2" as="a" href={contentHref} size="medium" variant="primary">
                  {overlay.cta.title}
                </Button>
              )}
              {overlay.ctaStyle.type === 'secondary' && (
                <Button className="mt-2" as="a" href={contentHref} size="medium" variant="secondary">
                  {overlay.cta.title}
                </Button>
              )}
              {overlay.ctaStyle.type === 'custom' && (
                <Button
                  className="mt-2"
                  style={
                    overlay.ctaStyle.type === 'custom'
                      ? {
                          backgroundColor: overlay.ctaStyle.backgroundColor || undefined,
                          color: overlay.ctaStyle.fontColor || undefined,
                        }
                      : undefined
                  }
                  as="a"
                  href={contentHref}
                  size="medium"
                  variant="primary"
                >
                  {overlay.cta.title}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </ParagraphConfig>
  );
}
