import { calculateViewport } from '@wla/app/(cms)/[...slug]/(store-locator)/calculate-viewport';
import { usePrevious } from '@wla/lib/hooks/use-previous';
import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

export type LatLngLiteral = { latitude: number; longitude: number };

export type ViewPort = {
  northeast: LatLngLiteral;
  southwest: LatLngLiteral;
};

export function useStoreLocation(threshold = 500) {
  const [permission, setPermission] = useState<boolean>();
  const [location, setLocation] = useState<LatLngLiteral>();
  const [viewPort, setViewPort] = useState<ViewPort>();
  const [searchValue, setSearchValue] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const debouncedSearchValue = useDebounceValue(searchValue, threshold);
  const previousSearchValue = usePrevious(debouncedSearchValue[0]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      () => {
        setPermission(true);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setPermission(false);
        }
      },
    );
  }, []);

  function fetchLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      setViewPort(calculateViewport(position.coords.latitude, position.coords.longitude));
    });
  }

  useEffect(() => {
    if (permission && !location) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    }
  }, [location, permission]);

  useEffect(() => {
    async function getLocation() {
      if (debouncedSearchValue[0].length > 2 && debouncedSearchValue[0] !== previousSearchValue) {
        const response = await fetch(`/checkout/api/geocode`, {
          method: 'POST',
          body: JSON.stringify({ query: debouncedSearchValue[0] }),
        });

        const { data } = await response.json();
        setLocation({ latitude: data.location.latitude, longitude: data.location.longitude });
        setViewPort(data.viewport);
      }
    }

    if (debouncedSearchValue[0].length > 0) {
      setHasSearched(true);
    }

    getLocation();
  }, [debouncedSearchValue[0], previousSearchValue]);

  return { location, viewPort, permission, fetchLocation, setSearchValue, searchValue, hasSearched };
}
