import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { FavoriteStoreFilters, FilterState } from '@wla/app/(cms)/(favorite-store)/favorite-store-filters';
import { FavoriteStoreList, StoreAtp, StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { FavoriteStoreSearch } from '@wla/app/(cms)/(favorite-store)/favorite-store-search';
import { StoreDrawer } from '@wla/app/(cms)/(favorite-store)/store-drawer/store-drawer';
import { useStoreLocation } from '@wla/app/(cms)/(favorite-store)/use-store-location';
import { useStores } from '@wla/app/(cms)/(favorite-store)/use-stores';
import { getClickCollectOrder, getProductIdsFromOrder } from '@wla/app/checkout/order-states';
import { Alert } from '@wla/components/ui/alert';
import { Spinner } from '@wla/components/ui/spinner';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';

type FavoriteDrawerProps = {
  product?: ProductFull | ProductTeaser;
  showInStoreFilter?: boolean;
};

export const FavoriteDrawer = NiceModal.create(({ product, showInStoreFilter }: FavoriteDrawerProps) => {
  const t = useTranslations();
  const modal = useModal();
  const { location, setSearchValue, hasSearched } = useStoreLocation();
  const { getStores, isLoading } = useStores();
  const { orders } = useShoppingSession();

  const { fetchStoresAtp } = useAtp();

  const defaultFilters = { isInStock: false, isShowInStore: showInStoreFilter || false };
  const [stores, setStores] = useState<StoreType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<FilterState>(defaultFilters);

  const clickCollectOrder = getClickCollectOrder(orders);
  const clickCollectProductIds = useMemo(() => getProductIdsFromOrder(clickCollectOrder), [clickCollectOrder]);
  const [clickCollectProductStoreAtp, setClickCollectProductStoreAtp] = useState<StoreAtp[]>([]);

  useEffect(() => {
    async function fetchStores() {
      const stores = await getStores({ location, productId: product?.id });
      setStores(stores || []);
      setSelectedFilters(defaultFilters);
    }
    fetchStores();
  }, [location, product?.id]);

  const handleSelectOption = useCallback(
    async (storeId: string) => {
      const selectedStore = stores.find((store) => store.id === storeId);

      if (!selectedStore) return;

      setSearchValue('');
      modal.resolve(selectedStore);
      modal.hide();
    },
    [stores],
  );

  const handleFilter = useCallback(
    ({ isInStock, isShowInStore }: FilterState) => {
      setSelectedFilters({ isInStock, isShowInStore });
    },
    [setSelectedFilters],
  );

  const filteredStores = useMemo(
    () =>
      stores.filter((store) => {
        const isInStock = !selectedFilters.isInStock || (store.atp?.units && store.atp.units > 0);
        const isOnDisplay = !selectedFilters.isShowInStore || store.atp?.onDisplay;

        return isInStock && isOnDisplay;
      }),
    [stores, selectedFilters.isInStock, selectedFilters.isShowInStore],
  );

  useEffect(() => {
    const fetchStoresForClickCollectBasket = async () => {
      if (!clickCollectProductIds?.length) return;

      const atpPromises = await Promise.all(
        clickCollectProductIds.map(async (productId) => await fetchStoresAtp(productId)),
      );

      const data = atpPromises.filter(Boolean) as StoreAtp[];

      setClickCollectProductStoreAtp(data);
    };

    fetchStoresForClickCollectBasket();
  }, [clickCollectProductIds]);

  return (
    <StoreDrawer
      isOpen={modal.visible}
      onClose={() => {
        modal.resolve({});
        modal.hide();
      }}
      title={product ? t('favorite-store.select-store') : t('favorite-store.select-your-jysk-store')}
    >
      {(hasSearched || stores?.length > 5) && <FavoriteStoreSearch setSearchValue={setSearchValue} />}
      {product?.id && <FavoriteStoreFilters onFilter={handleFilter} initialFilters={selectedFilters} />}

      {isLoading && (
        <div className="flex w-full justify-center pt-10">
          <Spinner size="lg" />
        </div>
      )}

      {!isLoading && stores.length > 0 && (
        <FavoriteStoreList
          stores={filteredStores}
          onClick={handleSelectOption}
          product={product}
          clickCollectBasketProductIds={clickCollectProductStoreAtp}
        />
      )}

      {!isLoading && !stores.length && (
        <Alert type="error" className="text-base">
          {t('favorite-store.errors.an-error-occurred-when-finding-store')}
        </Alert>
      )}
    </StoreDrawer>
  );
});
