'use client';
import NiceModal from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { TextArea } from '@wla/components/ui/forms/textArea';
import { GiftcardPreview } from '@wla/components/ui/giftcard/giftcard-preview';
import { countOrderLinesInOrder } from '@wla/lib/helpers/shopping-session';
import { DRAWER_ID } from '@wla/lib/modals';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { OrderType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import { CreateGiftcardAction } from './giftcard-actions';
import { CreateGiftcardFormData, createGiftcardSchema } from './giftcard-schema';

export function GiftcardForm() {
  const initialState = {
    success: false,
    message: '',
  };

  const t = useTranslations();
  const [result, formAction] = useFormState(CreateGiftcardAction, initialState);
  const { refetchShoppingSession } = useShoppingSession();
  const [messageLength, setMessageLength] = useState(0);

  const form = useForm<CreateGiftcardFormData>({
    mode: 'onBlur',
    resolver: zodResolver(createGiftcardSchema),
  });

  const {
    getValues,
    register,
    reset,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (result.success) {
      NiceModal.show(DRAWER_ID.POWER_STEP_DRAWER, {
        quantity: 1,
        totalInBasket: result.order ? countOrderLinesInOrder(result.order) : 0,
        line: result.order?.deliveryGroups?.[0].orderLines?.[result.order?.deliveryGroups?.[0].orderLines?.length - 1],
        orderType: OrderType.GiftCard,
        totalPrice: result.order?.totalPriceText,
        recommendedProducts: [],
      });

      refetchShoppingSession();

      reset();
    }
  }, [result, reset]);

  function handleOnTextAreaChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setMessageLength(event.target.value.length);
  }

  return (
    <Form form={form} action={formAction}>
      <h3 className="pb-2 text-base font-bold md:pb-3">{t('common.gift-card')}</h3>
      <div className="relative pb-4">
        <FormGroup
          label={t('common.amount')}
          inputType={InputTypes.Input}
          required
          validationError={errors.amount?.message && t('gift-card.errors.not-valid-amount')}
        >
          <Input
            {...register('amount', {
              valueAsNumber: true,
            })}
            type="number"
          />
        </FormGroup>
      </div>
      <p className="pb-2 text-base font-bold md:pb-4">{t('gift-card.write-a-personal-greeting')}</p>
      <FormGroup
        label={t('gift-card.receivers-name')}
        className="pb-3 md:pb-4"
        inputType={InputTypes.Input}
        required
        validationError={errors.receiverName?.message && t('gift-card.errors.not-valid-receiver')}
      >
        <Input {...register('receiverName')} />
      </FormGroup>
      <FormGroup
        label={t('gift-card.your-name')}
        className="pb-3 md:pb-4"
        inputType={InputTypes.Input}
        required
        validationError={errors.buyerName?.message && t('gift-card.errors.not-valid-buyer')}
      >
        <Input {...register('buyerName')} />
      </FormGroup>
      <FormGroup
        label={t('forms.email')}
        inputType={InputTypes.Input}
        required
        validationError={errors.email?.message && t('forms.errors.not-valid.email-not-valid')}
      >
        <Input {...register('email')} />
      </FormGroup>
      <p className="pb-3 pt-0.5 text-sm text-gray-500 md:pb-4">{t('gift-card.pdf-text')}</p>
      <FormGroup
        label={t('gift-card.gift-card-header')}
        className="pb-3 md:pb-4"
        inputType={InputTypes.Input}
        required
        validationError={errors.title?.message && t('gift-card.errors.not-valid-title')}
      >
        <Input {...register('title')} />
      </FormGroup>
      <FormGroup label={t('gift-card.optional-message')} inputType={InputTypes.TextArea} className="pb-4">
        <TextArea
          {...register('message')}
          onChange={handleOnTextAreaChange}
          maxLength={300}
          className="h-40"
          resize="none"
          counter={messageLength}
        />
      </FormGroup>
      <div className="flex flex-col gap-2 md:flex-row">
        <SubmitButton className="btn primary btn-default">{t('common.add-to-basket')}</SubmitButton>
        <GiftcardPreview getValues={getValues} />
      </div>
    </Form>
  );
}
