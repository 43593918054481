'use client';
import { Link } from '@wla/components/ui/link';
import { useTranslations } from 'next-intl';
import { Button } from './button/button';

type CatalogProps = {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
};

export function Catalog({ title, description, url, imageUrl }: CatalogProps) {
  const t = useTranslations('common');

  return (
    <div className="flex flex-col gap-5 pb-8 md:flex-row md:gap-14 lg:pb-14">
      <Link href={url} className="flex w-full items-center justify-center bg-gray-100 hover:cursor-pointer">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={imageUrl} alt={title} className="w-36 py-8 md:w-40 md:py-24" />
      </Link>
      <div className="flex flex-col md:min-w-[40%]">
        <div className="mb-2 text-lg font-semibold md:text-4xl lg:text-5xl">{title}</div>
        <div className="mb-3 lg:text-lg">{description}</div>
        <Button as="a" href={url} variant="primary" className="w-fit">
          {t('click-here')}
        </Button>
      </div>
    </div>
  );
}
