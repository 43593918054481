import { z } from 'zod';

export const createGiftcardSchema = z.object({
  email: z.string().email('forms.errors.not-valid.email-not-valid'),
  amount: z
    .number()
    .int('gift-card.errors.not-valid-amount-int')
    .nonnegative('gift-card.errors.not-valid-amount-negative')
    .min(100, { message: 'gift-card.errors.not-valid-amount-min' })
    .max(5000, { message: 'gift-card.errors.not-valid-amount-max' }),
  receiverName: z.string().min(1, 'gift-card.errors.not-valid-receiver'),
  buyerName: z.string().min(1, 'gift-card.errors.not-valid-buyer'),
  title: z.string().min(1, 'gift-card.errors.not-valid-title'),
  message: z.string().max(300, 'gift-card.errors.not-valid-message'),
});
export type CreateGiftcardFormData = z.infer<typeof createGiftcardSchema>;
