import { StatusIndicatorStatus } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { PageType } from '@wla/lib/helpers/(analytics)/get-page-type';
import { JSXElementConstructor, ReactElement, ReactNodeArray } from 'react';
import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';

export enum OnlineStockStatus {
  ExpectedBackInStock = 'ExpectedBackInStock',
  FewLeftInStock = 'FewLeftInStock',
  InStock = 'InStock',
  OutOfStock = 'OutOfStock',
  TemporaryOutOfStock = 'TemporaryOutOfStock',
  OutOfStockInStores = 'outOfStockInStores',
  OutOfStockInStoresButCanBeOrdered = 'outOfStockInStoresButCanBeOrdered',
  NotAvailableInStoresButCanBeOrdered = 'NotAvailableInStoresButCanBeOrdered',
  NotAvailableInStores = 'NotAvailableInStores',
}

export enum StoreStockStatus {
  InStock = 'InStock',
  OutOfStockInStores = 'outOfStockInStores',
  OutOfStockInStoresButCanBeOrdered = 'outOfStockInStoresButCanBeOrdered',
  NotAvailableInStores = 'NotAvailableInStores',
  NotAvailableInStoresError = 'NotAvailableInStoresError',
}

type Type = PageType.PRODUCT_DETAIL_PAGE | PageType.PRODUCT_LIST_PAGE;

export type GetOnlineStockStatus = {
  /**
   * Quanity of items for sale online
   */
  onlineQuantity: number;
  isOnlineSales?: boolean;
  /**
   * Next available date for online purchase
   */
  nextAtpDate?: string;
  type?: Type;
  productId: string;
};

export type StockStatus = {
  status: StatusIndicatorStatus;
  text: string | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray;
  subText?: string | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray;
  getNotified?: boolean;
};

export type GetStoreStockStatus = {
  product?: ProductFull | ProductTeaser;
  storesWithAtp: number;
  onlineAtp: number;
  selectedStoreUnit?: number;
  type?: Type;
  storeName?: string;
};
