import { AccordionParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { Accordion } from '../accordion';
import { AccordionOneOpen } from '../accordion-one-open';
import { Text } from '../text';

type ParagraphAccordionProps = {
  content: AccordionParagraph;
};

export function ParagraphAccordion({ content }: ParagraphAccordionProps) {
  const { config, items } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config}>
      {content.allowOpeningMultiple ? (
        items.map((item, index) => (
          <Accordion key={`${item.title}_${index}`} title={item.title}>
            <Text value={item.content} />
          </Accordion>
        ))
      ) : (
        <AccordionOneOpen items={items} />
      )}
    </ParagraphConfig>
  );
}
