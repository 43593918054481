'use client';

import NiceModal from '@ebay/nice-modal-react';
import { StatusIndicatorStatus } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { Link } from '@wla/components/ui/link';
import { PageType } from '@wla/lib/helpers/(analytics)/get-page-type';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { StoreLink } from '@wla/lib/hooks/product-atp/store-link';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { ProductFull, ProductTeaser, StoreFull } from '@jysk/api-types/drupalApi';
import { GetOnlineStockStatus, GetStoreStockStatus, OnlineStockStatus, StockStatus, StoreStockStatus } from './types';

const DEFAULT_DATE = '9999-12-31';

export function useProductAtp() {
  const t = useTranslations();
  const { favoriteStore: getStore } = useFavoriteStore();
  const [favoriteStore, setFavoriteStore] = useState<StoreFull | null>(null);

  useEffect(() => {
    if (getStore?.id === favoriteStore?.id) return;

    setFavoriteStore(getStore);
  }, [getStore]);

  function getOnlineStockMessage(ExpectedBackInStockDate?: string) {
    return {
      [OnlineStockStatus.InStock]: {
        text: t('common.in-stock'),
        getNotified: false,
        subText: undefined,
        status: StatusIndicatorStatus.Success,
      },
      [OnlineStockStatus.FewLeftInStock]: {
        text: (
          <>
            <span>{t('common.in-stock')} </span>
            <span className="whitespace-nowrap text-sm font-semibold text-red-500">
              {t('pdp.product-atp.online-messages.few-left')}
            </span>
          </>
        ),
        getNotified: false,
        subText: undefined,
        status: StatusIndicatorStatus.Success,
      },
      [OnlineStockStatus.ExpectedBackInStock]: {
        text: t('pdp.product-atp.online-messages.temporary-out-of-stock'),
        getNotified: true,
        subText: t('pdp.product-atp.online-messages.expected-back-in-stock', {
          nextAtpDate: ExpectedBackInStockDate,
        }),
        subTextColor: 'text-black',
        status: StatusIndicatorStatus.Warning,
      },
      [OnlineStockStatus.TemporaryOutOfStock]: {
        text: t('pdp.product-atp.online-messages.temporary-out-of-stock'),
        getNotified: true,
        subText: undefined,
        status: StatusIndicatorStatus.Warning,
      },
      [OnlineStockStatus.OutOfStock]: {
        text: t('pdp.product-atp.online-messages.not-available'),
        getNotified: false,
        subText: undefined,
        status: StatusIndicatorStatus.Error,
      },
    };
  }

  function getProductcardOnlineStockMessage(productId: string) {
    return {
      [OnlineStockStatus.InStock]: {
        text: t('common.delivery'),
        getNotified: false,
        subText: undefined,
        status: StatusIndicatorStatus.Success,
      },
      [OnlineStockStatus.FewLeftInStock]: {
        text: (
          <>
            <span>{t('common.delivery')} - </span>
            <span className="whitespace-nowrap text-xs font-semibold text-red-500">
              {t('pdp.product-atp.online-messages.few-left')}
            </span>
          </>
        ),
        getNotified: false,
        subText: undefined,
        status: StatusIndicatorStatus.Success,
      },
      [OnlineStockStatus.ExpectedBackInStock]: {
        text: t.rich('pdp.product-atp.deliver-with-custom-text', {
          link: () => (
            <Link
              as="button"
              onClick={() => NiceModal.show(DRAWER_ID.BACK_IN_STOCK_DRAWER_ID, { articleId: productId })}
              className="text-xs md:text-sm"
            >
              <span>{t('pdp.product-atp.get-notified')}</span>
            </Link>
          ),
        }),
        subText: undefined,
        status: StatusIndicatorStatus.Warning,
      },
      [OnlineStockStatus.TemporaryOutOfStock]: {
        text: t.rich('pdp.product-atp.deliver-with-custom-text', {
          link: () => (
            <button
              onClick={() => NiceModal.show(DRAWER_ID.BACK_IN_STOCK_DRAWER_ID, { articleId: productId })}
              className="text-xs text-brand-500 hover:underline md:text-sm"
            >
              <span>{t('pdp.product-atp.get-notified')}</span>
            </button>
          ),
        }),
        subText: undefined,
        status: StatusIndicatorStatus.Warning,
      },
      [OnlineStockStatus.OutOfStock]: {
        text: t('common.delivery'),
        getNotified: true,
        subText: undefined,
        status: StatusIndicatorStatus.Error,
      },
    };
  }

  function getOnlineStockStatus({
    productId,
    onlineQuantity,
    nextAtpDate,
    isOnlineSales,
    type = PageType.PRODUCT_DETAIL_PAGE,
  }: GetOnlineStockStatus): StockStatus {
    const messages =
      type === PageType.PRODUCT_DETAIL_PAGE
        ? getOnlineStockMessage(nextAtpDate)
        : getProductcardOnlineStockMessage(productId);

    //TODO: add feature flag
    const featureFlag = true;

    if (!featureFlag) return { ...messages[OnlineStockStatus.OutOfStock] };

    if (!isOnlineSales) return { ...messages[OnlineStockStatus.OutOfStock] };

    if (onlineQuantity > 5) return { ...messages[OnlineStockStatus.InStock] };

    if (onlineQuantity <= 5 && onlineQuantity > 0) return { ...messages[OnlineStockStatus.FewLeftInStock] };

    if (onlineQuantity < 1) {
      if (!nextAtpDate || nextAtpDate === DEFAULT_DATE) return { ...messages[OnlineStockStatus.TemporaryOutOfStock] };

      return { ...messages[OnlineStockStatus.ExpectedBackInStock] };
    }

    return { ...messages[OnlineStockStatus.OutOfStock] };
  }

  function getStoreStockMessage(product?: ProductFull | ProductTeaser, storesWithAtp?: number) {
    const isStoreSelected = Boolean(favoriteStore);

    return {
      [StoreStockStatus.InStock]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.in-stock-in', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              <StoreLink
                product={product}
                favoriteStoreName={`${storesWithAtp?.toString() ?? '0'} ${t('common.stores')}`}
              />
            ),
        }),
        subText: t('pdp.pick-up-in-30-min'),
        status: StatusIndicatorStatus.Success,
      },
      [StoreStockStatus.NotAvailableInStores]: {
        text: t('pdp.product-atp.store-messages.not-available-in-stores'),
        subText: t.rich('pdp.product-atp.store-product-card-messages.can-be-ordered-at', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              t('pdp.product-atp.store-messages.a-jysk-store')
            ),
        }),
        status: StatusIndicatorStatus.Warning,
      },
      [StoreStockStatus.NotAvailableInStoresError]: {
        text: t('pdp.product-atp.store-messages.not-available-in-stores'),
        subText: undefined,
        status: StatusIndicatorStatus.Error,
      },
      [StoreStockStatus.OutOfStockInStores]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.out-of-stock-in', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              t('pdp.product-atp.store-messages.all-stores')
            ),
        }),
        subText: t.rich('pdp.product-atp.store-product-card-messages.in-stock-in', {
          link: () => (
            <StoreLink
              product={product}
              favoriteStoreName={`${storesWithAtp?.toString() ?? '0'} ${t('common.stores')}`}
            />
          ),
        }),
        status: StatusIndicatorStatus.Error,
      },
      [StoreStockStatus.OutOfStockInStoresButCanBeOrdered]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.out-of-stock-in', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              t('pdp.product-atp.store-messages.all-stores')
            ),
        }),
        subText: t('pdp.product-atp.store-messages.but-can-be-ordered-at-jysk-stores'),
        status: StatusIndicatorStatus.Warning,
      },
    };
  }

  function getProductCardStoreStockMessage(
    type: PageType,
    storesWithAtp?: number,
    product?: ProductFull | ProductTeaser,
  ) {
    const isStoreSelected = Boolean(favoriteStore);

    return {
      [StoreStockStatus.InStock]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.in-stock-in', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              <StoreLink
                product={product}
                favoriteStoreName={`${storesWithAtp?.toString() ?? '0'} ${t('common.stores')}`}
              />
            ),
        }),
        subText: undefined,
        status: StatusIndicatorStatus.Success,
      },
      [StoreStockStatus.NotAvailableInStores]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.can-be-ordered-at', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              t('pdp.product-atp.store-messages.all-stores')
            ),
        }),
        subText: undefined,
        status: StatusIndicatorStatus.Warning,
      },
      [StoreStockStatus.NotAvailableInStoresError]: {
        text:
          type === PageType.PRODUCT_LIST_PAGE
            ? t('pdp.product-atp.store-messages.not-available-in-stores')
            : t.rich('pdp.product-atp.store-product-card-messages.can-be-ordered-at', {
                link: () =>
                  isStoreSelected ? (
                    <StoreLink
                      product={product}
                      favoriteStoreName={t('common.jysk-store', {
                        storeName: favoriteStore?.name,
                      })}
                    />
                  ) : (
                    t('pdp.product-atp.store-messages.all-stores')
                  ),
              }),
        subText: undefined,
        status: StatusIndicatorStatus.Error,
      },
      [StoreStockStatus.OutOfStockInStores]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.out-of-stock-in', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              t('pdp.product-atp.store-messages.all-stores')
            ),
        }),
        subText: undefined,
        status: StatusIndicatorStatus.Error,
      },
      [StoreStockStatus.OutOfStockInStoresButCanBeOrdered]: {
        text: t.rich('pdp.product-atp.store-product-card-messages.can-be-ordered-at', {
          link: () =>
            isStoreSelected ? (
              <StoreLink
                product={product}
                favoriteStoreName={t('common.jysk-store', {
                  storeName: favoriteStore?.name,
                })}
              />
            ) : (
              t('pdp.product-atp.store-messages.all-stores')
            ),
        }),
        subText: undefined,
        status: StatusIndicatorStatus.Warning,
      },
    };
  }

  function getStoreStockStatus(props: GetStoreStockStatus): StockStatus {
    const overwrittenProps = {
      ...props,
      storesWithAtp: props.product?.isStoreSales ? props.storesWithAtp : 0,
      selectedStoreUnit: props.product?.isStoreSales ? props.selectedStoreUnit : 0,
    };

    return !favoriteStore
      ? getMessageForNoSelectedStore(overwrittenProps)
      : getMessageForSelectedStore(overwrittenProps);
  }

  function getMessageForSelectedStore({
    product,
    storesWithAtp,
    onlineAtp,
    selectedStoreUnit = 0,
    type = PageType.PRODUCT_DETAIL_PAGE,
  }: GetStoreStockStatus) {
    const isClickCollect = product?.isClickCollect || false;
    const isOrderable = product?.isOrderableInStores || false;
    const isStoreSales = product?.isStoreSales || false;
    const isDirectDeliveryFromVendor = product?.isDirectDeliveryFromVendor || false;

    const messages =
      type === PageType.PRODUCT_DETAIL_PAGE
        ? getStoreStockMessage(product, storesWithAtp)
        : getProductCardStoreStockMessage(type, storesWithAtp, product);

    if (storesWithAtp <= 0 || !isStoreSales)
      return isOrderable && (onlineAtp > 0 || isDirectDeliveryFromVendor)
        ? {
            ...messages[StoreStockStatus.OutOfStockInStoresButCanBeOrdered],
          }
        : {
            ...messages[StoreStockStatus.NotAvailableInStoresError],
          };

    if (storesWithAtp > 0 && isStoreSales) {
      if (selectedStoreUnit > 0)
        return isClickCollect || type !== PageType.PRODUCT_DETAIL_PAGE
          ? { ...messages[StoreStockStatus.InStock] }
          : {
              ...messages[StoreStockStatus.InStock],
              subText: t('pdp.product-atp.store-messages.not-available-for-click-collect'),
            };
      if (selectedStoreUnit <= 0)
        return isOrderable && (onlineAtp > 0 || isDirectDeliveryFromVendor)
          ? { ...messages[StoreStockStatus.OutOfStockInStoresButCanBeOrdered] }
          : { ...messages[StoreStockStatus.OutOfStockInStores] };
    }

    return { ...messages[StoreStockStatus.NotAvailableInStores] };
  }

  function getMessageForNoSelectedStore({
    product,
    onlineAtp,
    storesWithAtp,
    type = PageType.PRODUCT_DETAIL_PAGE,
  }: GetStoreStockStatus) {
    const isClickCollect = product?.isClickCollect || false;
    const isOrderable = product?.isOrderableInStores || false;
    const isStoreSales = product?.isStoreSales || false;
    const isDirectDeliveryFromVendor = product?.isDirectDeliveryFromVendor || false;

    const messages =
      type === PageType.PRODUCT_DETAIL_PAGE
        ? getStoreStockMessage(product, storesWithAtp)
        : getProductCardStoreStockMessage(type, storesWithAtp, product);

    if (!isStoreSales)
      return isOrderable && (onlineAtp > 0 || isDirectDeliveryFromVendor)
        ? { ...messages[StoreStockStatus.NotAvailableInStores] }
        : { ...messages[StoreStockStatus.NotAvailableInStoresError] };

    if (storesWithAtp > 0)
      return isClickCollect || type !== PageType.PRODUCT_DETAIL_PAGE
        ? { ...messages[StoreStockStatus.InStock] }
        : {
            ...messages[StoreStockStatus.InStock],
            subText: t('pdp.product-atp.store-messages.not-available-for-click-collect'),
          };

    if (storesWithAtp <= 0)
      return isOrderable && (onlineAtp > 0 || isDirectDeliveryFromVendor)
        ? { ...messages[StoreStockStatus.OutOfStockInStoresButCanBeOrdered] }
        : { ...messages[StoreStockStatus.OutOfStockInStores], subText: undefined };

    return { ...messages[StoreStockStatus.OutOfStockInStores], subText: undefined };
  }

  return {
    getOnlineStockStatus,
    getStoreStockStatus,
  };
}
