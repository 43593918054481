import { Disclosure, DisclosurePanel, Transition } from '@headlessui/react';
import { StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { FavoriteStoreOpeningHours } from '@wla/app/(cms)/(favorite-store)/favorite-store-opening-hours';
import { FavoriteStoreOpeningStatus } from '@wla/app/(cms)/(favorite-store)/favorite-store-opening-status';
import { getStoreOpeningData } from '@wla/app/(cms)/(favorite-store)/get-store-opening-data';
import { OnlineAtp } from '@wla/app/(cms)/[...slug]/(product-detail-page)/add-to-basket';
import {
  StatusIndicator,
  StatusIndicatorStatus,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { useProductAtp } from '@wla/lib/hooks/product-atp/use-product-atp';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';

type FavoriteStoreDetailsProps = {
  store: StoreType;
  product?: ProductFull | ProductTeaser;
  onlineProductAtp: OnlineAtp | null;
};

export function FavoriteStoreDetails({ store, product, onlineProductAtp }: FavoriteStoreDetailsProps) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const openingInfo = getStoreOpeningData(store.openingHours);
  const { street, number, zipCode, city, id, openingHours, atp } = store;
  const storeAtpUnits = atp?.units || 0;
  const productId = product?.id;
  const { getStoreStockStatus } = useProductAtp();

  const storeStockStatus = getStoreStockStatus({
    product,
    storesWithAtp: storeAtpUnits,
    onlineAtp: onlineProductAtp?.qty || 0,
    selectedStoreUnit: storeAtpUnits,
    storeName: store.name,
  });

  function stockMessage() {
    const { status, subText, text } = storeStockStatus;

    if (status === StatusIndicatorStatus.Success) return t('favorite-store.stock-quantity', { amount: storeAtpUnits });
    if (status === StatusIndicatorStatus.Warning)
      return `${t('favorite-store.stock-quantity', { amount: 0 })} (${subText || text})`;

    return subText || text;
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between pt-3">
        {openingInfo && !productId && <FavoriteStoreOpeningStatus openingInfo={openingInfo} readonly />}

        {productId && (
          <StatusIndicator status={storeStockStatus.status} className="gap-x-2">
            <span className="pt-0.5 text-sm">{stockMessage()}</span>
          </StatusIndicator>
        )}

        <Link as="button" className="flex items-center gap-0.5 text-sm no-underline" onClick={() => setOpen(!open)}>
          <p className="text-right underline">{t('common.opening-hours')}</p>
          <Icon src={open ? 'w3-arrow-up' : 'w3-arrow-down'} size="18" className="text-gray-500" />
        </Link>
      </div>
      <Disclosure>
        <Transition
          as="div"
          show={open}
          enter="transition-[grid-template-rows] duration-500 ease-in-out"
          enterFrom="grid-rows-[0fr]"
          enterTo="grid-rows-[1fr]"
          leave="transition-[grid-template-rows] duration-500 ease-in-out"
          leaveFrom="grid-rows-[1fr]"
          leaveTo="grid-rows-[0fr]"
          className="grid"
        >
          <DisclosurePanel className="w-full cursor-default overflow-hidden">
            <div className="mb-4 grid grid-flow-row grid-cols-2 gap-x-4 md:grid-cols-3">
              <p className="col-span-2 mb-2 mt-5 font-bold md:col-span-3">{t('common.store-information')}</p>
              <p>{t('forms.address')}</p>
              <p className="md:col-span-2">
                {street} {number} {zipCode} {city}
                <Link href={`/stores-locator?storeId=${id}`} className="block w-fit text-blue-500 underline">
                  {t('common.get-directions')}
                </Link>
              </p>
              <p className="col-span-2 mb-1.5 mt-4 font-bold md:col-span-3">{t('common.opening-hours')}</p>
              {openingHours && <FavoriteStoreOpeningHours opening={openingHours} />}
            </div>
            <Link href={`/stores-locator?storeId=${id}`} className="text-blue-500 underline">
              {t('common.show-store')}
            </Link>
          </DisclosurePanel>
        </Transition>
      </Disclosure>
    </div>
  );
}
