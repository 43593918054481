import { z } from 'zod';

// Default B2B login schema to extend on
export const b2bLoginFormSchema = z.object({
  customerNo: z.union([
    z.string().email('forms.errors.not-valid.customer-number-or-email-not-valid'),
    z.string().regex(/^\d+$/, 'forms.errors.not-valid.customer-number-or-email-not-valid'),
  ]),
  password: z.string().min(8, 'forms.errors.not-valid.password-not-valid'),
  domainCode: z.string(),
});

export const b2bRequestResetPasswordFormSchema = z.object({
  customerNo: z.union([
    z.string().email('forms.errors.not-valid.customer-number-or-email-not-valid'),
    z.string().regex(/^\d+$/, 'forms.errors.not-valid.customer-number-or-email-not-valid'),
  ]),
});

export const b2bUpdatePasswordFormSchema = z
  .object({
    customerNo: z.union([
      z.string().email('forms.errors.not-valid.customer-number-or-email-not-valid'),
      z.string().regex(/^\d+$/, 'forms.errors.not-valid.customer-number-or-email-not-valid'),
    ]),
    password: z
      .string()
      .refine((value) => /^(?=.*[A-Z])(?=.*[a-z]).{8,}$/.test(value ?? ''), 'forms.errors.password.full-error'),
    newPassword: z
      .string()
      .refine((value) => /^(?=.*[A-Z])(?=.*[a-z]).{8,}$/.test(value ?? ''), 'forms.errors.password.full-error'),
    confirmNewPassword: z
      .string()
      .refine((value) => /^(?=.*[A-Z])(?=.*[a-z]).{8,}$/.test(value ?? ''), 'forms.errors.password.full-error'),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    message: 'forms.errors.password.password-dont-match',
  });

export type CustomerB2bLoginFormData = z.infer<typeof b2bLoginFormSchema>;
export type CustomerB2bRequestResetPasswordFormData = z.infer<typeof b2bRequestResetPasswordFormSchema>;
export type CustomerB2bUpdatePasswordFormData = z.infer<typeof b2bUpdatePasswordFormSchema>;
