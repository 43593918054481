import { Drawer } from '@wla/components/ui/drawer';
import { PropsWithChildren } from 'react';

type StoreDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
};

export function StoreDrawer({ children, onClose, isOpen, title }: PropsWithChildren<StoreDrawerProps>) {
  return (
    <Drawer open={isOpen} onClose={onClose}>
      <Drawer.Header title={title} />
      <Drawer.Body className="mt-2 flex-grow">{children}</Drawer.Body>
    </Drawer>
  );
}
