'use client';
import { Wrapper } from '@googlemaps/react-wrapper';
import { StoreInfo } from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator';
import { GoogleMapsMarkers } from '@wla/components/ui/maps/google-maps-markers';
import { cn } from '@wla/lib/helpers/cn';
import { useMessages } from 'next-intl';
import { useEffect, useState } from 'react';

export const DEFAULT_ZOOM = 14;

type GoogleMapsProps = {
  mapId: string;
  apiKey: string;
  title?: string;
  className?: string;
  storeInfo: StoreInfo[];
};

export function GoogleMaps({ mapId, apiKey, title = '', className, storeInfo }: GoogleMapsProps) {
  const messages = useMessages();
  const [mapElm, setMapElm] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!mapElm) return;
    const map = new window.google.maps.Map(mapElm, {
      center: { lat: storeInfo[0].lat, lng: storeInfo[0].lng },
      zoom: DEFAULT_ZOOM,
      mapId,
    });
    GoogleMapsMarkers({ map, title, storeInfo, messages });
  }, [mapElm, mapId, title, storeInfo]);

  return (
    <Wrapper apiKey={apiKey}>
      <div className={cn('h-full w-full', className)} ref={setMapElm} />
    </Wrapper>
  );
}
