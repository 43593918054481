import { sendToWCH } from 'libs/api-client/src/cache';
import qs from 'query-string';
import { logger } from './logger';

type ErrorHandler = (error: any) => typeof Error;

type ApiRequestConfig = {
  url?: string;
  method?: 'get' | 'post' | 'put' | 'delete' | 'patch' | 'head' | 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';
  params?: Record<string, any>;
  headers?: Record<string, any>;
  data?: Record<string, any> | string;
  cache?: RequestCache;
  tags?: (string | undefined | null)[];
  noJson?: boolean;
};

type ApiRequestOptions = {
  headers?: Record<string, any>;
  data?: Record<string, any> | string;
  cache?: RequestCache;
  tags?: (string | undefined | null)[];
  noJson?: boolean;
};

type CreateClientConfig = {
  baseURL: string;
  paramsSerializer?: typeof qs.stringify;
};

export type ApiResponse<T> = {
  status: number;
  headers: Headers;
  rawResponse?: Response;
  response: T;
};

function getJyskLocale(headers: Record<string, string>) {
  const host = headers['x-host'];
  const locale = getLocaleFromHostname(host);
  return locale;
}

const domains: Record<string, string> = {};

process.env.DOMAIN_CODES?.split(',')?.map((domain) => {
  process.env.SITE_PATTERNS?.split(',').map((pattern) => {
    domains[pattern.replace('DOMAIN_CODE', domain)] = domain;
  });
});

export function getLocaleFromHostname(hostname: string): string {
  if (!hostname) {
    throw new Error('No hostname was found.');
  }

  const locale = domains[hostname];
  if (!locale) {
    logger.error(
      {
        hostname,
        domains,
        domainCodes: process.env.DOMAIN_CODES,
        sitePatterns: process.env.SITE_PATTERNS,
      },
      'getLocaleFromHostname: Locale not found',
    );

    throw new Error('No locale was found.');
  }

  return locale;
}

function stripSensitiveData(data: Record<string, any> | string | undefined) {
  if (!data || typeof data === 'string') {
    return data;
  }

  const sensitiveKeys = ['password', 'newPassword', 'oldPassword', 'email', 'repeatEmail', 'phone', 'mobile'];
  const strippedData = { ...data };

  Object.keys(strippedData).forEach((key) => {
    if (sensitiveKeys.includes(key)) {
      strippedData[key] = '***';
    }

    if (Array.isArray(strippedData[key])) {
      strippedData[key] = strippedData[key].map((item: any) => {
        return stripSensitiveData(item);
      });
    } else if (typeof strippedData[key] === 'object') {
      strippedData[key] = stripSensitiveData(strippedData[key]);
    }
  });

  return strippedData;
}

function createClient({ baseURL, paramsSerializer }: CreateClientConfig, _errorHandler?: ErrorHandler) {
  return async <T>(config: ApiRequestConfig, options?: ApiRequestOptions): Promise<ApiResponse<T>> => {
    const { url, method = 'get', data, params, headers, cache = 'no-store', tags = [] } = config;

    const isProduction = process.env['NODE_ENV'] === 'production';
    const isDrupal = baseURL.startsWith(process.env['DRUPAL_URL'] || '');
    const requestHeaders: Record<string, string> = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(headers || {}),
      ...(options?.headers || {}),
    };

    const qsParams = params ? (paramsSerializer ? paramsSerializer(params) : qs.stringify(params)) : '';

    let requestBaseUrl = baseURL;

    if (!isProduction && isDrupal) {
      const jyskLocale = getJyskLocale(requestHeaders);
      requestBaseUrl = baseURL.replace('DOMAIN_CODE', jyskLocale || 'da');
    }

    const requestUrl = `${requestBaseUrl}${url}${qsParams ? `?${qsParams}` : ''}`;

    const requestCache = options?.cache || cache;
    const requestCacheTags = [...(options?.tags || []), ...tags].filter(Boolean) as string[];

    if (isDrupal && isProduction && process.env['DRUPAL_URL_NGINX_HOST_PATTERN']) {
      const jyskLocale = getJyskLocale(requestHeaders);
      requestHeaders['x-nginx-host'] = process.env['DRUPAL_URL_NGINX_HOST_PATTERN'].replace(
        'DOMAIN_CODE',
        jyskLocale || 'dk',
      );
    }

    // console.log('🔥 ', { method, requestUrl, requestHeaders, options, config });
    let response;
    try {
      response = await fetch(requestUrl, {
        method: method.toUpperCase(),
        headers: requestHeaders,
        body: typeof data === 'string' ? data : JSON.stringify(data),
        cache: requestCache,
        ...(requestCacheTags.length > 0 ? { next: { tags: requestCacheTags } } : {}),
      });

      if (options?.noJson || config?.noJson) {
        return { status: response.status, rawResponse: response } as ApiResponse<T>;
      }

      const json = (await response.json()) as T;

      if (response.status >= 400) {
        logger.error(
          {
            method,
            requestUrl,
            requestHeaders,
            options,
            json,
            body: stripSensitiveData(data),
          },
          'createClient: Api Client Status >= 400',
        );
      }

      // Send cache tags from drupal to cache handler
      if (isDrupal) {
        await sendToWCH(requestHeaders, response.headers, requestUrl);
      }

      return { response: json, headers: response.headers, status: response.status, rawResponse: response };
    } catch (error) {
      logger.error(
        {
          errorName: (error as Error).name,
          errorStack: (error as Error).stack || '',
          method,
          requestUrl,
          requestHeaders,
          options,
          error,
          body: stripSensitiveData(data),
        },
        'createClient: Catch error',
      );
      return {
        status: response?.status,
        headers: response?.headers,
        response: null,
        rawResponse: response,
      } as ApiResponse<T>;
    }
  };
}

const _websapApiClient = createClient(
  {
    baseURL: process.env['WEB_SAP_API_URL'] || '',
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'comma',
      }),
  },
  () => Error,
);
const _websapApiV2Client = createClient(
  {
    baseURL: `${process.env['WEB_SAP_API_URL']}/api/v2`,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'comma',
      }),
  },
  () => Error,
);
const _webSapShopsApiClient = createClient(
  {
    baseURL: process.env['WEB_SAP_API_URL'] || '',
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'none',
      }),
  },
  () => Error,
);
const _orderManagementApiClient = createClient(
  {
    baseURL: `${process.env['ORDER_MANAGEMENT_URL']}/json/api/checkout/v2`,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'comma',
      }),
  },
  () => Error,
);
const _orderManagementCustomerApiClient = createClient(
  {
    baseURL: `${process.env['ORDER_MANAGEMENT_URL']}/json/v2`,
  },
  () => Error,
);
const _orderManagementGiftcardApiClient = createClient(
  {
    baseURL: `${process.env['ORDER_MANAGEMENT_URL']}`,
  },
  () => Error,
);
const _orderManagementSharedBasketApiClient = createClient(
  {
    baseURL: `${process.env['ORDER_MANAGEMENT_URL']}`,
  },
  () => Error,
);
const _drupalApiClient = createClient(
  {
    baseURL: `${process.env['DRUPAL_URL']}/api/v1`,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'comma',
      }),
  },
  () => Error,
);
const _wssApiClient = createClient({ baseURL: `${process.env['WSS_API_URL']}/json2` }, () => Error);
const _wssCustomerApiClient = createClient({ baseURL: `${process.env['WSS_API_URL']}/json/v2` }, (_error) => {
  // if (error.response?.data?.restErrorType === 'REQUEST_VALIDATION') {
  //   return Error;
  // }
  // if (error.response?.data?.restErrorType === 'RESOURCE_UNAUTHORIZED') {
  //   return Error;
  // }
  return Error;
});
const _wssReviewsApiClient = createClient(
  {
    baseURL: `${process.env['WSS_REVIEWS_API_URL']}/json/productreview`,
  },
  () => Error,
);
const _wssZipSuggestionApiClient = createClient(
  {
    baseURL: `${process.env['WSS_API_URL']}/json/zipsuggestion`,
  },
  () => Error,
);
const _wssFilterAxisApiClient = createClient(
  {
    baseURL: `${process.env['WSS_API_URL']}/json`,
  },
  () => Error,
);
const _trustpilotApiClient = createClient(
  {
    baseURL: `https://api.trustpilot.com/v1/business-units`,
  },
  () => Error,
);
const _cmdApiClient = createClient(
  {
    baseURL: `${process.env['CMD_API_URL']}`,
  },
  () => Error,
);
const _csmB2BApiClient = createClient(
  {
    baseURL: `${process.env['CSM_API_URL']}/rest/public/b2b`,
  },
  (error) => {
    if (error.response?.status === 400) {
      return Error;
    }
    return Error;
  },
);
const _csmTokenApiClient = createClient(
  {
    baseURL: `${process.env['CSM_API_URL']}/rest/public`,
  },
  () => Error,
);
const _searchApiClient = createClient(
  {
    baseURL: `${process.env['SEARCH_API_URL']}`,
  },
  () => Error,
);

// The Orval parser is a bit naive and doesn't see the above as valid functions.
// So we have to export wrappers to make it happy.
export const webSapApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _websapApiClient<T>(config, options);
export const webSapApiV2Client = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _websapApiV2Client<T>(config, options);
export const webSapShopsApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _webSapShopsApiClient<T>(config, options);
export const orderManagementApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _orderManagementApiClient<T>(config, options);
export const orderManagementCustomerApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _orderManagementCustomerApiClient<T>(config, options);
export const orderManagementGiftcardApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _orderManagementGiftcardApiClient<T>(config, options);
export const orderManagementSharedBasketApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _orderManagementSharedBasketApiClient<T>(config, options);
export const drupalApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _drupalApiClient<T>(config, options);
export const wssApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _wssApiClient<T>(config, options);
export const wssCustomerApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _wssCustomerApiClient<T>(config, options);
export const wssReviewsApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _wssReviewsApiClient<T>(config, options);
export const wssZipSuggestionApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _wssZipSuggestionApiClient<T>(config, options);
export const wssFilterAxisApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _wssFilterAxisApiClient<T>(config, options);
export const trustpilotApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _trustpilotApiClient<T>(config, options);
export const cmdApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _cmdApiClient<T>(config, options);
export const csmB2BApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _csmB2BApiClient<T>(config, options);
export const csmTokenApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _csmTokenApiClient<T>(config, options);
export const searchApiClient = <T>(config: ApiRequestConfig, options?: ApiRequestConfig) =>
  _searchApiClient<T>(config, options);
