'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Drawer } from '@wla/components/ui/drawer';
import { Link } from '@wla/components/ui/link';
import { Header } from '@wla/components/ui/navigation/navigation-header';
import { Item } from '@wla/components/ui/navigation/navigation-item';
import { Section } from '@wla/components/ui/navigation/navigation-section';
import { cn } from '@wla/lib/helpers/cn';
import { useStack } from '@wla/lib/hooks/use-stack';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MenuItem } from '@jysk/api-types/drupalApi';

const DEFAULT_NAVIGATION_ITEM = {
  id: '',
  children: [],
  title: '',
  description: '',
  url: '',
  target: null,
  style: null,
};

export type NavigationItem = MenuItem;

export type NavigationDrawerProps = { items: NavigationItem[]; secondaryItems: NavigationItem[] };

export const NavigationDrawer = NiceModal.create(({ items, secondaryItems }: NavigationDrawerProps) => {
  const t = useTranslations('common');
  const [navigationItem, setNavigationItem] = useState<NavigationItem>({
    ...DEFAULT_NAVIGATION_ITEM,
    children: items,
  });

  const [animationKey, setAnimationKey] = useState({ key: Date.now(), direction: '' });
  const { isEmpty, items: stack, push: pushStack, pop: popStack, top, clear: clearStack } = useStack<NavigationItem>();
  const { visible, hide } = useModal();
  const currentPage = usePathname();
  const initialRef = useRef(null);

  const handleClick = useCallback(
    (item: NavigationItem) => {
      if (!item.children) return;

      setNavigationItem(item);
      setAnimationKey({ key: Date.now(), direction: 'right' });
      pushStack(navigationItem);
    },
    [navigationItem, pushStack],
  );

  const handleBack = () => {
    if (isEmpty()) return;
    setAnimationKey({ key: Date.now(), direction: 'left' });
    const poppedItem = popStack();
    if (!poppedItem) return;

    setNavigationItem(poppedItem);
  };

  useEffect(() => {
    setNavigationItem({ ...DEFAULT_NAVIGATION_ITEM, children: items });
    clearStack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, items]);

  return (
    <Drawer open={visible} onClose={hide} position="left" initialFocus={initialRef}>
      <div
        key={animationKey.key}
        className={cn({
          'animate-slide-in-from-right':
            top()?.children !== navigationItem.children && animationKey.direction === 'right',
          'animate-slide-in-from-left':
            top()?.children !== navigationItem.children && animationKey.direction === 'left',
        })}
      >
        <Drawer.Header
          title="Menu"
          headerSlot={<Header title={navigationItem.title ?? ''} stack={stack ?? []} onClick={handleBack} />}
          className="justify-between"
        />
        <Drawer.Body className="mt-10">
          <Section>
            {navigationItem.url && (
              <Link
                href={navigationItem.url}
                className="text-lg text-current hover:text-current"
                onClick={hide}
                title={`${t('show-all')} ${navigationItem.title}`}
                aria-label={`${t('show-all')} ${navigationItem.title}`}
              >
                {t('show-all')}
              </Link>
            )}
            {navigationItem.children?.map((item, index) => (
              <Item
                key={`${item.title}-${index}`}
                item={item}
                onClick={handleClick}
                onClose={hide}
                ref={index === 0 ? initialRef : null}
              />
            ))}
          </Section>
          {stack?.length <= 0 && (
            <Section classname="pb-12">
              <hr className="border-gray-350 h-0 w-full" />
              {secondaryItems?.map((item, index) => (
                <Item key={`${item.title}-${index}`} item={item} className="text-gray-500" onClose={hide} />
              ))}
            </Section>
          )}
        </Drawer.Body>
      </div>
    </Drawer>
  );
});
