import { Tooltip } from '@wla/components/ui/tooltip';
import { cn } from '@wla/lib/helpers/cn';

type SwitchProps = {
  label?: string;
  tooltip?: React.ReactNode;
  disabled?: boolean;
  isChecked: boolean;
  isLeftLabel?: boolean;
  labelClassName?: string;
  onChange: (checked: boolean) => void;
};

export function Switch({ label, tooltip, disabled, isChecked, isLeftLabel, labelClassName, onChange }: SwitchProps) {
  function handleToggle(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      onChange(!isChecked);
    }
  }

  return (
    <label
      className="flex items-center gap-2"
      onClick={handleToggle}
      aria-label={`Filter - ${label}`}
      aria-selected={isChecked}
    >
      <div
        className={cn(
          'flex w-full cursor-pointer items-center gap-2',
          { 'cursor-not-allowed opacity-50': disabled },
          { 'flex-row-reverse': isLeftLabel },
        )}
      >
        <div
          tabIndex={disabled ? -1 : 0}
          className={cn(
            'relative flex h-5 w-10 items-center rounded-full p-0.5 transition-all duration-300',
            isChecked ? 'bg-blue-500' : 'bg-gray-200',
            { 'cursor-not-allowed': disabled },
          )}
        >
          <div
            className={cn(
              'h-[15px] w-[15px] rounded-full bg-white transition-transform duration-300',
              isChecked ? 'translate-x-[21px]' : 'translate-x-0',
              { 'cursor-not-allowed': disabled },
            )}
          />
        </div>
        {label && (
          <div className="mr-auto flex items-center gap-2">
            <span className={cn('text-sm', labelClassName)}>{label}</span>
            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </div>
        )}
      </div>

      <input type="checkbox" checked={isChecked} readOnly className="sr-only" />
    </label>
  );
}
