'use client';
import { GetPage200EntityData, ProductFull } from '@jysk/api-types/drupalApi';
import { createContext, useState } from 'react';

export type PageTypes =
  | 'Page'
  | 'StructuralPage'
  | 'ParentCategoryPage'
  | 'ProductListPage'
  | 'Product'
  | 'ProductBrands'
  | 'BlogPost'
  | 'BlogPostCategories'
  | 'FaqCategories'
  | 'User'
  | 'Store'
  | 'Search';

type PageContext = {
  page: GetPage200EntityData | null;
  setPage: React.Dispatch<React.SetStateAction<GetPage200EntityData | null>>;
  pageType: PageTypes[keyof PageTypes] | null;
  setPageType: React.Dispatch<React.SetStateAction<PageTypes[keyof PageTypes] | null>>;
};

export const PageContext = createContext<PageContext>({
  page: null,
  setPage: () => null,
  pageType: null,
  setPageType: () => null,
});

type PageProviderProps = {
  children: React.ReactNode;
};

export function PageProvider({ children }: PageProviderProps) {
  const [page, setPage] = useState<GetPage200EntityData | null>(null);
  const [pageType, setPageType] = useState<PageTypes[keyof PageTypes] | null>(null);

  return <PageContext.Provider value={{ page, setPage, pageType, setPageType }}>{children}</PageContext.Provider>;
}

export function isProductFull(page: GetPage200EntityData | null): page is ProductFull {
  return (page as ProductFull)?.id !== undefined && page?.dataType === 'Product';
}
