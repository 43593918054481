'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Drawer } from '@wla/components/ui/drawer';
import { Icon } from '@wla/components/ui/icon';
import { DRAWER_ID } from '@wla/lib/modals';
import React from 'react';

type ProductsInformationDrawerProps = {
  children?: React.ReactNode;
  title: string;
};

export const ProductsInformationDrawer = NiceModal.create(({ children, title }: ProductsInformationDrawerProps) => {
  const modal = useModal();

  return (
    <Drawer open={modal.visible} onClose={() => modal.hide()}>
      <Drawer.Header title={title} className="text-center" />
      <Drawer.Body className="mb-8 mt-0">{children}</Drawer.Body>
    </Drawer>
  );
});

export type ProductInformationButtonProps = {
  children: React.ReactNode;
  title: string;
  btnSection?: React.ReactNode;
};

export function ProductInformationButton({ children, title, btnSection }: ProductInformationButtonProps) {
  function onAccessoryClick() {
    NiceModal.show(DRAWER_ID.PRODUCTS_INFORMATION_DRAWER, { children, title });
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onAccessoryClick();
      }}
      className="flex w-full cursor-pointer items-center justify-between rounded-sm"
    >
      <div className="flex text-left">
        <h2 className="flex pr-2 pt-0.5 text-lg md:text-3xl">{title}</h2>
        {btnSection && btnSection}
      </div>
      <div className="py-7 md:py-8">
        <Icon src="w3-arrow-right" size="18" />
      </div>
    </button>
  );
}
