'use client';

import { Button } from '@wla/components/ui/button/button';
import { cn } from '@wla/lib/helpers/cn';
import { useChatAvailability } from '@wla/lib/hooks/use-chat-availability';
import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import { ReactElement, useEffect, useRef } from 'react';

type LiveChatButtonProps = {
  offLineContent: ReactElement;
  onLineContent: ReactElement;
  className?: string;
  styleAsButton?: boolean;
};

export function LiveChatButton({
  offLineContent,
  onLineContent,
  className,
  styleAsButton = true,
}: LiveChatButtonProps) {
  const hasConsent = useCookieConsent(CookieTypesEnum.FUNCTIONAL);
  const salesforceOrgId = process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID;
  const salesforceDevId = process.env.NEXT_PUBLIC_SALESFORCE_DEPLOYMENT_ID;
  const buttonId = process.env.NEXT_PUBLIC_SALESFORCE_BUTTON_ID;
  const chatUrl = process.env.NEXT_PUBLIC_SALESFORCE_CHAT_URL;
  const chatButtonRef = useRef<HTMLButtonElement>(null);
  const isChatAvailable = useChatAvailability();
  const StyledButton = styleAsButton ? Button : 'button';

  function handleOnButtonClick() {
    window.liveagent?.startChat(buttonId);
  }

  useEffect(() => {
    if (!hasConsent) {
      return;
    }

    if (!window._laq) {
      window._laq = [];
    }

    window._laq.push(function () {
      window?.liveagent.showWhenOnline(buttonId, chatButtonRef.current);
    });

    window?.liveagent?.init?.(`${chatUrl}/chat`, salesforceDevId, salesforceOrgId);
  }, [hasConsent, isChatAvailable]);

  if (!hasConsent) {
    return null;
  }

  return (
    <StyledButton
      className={cn('sf-chat-button', className)}
      type="button"
      onClick={handleOnButtonClick}
      disabled={!isChatAvailable}
      ref={chatButtonRef}
    >
      {isChatAvailable ? onLineContent : offLineContent}
    </StyledButton>
  );
}
