import { Button } from '@wla/components/ui/button/button';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { PropsWithChildren } from 'react';

type StoreCardProps = {
  title: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  headerSlot?: React.ReactNode;
};

export function StoreCard({
  title,
  isDisabled,
  isSelected,
  onClick,
  headerSlot,
  children,
}: PropsWithChildren<StoreCardProps>) {
  const t = useTranslations();

  return (
    <div
      className={cn('rounded-md border border-gray-400 px-5 py-6', {
        'border-2 border-blue-500': isSelected,
      })}
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h4 className="text-lg font-bold">{title}</h4>
          {headerSlot}
        </div>
        <Button variant="primary" size="medium" disabled={isDisabled} className="min-w-fit" onClick={onClick}>
          {t('favorite-store.select-store')}
        </Button>
      </div>
      {children}
    </div>
  );
}
