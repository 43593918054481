import { FilterAxisItem } from '@jysk/api-types/wssFilterAxisApi/model';
import { getParentId } from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { Switch } from '@wla/components/ui/switch';
import { cn } from '@wla/lib/helpers/cn';
import { filterActions, handleChangeFilter } from '@wla/lib/helpers/filter-change-handler';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';

type ProductCountProps = {
  productCount: number;
  promotedFilter?: FilterAxisItem;
  id?: string;
  className?: string;
};

export function FilterProductCount({ productCount, promotedFilter, id, className }: ProductCountProps) {
  const t = useTranslations();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryFilters = searchParams.get('filters');
  return (
    <>
      <div id={id} className={cn('w-full flex-wrap justify-between lg:w-auto', className)}>
        <p>{t('common.products-count', { count: productCount })}</p>
        {promotedFilter && (
          <>
            <div className="hidden px-4 text-gray-300 lg:block">|</div>
            <Switch
              isLeftLabel
              labelClassName="text-md"
              label={promotedFilter.caption}
              isChecked={promotedFilter.selected}
              onChange={() =>
                handleChangeFilter(
                  {
                    id: getParentId(promotedFilter.handle),
                    value: promotedFilter.value,
                    handle: !promotedFilter.selected ? filterActions.ADD : filterActions.REMOVE,
                    caption: promotedFilter.caption,
                  },
                  { pathname, searchParams, queryFilters },
                )
              }
            />
          </>
        )}
      </div>
    </>
  );
}
