import { cn } from '@wla/lib/helpers/cn';
import { Layout, SectionConfigColumnWidths, SectionRegionsOneOfItem, TextParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphItemWrapper } from './paragraph/paragraph-item-wrapper';
import { Wrapper } from './wrapper';

type ContentLayoutProps = {
  content: Layout;
  excludeWrapper?: boolean;
  type?: string;
};

export function ContentLayout({ content, excludeWrapper = false, type }: ContentLayoutProps) {
  function getColumnWidths(columnWidths: string) {
    return columnWidths
      .replace('W', '')
      .split('_')
      .map((width) => `${width}%`);
  }

  function getBackgroundColor(paragraphs: SectionRegionsOneOfItem) {
    const backgroundColor = (paragraphs as TextParagraph[])?.find(
      (paragraph) => paragraph?.paragraphType === 'Text' && paragraph.backgroundColor,
    )?.backgroundColor;

    if (backgroundColor && backgroundColor !== '#FFFFFF') {
      return backgroundColor;
    }

    return undefined;
  }

  return (
    <>
      {content.map((item, index) => {
        if (!item.regions || item.regions.length === 0) {
          return null;
        }

        const columnWidths = getColumnWidths(item.config.columnWidths);

        return (
          <Wrapper key={index} fullWidth={item.config.fullWidth} excludeWrapper={excludeWrapper}>
            {item.regions.length === 1 && (
              <div
                className={cn('w-full gap-4', {
                  'gap-0': item.config.removeWhitespace,
                })}
              >
                {item.regions.map((paragraphs, index) => (
                  <div key={index} className="flex flex-col">
                    {paragraphs && <ParagraphItemWrapper paragraphs={paragraphs} content={content} type={type} />}
                  </div>
                ))}
              </div>
            )}

            {item.regions.length === 2 && (
              <div
                className={cn('flex min-w-full flex-col gap-4 sm:flex-row', {
                  'gap-0': item.config.removeWhitespace,
                })}
              >
                {item.regions.map((paragraphs, index) => (
                  <div
                    key={index}
                    className={cn(`flex flex-col`, {
                      'sm:w-[30%]': columnWidths[index] === '30%',
                      'sm:w-[50%]': columnWidths[index] === '50%',
                      'sm:w-[70%]': columnWidths[index] === '70%',
                    })}
                    style={{ backgroundColor: getBackgroundColor(paragraphs) }}
                  >
                    {paragraphs && <ParagraphItemWrapper paragraphs={paragraphs} content={content} type={type} />}
                  </div>
                ))}
              </div>
            )}

            {item.regions.length === 3 && (
              <div
                className={cn('grid gap-4', {
                  'gap-0': item.config.removeWhitespace,
                  'grid-cols-1 sm:grid-cols-3': item.config.columnWidths === SectionConfigColumnWidths.W33_34_33,
                  'grid-cols-4 grid-rows-2 sm:grid-rows-1':
                    item.config.columnWidths === SectionConfigColumnWidths.W25_50_25 ||
                    item.config.columnWidths === SectionConfigColumnWidths.W25_25_50 ||
                    item.config.columnWidths === SectionConfigColumnWidths.W50_25_25,
                })}
              >
                {item.regions.map((paragraphs, index) => (
                  <div
                    key={index}
                    className={cn({
                      'col-span-2 col-start-1 row-start-2 sm:col-span-1 sm:row-start-1':
                        item.config.columnWidths === SectionConfigColumnWidths.W25_50_25 && index === 0,
                      'col-span-4 col-start-1 row-start-1 sm:col-span-2 sm:col-start-2':
                        item.config.columnWidths === SectionConfigColumnWidths.W25_50_25 && index === 1,
                      'col-span-2 col-start-3 row-start-2 sm:col-span-1 sm:col-start-4 sm:row-start-1':
                        (item.config.columnWidths === SectionConfigColumnWidths.W25_50_25 && index === 2) ||
                        (item.config.columnWidths === SectionConfigColumnWidths.W50_25_25 && index === 2),

                      'col-span-2 col-start-1 row-start-1 sm:col-span-1':
                        item.config.columnWidths === SectionConfigColumnWidths.W25_25_50 && index === 0,
                      'col-span-2 col-start-3 row-start-1 sm:col-span-1 sm:col-start-2':
                        item.config.columnWidths === SectionConfigColumnWidths.W25_25_50 && index === 1,
                      'col-span-4 col-start-1 row-start-2 sm:col-span-2 sm:row-start-1':
                        item.config.columnWidths === SectionConfigColumnWidths.W25_25_50 && index === 2,

                      'col-span-4 col-start-1 row-start-1 sm:col-span-2':
                        item.config.columnWidths === SectionConfigColumnWidths.W50_25_25 && index === 0,
                      'col-span-2 col-start-1 row-start-2 sm:col-span-1 sm:col-start-3 sm:row-start-1':
                        item.config.columnWidths === SectionConfigColumnWidths.W50_25_25 && index === 1,
                    })}
                    style={{ backgroundColor: getBackgroundColor(paragraphs) }}
                  >
                    {paragraphs && <ParagraphItemWrapper paragraphs={paragraphs} content={content} type={type} />}
                  </div>
                ))}
              </div>
            )}

            {item.regions.length === 4 && (
              <div
                className={cn('grid grid-cols-2 gap-4 sm:grid-cols-4', {
                  'gap-0': item.config.removeWhitespace,
                })}
              >
                {item.regions.map((paragraphs, index) => (
                  <div key={index} className="w-full" style={{ backgroundColor: getBackgroundColor(paragraphs) }}>
                    {paragraphs && <ParagraphItemWrapper paragraphs={paragraphs} content={content} type={type} />}
                  </div>
                ))}
              </div>
            )}
          </Wrapper>
        );
      })}
    </>
  );
}
