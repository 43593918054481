import { useState } from 'react';

type Stack<T> = {
  items: T[];
  push: (item: T) => void;
  pop: () => T | undefined;
  top: () => T | undefined;
  isEmpty: () => boolean;
  clear: () => void;
};

export function useStack<T>(initialItems: T[] = []): Stack<T> {
  const [items, setItems] = useState<T[]>(initialItems);

  function push(item: T) {
    setItems([...items, item]);
  }

  function pop(): T | undefined {
    const poppedItem = items.pop();
    setItems([...items]);
    return poppedItem;
  }

  function top(): T | undefined {
    return items.length > 0 ? items[items.length - 1] : undefined;
  }

  function isEmpty(): boolean {
    return items.length === 0;
  }

  function clear() {
    setItems([]);
  }

  return { items, push, pop, top, isEmpty, clear };
}
