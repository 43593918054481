import { Disclosure } from '@headlessui/react';
import { PostSearch200ProductsFacetsSortedItemOneOfEight } from '@jysk/api-types/searchApi/model';
import { hasSelectedFilter } from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { FacetProps } from '@wla/app/(cms)/[...slug]/(filters)/filters-search';
import { Switch } from '@wla/components/ui/switch';

type SwitchFacetProps = FacetProps & {
  facet: PostSearch200ProductsFacetsSortedItemOneOfEight;
};

export function SwitchFacet({
  facet,
  queryFilters,
  handleChangeSeachFilter,
  isOpen,
  pathname,
  searchParams,
}: SwitchFacetProps) {
  return (
    <Disclosure as="div" className="border-b" defaultOpen={isOpen} key={facet.name}>
      <Switch
        isLeftLabel
        labelClassName="text-md font-semibold mr-auto my-5 h-12 flex items-center text-gray-1000"
        label={facet.name}
        disabled={facet.numberOfDocuments === 0}
        isChecked={hasSelectedFilter(facet.name, queryFilters)}
        onChange={() =>
          handleChangeSeachFilter(
            {
              key: facet.name,
              value: facet.name,
            },
            { pathname, searchParams, queryFilters },
          )
        }
      />
    </Disclosure>
  );
}
