'use client';

import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { Icon } from '@wla/components/ui/icon';
import { Text } from '@wla/components/ui/text';
import { useHash } from '@wla/lib/hooks/use-hash';
import { useEffect, useRef, useState } from 'react';
import { AccordionParagraphAllOfItemsItem } from '@jysk/api-types/drupalApi';

type AccordionOneOpenProps = {
  items: AccordionParagraphAllOfItemsItem[];
};

export function AccordionOneOpen({ items }: AccordionOneOpenProps) {
  const [isMounted, setIsMounted] = useState(false);
  const hash = useHash();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const isManualToggle = useRef(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted || !hash) return;

    const hashItem = items.find((item) => item.anchor && item.anchor === hash);
    const targetIndex = hashItem ? items.indexOf(hashItem) : null;

    setOpenIndex(targetIndex);

    if (hashItem && !isManualToggle.current) {
      const element = document.getElementById(hashItem.title);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 600);
      }
    }

    isManualToggle.current = false;
  }, [hash, isMounted, items]);

  function togglePanel(index: number) {
    isManualToggle.current = true;

    if (openIndex === index) {
      history.replaceState(null, '', window.location.pathname);
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
      if (items[index].anchor) {
        history.replaceState(null, '', `#${items[index].anchor}`);
      }
    }
  }

  if (items.length === 0 || !isMounted) return null;

  return (
    <div className="border-t border-gray-300 @container/terms-conditions">
      {items.map((item, index) => (
        <Disclosure key={index} as="div" defaultOpen={openIndex === index}>
          <div className="border-b border-gray-300" id={item.title}>
            <DisclosureButton
              className="w-full"
              data-type={item.anchor || undefined}
              onClick={() => togglePanel(index)}
            >
              <div className="flex h-full w-full items-center justify-center">
                <p className="my-7 w-full text-left text-base @md/terms-conditions:my-10">{item.title}</p>
                <Icon src={openIndex === index ? 'w3-arrow-up' : 'w3-arrow-down'} size="24" />
              </div>
            </DisclosureButton>
            <Transition
              as="div"
              show={openIndex === index}
              enter="transition-[grid-template-rows] duration-500 ease-out"
              enterFrom="grid-rows-[0fr]"
              enterTo="grid-rows-[1fr]"
              leave="transition-[grid-template-rows] duration-500 ease-in"
              leaveFrom="grid-rows-[1fr]"
              leaveTo="grid-rows-[0fr]"
              className="grid"
            >
              <DisclosurePanel className="w-full overflow-hidden pb-5 sm:pb-8">
                <Text value={item.content} />
              </DisclosurePanel>
            </Transition>
          </div>
        </Disclosure>
      ))}
    </div>
  );
}
