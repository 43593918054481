import { Icon } from '@wla/components/ui/icon';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { MouseEvent, useState } from 'react';

export const RatingSizes = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-3xl',
  '3xl': 'text-4xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
};

type RatingSize = keyof typeof RatingSizes;

type RatingProps = {
  rating?: number | null;
  size?: RatingSize;
  max?: number;
  onChange?: (newRating: number) => void;
  className?: string;
  iconSize?: string;
};

export function Rating({ size, max = 5, rating, onChange, className, iconSize }: RatingProps) {
  const [hovered, setHovered] = useState(-1);
  const useAsInput = Boolean(onChange);

  const {
    featureFlags: { enableProductCustomerReviews },
  } = usePublicConfig();

  if (!enableProductCustomerReviews) return null;

  const stars = new Array(max).fill('').map((_, i) => (rating && rating > i ? Math.min((rating - i) * 100, 100) : 0));

  const StarComponent = useAsInput ? 'button' : 'span';
  const getActionProps = (index: number) =>
    useAsInput
      ? {
          onMouseLeave: () => setHovered(-1),
          onMouseEnter: () => setHovered(index),
          onClick: (event: MouseEvent<HTMLElement>) => {
            event.preventDefault();
            onChange?.(index + 1);
          },
        }
      : {};

  // TODO: Could use some aria labels for a11y
  return (
    <div className={cn('whitespace-nowrap', size && RatingSizes[size], className)}>
      {stars.map((s, i) => (
        <StarComponent
          key={i}
          className={cn('relative inline-block text-gray-400 last:mr-0', useAsInput && 'cursor-pointer')}
          {...getActionProps(i)}
        >
          <Icon src="w3-star" className={iconSize} />
          <span
            className="pointer-events-none absolute left-0 top-0 mr-[3px] h-full select-none overflow-hidden text-black"
            style={{ width: (hovered >= i ? 100 : s) + '%' }}
          >
            <Icon src="w3-star-filled" className={iconSize} />
          </span>
        </StarComponent>
      ))}
    </div>
  );
}
