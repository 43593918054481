import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ProductVariantProduct } from '@jysk/api-types/drupalApi';
import { ProductVariantType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-size-picker';
import { Drawer } from '@wla/components/ui/drawer';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';

type ProductSizePickerDrawerProps = {
  currentProduct: ProductVariantProduct;
  filteredProducts: ProductVariantProduct[]; // Updated to receive the filtered products
};

export const ProductSizePickerDrawer = NiceModal.create(
  ({ currentProduct, filteredProducts }: ProductSizePickerDrawerProps) => {
    const t = useTranslations('common');
    const { visible, hide } = useModal();

    // Function to sort sizes by width first and then by length, and then by priceDifference if sizes are the same
    function sortSizes(
      a: { sizeName: string; priceDifference: string | null },
      b: { sizeName: string; priceDifference: string | null },
    ) {
      const [widthA, lengthA] = a.sizeName.split('x').map(Number);
      const [widthB, lengthB] = b.sizeName.split('x').map(Number);

      if (widthA !== widthB) {
        return widthA - widthB;
      } else if (lengthA !== lengthB) {
        return lengthA - lengthB;
      } else {
        // If sizes are the same, sort by priceDifference
        const priceDiffA = parseFloat(a.priceDifference?.replace(/[^0-9.-]+/g, '') || '0');
        const priceDiffB = parseFloat(b.priceDifference?.replace(/[^0-9.-]+/g, '') || '0');
        return priceDiffA - priceDiffB;
      }
    }

    // Map the product sizes and sort them
    const sortedProductSizes = filteredProducts
      .map((product) => {
        const sizeOption = product.options.find((option) =>
          option.typeId.toLowerCase().includes(ProductVariantType.Size.toLowerCase()),
        );

        return {
          ...product,
          sizeName: sizeOption?.name || '',
          priceDifference: product.priceDifference || '',
        };
      })
      .sort((productA, productB) => sortSizes(productA, productB));

    return (
      <Drawer open={visible} onClose={hide}>
        <Drawer.Header title={t('size')} />
        <Drawer.Body className="flex flex-col gap-4">
          {sortedProductSizes.map((product) => (
            <Link
              key={product.id}
              href={`/product-lookup/${product.id}`}
              className={cn(
                'flex items-center justify-between rounded border border-gray-400 p-6 hover:cursor-pointer hover:border-gray-500',
                {
                  'border-2 border-blue-500': product.id === currentProduct.id,
                },
              )}
              onClick={hide}
            >
              <p>{product.sizeName}</p>
              <p className="text-sm text-gray-500">{product.priceDifference}</p>
            </Link>
          ))}
        </Drawer.Body>
      </Drawer>
    );
  },
);
