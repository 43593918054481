type IframeProps = {
  url: string;
  title?: string;
  className?: string;
  allow?: string;
};

export function Iframe({ url, title, className = 'aspect-video', allow }: IframeProps) {
  return (
    <iframe
      className={className}
      src={url} // TODO! This should be an empty string when we are ready with cookies
      title={title}
      width="100%"
      height="100%"
      scrolling="no"
      frameBorder="0"
      loading="lazy"
      allow={allow}
      data-consent-src={url}
      data-category-consent="cookie_cat_statistic"
    />
  );
}
