import NiceModal from '@ebay/nice-modal-react';
import { BackInStockDrawer } from '@wla/app/(cms)/(back-in-stock-drawer)/back-in-stock-drawer';
import { ShareFavouritesDrawer } from '@wla/app/(cms)/(customer)/customer/(authenticated)/favourites/share-favourites-drawer';
import { RequestDeleteModal } from '@wla/app/(cms)/(customer)/customer/(authenticated)/profile/request-delete-modal';
import { CarrierDrawer } from '@wla/app/(cms)/(favorite-store)/carrier-drawer';
import { FavoriteDrawer } from '@wla/app/(cms)/(favorite-store)/favorite-store-drawer';
import { MaximumQuantityModal } from '@wla/app/(cms)/[...slug]/(product-detail-page)/maximum-quantity-modal';
import { OnlineMinimumQuantityModal } from '@wla/app/(cms)/[...slug]/(product-detail-page)/online-minimum-quantity-modal';
import { PowerStepDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/power-step-drawer/power-step-drawer';
import { ProductsDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-accessory-button';
import { ProductsInformationDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-information-button';
import { ProductGalleryDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-gallery-drawer';
import { ProductSizePickerDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-size-picker-drawer';
import { CreateReviewDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/create-review-drawer';
import { ReviewDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/review-drawer';
import { ReviewGuidelinesDrawer } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/review-guidelines';
import { LoginNavigationDrawer } from '@wla/app/(cms)/login-drawer';
import { NavigationDrawer } from '@wla/app/(cms)/navigation-drawer';
import { AddressClickCollectDetailsDrawer } from '@wla/app/checkout/(address)/address-click-collect-details-drawer';
import { CarrierServiceInfoDrawer } from '@wla/app/checkout/(carriers)/carrier-service-info-drawer';
import { PaymentPayoneDrawer } from '@wla/app/checkout/(payment)/payment-payone-drawer';
import { CheckoutConvertBasketModal } from '@wla/app/checkout/convert-basket-modal';
import { CheckoutZipDrawer } from '@wla/app/checkout/zip-drawer';
import { TermsAndConditionsDrawer } from '@wla/components/ui/forms/terms-and-conditions-drawer';
import { GiftcardPreviewModal } from '@wla/components/ui/giftcard/giftcard-preview-modal';
import { PDPSideDrawer } from '@wla/components/ui/side-drawer';
import { FavouritesErrorModal } from '@wla/lib/hooks/favourites/favourites-error-modal';

export enum DRAWER_ID {
  ADDRESS_CLICK_COLLECT_DETAILS_DRAWER = 'address-click-collect-details-drawer',
  BACK_IN_STOCK_DRAWER_ID = 'back-in-stock-drawer-id',
  CARRIER_SERVICE_INFO_DRAWER = 'carrier-service-info-drawer',
  CHECKOUT_CONVERT_BASKET_MODAL = 'checkout-convert-basket-modal',
  CHECKOUT_ZIP_DRAWER = 'checkout-zip-drawer',
  CREATE_REVIEW_DRAWER = 'create-review-drawer',
  FAVORITE_STORE_DRAWER = 'favorite-store-drawer',
  FAVOURITES_ERROR_MODAL = 'favourites-error-modal',
  GIFTCARD_PREVIEW_MODAL = 'giftcard-preview-modal',
  LOGIN_NAVIGATION_DRAWER_ID = 'login-navigation-drawer-id',
  MAX_QTY_MODAL = 'maximum-quantity-modal',
  NAVIGATION_DRAWER_ID = 'navigation-drawer',
  ONLINE_MIN_QTY_MODAL = 'online-minimum-quantity-modal',
  PAYMENT_PAYONE_DRAWER = 'payment-payone-drawer',
  PDP_SIDE_DRAWER_ID = 'pdp_side-drawer-id',
  POWER_STEP_DRAWER = 'power-step-modal',
  PRODUCTS_DRAWER = 'products-drawer',
  PRODUCTS_INFORMATION_DRAWER = 'products-information-drawer',
  PRODUCT_GALLERY_DRAWER = 'product-gallery-drawer',
  PRODUCT_SIZE_PICKER = 'product-size-picker',
  REVIEW_DRAWER = 'review-drawer',
  REVIEW_GUIDELINES_DRAWER = 'ReviewGuidelinesDrawer',
  REQUEST_DELETE_MODAL = 'request-delete-customer-modal',
  SHARE_FAVOURITES_DRAWER = 'share-favourites-drawer',
  TERMS_AND_CONDITIONS_DRAWER = 'terms-and-conditions-drawer',
  CARRIER_DRAWER = 'carrier-drawer',
}

NiceModal.register(DRAWER_ID.ADDRESS_CLICK_COLLECT_DETAILS_DRAWER, AddressClickCollectDetailsDrawer);
NiceModal.register(DRAWER_ID.BACK_IN_STOCK_DRAWER_ID, BackInStockDrawer);
NiceModal.register(DRAWER_ID.CARRIER_SERVICE_INFO_DRAWER, CarrierServiceInfoDrawer);
NiceModal.register(DRAWER_ID.CHECKOUT_CONVERT_BASKET_MODAL, CheckoutConvertBasketModal);
NiceModal.register(DRAWER_ID.CHECKOUT_ZIP_DRAWER, CheckoutZipDrawer);
NiceModal.register(DRAWER_ID.CREATE_REVIEW_DRAWER, CreateReviewDrawer);
NiceModal.register(DRAWER_ID.FAVORITE_STORE_DRAWER, FavoriteDrawer);
NiceModal.register(DRAWER_ID.FAVOURITES_ERROR_MODAL, FavouritesErrorModal);
NiceModal.register(DRAWER_ID.GIFTCARD_PREVIEW_MODAL, GiftcardPreviewModal);
NiceModal.register(DRAWER_ID.LOGIN_NAVIGATION_DRAWER_ID, LoginNavigationDrawer);
NiceModal.register(DRAWER_ID.MAX_QTY_MODAL, MaximumQuantityModal);
NiceModal.register(DRAWER_ID.NAVIGATION_DRAWER_ID, NavigationDrawer);
NiceModal.register(DRAWER_ID.ONLINE_MIN_QTY_MODAL, OnlineMinimumQuantityModal);
NiceModal.register(DRAWER_ID.PAYMENT_PAYONE_DRAWER, PaymentPayoneDrawer);
NiceModal.register(DRAWER_ID.PDP_SIDE_DRAWER_ID, PDPSideDrawer);
NiceModal.register(DRAWER_ID.POWER_STEP_DRAWER, PowerStepDrawer);
NiceModal.register(DRAWER_ID.PRODUCTS_DRAWER, ProductsDrawer);
NiceModal.register(DRAWER_ID.PRODUCTS_INFORMATION_DRAWER, ProductsInformationDrawer);
NiceModal.register(DRAWER_ID.PRODUCT_GALLERY_DRAWER, ProductGalleryDrawer);
NiceModal.register(DRAWER_ID.PRODUCT_SIZE_PICKER, ProductSizePickerDrawer);
NiceModal.register(DRAWER_ID.REVIEW_DRAWER, ReviewDrawer);
NiceModal.register(DRAWER_ID.REVIEW_GUIDELINES_DRAWER, ReviewGuidelinesDrawer);
NiceModal.register(DRAWER_ID.REQUEST_DELETE_MODAL, RequestDeleteModal);
NiceModal.register(DRAWER_ID.SHARE_FAVOURITES_DRAWER, ShareFavouritesDrawer);
NiceModal.register(DRAWER_ID.TERMS_AND_CONDITIONS_DRAWER, TermsAndConditionsDrawer);
NiceModal.register(DRAWER_ID.CARRIER_DRAWER, CarrierDrawer);
