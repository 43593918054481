import { PostSearch200ProductsFacets, PostSearch200ProductsFacetsSortedItem } from '@jysk/api-types/searchApi/model';
import { FiltersSearch } from '@wla/app/(cms)/[...slug]/(filters)/filters-search';
import { Button } from '@wla/components/ui/button/button';
import { Drawer } from '@wla/components/ui/drawer';
import { useTranslations } from 'next-intl';
import { ResetFiltersButton } from './reset-filters-button';

type FilterSearchDrawerProps = {
  filters: PostSearch200ProductsFacets;
  toggleFilterDrawer: boolean;
  isLoading?: boolean;
  preOpenFilter?: PostSearch200ProductsFacetsSortedItem | null;
  productCount: number;
  onFilterDrawerToggle: () => void;
  showCount?: boolean;
};

export function FilterSearchDrawer({
  filters,
  isLoading,
  toggleFilterDrawer,
  productCount,
  preOpenFilter,
  onFilterDrawerToggle,
  showCount,
}: FilterSearchDrawerProps) {
  const t = useTranslations();
  return (
    <Drawer open={toggleFilterDrawer} onClose={onFilterDrawerToggle}>
      <Drawer.Header title={t('common.filter')} />
      <Drawer.Body>
        <FiltersSearch filters={filters} preOpenFilter={preOpenFilter} isLoading={isLoading} />
      </Drawer.Body>
      <Drawer.Footer className="mt-auto flex gap-2 border-none px-5">
        <ResetFiltersButton className="min-w-28" />
        <Button onClick={onFilterDrawerToggle} variant="primary" className="flex w-full justify-center">
          {showCount ? t('plp.show-filters-results', { results: productCount }) : t('common.apply')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
}
