/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
'use client';
import Image from 'next/image';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

type ImageProps = React.ComponentProps<typeof Image>;

export default function CustomImage(props: ImageProps) {
  function fallbackRender({ error }: FallbackProps) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    console.log('🔴 Image Error', error);
    console.log('props', props);

    const placeholderUrl = `https://placehold.co/${props.width || '300'}x${props.height || '300'}/FF1100/FFFFFF?text=Error`;

    return <img src={placeholderUrl} alt={props.alt} className={props.className} />;
  }

  // TODO: Remove this before production
  if (!props.src) {
    const placeholderUrl = `https://placehold.co/${props.width || '300'}x${props.height || '300'}${props.alt ? `?text=${props.alt}` : ''}`;

    return <img src={placeholderUrl} alt={props.alt} className={props.className} />;
  }

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <Image {...props} />
    </ErrorBoundary>
  );
}
