import { cn } from '@wla/lib/helpers/cn';
import { Drawer } from '../drawer';

type SectionProps = {
  children: React.ReactNode;
  classname?: string;
};

export function Section({ children, classname }: SectionProps) {
  return (
    <Drawer.BodySection className={cn('pb-0', classname)}>
      <nav className="flex flex-col gap-y-6">{children}</nav>
    </Drawer.BodySection>
  );
}
