'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { EmbeddedContentType } from '@jysk/api-types/drupalApi';
import { Drawer } from '@wla/components/ui/drawer';
import { EmbeddedContent } from '@wla/components/ui/embedded-content/embedded-content';
import { useTranslations } from 'next-intl';

type CarrierServiceInfoModalProps = {
  contentType: EmbeddedContentType;
};

export const CarrierServiceInfoDrawer = NiceModal.create(({ contentType }: CarrierServiceInfoModalProps) => {
  const t = useTranslations('checkout');
  const modal = useModal();

  if (
    contentType !== EmbeddedContentType.CheckoutCarrierDeliveryCarryIn &&
    contentType !== EmbeddedContentType.CheckoutCarrierDeliveryFlex
  ) {
    return null;
  }

  return (
    <Drawer
      open={modal.visible}
      onClose={() => {
        modal.resolve({});
        modal.hide();
      }}
    >
      <Drawer.Header
        title={
          contentType === EmbeddedContentType.CheckoutCarrierDeliveryCarryIn
            ? t('carrier.service-info-carry-in')
            : t('carrier.service-info-flex-delivery')
        }
      />
      <Drawer.Body>
        <EmbeddedContent contentTypes={contentType} excludeWrapper />
      </Drawer.Body>
    </Drawer>
  );
});
