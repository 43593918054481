'use client';

import { PageContext } from '@wla/app/(cms)/[...slug]/page-provider';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { ProductCardHeader } from '@wla/components/ui/product-card/product-card-header';
import { ProductCardInfo } from '@wla/components/ui/product-card/product-card-info';
import { usePublicConfig } from '@wla/lib/configs';
import { getProductImage } from '@wla/lib/helpers/product';
import { useAnalyticsData } from '@wla/lib/hooks/analytics/use-analytics-data';
import { trackSelectItem } from '@wla/lib/tracking/google-analytics-tracking';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { OnlineAtpWithStoreStatusMap } from '@jysk/api-types/webSapApiV2/model/onlineAtpWithStoreStatusMap';

export type AddedRecommendedProduct = {
  id: string;
  counter: number;
  success: boolean;
};

type ProductCardProps = {
  product: ProductTeaser | PostSearch200ProductsHitsItem;
  refId?: string;
  showAddToBasketBtn?: boolean;
  addedRecommendedProduct?: AddedRecommendedProduct;
  stockStatus?: OnlineAtpWithStoreStatusMap;
  isLoadingStock?: boolean;
  isCompact?: boolean;
  isAboveTheFold?: boolean;
  onProductVisible?: (product: ProductTeaser | PostSearch200ProductsHitsItem) => void;
  onClick?: () => void;
  onAddToBasket?: (addedRecommendedProduct: Omit<AddedRecommendedProduct, 'success'>) => void;
  onProductCardClick?: () => void;
};

export function ProductCard({
  product,
  refId,
  showAddToBasketBtn = false,
  addedRecommendedProduct,
  stockStatus,
  isLoadingStock,
  isCompact,
  isAboveTheFold,
  onProductVisible,
  onClick,
  onAddToBasket,
  onProductCardClick,
}: ProductCardProps) {
  const t = useTranslations();
  const { url } = product;
  const { productCategories } = useAnalyticsData();
  const { currency } = usePublicConfig();
  const { pageType } = useContext(PageContext);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // It counts how many of that product are being added as a recommended product via the power-step-drawer. Necessary to trigger every time the showCheckmark button.
  const [counter, setCounter] = useState<number>(addedRecommendedProduct?.counter || 0);
  const router = useRouter();
  const image = getProductImage(product);
  const fullURL = refId ? `${url}?refId=${refId}` : url;
  const productRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      trackSelectItem({
        product,
        productCategories,
        pageType,
        refId,
        currency,
      });
      onClick?.();
      router.push(fullURL);
      onProductCardClick?.();
    },
    [product, productCategories, pageType, refId, router, onProductCardClick, fullURL],
  );

  function onAddToBasketClick() {
    onAddToBasket?.({ id: product.id, counter: counter + 1 });
    setIsLoading(true);
  }

  function handleIntersect(entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
      onProductVisible?.(product);
    }
  }

  useEffect(() => {
    setIsLoading(false);
    addedRecommendedProduct?.id && addedRecommendedProduct.counter > counter && setShowCheckmark(true);
    setTimeout(() => {
      setShowCheckmark(false);
      product.id === addedRecommendedProduct?.id && setCounter(addedRecommendedProduct.counter);
    }, 2000);
  }, [addedRecommendedProduct]);

  useEffect(() => {
    const currentElement = productRef.current;
    if (!currentElement) return;

    const observer = new IntersectionObserver(handleIntersect, { threshold: 0.5 });
    observer.observe(currentElement);
    return () => observer.disconnect();
  }, [product.id]);

  return (
    <article ref={productRef} className="relative flex h-full flex-col">
      <ProductCardHeader
        isAboveTheFold={isAboveTheFold}
        product={product}
        url={fullURL}
        image={image}
        onClick={handleClick}
        isCompact={isCompact}
      />
      <ProductCardInfo
        product={product}
        url={fullURL}
        stockStatus={stockStatus}
        onClick={handleClick}
        isLoadingStock={isLoadingStock}
      />
      {showAddToBasketBtn && (
        <SubmitButton
          variant="primary"
          size="medium"
          loading={isLoading}
          loadingMessage={t('common.loading')}
          onClick={onAddToBasketClick}
          {...(showCheckmark &&
            product.id === addedRecommendedProduct?.id && {
              iconPosition: 'only',
              customIcon: 'w3-checkmark',
            })}
          className="mt-2 w-auto py-2.5"
        >
          {t('common.add-to-basket')}
        </SubmitButton>
      )}
    </article>
  );
}
