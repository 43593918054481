import { Switch } from '@wla/components/ui/switch';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export type FilterState = {
  isShowInStore: boolean;
  isInStock: boolean;
};

type FavoriteStoreFiltersProps = {
  onFilter: (filters: FilterState) => void;
  initialFilters?: Partial<FilterState>;
};

export function FavoriteStoreFilters({
  onFilter,
  initialFilters: { isInStock, isShowInStore } = {},
}: FavoriteStoreFiltersProps) {
  const t = useTranslations();
  const [filters, setFilters] = useState<FilterState>({
    isShowInStore: isShowInStore || false,
    isInStock: isInStock || false,
  });

  useEffect(() => {
    onFilter(filters);
  }, [filters, onFilter]);

  useEffect(() => {
    setFilters({
      isShowInStore: isShowInStore || false,
      isInStock: isInStock || false,
    });
  }, [isInStock, isShowInStore]);

  const handleChange = (filterName: string, value: boolean) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  return (
    <div className="mb-4 mt-2 flex gap-4">
      <Switch
        label={t('common.in-stock')}
        isChecked={filters.isInStock}
        onChange={(value) => handleChange('isInStock', value)}
      />
      <Switch
        label={t('favorite-store.shop-with-display-model')}
        isChecked={filters.isShowInStore}
        onChange={(value) => handleChange('isShowInStore', value)}
      />
    </div>
  );
}
