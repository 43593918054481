import { MenuParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { MenuLinks } from '../menu-links';

type ParagraphMenuProps = {
  content: MenuParagraph;
};

export function ParagraphMenu({ content }: ParagraphMenuProps) {
  const { config, items } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config}>
      <MenuLinks items={items} />
    </ParagraphConfig>
  );
}
