import { Icon } from '@wla/components/ui/icon';

type InfoWarningProps = {
  text: string;
};

export function InfoWarning({ text }: InfoWarningProps) {
  return (
    <div className="flex gap-3 bg-yellow-100 px-5 py-3">
      <Icon src="w3-info" size="20" />
      <p className="text-base">{text}</p>
    </div>
  );
}
