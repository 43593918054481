import type { TableParagraphAllOfRowsOneOfItemColumnsOneOfItem } from '@jysk/api-types/drupalApi';
import { cn } from '@wla/lib/helpers/cn';
import Image from 'next/image';

type TableCellProps = {
  column: TableParagraphAllOfRowsOneOfItemColumnsOneOfItem;
  firstColumnSticky: boolean;
  isOddRow: boolean;
};

export function TableCell({ column, firstColumnSticky, isOddRow }: TableCellProps) {
  if (!column) return null;
  return (
    <td
      className={cn('min-w-40 px-4 py-2 shadow-[inset_-1px_-1px_0] shadow-gray-300', {
        'sticky left-0': firstColumnSticky,
        'bg-gray-100': isOddRow,
        'bg-white': !isOddRow,
      })}
    >
      {column.image && (
        <Image
          src={column.image.url}
          alt={column.image.alt || ''}
          width={column.image.dimensions?.width}
          height={column.image.dimensions?.height}
        />
      )}
      {column.text && <div className="text-base" dangerouslySetInnerHTML={{ __html: column.text.value }} />}
    </td>
  );
}
