'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MediaGroupName } from '@wla/app/(cms)/[...slug]/(product-detail-page)/helpers/product-images-mapping';
import { FullImageType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-image';
import { MappedMediaItem } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-media';
import { FullVideoType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-video';
import { Drawer } from '@wla/components/ui/drawer';
import { Icon } from '@wla/components/ui/icon';
import { useEffect, useState } from 'react';
import { ProductGallerySwiper } from './product-gallery-swiper';

type ProductGalleryDrawerProps = {
  medias: MappedMediaItem[];
  is360Image?: boolean;
  initialSlide?: number;
};

export function isVideo(media: FullVideoType | FullImageType): media is FullVideoType {
  return (media as FullVideoType).groupName === MediaGroupName.Video;
}

export const ProductGalleryDrawer = NiceModal.create(
  ({ medias, is360Image = false, initialSlide }: ProductGalleryDrawerProps) => {
    const modal = useModal();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
      const raf = requestAnimationFrame(() => {
        return setIsDrawerOpen(true);
      });
      return () => {
        cancelAnimationFrame(raf);
        setIsDrawerOpen(false);
      };
    }, [modal.visible]);

    return (
      <Drawer open={modal.visible} onClose={modal.hide} position="fullscreen">
        <button onClick={modal.hide} className="fixed right-2 top-2 z-10 p-3 outline-0">
          <Icon src="w3-close" size="24" />
        </button>
        <Drawer.Body className="relative mt-0 h-full px-0 s:px-0 lg:px-0">
          {isDrawerOpen && <ProductGallerySwiper medias={medias} is360Image={is360Image} initialSlide={initialSlide} />}
        </Drawer.Body>
      </Drawer>
    );
  },
);
