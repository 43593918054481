export function JyskLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 32">
      <path fill="#143c8a" d="M0 0h70.069v31.991H0V0z" />
      <path
        fill="#fff"
        d="M23.446 8.32v9.89c0 2.92-2.064 5.022-5.003 5.022-1.519 0-3.192-.661-4.379-1.597l1.81-4.536c.274.701.584 1.539 1.421 1.539.858 0 .935-.974.935-1.597V8.319zm6.861 0 1.635 3.837h.156c.156-.467.291-.934.487-1.382l1.051-2.452h5.997l-5.023 8.349v6.327h-5.218v-6.327l-5.12-8.352zm9.805 9.422.273.215c.954.74 2.102 1.344 3.328 1.344.526 0 1.362-.214 1.362-.876s-.701-.721-1.188-.818l-1.069-.194c-2.085-.369-3.836-1.595-3.836-3.893 0-3.485 3.173-5.43 6.365-5.43 1.674 0 3.212.409 4.653 1.285l-1.441 3.757c-.915-.623-1.945-1.109-3.095-1.109-.467 0-1.149.175-1.149.778 0 .546.663.623 1.071.721l1.207.272c2.258.506 3.894 1.713 3.894 4.204 0 3.503-3.192 5.237-6.365 5.237-1.849 0-3.992-.506-5.646-1.343zM52.079 8.32h5.197l-.117 6.365h.117c.118-.602.448-1.127.741-1.653l2.57-4.711h5.821l-4.596 7.143 5.08 7.532h-6.191l-2.531-4.438c-.311-.565-.777-1.109-.896-1.713h-.117l.117 6.151h-5.196zM5.177 5.242h-.008a.743.743 0 0 0-.529.22.749.749 0 0 0-.198.544c.006.331.185.701.481.983.512.49 1.187.589 1.539.224a.745.745 0 0 0 .199-.508v-.022.001c0-.326-.168-.698-.482-1.001-.296-.284-.671-.45-1.002-.443z"
      />
      <path
        fill="#fff"
        d="M7.275 31.997a9.49 9.49 0 0 0 .28-1.615q.037-.337.054-.683c.136-2.782-.862-6.236-2.911-8.997-2.082-2.808-3.402-4.841-3.402-6.543s1.243-2.519 2.127-2.715 3.206-.198 4.057-.916c.83-.702 1.308-1.701 1.21-2.943s-.402-3.966-4.612-4.156c-1.479-.066-3.032.54-4.077 1.343l.002-2.198a9.434 9.434 0 0 1 4.209-.976h.032-.002c3.468 0 5.07 2.224 5.07 2.224s2.061.85 3.501 1.962 2.617 2.323 2.715 2.487.36.784-.524 1.079-2.649.621-4.449.621c0 0-1.177 2.094-3.042 2.748s-4.027.028-4.122 1.734c-.065 1.178.981 2.585 1.374 3.14s4.87 5.01 4.613 11.842c-.056 1.469-.287 2.564-.287 2.564l-1.816-.003zm3.389-23.76s1.016.131 1.864.164.72-.359.72-.359c-.392-.327-2.715-1.635-2.715-1.635-.328.851.13 1.832.13 1.832z"
      />
    </svg>
  );
}
