'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@wla/components/ui/dialog';
import { useTranslations } from 'next-intl';

export const RequestDeleteModal = NiceModal.create(() => {
  const t = useTranslations();
  const modal = useModal();

  return (
    <Dialog
      open={modal.visible}
      onClose={() => {
        modal.hide();
      }}
      isModal
    >
      <Dialog.Panel>
        <Dialog.Header>
          <Dialog.Title>
            <div className="text-xl normal-case">{t('customer.delete.you-are-about-to-delete-your-account')}</div>
          </Dialog.Title>
          <Dialog.Close />
        </Dialog.Header>
        <Dialog.Body className="space-y-5">
          <p>{t('customer.delete.click-confirmation-link')}</p>
          <p className="text-xl font-bold">{t('customer.delete.What-are-we-deleting')}</p>
          <p>{t('customer.delete.data-information')}</p>
          <ul className="ml-4 list-disc">
            <li> {t('forms.name')}</li>
            <li> {t('forms.mobile-number')}</li>
            <li> {t('forms.address')}</li>
            <li> {t('forms.email')}</li>
          </ul>
          <p>{t('customer.delete.ignore-email')}</p>
        </Dialog.Body>
      </Dialog.Panel>
    </Dialog>
  );
});
