import NiceModal from '@ebay/nice-modal-react';
import { PageAnalytics } from '@wla/app/(cms)/[...slug]/page-analytics';
import { ContentLayout } from '@wla/components/ui/content-layout';
import { cn } from '@wla/lib/helpers/cn';
import { scrollToOffset } from '@wla/lib/helpers/scroll-to';
import { DRAWER_ID } from '@wla/lib/modals';
import { StructuralPageFull } from '@jysk/api-types/drupalApi';

type NewsletterTermsAndConditionsProps = {
  sectionId: string;
  triggerText: string;
  title: string;
  className?: string;
};

export function NewsletterTermsAndConditions({
  sectionId,
  triggerText,
  className,
  title,
}: NewsletterTermsAndConditionsProps) {
  async function handleOnTermsAndConditionsClick() {
    // NiceModal is not designed to handle async operations, so we need to show the modal first and then fetch the data.
    NiceModal.show(DRAWER_ID.PDP_SIDE_DRAWER_ID, {
      title,
    });
    const response = await fetch(`/content/get-page?path=/structural-page/TermsConditions?redirect=false`);
    const { data } = await response.json();
    NiceModal.show(DRAWER_ID.PDP_SIDE_DRAWER_ID, {
      title,
      bodySlot: (
        <>
          <PageAnalytics pageType={(data.entity.data as StructuralPageFull).structuralPageType} />
          <ContentLayout content={(data.entity.data as StructuralPageFull).content} />
        </>
      ),
    });
    requestAnimationFrame(() => {
      const button = document.querySelector('[data-type="' + sectionId + '"]');
      const drawerHeader = document.querySelector('[data-drawerheader]');
      const drawerbody = document.querySelector('[data-drawerbody]');
      const buttonTop = (button?.getBoundingClientRect().top ?? 0) - (drawerHeader?.clientHeight ?? 0);

      button?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
      setTimeout(() => {
        drawerbody && scrollToOffset(buttonTop, drawerbody);
      }, 500);
    });
  }

  return (
    <button
      type="button"
      className={cn('py-1 text-sm text-gray-500 hover:underline', className)}
      onClick={handleOnTermsAndConditionsClick}
    >
      {triggerText}
    </button>
  );
}
