import { FavoriteStoreOpeningStatus } from '@wla/app/(cms)/(favorite-store)/favorite-store-opening-status';
import { formatTime, getStoreOpeningData } from '@wla/app/(cms)/(favorite-store)/get-store-opening-data';
import { StoreInfo } from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator';
import { Button } from '@wla/components/ui/button/button';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { useTranslations } from 'next-intl';
import { StoreFull } from '@jysk/api-types/drupalApi';

type MarkerTooltipProps = {
  storeInfo: StoreInfo;
  onClose: () => void;
  focusedStore?: StoreFull;
};

export function MarkerTooltip({ storeInfo, onClose, focusedStore }: MarkerTooltipProps) {
  const t = useTranslations();
  const openingInfo = getStoreOpeningData(storeInfo.openingHours);
  const isFocusedStoreOpen = focusedStore && focusedStore.id === storeInfo.id;

  return (
    <div className="relative">
      <div className="absolute -bottom-2 left-1/2 h-5 w-5 -translate-x-1/2 rotate-45 transform rounded bg-white" />
      <div className="relative w-72 rounded bg-white p-4">
        <button onClick={onClose} className="absolute right-4 top-3">
          <Icon size="20" src="w3-close" />
        </button>
        <h3 className="text-lg font-semibold pr-5">
          {t('common.jysk-store', {
            storeName: storeInfo.name,
          })}
        </h3>
        <p className="text-sm text-gray-500">{storeInfo.address}</p>
        <Link
          href={`https://maps.google.com?saddr=&daddr=${storeInfo.lat},${storeInfo.lng}`}
          className="my-2 block w-fit text-sm"
          target="_blank"
          rel="noreferrer"
        >
          {t('common.get-directions')}
        </Link>
        {openingInfo && !isFocusedStoreOpen && <FavoriteStoreOpeningStatus openingInfo={openingInfo} readonly />}
        {openingInfo && isFocusedStoreOpen && (
          <div className="text-sm">
            <p className="font-bold">{t('common.opening-hours')}</p>
            <div className="text-gray-500">
              <div className="grid grid-cols-2">
                <p>{t('store-locator.today')}</p>
                <p>
                  {storeInfo.openingHours
                    ? `${formatTime(storeInfo.openingHours[0].startHours)} - ${formatTime(storeInfo.openingHours[0].endHours)}`
                    : t('common.closed')}
                </p>
              </div>
              <div className="grid grid-cols-2">
                <p>{t('store-locator.tomorrow')}</p>
                <p>
                  {storeInfo.openingHours
                    ? `${formatTime(storeInfo.openingHours[1].startHours)} - ${formatTime(storeInfo.openingHours[1].endHours)}`
                    : t('common.closed')}
                </p>
              </div>
            </div>
          </div>
        )}
        {!isFocusedStoreOpen && (
          <Button
            variant="primary"
            size="medium"
            onClick={() => (window.location.pathname = storeInfo.url)}
            className="mt-2 w-full"
          >
            {t('common.show-store')}
          </Button>
        )}
      </div>
    </div>
  );
}
