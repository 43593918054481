'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { PaginatedReviews } from '@jysk/api-types/wssReviewsApi/model';
import { ReviewBlock } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/review-block';
import { Drawer } from '@wla/components/ui/drawer';
import { useTranslations } from 'next-intl';

type ReviewDrawerProps = {
  reviews: PaginatedReviews;
  productId: string;
};

export const ReviewDrawer = NiceModal.create(({ reviews, productId }: ReviewDrawerProps) => {
  const t = useTranslations();
  const modal = useModal();

  return (
    <Drawer open={modal.visible} onClose={modal.hide} position="right">
      <Drawer.Header title={t('pdp.review.review')} />
      <Drawer.Body className="mt-0">
        <ReviewBlock reviews={reviews} productId={productId} onOpenCreateReviewDrawer={modal.hide} />
      </Drawer.Body>
    </Drawer>
  );
});
