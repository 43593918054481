import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { PostSearch200ProductsFacetsSortedItemOneOfFour } from '@jysk/api-types/searchApi/model';
import { FacetProps } from '@wla/app/(cms)/[...slug]/(filters)/filters-search';
import { Icon } from '@wla/components/ui/icon';
import { RangeSlider } from '@wla/components/ui/range-slider';
import { cn } from '@wla/lib/helpers/cn';
import { filterActions } from '@wla/lib/helpers/filter-change-handler';

type RangeFacetProps = FacetProps & {
  facet: PostSearch200ProductsFacetsSortedItemOneOfFour;
};

export function RangeFacet({
  facet,
  queryFilters,
  handleChangeSeachFilter,
  isOpen,
  pathname,
  searchParams,
}: RangeFacetProps) {
  return (
    <Disclosure as="div" key={facet.name} className="border-b" defaultOpen={isOpen}>
      {({ open }) => (
        <div className="flow-root">
          <DisclosureButton
            id={facet.name}
            as="button"
            className="my-5 flex h-12 w-full items-center justify-between text-gray-800 hover:text-black"
          >
            <div className="flex w-full items-center justify-between">
              <span className="text-md font-semibold text-gray-1000">{facet.name}</span>
            </div>
            <span className="ml-6 flex items-center">
              <Icon
                src="w3-arrow-down"
                size="20"
                className={cn('transform transition-transform', { 'rotate-180': open })}
              />
            </span>
          </DisclosureButton>

          <DisclosurePanel className="pb-6">
            <RangeSlider
              min={facet.min || 0}
              max={facet.max || 0}
              thumbMin={Number(facet.selectedValue) || facet.min}
              thumbMax={Number(facet.selectedValue) || facet.max}
              onChange={(range) =>
                handleChangeSeachFilter(
                  {
                    key: facet.name,
                    value: range.join('-'),
                    handle: filterActions.OVERRIDE,
                  },
                  { pathname, searchParams, queryFilters },
                )
              }
            />
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
}
