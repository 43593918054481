import pino, { Logger } from 'pino';

export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error';

// We are not importing @jysk/logger here because pino-pretty breaks the wla frontend
export const createLogger = (name: string, level?: string): Logger => {
  const loggerOptions: pino.LoggerOptions = {
    name,
    level: level || process.env['LOG_LEVEL'] || process.env['NEXT_PUBLIC_LOG_LEVEL'] || 'info',
    timestamp: pino.stdTimeFunctions.isoTime,
  };

  return pino(loggerOptions);
};

export const logger = process.env['NODE_ENV'] !== 'production' ? console : createLogger('api-client');
