'use client';

import { Text } from '@wla/components/ui/text';
import { usePublicConfig } from '@wla/lib/configs';
import { useEffect, useRef } from 'react';

export function CookiesOverview() {
  const config = usePublicConfig();
  const scriptContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (config.cookieConsent && scriptContainerRef.current) {
      const script = document.createElement('script');
      script.src = 'https://policy.cookieinformation.com/76494553-021f-4d17-a28c-fc9a4b83de58/cd.js';
      script.async = true;
      script.setAttribute('data-culture', config.languageISOCode);
      script.type = 'text/javascript';
      script.id = 'CookiePolicy';
      scriptContainerRef.current.appendChild(script);
    }
  }, [config.cookieConsent, config.languageISOCode]);

  if (!config.cookieConsent) {
    return null;
  }

  return (
    <Text>
      <div ref={scriptContainerRef} />
    </Text>
  );
}
