/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Webshop Backend API
 * Endpoints provided by the Webshop Backend API
 * OpenAPI spec version: 0.121.1
 */
import { drupalApiClient } from '../../custom-api-clients';

export type GetSearchDeletedData200PageAllOf = {
  /** If the hasNext response element returns false, there are no more response elements to return. Task is complete. Otherwise true is returned. */
  hasNext: boolean;
};

export type GetSearchDeletedData200Page = Page & GetSearchDeletedData200PageAllOf;

export type GetSearchDeletedData200DataOneOfItem = {
  id: string;
};

export type GetSearchDeletedData200Data = GetSearchDeletedData200DataOneOfItem[] | null;

export type GetSearchDeletedData200 = {
  data: GetSearchDeletedData200Data;
  page: GetSearchDeletedData200Page;
};

export type GetSearchDeletedDataParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
  /**
   * Date in timestamp format. If no timestamp is provided all items will be returned regardless of when they have been updated.
   */
  updatedSince?: number;
};

export type GetSearchUpdatedData200PageAllOf = {
  /** If the hasNext response element returns false, there are no more response elements to return. Task is complete. Otherwise true is returned. */
  hasNext: boolean;
};

export type GetSearchUpdatedData200Page = Page & GetSearchUpdatedData200PageAllOf;

export type GetSearchUpdatedData200 = {
  data: GetSearchUpdatedData200Data;
  page: GetSearchUpdatedData200Page;
};

export type GetSearchUpdatedData200DataOneOfItem =
  | BlogPostFull
  | PageFull
  | StructuralPageFull
  | ProductFull
  | ParentCategoryPage
  | ProductListPage
  | StoreFull
  | FaqItemFull;

export type GetSearchUpdatedData200Data = GetSearchUpdatedData200DataOneOfItem[] | null;

export type GetSearchUpdatedDataParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
  /**
   * Date in timestamp format. If no timestamp is provided all items will be returned regardless of when they have been updated.
   */
  updatedSince?: number;
};

export type GetProductsExportFile200Data = {
  downloadUrl: string;
};

export type GetProductsExportFile200 = {
  data: GetProductsExportFile200Data;
};

export type GetProductsExportFileParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
};

export type GetTermsAndConditionsFile200Data = {
  downloadUrl: string;
};

export type GetTermsAndConditionsFile200 = {
  data: GetTermsAndConditionsFile200Data;
};

export type GetTermsAndConditionsFileParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * Date in RFC3339 format
   */
  date?: string;
};

export type GetPage3xx = {
  redirectTo: Url;
  statusCode: number;
};

export type GetPage200 = {
  breadcrumbs: GetPage200Breadcrumbs;
  entity: GetPage200Entity;
  /** the page title, to use e.g. for the breadcrumb */
  label: string;
  menu: GetPage200Menu;
  /** Object contains meta tags for the entity */
  metaTags: Metatag[];
};

export type GetPage200MenuAnyOfRootId = string | null;

export type GetPage200MenuAnyOf = {
  name: MenuName;
  rootId: GetPage200MenuAnyOfRootId;
};

export type GetPage200Menu = null | GetPage200MenuAnyOf;

/**
 * Normalized entity data
 */
export type GetPage200EntityData =
  | ParentCategoryPage
  | ProductListPage
  | ProductFull
  | ProductBrandFull
  | BlogPostFull
  | BlogPostCategoryFull
  | FaqCategoryFull
  | PageFull
  | StructuralPageFull
  | UserFull
  | StoreFull;

export type GetPage200Entity = {
  /** Normalized entity data */
  data: GetPage200EntityData;
  id: Uuid;
};

export type GetPage200Breadcrumbs = Breadcrumb[] | null;

export type GetPageParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
  /**
   * Path
   */
  path?: string;
};

export type GetGeocode200Data = Geocoding | null;

export type GetGeocode200 = {
  data: GetGeocode200Data;
};

export type GetGeocodeParams = {
  /**
   * address query (can also be partial, e.g. just a zipcode)
   */
  query: string;
};

export type GetHeaderFooter200 = {
  data: HeaderFooter;
};

export type GetHeaderFooterParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
};

export type GetNavigationMenu200 = {
  data: Menu;
};

export type GetNavigationMenuParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
  /**
   * The root Id to get the menu for. If not provided, the default menu is returned.
   */
  rootId?: string;
};

export type GetNavigationMenus200Data = Menu[] | null;

export type GetNavigationMenus200 = {
  data: GetNavigationMenus200Data;
  page: Page;
};

export type GetNavigationMenusParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
};

export type GetGeneralNotice200Data = GeneralNotice | null;

export type GetGeneralNotice200 = {
  data: GetGeneralNotice200Data;
};

export type GetGeneralNoticeParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
};

export type GetCampaignBar200Data = CampaignBar | null;

export type GetCampaignBar200 = {
  data: GetCampaignBar200Data;
};

export type GetCampaignBarParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
};

export type GetEmbeddedContent200Data = EmbeddedContentFull[] | null;

export type GetEmbeddedContent200 = {
  data: GetEmbeddedContent200Data;
  page: Page;
};

export type GetEmbeddedContentParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
  /**
   * embedded content types to filter for
   */
  types?: EmbeddedContentType[];
};

export type GetProduct200 = {
  data: ProductFull;
};

export type GetProductParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
};

export type GetProducts200Data = ProductTeaser[] | null;

export type GetProducts200 = {
  data: GetProducts200Data;
  page: Page;
};

export type GetProductsParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
  /**
   * (external) product Ids to filter for.
   */
  productIds?: string[];
  /**
   * Product family to filter to.
   */
  familyId?: string;
  /**
   * Product series to filter to.
   */
  seriesId?: string;
  /**
   * The uuid of the product category to filter on.
   */
  categoryIds?: string[];
};

export type GetBlogPost200 = {
  data: BlogPostFull;
};

export type GetBlogPostParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
};

export type GetBlogPosts200Data = BlogPostTeaser[] | null;

export type GetBlogPosts200 = {
  data: GetBlogPosts200Data;
  page: Page;
};

/**
 * The mapped value from taxonomy.
 */
export type GetBlogPostsTypesItem = (typeof GetBlogPostsTypesItem)[keyof typeof GetBlogPostsTypesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBlogPostsTypesItem = {
  Guidance: 'Guidance',
  Inspiration: 'Inspiration',
  ProductChoices: 'ProductChoices',
} as const;

export type GetBlogPostsParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * The customer type to get content for. All customers are considered B2C if not logged in as B2B.
   */
  customerType?: CustomerTypeParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
  /**
   * Array of entity uuids
   */
  ids?: IdsParameter;
  /**
   * Parent Category uuid to filter for.
   * @deprecated
   */
  blogCategoryId?: string;
  /**
   * Category uuid to filter for.
   */
  blogCategoryIds?: string[];
  /**
   * Product Category uuid to filter for. Will include posts in parent categories of the given one.
   */
  productCategoryId?: string;
  /**
   * author uuid to filter for (to get blog posts by...)
   */
  authorId?: string;
  types?: GetBlogPostsTypesItem[];
};

export type GetStore200 = {
  data: StoreFull;
};

export type GetStoreParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
};

export type GetStores200Data = StoreFull[] | null;

export type GetStores200 = {
  data: GetStores200Data;
  page: Page;
};

export type GetStoresParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page
   */
  pageSize?: number;
  /**
   * latitude
   */
  latitude?: number;
  /**
   * longitude
   */
  longitude?: number;
};

export type GetConfiguration200 = {
  data: Configuration;
};

export type GetBlogTypes200Data = BlogPostTypeFull[] | null;

export type GetBlogTypes200 = {
  data?: GetBlogTypes200Data;
};

export type GetBlogTypesParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * Category uuid used for counting blog posts.
   */
  blogCategoryId?: string;
};

export type GetBlogCategory200 = {
  data: BlogPostCategoryFull;
};

export type GetBlogCategoryParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
};

export type GetBlogCategories200Data = BlogPostCategoryTeaser[] | null;

export type GetBlogCategories200 = {
  data?: GetBlogCategories200Data;
  page?: Page;
};

/**
 * Array of entity uuids
 */
export type IdsParameter = string[];

export type CustomerTypeParameter = (typeof CustomerTypeParameter)[keyof typeof CustomerTypeParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerTypeParameter = {
  B2B: 'B2B',
  B2C: 'B2C',
} as const;

/**
 * Items per page (500 is the maximum)
 */
export type PageSizeParameter = number;

/**
 * the page number
 */
export type PageParameter = number;

export type LanguageParameter = (typeof LanguageParameter)[keyof typeof LanguageParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LanguageParameter = {
  'da-dk': 'da-dk',
  'pt-pt': 'pt-pt',
  'da-DK': 'da-DK',
  'pt-PT': 'pt-PT',
} as const;

export type GetBlogCategoriesParams = {
  /**
   * expected language
   */
  language?: LanguageParameter;
  /**
   * the page number
   */
  page?: PageParameter;
  /**
   * Items per page (500 is the maximum)
   */
  pageSize?: PageSizeParameter;
};

/**
 * Not found
 */
export type NotFoundResponseResponse = ErrorResponse;

export type ForbiddenResponseResponse = {
  details: string;
  message: string;
};

/**
 * Server Error
 */
export type ServerErrorResponseResponse = ErrorResponse;

/**
 * Bad Request
 */
export type BadRequestResponseResponse = ErrorResponse;

/**
 * Unexpected Error
 */
export type UnexpectedErrorResponseResponse = ErrorResponse;

export type UserFullAllOfRolesItem = (typeof UserFullAllOfRolesItem)[keyof typeof UserFullAllOfRolesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserFullAllOfRolesItem = {
  anonymous: 'anonymous',
  authenticated: 'authenticated',
  administrator: 'administrator',
  e_business: 'e_business',
  smdc: 'smdc',
  customer_service: 'customer_service',
} as const;

export type UserFullAllOfDataType = (typeof UserFullAllOfDataType)[keyof typeof UserFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserFullAllOfDataType = {
  User: 'User',
} as const;

export type UserFullAllOf = {
  /** Information about the user */
  about: string;
  dataType: UserFullAllOfDataType;
  email: string;
  /** the user's CMS related roles */
  roles: UserFullAllOfRolesItem[];
};

export type UserTeaser = {
  fullName: string;
  id?: Uuid;
  /** The users public name */
  publicName: string;
  /** The users public title/job position */
  publicTitle: string;
};

export type UserFull = UserTeaser & UserFullAllOf;

/**
 * The type of the structural page
 */
export type StructuralPageFullAllOfStructuralPageType =
  (typeof StructuralPageFullAllOfStructuralPageType)[keyof typeof StructuralPageFullAllOfStructuralPageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StructuralPageFullAllOfStructuralPageType = {
  Inspiration: 'Inspiration',
  InspirationAuthor: 'InspirationAuthor',
  Brands: 'Brands',
  BrandsBrand: 'BrandsBrand',
  CampaignPapers: 'CampaignPapers',
  CustomerService: 'CustomerService',
  CustomerServiceSubcategory: 'CustomerServiceSubcategory',
  CampaignProducts: 'CampaignProducts',
  OutletProducts: 'OutletProducts',
  EdlpProducts: 'EdlpProducts',
  Giftcard: 'Giftcard',
  Home: 'Home',
  PageNotFound: 'PageNotFound',
  Search: 'Search',
  StoresLocator: 'StoresLocator',
  NewsletterSuccess: 'NewsletterSuccess',
  ReturnsForm: 'ReturnsForm',
  Customer: 'Customer',
  B2B: 'B2B',
  Press: 'Press',
  TermsConditions: 'TermsConditions',
  CookiesOverview: 'CookiesOverview',
  BlackFriday: 'BlackFriday',
} as const;

export type StructuralPageFullAllOfDataType =
  (typeof StructuralPageFullAllOfDataType)[keyof typeof StructuralPageFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StructuralPageFullAllOfDataType = {
  StructuralPage: 'StructuralPage',
} as const;

export type StructuralPageFullAllOf = {
  content: Layout;
  dataType: StructuralPageFullAllOfDataType;
  id: Uuid;
  /** The type of the structural page */
  structuralPageType: StructuralPageFullAllOfStructuralPageType;
};

export type StructuralPageFull = DefaultEntity & StructuralPageFullAllOf;

export type PageFullAllOfImageTeaser = ImageMedia | null;

export type PageFullAllOfDataType = (typeof PageFullAllOfDataType)[keyof typeof PageFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageFullAllOfDataType = {
  Page: 'Page',
} as const;

export type PageFullAllOf = {
  content: Layout;
  dataType: PageFullAllOfDataType;
  id: Uuid;
  imageTeaser: PageFullAllOfImageTeaser;
};

export type DefaultEntity = {
  title: string;
  url: Url;
};

export type PageFull = DefaultEntity & PageFullAllOf;

export type FaqCategoryFull = FaqCategoryTeaser & FaqCategoryFullAllOf;

export type FaqCategoryFullAllOfSectionsOneOfItemTitle = string | null;

export type FaqCategoryFullAllOfSectionsOneOfItemItems = FaqItemFull[] | null;

export type FaqCategoryFullAllOfSectionsOneOfItem = {
  items: FaqCategoryFullAllOfSectionsOneOfItemItems;
  title: FaqCategoryFullAllOfSectionsOneOfItemTitle;
};

export type FaqCategoryFullAllOfSections = FaqCategoryFullAllOfSectionsOneOfItem[] | null;

/**
 * Description of the category.
 */
export type FaqCategoryFullAllOfDescription = string | null;

export type FaqCategoryFullAllOfDataType =
  (typeof FaqCategoryFullAllOfDataType)[keyof typeof FaqCategoryFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaqCategoryFullAllOfDataType = {
  FaqCategories: 'FaqCategories',
} as const;

/**
 * Optional properties of Faq Categories which are overwritten by the closest active item.
 */
export type FaqCategoryFullAllOf = {
  dataType: FaqCategoryFullAllOfDataType;
  /** Description of the category. */
  description: FaqCategoryFullAllOfDescription;
  sections: FaqCategoryFullAllOfSections;
};

export type FaqItemFullAllOfEmail = string | null;

export type FaqItemFullAllOfDescription = string | null;

/**
 * FAQ item
 */
export type FaqItemFullAllOf = {
  /** Show chat button in FAQs. */
  chat: boolean;
  description: FaqItemFullAllOfDescription;
  email: FaqItemFullAllOfEmail;
  /** Show file upload field. */
  fileUpload: boolean;
  /** Show order number field. */
  orderNumber: boolean;
};

export type FaqItemFull = FaqItemTeaser & FaqItemFullAllOf;

export type ProductBrandFull = ProductBrandTeaser & ProductBrandFullAllOf;

export type ProductBrandFullAllOfTopImage = ImageMedia | null;

export type ProductBrandFullAllOfDescription = string | null;

export type ProductBrandFullAllOfDataType =
  (typeof ProductBrandFullAllOfDataType)[keyof typeof ProductBrandFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductBrandFullAllOfDataType = {
  ProductBrands: 'ProductBrands',
} as const;

export type ProductBrandFullAllOf = {
  dataType: ProductBrandFullAllOfDataType;
  description: ProductBrandFullAllOfDescription;
  /** The title that will be displayed as headline in the main content section. */
  title: string;
  topImage: ProductBrandFullAllOfTopImage;
};

export type ProductListPage = ProductCategoryTeaser & ProductListPageAllOf;

export type ProductListPageAllOfRelationsOneOfBlogPostIds = Uuid[] | null;

export type ProductListPageAllOfRelationsOneOf = {
  blogPostIds: ProductListPageAllOfRelationsOneOfBlogPostIds;
};

export type ProductListPageAllOfRelations = ProductListPageAllOfRelationsOneOf | null;

export type ProductListPageAllOfFooterDescription = string | null;

export type ProductListPageAllOfDescription = string | null;

export type ProductListPageAllOfDataType =
  (typeof ProductListPageAllOfDataType)[keyof typeof ProductListPageAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductListPageAllOfDataType = {
  ProductListPage: 'ProductListPage',
} as const;

export type ProductListPageAllOfCategoryBanner = ProductCategoryBanner | null;

export type ProductListPageAllOfCategories = ProductCategoryTeaser[] | null;

export type ProductListPageAllOf = {
  categories: ProductListPageAllOfCategories;
  categoryBanner: ProductListPageAllOfCategoryBanner;
  dataType: ProductListPageAllOfDataType;
  description: ProductListPageAllOfDescription;
  footerDescription: ProductListPageAllOfFooterDescription;
  relations: ProductListPageAllOfRelations;
  /** The title that will be displayed as headline in the main content section. */
  title: string;
};

export type ParentCategoryPage = ProductCategoryTeaser & ParentCategoryPageAllOf;

export type ParentCategoryPageAllOfRelationsOneOfBlogPostIds = Uuid[] | null;

export type ParentCategoryPageAllOfRelationsOneOf = {
  blogPostIds: ParentCategoryPageAllOfRelationsOneOfBlogPostIds;
};

export type ParentCategoryPageAllOfRelations = ParentCategoryPageAllOfRelationsOneOf | null;

export type ParentCategoryPageAllOfFooterDescription = string | null;

export type ParentCategoryPageAllOfDescription = string | null;

export type ParentCategoryPageAllOfDataType =
  (typeof ParentCategoryPageAllOfDataType)[keyof typeof ParentCategoryPageAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParentCategoryPageAllOfDataType = {
  ParentCategoryPage: 'ParentCategoryPage',
} as const;

export type ParentCategoryPageAllOfCategories = ProductCategoryTeaser[] | null;

export type ParentCategoryPageAllOf = {
  categories: ParentCategoryPageAllOfCategories;
  categoryBanner: ParentCategoryPageAllOfCategoryBanner;
  dataType: ParentCategoryPageAllOfDataType;
  description: ParentCategoryPageAllOfDescription;
  footerDescription: ParentCategoryPageAllOfFooterDescription;
  relations: ParentCategoryPageAllOfRelations;
  /** The title that will be displayed as headline in the main content section. */
  title: string;
};

export type ProductCategoryBannerItem = {
  image: ImageMedia;
  link: ProductCategoryBannerItemLink;
};

/**
 * the category banner, 3 images with individual links
 * @maxItems 3
 */
export type ProductCategoryBanner = ProductCategoryBannerItem[];

export type ParentCategoryPageAllOfCategoryBanner = ProductCategoryBanner | null;

/**
 * the target window, empty being equivalent to the current window.
 */
export type ProductCategoryBannerItemLinkOneOfTarget = string | null;

export type ProductCategoryBannerItemLinkOneOf = {
  /** the target window, empty being equivalent to the current window. */
  target: ProductCategoryBannerItemLinkOneOfTarget;
  url: Url;
};

export type ProductCategoryBannerItemLink = ProductCategoryBannerItemLinkOneOf | null;

export type ProductCategoryTeaserImage = ImageMedia | null;

export type ProductCategoryTeaserExternalId = ExternalId | null;

export type ProductCategoryTeaser = {
  externalId: ProductCategoryTeaserExternalId;
  id: Uuid;
  image: ProductCategoryTeaserImage;
  /** Category name */
  name: string;
  url: Url;
};

export type Breadcrumb = {
  text: string;
  url: Url;
};

export type MetatagType = (typeof MetatagType)[keyof typeof MetatagType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetatagType = {
  title: 'title',
  meta: 'meta',
  link: 'link',
} as const;

export type MetatagLanguage = string | null;

export type MetatagGroup = 'openGraph' | 'basic' | 'advanced' | 'hreflang' | null;

export type Metatag = {
  content: string;
  group: MetatagGroup;
  language: MetatagLanguage;
  property: string;
  type: MetatagType;
};

export type GeocodingAddressComponentsPostalCode = string | null;

export type GeocodingAddressComponents = {
  postalCode: GeocodingAddressComponentsPostalCode;
};

/**
 * coordinate pair of lat/lng as returned/used by geocoding/map applications
 */
export type GeocodingCoordinates = {
  latitude: number;
  longitude: number;
};

export type GeocodingViewport = {
  /** northeast (top right) corner of the viewport to zoom to */
  northeast: GeocodingCoordinates;
  /** soutwest (bottom left) corner of the viewport to zoom to */
  southwest: GeocodingCoordinates;
};

export type Geocoding = {
  addressComponents: GeocodingAddressComponents;
  location: GeocodingCoordinates;
  viewport: GeocodingViewport;
};

export type HeaderFooterHeader = {
  mainMenu: Menu;
  topMenu: Menu;
};

export type HeaderFooter = {
  footer: HeaderFooterFooter;
  header: HeaderFooterHeader;
};

export type HeaderFooterFooterCompanyInformationContent = EmbeddedContentFull | null;

export type HeaderFooterFooter = {
  categoriesMenu: Menu;
  companyInformationContent: HeaderFooterFooterCompanyInformationContent;
  customerServiceMenu: Menu;
  pagesMenu: Menu;
};

/**
 * the target window, empty being equivalent to the current window.
 */
export type MenuItemTarget = string | null;

export type MenuItemStyle = 'bold' | 'italic' | 'red' | 'green' | 'blue' | null;

export type MenuItemIcon = Icon | null;

export type MenuItemDescription = string | null;

export type MenuItemChildren = MenuItem[] | null;

export type MenuItem = {
  children: MenuItemChildren;
  description: MenuItemDescription;
  icon?: MenuItemIcon;
  id: Uuid;
  style: MenuItemStyle;
  /** the target window, empty being equivalent to the current window. */
  target: MenuItemTarget;
  /** The text displayed on the menu item. */
  title: string;
  url: Url;
};

export type MenuItems = MenuItem[] | null;

/**
 * the unique name of a menu
 */
export type MenuName = (typeof MenuName)[keyof typeof MenuName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MenuName = {
  BusinessToBusiness: 'BusinessToBusiness',
  CustomerService: 'CustomerService',
  InspirationUniverseMenu: 'InspirationUniverseMenu',
  MegaMenu: 'MegaMenu',
  Pages: 'Pages',
  TopSiteMenu: 'TopSiteMenu',
  Categories: 'Categories',
} as const;

export type Menu = {
  id: Uuid;
  items: MenuItems;
  name: MenuName;
};

export type GeneralNoticeAllOfType = (typeof GeneralNoticeAllOfType)[keyof typeof GeneralNoticeAllOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeneralNoticeAllOfType = {
  GeneralNotice: 'GeneralNotice',
} as const;

export type GeneralNoticeAllOf = {
  type: GeneralNoticeAllOfType;
};

export type GeneralNotice = InfoBarEntity & GeneralNoticeAllOf;

export type CampaignBarAllOfType = (typeof CampaignBarAllOfType)[keyof typeof CampaignBarAllOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CampaignBarAllOfType = {
  Campaign: 'Campaign',
} as const;

export type CampaignBarAllOf = {
  showOnFrontPage: boolean;
  type: CampaignBarAllOfType;
};

/**
 * the background color of the information bar
 */
export type InfoBarEntityBackgroundColor = string | null;

export type InfoBarEntity = {
  /** the background color of the information bar */
  backgroundColor: InfoBarEntityBackgroundColor;
  /** array of messages (+ optional button with link per message) including colors */
  messages: MessagesParagraph[];
};

export type CampaignBar = InfoBarEntity & CampaignBarAllOf;

export type MessagesParagraphParagraphType =
  (typeof MessagesParagraphParagraphType)[keyof typeof MessagesParagraphParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessagesParagraphParagraphType = {
  InfoBarMessage: 'InfoBarMessage',
} as const;

export type MessagesParagraphColors = MessagesParagraphColorsOneOf | null;

export type MessagesParagraph = {
  button: MessagesParagraphButton;
  colors: MessagesParagraphColors;
  paragraphType: MessagesParagraphParagraphType;
  text: string;
};

/**
 * the text color of the message
 */
export type MessagesParagraphColorsOneOfText = string | null;

/**
 * the text color of the button
 */
export type MessagesParagraphColorsOneOfButtonText = string | null;

/**
 * the background color of the button
 */
export type MessagesParagraphColorsOneOfButtonBackground = string | null;

export type MessagesParagraphColorsOneOf = {
  /** the background color of the button */
  buttonBackground: MessagesParagraphColorsOneOfButtonBackground;
  /** the text color of the button */
  buttonText: MessagesParagraphColorsOneOfButtonText;
  /** the text color of the message */
  text: MessagesParagraphColorsOneOfText;
};

export type MessagesParagraphButton = MessagesParagraphButtonOneOf | null;

export type MessagesParagraphButtonOneOfUrl = string | null;

export type MessagesParagraphButtonOneOfTitle = string | null;

/**
 * the target window, empty being equivalent to the current window.
 */
export type MessagesParagraphButtonOneOfTarget = string | null;

export type MessagesParagraphButtonOneOf = {
  /** the target window, empty being equivalent to the current window. */
  target: MessagesParagraphButtonOneOfTarget;
  title: MessagesParagraphButtonOneOfTitle;
  url: MessagesParagraphButtonOneOfUrl;
};

/**
 * The type of an embedded content item
 */
export type EmbeddedContentType = (typeof EmbeddedContentType)[keyof typeof EmbeddedContentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmbeddedContentType = {
  FooterContactInformation: 'FooterContactInformation',
  CscContactInformation: 'CscContactInformation',
  CheckoutCarrierDeliveryFlex: 'CheckoutCarrierDeliveryFlex',
  CheckoutCarrierDeliveryCarryIn: 'CheckoutCarrierDeliveryCarryIn',
  CheckoutUsp: 'CheckoutUsp',
  CheckoutClickCollectReceipt: 'CheckoutClickCollectReceipt',
  CustomerLoginCreateAccountTextB2C: 'CustomerLoginCreateAccountTextB2C',
  CustomerLoginCreateAccountTextB2B: 'CustomerLoginCreateAccountTextB2B',
  CustomerLoginNewsletterSignupTextB2C: 'CustomerLoginNewsletterSignupTextB2C',
  FooterUsp: 'FooterUsp',
  PdpUsp: 'PdpUsp',
  PdpProductReviewTermsAndCondition: 'PdpProductReviewTermsAndCondition',
  PdpDeliveryInformation: 'PdpDeliveryInformation',
  PdpSmosDanstrupWardrobeDescription: 'PdpSmosDanstrupWardrobeDescription',
  MostPopularFaqQuestion: 'MostPopularFaqQuestion',
} as const;

/**
 * embedded content with unique name
 */
export type EmbeddedContentFull = {
  content: Layout;
  type: EmbeddedContentType;
};

export type ProductFull = ProductBase & ProductFullAllOf;

export type ProductFullAllOfVariants = ProductVariants | null;

/**
 * product internal status code, will hopefully be removed as the logic does not belong here.
 */
export type ProductFullAllOfStatusCode = string | null;

export type ProductFullAllOfSpecifications = ProductSpecifications | null;

export type ProductFullAllOfResources = ProductResources | null;

export type ProductFullAllOfRelations = ProductFullAllOfRelationsOneOf | null;

export type ProductFullAllOf = {
  campaign: ProductFullAllOfCampaign;
  categoryId: Uuid;
  dataType: ProductFullAllOfDataType;
  /** whether this is a product with a capped low price, also called edlp, everyday low price. */
  isEveryDayLowPrice: boolean;
  /** whether the product is new */
  isNew: boolean;
  /** whether the product is an offer */
  isOffer: boolean;
  /** whether the product offer is only available while stock lasts */
  isWhileStockLasts: boolean;
  media: ProductFullAllOfMedia;
  /** the unformatted gross price of the product. */
  onlineMinimumPrice: ProductFullAllOfOnlineMinimumPrice;
  onlineMinimumQuantity: ProductFullAllOfOnlineMinimumQuantity;
  productDescription: ProductFullAllOfProductDescription;
  relations: ProductFullAllOfRelations;
  resources: ProductFullAllOfResources;
  /** whether to show the campaign label or not. */
  showCampaignLabel: boolean;
  /** whether to show the on display label or not. */
  showOnDisplayLabel: boolean;
  specifications: ProductFullAllOfSpecifications;
  /** product internal status code, will hopefully be removed as the logic does not belong here. */
  statusCode: ProductFullAllOfStatusCode;
  url: Url;
  variants: ProductFullAllOfVariants;
};

/**
 * if this product is a set, this will list that set's parts.
 */
export type ProductFullAllOfRelationsOneOfSetOneOf = {
  /** ids of products in the set. */
  productIds: ExternalId[];
};

export type ProductFullAllOfRelationsOneOfSet = ProductFullAllOfRelationsOneOfSetOneOf | null;

export type ProductFullAllOfRelationsOneOfSeriesOneOfProductIds = ExternalId[] | null;

export type ProductFullAllOfRelationsOneOfSeriesOneOf = {
  productIds: ProductFullAllOfRelationsOneOfSeriesOneOfProductIds;
  seriesId: string;
};

export type ProductFullAllOfRelationsOneOfSeries = ProductFullAllOfRelationsOneOfSeriesOneOf | null;

/**
 * products belonging to the same family (e.g. same product in different colors or sizes)
 */
export type ProductFullAllOfRelationsOneOfFamilyOneOf = {
  defaultProductId: string;
  productIds: ExternalId[];
};

export type ProductFullAllOfRelationsOneOfFamily = ProductFullAllOfRelationsOneOfFamilyOneOf | null;

export type ProductFullAllOfRelationsOneOfComplementary = ProductRelations | null;

export type ProductFullAllOfRelationsOneOfBrand = ProductFullAllOfRelationsOneOfBrandOneOf | null;

export type ProductFullAllOfRelationsOneOfBrandOneOfDescription = string | null;

export type ProductFullAllOfRelationsOneOfBrandOneOfBrandLogoImage = ImageMedia | null;

export type ProductFullAllOfRelationsOneOfBrandOneOf = {
  brandLogoImage: ProductFullAllOfRelationsOneOfBrandOneOfBrandLogoImage;
  description: ProductFullAllOfRelationsOneOfBrandOneOfDescription;
  name: string;
};

export type ProductFullAllOfRelationsOneOfBlogPostIds = Uuid[] | null;

export type ProductFullAllOfRelationsOneOf = {
  blogPostIds: ProductFullAllOfRelationsOneOfBlogPostIds;
  brand: ProductFullAllOfRelationsOneOfBrand;
  complementary: ProductFullAllOfRelationsOneOfComplementary;
  family: ProductFullAllOfRelationsOneOfFamily;
  series: ProductFullAllOfRelationsOneOfSeries;
  set: ProductFullAllOfRelationsOneOfSet;
};

export type ProductFullAllOfProductDescription = string | null;

export type ProductFullAllOfOnlineMinimumQuantity = number | null;

/**
 * the unformatted gross price of the product.
 */
export type ProductFullAllOfOnlineMinimumPrice = number | null;

export type ProductFullAllOfMedia = ProductMedia | null;

export type ProductFullAllOfDataType = (typeof ProductFullAllOfDataType)[keyof typeof ProductFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductFullAllOfDataType = {
  Product: 'Product',
} as const;

export type ProductFullAllOfCampaignOneOfStart = Date | null;

export type ProductFullAllOfCampaignOneOfEnd = Date | null;

/**
 * campaign period
 */
export type ProductFullAllOfCampaignOneOf = {
  end: ProductFullAllOfCampaignOneOfEnd;
  start: ProductFullAllOfCampaignOneOfStart;
};

export type ProductFullAllOfCampaign = ProductFullAllOfCampaignOneOf | null;

export type ProductVariantProducts = ProductVariantProduct[];

export type ProductVariants = {
  id: number;
  products: ProductVariantProducts;
  types: ProductVariantTypes;
};

export type ProductVariantProductPriceDifference = string | null;

export type ProductVariantProductOptionsItemAllOf = {
  typeId: string;
};

export type ProductVariantProductImageUrl = ExternalImageUrl | null;

export type ProductVariantOption = {
  id: string;
  name: string;
};

export type ProductVariantProductOptionsItem = ProductVariantOption & ProductVariantProductOptionsItemAllOf;

export type ProductVariantProduct = {
  id: string;
  imageUrl: ProductVariantProductImageUrl;
  options: ProductVariantProductOptionsItem[];
  price: string;
  priceDifference: ProductVariantProductPriceDifference;
};

export type ProductVariantType = {
  id: string;
  name: string;
  options: ProductVariantOption[];
};

export type ProductVariantTypes = ProductVariantType[];

/**
 * Date normalized as RFC3339
 */
export type Date = string;

/**
 * resource type
 */
export type ProductResourcesItem = {
  title: string;
  /** resources, files to download within resource type. */
  urls: string[];
};

/**
 * all resources (manuals etc) available for the current product, structured by types.
 */
export type ProductResources = ProductResourcesItem[];

/**
 * Product Specifications.
 */
export type ProductSpecifications = {
  energy: ProductSpecificationsEnergy;
  general: ProductSpecificationsGeneral;
  substitutions: ProductSpecificationsSubstitutions;
};

export type ProductSpecificationsSubstitutionsOneOfItemSearch = string | null;

export type ProductSpecificationsSubstitutionsOneOfItemReplace = string | null;

export type ProductSpecificationsSubstitutionsOneOfItemImageUrl = ExternalImageUrl | null;

export type ProductSpecificationsSubstitutionsOneOfItem = {
  alwaysShow: boolean;
  imageUrl: ProductSpecificationsSubstitutionsOneOfItemImageUrl;
  replace: ProductSpecificationsSubstitutionsOneOfItemReplace;
  search: ProductSpecificationsSubstitutionsOneOfItemSearch;
};

export type ProductSpecificationsSubstitutions = ProductSpecificationsSubstitutionsOneOfItem[] | null;

export type ProductSpecificationsGeneralOneOfItemText = string | null;

export type ProductSpecificationsGeneralOneOfItemName = string | null;

export type ProductSpecificationsGeneralOneOfItem = {
  name: ProductSpecificationsGeneralOneOfItemName;
  text: ProductSpecificationsGeneralOneOfItemText;
};

export type ProductSpecificationsGeneral = ProductSpecificationsGeneralOneOfItem[] | null;

export type ProductSpecificationsEnergyOneOfItemText = string | null;

export type ProductSpecificationsEnergyOneOfItemName = string | null;

export type ProductSpecificationsEnergyOneOfItem = {
  name: ProductSpecificationsEnergyOneOfItemName;
  text: ProductSpecificationsEnergyOneOfItemText;
};

export type ProductSpecificationsEnergy = ProductSpecificationsEnergyOneOfItem[] | null;

/**
 * the video/service type, right now we only use vimeo.
 */
export type ProductMediaVideosOneOfItemType =
  (typeof ProductMediaVideosOneOfItemType)[keyof typeof ProductMediaVideosOneOfItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductMediaVideosOneOfItemType = {
  vimeo: 'vimeo',
} as const;

export type ProductMediaVideosOneOfItemDimensions = MediaDimensions | null;

export type ProductMediaVideosOneOfItem = {
  dimensions: ProductMediaVideosOneOfItemDimensions;
  /** the duration of the video, in seconds. */
  durationSeconds: number;
  /** the video ID provided by the video service, hosting the video. eg. Vimeo. */
  externalId: number;
  thumbnailUrl: ExternalImageUrl;
  title: string;
  /** the video/service type, right now we only use vimeo. */
  type: ProductMediaVideosOneOfItemType;
};

export type ProductMediaVideos = ProductMediaVideosOneOfItem[] | null;

/**
 * urls to extra images if the product has these properties
 */
export type ProductMediaReferencesOneOf = {
  energyLabel: ExternalImageUrl;
  energyMarking: ExternalImageUrl;
};

export type ProductMediaReferences = ProductMediaReferencesOneOf | null;

/**
 * resources on a product
 */
export type ProductMedia = {
  images: ProductImageFull[];
  references: ProductMediaReferences;
  videos: ProductMediaVideos;
};

/**
 * Product relations.
 */
export type ProductRelations = ProductRelationsItem[];

export type ProductRelationsItemType = string | null;

export type ProductRelationsItemProductIds = ExternalId[] | null;

export type ProductRelationsItemName = string | null;

export type ProductRelationsItemImageUrl = ExternalImageUrl | null;

/**
 * Relation group.
 */
export type ProductRelationsItem = {
  imageUrl: ProductRelationsItemImageUrl;
  name: ProductRelationsItemName;
  productIds: ProductRelationsItemProductIds;
  type: ProductRelationsItemType;
};

export type BlogPostFull = BlogPostTeaser & BlogPostFullAllOf;

export type BlogPostFullAllOfVideo = ExternalVideoMedia | null;

export type BlogPostFullAllOfRelatedProductsOneOfProductInclude = ExternalId[] | null;

export type BlogPostFullAllOfRelatedProductsOneOfProductExclude = ExternalId[] | null;

export type BlogPostFullAllOfRelatedProductsOneOfCategoryInclude = ExternalId[] | null;

export type BlogPostFullAllOfRelatedProductsOneOfCategoryExclude = ExternalId[] | null;

export type BlogPostFullAllOfRelatedProductsOneOf = {
  categoryExclude: BlogPostFullAllOfRelatedProductsOneOfCategoryExclude;
  categoryInclude: BlogPostFullAllOfRelatedProductsOneOfCategoryInclude;
  productExclude: BlogPostFullAllOfRelatedProductsOneOfProductExclude;
  productInclude: BlogPostFullAllOfRelatedProductsOneOfProductInclude;
};

/**
 * Related product ids
 */
export type BlogPostFullAllOfRelatedProducts = BlogPostFullAllOfRelatedProductsOneOf | null;

export type BlogPostFullAllOfImage = ImageMedia | null;

export type BlogPostFullAllOfDataType = (typeof BlogPostFullAllOfDataType)[keyof typeof BlogPostFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlogPostFullAllOfDataType = {
  BlogPost: 'BlogPost',
} as const;

export type BlogPostFullAllOfCategoriesItem = {
  id: string;
  title: string;
  url: Url;
};

export type BlogPostFullAllOf = {
  author: BlogPostFullAllOfAuthor;
  categories: BlogPostFullAllOfCategoriesItem[];
  content: Layout;
  dataType: BlogPostFullAllOfDataType;
  /** Created date */
  date: DateTime;
  image: BlogPostFullAllOfImage;
  /** Related product ids */
  relatedProducts: BlogPostFullAllOfRelatedProducts;
  video: BlogPostFullAllOfVideo;
};

export type BlogPostFullAllOfAuthorTitle = string | null;

export type BlogPostFullAllOfAuthorName = string | null;

export type BlogPostFullAllOfAuthorImage = ImageMedia | null;

export type BlogPostFullAllOfAuthor = {
  image: BlogPostFullAllOfAuthorImage;
  initials: string;
  name: BlogPostFullAllOfAuthorName;
  title: BlogPostFullAllOfAuthorTitle;
};

export type SectionRegionsOneOfItem = ParagraphArray | null;

export type SectionRegions = SectionRegionsOneOfItem[] | null;

export type SectionLayout = (typeof SectionLayout)[keyof typeof SectionLayout];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionLayout = {
  OneColumn: 'OneColumn',
  TwoColumn: 'TwoColumn',
  ThreeColumn: 'ThreeColumn',
  FourColumn: 'FourColumn',
} as const;

export type SectionConfigColumnWidths = (typeof SectionConfigColumnWidths)[keyof typeof SectionConfigColumnWidths];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SectionConfigColumnWidths = {
  W100: 'W100',
  W50_50: 'W50_50',
  W70_30: 'W70_30',
  W30_70: 'W30_70',
  W33_34_33: 'W33_34_33',
  W25_50_25: 'W25_50_25',
  W50_25_25: 'W50_25_25',
  W25_25_50: 'W25_25_50',
  W25_25_25_25: 'W25_25_25_25',
} as const;

/**
 * Section configuration
 */
export type SectionConfig = {
  columnWidths: SectionConfigColumnWidths;
  fullWidth: boolean;
  removeWhitespace: boolean;
};

/**
 * Paragraph layout
 */
export type Section = {
  /** Section configuration */
  config: SectionConfig;
  layout: SectionLayout;
  regions: SectionRegions;
};

/**
 * Array of Sections (page blocks)
 */
export type Layout = Section[];

export type HeroBannerSecondary = ParagraphBase & HeroBannerSecondaryAllOf;

export type ParagraphArrayItem =
  | AccordionParagraph
  | TextParagraph
  | ProductCarouselParagraph
  | FaqCategoryListParagraph
  | IframeParagraph
  | TableOfContentsParagraph
  | CategoriesParagraph
  | MenuParagraph
  | TooltipParagraph
  | PlaceholderParagraph
  | CountdownBannerParagraph
  | MediaParagraph
  | BackgroundColorWithOverlayParagraph
  | SeparatorParagraph
  | UspParagraph
  | ProductBrandCarouselParagraph
  | ProductBrandOverviewParagraph
  | ButtonListParagraph
  | TableParagraph
  | FooterContactInformationParagraph
  | CSCContactInformationParagraph
  | PublicationListParagraph
  | MostPopularFaqQuestionParagraph
  | HeroBannerPrimary
  | HeroBannerSecondary
  | ProductListWithFiltersParagraph;

/**
 * Array of paragraphs (page blocks)
 */
export type ParagraphArray = ParagraphArrayItem[];

export type HeroBannerSecondaryAllOfParagraphType =
  (typeof HeroBannerSecondaryAllOfParagraphType)[keyof typeof HeroBannerSecondaryAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeroBannerSecondaryAllOfParagraphType = {
  HeroBannerSecondary: 'HeroBannerSecondary',
} as const;

export type HeroBannerSecondaryAllOfItemsItem = HeroBannerSecondaryAllOfItemsItemOneOf | null;

export type HeroBannerSecondaryAllOf = {
  items: HeroBannerSecondaryAllOfItemsItem[];
  paragraphType: HeroBannerSecondaryAllOfParagraphType;
};

export type HeroBannerSecondaryAllOfItemsItemOneOfTitle = string | null;

export type HeroBannerSecondaryAllOfItemsItemOneOfLink = Link | null;

export type HeroBannerSecondaryAllOfItemsItemOneOfImage = ImageMedia | null;

export type HeroBannerSecondaryAllOfItemsItemOneOf = {
  image: HeroBannerSecondaryAllOfItemsItemOneOfImage;
  link: HeroBannerSecondaryAllOfItemsItemOneOfLink;
  title: HeroBannerSecondaryAllOfItemsItemOneOfTitle;
};

export type HeroBannerPrimary = ParagraphBase & HeroBannerPrimaryAllOf;

export type HeroBannerPrimaryAllOfParagraphType =
  (typeof HeroBannerPrimaryAllOfParagraphType)[keyof typeof HeroBannerPrimaryAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeroBannerPrimaryAllOfParagraphType = {
  HeroBannerPrimary: 'HeroBannerPrimary',
} as const;

export type HeroBannerPrimaryAllOfLink = Link | null;

export type HeroBannerPrimaryAllOfImage = HeroBannerPrimaryAllOfImageOneOf | null;

export type HeroBannerPrimaryAllOf = {
  image: HeroBannerPrimaryAllOfImage;
  link: HeroBannerPrimaryAllOfLink;
  paragraphType: HeroBannerPrimaryAllOfParagraphType;
};

export type HeroBannerPrimaryAllOfImageOneOfMobile = ImageMedia | null;

export type HeroBannerPrimaryAllOfImageOneOfDesktop = ImageMedia | null;

export type HeroBannerPrimaryAllOfImageOneOf = {
  desktop: HeroBannerPrimaryAllOfImageOneOfDesktop;
  mobile: HeroBannerPrimaryAllOfImageOneOfMobile;
};

export type ProductListWithFiltersParagraphAllOfWhileStockLasts = boolean | null;

export type ProductListWithFiltersParagraphAllOfStoreSales = boolean | null;

export type ProductListWithFiltersParagraphAllOfStatusCode = number | null;

export type ProductListWithFiltersParagraphAllOfProductSkus = string[] | null;

export type ProductListWithFiltersParagraphAllOfPriceHero = boolean | null;

export type ProductListWithFiltersParagraphAllOfPrice = ProductListWithFiltersParagraphAllOfPriceOneOf | null;

export type ProductListWithFiltersParagraphAllOfPriceOneOfMin = number | null;

export type ProductListWithFiltersParagraphAllOfPriceOneOfMax = number | null;

export type ProductListWithFiltersParagraphAllOfPriceOneOf = {
  max: ProductListWithFiltersParagraphAllOfPriceOneOfMax;
  min: ProductListWithFiltersParagraphAllOfPriceOneOfMin;
};

export type ProductListWithFiltersParagraphAllOfParagraphType =
  (typeof ProductListWithFiltersParagraphAllOfParagraphType)[keyof typeof ProductListWithFiltersParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductListWithFiltersParagraphAllOfParagraphType = {
  ProductListWithFilters: 'ProductListWithFilters',
} as const;

export type ProductListWithFiltersParagraphAllOfOnSale = boolean | null;

export type ProductListWithFiltersParagraphAllOfOnlineSales = boolean | null;

export type ProductListWithFiltersParagraphAllOfEveryDayLowPrice = boolean | null;

export type ProductListWithFiltersParagraphAllOfEventMarks = string[] | null;

export type ProductListWithFiltersParagraphAllOfClickAndCollect = boolean | null;

export type ProductListWithFiltersParagraphAllOfCategories = string[] | null;

export type ProductListWithFiltersParagraphAllOfBrands = string[] | null;

export type ProductListWithFiltersParagraphAllOf = {
  brands: ProductListWithFiltersParagraphAllOfBrands;
  categories: ProductListWithFiltersParagraphAllOfCategories;
  clickAndCollect: ProductListWithFiltersParagraphAllOfClickAndCollect;
  eventMarks?: ProductListWithFiltersParagraphAllOfEventMarks;
  everyDayLowPrice: ProductListWithFiltersParagraphAllOfEveryDayLowPrice;
  onlineSales: ProductListWithFiltersParagraphAllOfOnlineSales;
  onSale: ProductListWithFiltersParagraphAllOfOnSale;
  paragraphType: ProductListWithFiltersParagraphAllOfParagraphType;
  price: ProductListWithFiltersParagraphAllOfPrice;
  priceHero: ProductListWithFiltersParagraphAllOfPriceHero;
  productSkus: ProductListWithFiltersParagraphAllOfProductSkus;
  statusCode: ProductListWithFiltersParagraphAllOfStatusCode;
  storeSales: ProductListWithFiltersParagraphAllOfStoreSales;
  whileStockLasts: ProductListWithFiltersParagraphAllOfWhileStockLasts;
};

export type ProductListWithFiltersParagraph = ParagraphBase & ProductListWithFiltersParagraphAllOf;

export type MostPopularFaqQuestionParagraphAllOfParagraphType =
  (typeof MostPopularFaqQuestionParagraphAllOfParagraphType)[keyof typeof MostPopularFaqQuestionParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MostPopularFaqQuestionParagraphAllOfParagraphType = {
  MostPopularFaqQuestion: 'MostPopularFaqQuestion',
} as const;

export type MostPopularFaqQuestionParagraphAllOf = {
  items: MostPopularFaqQuestionParagraphAllOfItems;
  paragraphType: MostPopularFaqQuestionParagraphAllOfParagraphType;
};

export type MostPopularFaqQuestionParagraph = ParagraphBase & MostPopularFaqQuestionParagraphAllOf;

/**
 * FAQ item
 */
export type FaqItemTeaser = {
  id: Uuid;
  title: string;
  /** The url consist of the faq category path and a query parameter for the question. */
  url: Url;
};

export type MostPopularFaqQuestionParagraphAllOfItems = FaqItemTeaser[] | null;

export type PublicationListParagraph = ParagraphBase & PublicationListParagraphAllOf;

export type PublicationListParagraphAllOfParagraphType =
  (typeof PublicationListParagraphAllOfParagraphType)[keyof typeof PublicationListParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublicationListParagraphAllOfParagraphType = {
  PublicationList: 'PublicationList',
} as const;

export type Publication = {
  description: string;
  /** External url to the thumbnail on the ipapercms platform */
  imageUrl: ExternalImageUrl;
  title: string;
  url: Url;
};

export type PublicationListParagraphAllOfItems = Publication[] | null;

export type PublicationListParagraphAllOf = {
  items: PublicationListParagraphAllOfItems;
  paragraphType: PublicationListParagraphAllOfParagraphType;
};

export type CSCContactInformationParagraphYellowNoticeMessage = string | null;

export type CSCContactInformationParagraphWhatsAppChatResponseTime = string | null;

export type CSCContactInformationParagraphWhatsAppChatLink = Url | null;

export type CSCContactInformationParagraphPhoneResponseTime = string | null;

export type CSCContactInformationParagraphParagraphType =
  (typeof CSCContactInformationParagraphParagraphType)[keyof typeof CSCContactInformationParagraphParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CSCContactInformationParagraphParagraphType = {
  CscContactInformation: 'CscContactInformation',
} as const;

export type CSCContactInformationParagraphOpeningHours = string | null;

export type CSCContactInformationParagraphMessengerChatResponseTime = string | null;

export type CSCContactInformationParagraphMessengerChatLink = Url | null;

export type CSCContactInformationParagraphEmailFormResponseTime = string | null;

export type CSCContactInformationParagraphCscPhoneNumber = string | null;

export type CSCContactInformationParagraphCscImage = ImageMedia | null;

export type CSCContactInformationParagraphChatResponseTime = string | null;

export type CSCContactInformationParagraph = {
  chatResponseTime: CSCContactInformationParagraphChatResponseTime;
  cscImage: CSCContactInformationParagraphCscImage;
  cscPhoneNumber: CSCContactInformationParagraphCscPhoneNumber;
  emailFormEnabled: boolean;
  emailFormResponseTime: CSCContactInformationParagraphEmailFormResponseTime;
  messengerChatEnabled: boolean;
  messengerChatLink: CSCContactInformationParagraphMessengerChatLink;
  messengerChatResponseTime: CSCContactInformationParagraphMessengerChatResponseTime;
  openingHours: CSCContactInformationParagraphOpeningHours;
  paragraphType: CSCContactInformationParagraphParagraphType;
  phoneResponseTime: CSCContactInformationParagraphPhoneResponseTime;
  whatsAppChatEnabled: boolean;
  whatsAppChatLink: CSCContactInformationParagraphWhatsAppChatLink;
  whatsAppChatResponseTime: CSCContactInformationParagraphWhatsAppChatResponseTime;
  yellowNoticeMessage: CSCContactInformationParagraphYellowNoticeMessage;
};

export type FooterContactInformationParagraphParagraphType =
  (typeof FooterContactInformationParagraphParagraphType)[keyof typeof FooterContactInformationParagraphParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FooterContactInformationParagraphParagraphType = {
  FooterContactInformation: 'FooterContactInformation',
} as const;

export type FooterContactInformationParagraphCustomerService =
  FooterContactInformationParagraphCustomerServiceOneOf | null;

export type FooterContactInformationParagraph = {
  b2b: FooterContactInformationParagraphB2b;
  company: FooterContactInformationParagraphCompany;
  customerService: FooterContactInformationParagraphCustomerService;
  paragraphType: FooterContactInformationParagraphParagraphType;
};

export type FooterContactInformationParagraphCustomerServiceOneOfPhone = string | null;

export type FooterContactInformationParagraphCustomerServiceOneOfContactPageUrl = string | null;

export type FooterContactInformationParagraphCustomerServiceOneOf = {
  contactPageUrl: FooterContactInformationParagraphCustomerServiceOneOfContactPageUrl;
  phone: FooterContactInformationParagraphCustomerServiceOneOfPhone;
};

export type FooterContactInformationParagraphCompany = FooterContactInformationParagraphCompanyOneOf | null;

export type FooterContactInformationParagraphCompanyOneOfVatNo = string | null;

export type FooterContactInformationParagraphCompanyOneOfReg = string | null;

export type FooterContactInformationParagraphCompanyOneOfPhone = string | null;

export type FooterContactInformationParagraphCompanyOneOfFax = string | null;

export type FooterContactInformationParagraphCompanyOneOfAddress = string | null;

export type FooterContactInformationParagraphCompanyOneOf = {
  address: FooterContactInformationParagraphCompanyOneOfAddress;
  fax: FooterContactInformationParagraphCompanyOneOfFax;
  phone: FooterContactInformationParagraphCompanyOneOfPhone;
  reg: FooterContactInformationParagraphCompanyOneOfReg;
  vatNo: FooterContactInformationParagraphCompanyOneOfVatNo;
};

export type FooterContactInformationParagraphB2b = FooterContactInformationParagraphB2bOneOf | null;

export type FooterContactInformationParagraphB2bOneOfPhone = string | null;

export type FooterContactInformationParagraphB2bOneOfEmail = string | null;

export type FooterContactInformationParagraphB2bOneOfContactPageUrl = string | null;

export type FooterContactInformationParagraphB2bOneOf = {
  contactPageUrl: FooterContactInformationParagraphB2bOneOfContactPageUrl;
  email: FooterContactInformationParagraphB2bOneOfEmail;
  phone: FooterContactInformationParagraphB2bOneOfPhone;
};

export type TableParagraph = ParagraphBase & TableParagraphAllOf;

export type TableParagraphAllOfRowsOneOfItem = {
  columns: TableParagraphAllOfRowsOneOfItemColumns;
};

export type TableParagraphAllOfRows = TableParagraphAllOfRowsOneOfItem[] | null;

export type TableParagraphAllOf = {
  alternateRowColors: boolean;
  firstColumnSticky: boolean;
  paragraphType: TableParagraphAllOfParagraphType;
  rows: TableParagraphAllOfRows;
};

export type TableParagraphAllOfRowsOneOfItemColumnsOneOfItemText = TextParagraph | null;

export type TableParagraphAllOfRowsOneOfItemColumnsOneOfItemImage = ImageMedia | null;

export type TableParagraphAllOfRowsOneOfItemColumnsOneOfItem = {
  image: TableParagraphAllOfRowsOneOfItemColumnsOneOfItemImage;
  text: TableParagraphAllOfRowsOneOfItemColumnsOneOfItemText;
};

export type TableParagraphAllOfRowsOneOfItemColumns = TableParagraphAllOfRowsOneOfItemColumnsOneOfItem[] | null;

export type TableParagraphAllOfParagraphType =
  (typeof TableParagraphAllOfParagraphType)[keyof typeof TableParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TableParagraphAllOfParagraphType = {
  Table: 'Table',
} as const;

export type ButtonListParagraph = ParagraphBase & ButtonListParagraphAllOf;

export type ButtonListParagraphAllOfPosition =
  (typeof ButtonListParagraphAllOfPosition)[keyof typeof ButtonListParagraphAllOfPosition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ButtonListParagraphAllOfPosition = {
  inline: 'inline',
  stacked: 'stacked',
} as const;

export type ButtonListParagraphAllOfParagraphType =
  (typeof ButtonListParagraphAllOfParagraphType)[keyof typeof ButtonListParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ButtonListParagraphAllOfParagraphType = {
  ButtonList: 'ButtonList',
} as const;

export type ButtonListParagraphAllOfItemsItem = {
  link: Link;
  style: ButtonListParagraphAllOfItemsItemStyle;
};

export type ButtonListParagraphAllOf = {
  alignment: ButtonListParagraphAllOfAlignment;
  items: ButtonListParagraphAllOfItemsItem[];
  paragraphType: ButtonListParagraphAllOfParagraphType;
  position: ButtonListParagraphAllOfPosition;
};

export type ButtonListParagraphAllOfItemsItemStyleType =
  (typeof ButtonListParagraphAllOfItemsItemStyleType)[keyof typeof ButtonListParagraphAllOfItemsItemStyleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ButtonListParagraphAllOfItemsItemStyleType = {
  primary: 'primary',
  secondary: 'secondary',
  custom: 'custom',
} as const;

/**
 * Hex color code. Only provided if style is of type "custom"
 */
export type ButtonListParagraphAllOfItemsItemStyleFontColor = string | null;

/**
 * Hex color code. Only provided if style is of type "custom"
 */
export type ButtonListParagraphAllOfItemsItemStyleBackgroundColor = string | null;

export type ButtonListParagraphAllOfItemsItemStyle = {
  /** Hex color code. Only provided if style is of type "custom" */
  backgroundColor: ButtonListParagraphAllOfItemsItemStyleBackgroundColor;
  /** Hex color code. Only provided if style is of type "custom" */
  fontColor: ButtonListParagraphAllOfItemsItemStyleFontColor;
  type: ButtonListParagraphAllOfItemsItemStyleType;
};

export type ButtonListParagraphAllOfAlignment =
  (typeof ButtonListParagraphAllOfAlignment)[keyof typeof ButtonListParagraphAllOfAlignment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ButtonListParagraphAllOfAlignment = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const;

export type ProductBrandOverviewParagraphAllOfParagraphType =
  (typeof ProductBrandOverviewParagraphAllOfParagraphType)[keyof typeof ProductBrandOverviewParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductBrandOverviewParagraphAllOfParagraphType = {
  ProductBrandOverview: 'ProductBrandOverview',
} as const;

export type ProductBrandOverviewParagraphAllOf = {
  brands: ProductBrandOverviewParagraphAllOfBrands;
  paragraphType: ProductBrandOverviewParagraphAllOfParagraphType;
};

export type ProductBrandOverviewParagraph = ParagraphBase & ProductBrandOverviewParagraphAllOf;

export type ProductBrandCarouselParagraph = ParagraphBase & ProductBrandCarouselParagraphAllOf;

export type ProductBrandCarouselParagraphAllOfParagraphType =
  (typeof ProductBrandCarouselParagraphAllOfParagraphType)[keyof typeof ProductBrandCarouselParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductBrandCarouselParagraphAllOfParagraphType = {
  ProductBrandCarousel: 'ProductBrandCarousel',
} as const;

export type ProductBrandTeaserBrandLogoImage = ImageMedia | null;

export type ProductBrandTeaser = {
  brandLogoImage: ProductBrandTeaserBrandLogoImage;
  id: Uuid;
  /** Brand name */
  name: string;
  url: Url;
};

export type ProductBrandOverviewParagraphAllOfBrands = ProductBrandTeaser[] | null;

export type ProductBrandCarouselParagraphAllOfBrands = ProductBrandTeaser[] | null;

export type ProductBrandCarouselParagraphAllOf = {
  brands: ProductBrandCarouselParagraphAllOfBrands;
  paragraphType: ProductBrandCarouselParagraphAllOfParagraphType;
};

export type UspParagraphAllOfParagraphType =
  (typeof UspParagraphAllOfParagraphType)[keyof typeof UspParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UspParagraphAllOfParagraphType = {
  Usp: 'Usp',
} as const;

export type UspParagraphAllOfItemsItem = {
  icon: Icon;
  text: string;
  title: string;
  url: Url;
};

export type UspParagraphAllOf = {
  items: UspParagraphAllOfItemsItem[];
  paragraphType: UspParagraphAllOfParagraphType;
};

export type UspParagraph = ParagraphBase & UspParagraphAllOf;

export type SeparatorParagraphAllOfParagraphType =
  (typeof SeparatorParagraphAllOfParagraphType)[keyof typeof SeparatorParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeparatorParagraphAllOfParagraphType = {
  Separator: 'Separator',
} as const;

export type SeparatorParagraphAllOf = {
  paragraphType: SeparatorParagraphAllOfParagraphType;
};

export type SeparatorParagraph = ParagraphBase & SeparatorParagraphAllOf;

export type BackgroundColorWithOverlayParagraphAllOfParagraphType =
  (typeof BackgroundColorWithOverlayParagraphAllOfParagraphType)[keyof typeof BackgroundColorWithOverlayParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BackgroundColorWithOverlayParagraphAllOfParagraphType = {
  BackgroundColorWithOverlay: 'BackgroundColorWithOverlay',
} as const;

export type BackgroundColorWithOverlayParagraphAllOf = {
  /** Hex color code */
  backgroundColor: string;
  overlay: BackgroundColorWithOverlayParagraphAllOfOverlay;
  paragraphType: BackgroundColorWithOverlayParagraphAllOfParagraphType;
};

export type BackgroundColorWithOverlayParagraph = ParagraphBase & BackgroundColorWithOverlayParagraphAllOf;

export type BackgroundColorWithOverlayParagraphAllOfOverlayText = string | null;

export type BackgroundColorWithOverlayParagraphAllOfOverlayPosition =
  | 'top_left'
  | 'top_center'
  | 'top_right'
  | 'center_left'
  | 'center'
  | 'center_right'
  | 'bottom_left'
  | 'bottom_center'
  | 'bottom_right'
  | null;

export type BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleType =
  (typeof BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleType)[keyof typeof BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleType = {
  primary: 'primary',
  secondary: 'secondary',
  custom: 'custom',
} as const;

/**
 * Hex color code. Only provided if style is of type "custom"
 */
export type BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleFontColor = string | null;

/**
 * Hex color code. Only provided if style is of type "custom"
 */
export type BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleBackgroundColor = string | null;

export type BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyle = {
  /** Hex color code. Only provided if style is of type "custom" */
  backgroundColor: BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleBackgroundColor;
  /** Hex color code. Only provided if style is of type "custom" */
  fontColor: BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleFontColor;
  type: BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyleType;
};

export type BackgroundColorWithOverlayParagraphAllOfOverlayCta = Link | null;

export type BackgroundColorWithOverlayParagraphAllOfOverlayBackgroundColor = string | null;

export type BackgroundColorWithOverlayParagraphAllOfOverlay = {
  backgroundColor: BackgroundColorWithOverlayParagraphAllOfOverlayBackgroundColor;
  cta: BackgroundColorWithOverlayParagraphAllOfOverlayCta;
  ctaStyle: BackgroundColorWithOverlayParagraphAllOfOverlayCtaStyle;
  position: BackgroundColorWithOverlayParagraphAllOfOverlayPosition;
  text: BackgroundColorWithOverlayParagraphAllOfOverlayText;
};

export type MediaParagraphAllOfSize = 'default' | 'landscape' | 'wide' | 'square' | null;

export type MediaParagraphAllOf = {
  alignment: MediaParagraphAllOfAlignment;
  link: MediaParagraphAllOfLink;
  media: MediaParagraphAllOfMedia;
  overlay: MediaParagraphAllOfOverlay;
  paragraphType: MediaParagraphAllOfParagraphType;
  size: MediaParagraphAllOfSize;
};

export type MediaParagraph = ParagraphBase & MediaParagraphAllOf;

export type MediaParagraphAllOfParagraphType =
  (typeof MediaParagraphAllOfParagraphType)[keyof typeof MediaParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaParagraphAllOfParagraphType = {
  Media: 'Media',
} as const;

export type MediaParagraphAllOfOverlayOneOfText = string | null;

export type MediaParagraphAllOfOverlayOneOfPosition =
  | 'top_left'
  | 'top_center'
  | 'top_right'
  | 'center_left'
  | 'center'
  | 'center_right'
  | 'bottom_left'
  | 'bottom_center'
  | 'bottom_right'
  | null;

/**
 * Only used if media is a image
 */
export type MediaParagraphAllOfOverlayOneOf = {
  backgroundColor: MediaParagraphAllOfOverlayOneOfBackgroundColor;
  cta: MediaParagraphAllOfOverlayOneOfCta;
  ctaStyle: MediaParagraphAllOfOverlayOneOfCtaStyle;
  position: MediaParagraphAllOfOverlayOneOfPosition;
  text: MediaParagraphAllOfOverlayOneOfText;
};

export type MediaParagraphAllOfOverlay = MediaParagraphAllOfOverlayOneOf | null;

export type MediaParagraphAllOfOverlayOneOfCtaStyleType =
  (typeof MediaParagraphAllOfOverlayOneOfCtaStyleType)[keyof typeof MediaParagraphAllOfOverlayOneOfCtaStyleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaParagraphAllOfOverlayOneOfCtaStyleType = {
  primary: 'primary',
  secondary: 'secondary',
  custom: 'custom',
} as const;

/**
 * Hex color code. Only provided if style is of type "custom"
 */
export type MediaParagraphAllOfOverlayOneOfCtaStyleFontColor = string | null;

/**
 * Hex color code. Only provided if style is of type "custom"
 */
export type MediaParagraphAllOfOverlayOneOfCtaStyleBackgroundColor = string | null;

export type MediaParagraphAllOfOverlayOneOfCtaStyle = {
  /** Hex color code. Only provided if style is of type "custom" */
  backgroundColor: MediaParagraphAllOfOverlayOneOfCtaStyleBackgroundColor;
  /** Hex color code. Only provided if style is of type "custom" */
  fontColor: MediaParagraphAllOfOverlayOneOfCtaStyleFontColor;
  type: MediaParagraphAllOfOverlayOneOfCtaStyleType;
};

export type MediaParagraphAllOfOverlayOneOfCta = Link | null;

export type MediaParagraphAllOfOverlayOneOfBackgroundColor = string | null;

export type MediaParagraphAllOfMedia = ExternalVideoMedia | ImageMedia;

export type MediaParagraphAllOfLink = Link | null;

export type MediaParagraphAllOfAlignment =
  (typeof MediaParagraphAllOfAlignment)[keyof typeof MediaParagraphAllOfAlignment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaParagraphAllOfAlignment = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const;

export type CountdownBannerParagraphAllOfTextAfterEndTime = string | null;

export type CountdownBannerParagraphAllOfParagraphType =
  (typeof CountdownBannerParagraphAllOfParagraphType)[keyof typeof CountdownBannerParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountdownBannerParagraphAllOfParagraphType = {
  CountdownBanner: 'CountdownBanner',
} as const;

export type CountdownBannerParagraphAllOfOverlayText = string | null;

export type CountdownBannerParagraphAllOfLink = Link | null;

/**
 * the background color of the Banner
 */
export type CountdownBannerParagraphAllOfBackgroundColor = string | null;

export type CountdownBannerParagraphAllOf = {
  /** the background color of the Banner */
  backgroundColor: CountdownBannerParagraphAllOfBackgroundColor;
  bannerImage: ImageMedia;
  dueDate: string;
  hideSeconds: boolean;
  link: CountdownBannerParagraphAllOfLink;
  overlayText: CountdownBannerParagraphAllOfOverlayText;
  paragraphType: CountdownBannerParagraphAllOfParagraphType;
  textAfterEndTime: CountdownBannerParagraphAllOfTextAfterEndTime;
};

export type CountdownBannerParagraph = ParagraphBase & CountdownBannerParagraphAllOf;

export type PlaceholderParagraphAllOfParagraphType =
  (typeof PlaceholderParagraphAllOfParagraphType)[keyof typeof PlaceholderParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlaceholderParagraphAllOfParagraphType = {
  Placeholder: 'Placeholder',
} as const;

/**
 * A placeholder machine name that should not change, enabling a placeholder for custom building blocks.
 */
export type PlaceholderParagraphAllOf = {
  id: string;
  paragraphType: PlaceholderParagraphAllOfParagraphType;
};

export type PlaceholderParagraph = ParagraphBase & PlaceholderParagraphAllOf;

export type TooltipParagraph = ParagraphBase & TooltipParagraphAllOf;

export type TooltipParagraphAllOfTooltips = TooltipParagraphAllOfTooltipsOneOfItem[] | null;

/**
 * Tooltip type
 */
export type TooltipParagraphAllOfTooltipsOneOfItemType =
  (typeof TooltipParagraphAllOfTooltipsOneOfItemType)[keyof typeof TooltipParagraphAllOfTooltipsOneOfItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TooltipParagraphAllOfTooltipsOneOfItemType = {
  product: 'product',
  link: 'link',
} as const;

export type TooltipParagraphAllOfTooltipsOneOfItemProduct = ProductTeaser | null;

export type TooltipParagraphAllOfTooltipsOneOfItemPosition = {
  x?: number;
  y?: number;
};

export type TooltipParagraphAllOfTooltipsOneOfItemLink = Link | null;

export type TooltipParagraphAllOfTooltipsOneOfItem = {
  link: TooltipParagraphAllOfTooltipsOneOfItemLink;
  position: TooltipParagraphAllOfTooltipsOneOfItemPosition;
  product: TooltipParagraphAllOfTooltipsOneOfItemProduct;
  /** Tooltip type */
  type: TooltipParagraphAllOfTooltipsOneOfItemType;
};

export type TooltipParagraphAllOfParagraphType =
  (typeof TooltipParagraphAllOfParagraphType)[keyof typeof TooltipParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TooltipParagraphAllOfParagraphType = {
  Tooltip: 'Tooltip',
} as const;

export type TooltipParagraphAllOfImageSize =
  (typeof TooltipParagraphAllOfImageSize)[keyof typeof TooltipParagraphAllOfImageSize];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TooltipParagraphAllOfImageSize = {
  default: 'default',
  landscape: 'landscape',
  wide: 'wide',
  square: 'square',
} as const;

export type TooltipParagraphAllOf = {
  image: ImageMedia;
  imageSize: TooltipParagraphAllOfImageSize;
  paragraphType: TooltipParagraphAllOfParagraphType;
  tooltips: TooltipParagraphAllOfTooltips;
};

export type LinkTitle = string | null;

/**
 * the target window, empty being equivalent to the current window.
 */
export type LinkTarget = string | null;

/**
 * Link
 */
export type Link = {
  /** the target window, empty being equivalent to the current window. */
  target: LinkTarget;
  title: LinkTitle;
  url: Url;
};

export type MenuParagraph = ParagraphBase & MenuParagraphAllOf;

export type MenuParagraphAllOfParagraphType =
  (typeof MenuParagraphAllOfParagraphType)[keyof typeof MenuParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MenuParagraphAllOfParagraphType = {
  Menu: 'Menu',
} as const;

/**
 * the target window, empty being equivalent to the current window.
 */
export type MenuParagraphAllOfItemsItemTarget = string | null;

export type MenuParagraphAllOfItemsItem = {
  /** the target window, empty being equivalent to the current window. */
  target: MenuParagraphAllOfItemsItemTarget;
  title: string;
  url: Url;
};

export type MenuParagraphAllOf = {
  items: MenuParagraphAllOfItemsItem[];
  paragraphType: MenuParagraphAllOfParagraphType;
};

export type CategoriesParagraph = ParagraphBase & CategoriesParagraphAllOf;

/**
 * The title color of category block.
 */
export type CategoriesParagraphAllOfTitleColor = string | null;

export type CategoriesParagraphAllOfParagraphType =
  (typeof CategoriesParagraphAllOfParagraphType)[keyof typeof CategoriesParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoriesParagraphAllOfParagraphType = {
  Categories: 'Categories',
} as const;

export type CategoriesParagraphAllOfMobileLayout =
  (typeof CategoriesParagraphAllOfMobileLayout)[keyof typeof CategoriesParagraphAllOfMobileLayout];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoriesParagraphAllOfMobileLayout = {
  list: 'list',
  grid: 'grid',
} as const;

export type CategoriesParagraphAllOfItemsItemImage = ImageMedia | null;

export type CategoriesParagraphAllOfItemsItem = {
  image: CategoriesParagraphAllOfItemsItemImage;
  name: string;
  url: Url;
};

/**
 * The background color of category block.
 */
export type CategoriesParagraphAllOfBackgroundColor = string | null;

/**
 * The Categories paragraph
 */
export type CategoriesParagraphAllOf = {
  /** The background color of category block. */
  backgroundColor: CategoriesParagraphAllOfBackgroundColor;
  items: CategoriesParagraphAllOfItemsItem[];
  mobileLayout: CategoriesParagraphAllOfMobileLayout;
  paragraphType: CategoriesParagraphAllOfParagraphType;
  /** The title color of category block. */
  titleColor: CategoriesParagraphAllOfTitleColor;
};

export type TableOfContentsParagraphAllOfParagraphType =
  (typeof TableOfContentsParagraphAllOfParagraphType)[keyof typeof TableOfContentsParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TableOfContentsParagraphAllOfParagraphType = {
  TableOfContents: 'TableOfContents',
} as const;

export type TableOfContentsParagraphAllOf = {
  paragraphType: TableOfContentsParagraphAllOfParagraphType;
};

export type TableOfContentsParagraph = ParagraphBase & TableOfContentsParagraphAllOf;

export type IframeParagraphAllOfParagraphType =
  (typeof IframeParagraphAllOfParagraphType)[keyof typeof IframeParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IframeParagraphAllOfParagraphType = {
  Iframe: 'Iframe',
} as const;

export type IframeParagraphAllOf = {
  paragraphType: IframeParagraphAllOfParagraphType;
  url: Url;
};

export type IframeParagraph = ParagraphBase & IframeParagraphAllOf;

export type FaqCategoryListParagraphAllOfParagraphType =
  (typeof FaqCategoryListParagraphAllOfParagraphType)[keyof typeof FaqCategoryListParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FaqCategoryListParagraphAllOfParagraphType = {
  FaqCategoryList: 'FaqCategoryList',
} as const;

export type FaqCategoryListParagraphAllOf = {
  items: FaqCategoryListParagraphAllOfItemsItem[];
  paragraphType: FaqCategoryListParagraphAllOfParagraphType;
};

export type FaqCategoryListParagraph = ParagraphBase & FaqCategoryListParagraphAllOf;

export type FaqCategoryTeaserIcon = Icon | null;

/**
 * Optional properties of Faq Categories which are overwritten by the closest active item.
 */
export type FaqCategoryTeaser = {
  icon: FaqCategoryTeaserIcon;
  title: string;
  url: Url;
};

export type FaqCategoryListParagraphAllOfItemsItem = FaqCategoryTeaser | null;

export type ProductCarouselParagraphAllOfParagraphType =
  (typeof ProductCarouselParagraphAllOfParagraphType)[keyof typeof ProductCarouselParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCarouselParagraphAllOfParagraphType = {
  ProductSlider: 'ProductSlider',
} as const;

export type ProductCarouselParagraphAllOf = {
  items: ProductCarouselParagraphAllOfItems;
  paragraphType: ProductCarouselParagraphAllOfParagraphType;
};

export type ProductCarouselParagraph = ParagraphBase & ProductCarouselParagraphAllOf;

export type ProductTeaserAllOfRelations = ProductTeaserAllOfRelationsOneOf | null;

export type ProductTeaserAllOf = {
  media: ProductTeaserAllOfMedia;
  relations: ProductTeaserAllOfRelations;
  url: Url;
};

export type ProductTeaser = ProductBase & ProductTeaserAllOf;

export type ProductCarouselParagraphAllOfItems = ProductTeaser[] | null;

export type ProductTeaserAllOfRelationsOneOfSeriesOneOf = {
  seriesId: string;
};

export type ProductTeaserAllOfRelationsOneOfSeries = ProductTeaserAllOfRelationsOneOfSeriesOneOf | null;

export type ProductTeaserAllOfRelationsOneOfFamilyOneOf = {
  defaultProductId: ExternalId;
};

export type ProductTeaserAllOfRelationsOneOfFamily = ProductTeaserAllOfRelationsOneOfFamilyOneOf | null;

export type ProductTeaserAllOfRelationsOneOfBrandOneOf = {
  name: string;
};

export type ProductTeaserAllOfRelationsOneOfBrand = ProductTeaserAllOfRelationsOneOfBrandOneOf | null;

export type ProductTeaserAllOfRelationsOneOf = {
  brand: ProductTeaserAllOfRelationsOneOfBrand;
  family: ProductTeaserAllOfRelationsOneOfFamily;
  series: ProductTeaserAllOfRelationsOneOfSeries;
};

export type ProductTeaserAllOfMedia = ProductTeaserAllOfMediaOneOf | null;

/**
 * urls to extra images if the product has these properties
 */
export type ProductTeaserAllOfMediaOneOfReferencesOneOf = {
  energyMarking: ExternalImageUrl;
};

export type ProductTeaserAllOfMediaOneOfReferences = ProductTeaserAllOfMediaOneOfReferencesOneOf | null;

export type ProductTeaserAllOfMediaOneOfImages = ProductImageFull[] | null;

export type ProductTeaserAllOfMediaOneOf = {
  images: ProductTeaserAllOfMediaOneOfImages;
  references: ProductTeaserAllOfMediaOneOfReferences;
};

export type ProductImageFullAllOfTypeGroup =
  (typeof ProductImageFullAllOfTypeGroup)[keyof typeof ProductImageFullAllOfTypeGroup];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductImageFullAllOfTypeGroup = {
  cutOut: 'cutOut',
  environment: 'environment',
  detail: 'detail',
  function: 'function',
  NUMBER_360: '360',
  video: 'video',
  fallback: 'fallback',
} as const;

/**
 * internal image type, likely no special meaning except 360 images.
 */
export type ProductImageFullAllOfType = (typeof ProductImageFullAllOfType)[keyof typeof ProductImageFullAllOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductImageFullAllOfType = {
  Web: 'Web',
  Cut_out: 'Cut out',
  Environment_standard: 'Environment standard',
  Detail: 'Detail',
  NUMBER_360: '360',
  Internal: 'Internal',
  Stilleben: 'Stilleben',
  Environment_web: 'Environment web',
  Cut_out_stack: 'Cut out stack',
  Cut_out_front_right: 'Cut out front right',
  Function: 'Function',
  Cut_through: 'Cut through',
  Cut_out_back: 'Cut out back',
  Cut_out_front: 'Cut out front',
  Cut_out_sets: 'Cut out sets',
  Cut_out_side: 'Cut out side',
  'Cut_out_w/model': 'Cut out w/model',
  'Cut_out_w/props': 'Cut out w/props',
  'Cut_out_w/o_shadows': 'Cut out w/o shadows',
  Cut_out_front_no_padding: 'Cut out front no padding',
  Cut_out_back_no_padding: 'Cut out back no padding',
  Cut_out_function: 'Cut out function',
  Environment_single_product: 'Environment single product',
  Environment_multiple_products: 'Environment multiple products',
  'Environment_w/model': 'Environment w/model',
  Environment_SoMe: 'Environment SoMe',
  Environment_instore: 'Environment instore',
  'Detail_#1': 'Detail #1',
  'Detail_#2': 'Detail #2',
  'Detail_#3': 'Detail #3',
  'Detail_w/props': 'Detail w/props',
  Line_drawing: 'Line drawing',
  Measurement_illustration: 'Measurement illustration',
  'Packaging_w/model': 'Packaging w/model',
  '360°': '360°',
  'Video°': 'Video°',
} as const;

/**
 * product image hosted on the mediamanagement cdn.
 */
export type ProductImageFullAllOf = {
  alt: string;
  /** internal image type, likely no special meaning except 360 images. */
  type: ProductImageFullAllOfType;
  typeGroup: ProductImageFullAllOfTypeGroup;
};

export type ProductImageFull = ProductImageTeaser & ProductImageFullAllOf;

export type ProductImageTeaserDimensions = MediaDimensions | null;

/**
 * full URL to externally hosted image, e.g. our cdn images
 */
export type ExternalImageUrl = string;

/**
 * product image hosted on the media management cdn.
 */
export type ProductImageTeaser = {
  dimensions: ProductImageTeaserDimensions;
  url: ExternalImageUrl;
};

export type ProductBaseSmosUrl = Url | null;

export type ProductBaseSecondaryProductId = ExternalId | null;

/**
 * the product rating, with max 1 decimal
 */
export type ProductBaseRating = number | null;

export type ProductBasePrice = ProductPrice | null;

export type ProductBaseLabels = ProductLabels | null;

export type ProductBaseCategoryNames = string[] | null;

/**
 * product
 */
export type ProductBase = {
  categoryNames: ProductBaseCategoryNames;
  customFields: ProductBaseCustomFields;
  id: ExternalId;
  /** whether the product can be purchased with click & collect */
  isClickCollect: boolean;
  /** whether the product is delivered directly from the vendor */
  isDirectDeliveryFromVendor: boolean;
  isOnlineSales: boolean;
  /** whether the product can only be ordered in stores */
  isOrderableInStores: boolean;
  isPriceHero: boolean;
  isSingleOut: boolean;
  /** whether the product is sold in stores */
  isStoreSales: boolean;
  labels: ProductBaseLabels;
  price: ProductBasePrice;
  /** the product rating, with max 1 decimal */
  rating: ProductBaseRating;
  secondaryProductId: ProductBaseSecondaryProductId;
  /** whether to show the cheapest price notice or not. */
  shouldShowCheapestPriceNotice: boolean;
  smosUrl: ProductBaseSmosUrl;
  /** The product title */
  title: string;
};

/**
 * extra fields, optional.
 */
export type ProductCustomFields = {
  /** JFR only. would lead to displaying the "Soldes" label. */
  isSpecialSale: boolean;
};

export type ProductBaseCustomFields = ProductCustomFields | null;

export type ProductLabelsItem = (typeof ProductLabelsItem)[keyof typeof ProductLabelsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductLabelsItem = {
  basic: 'basic',
  plus: 'plus',
  gold: 'gold',
  campaign: 'campaign',
  coupon: 'coupon',
  specialSale: 'specialSale',
  new: 'new',
  discount: 'discount',
  whileStockLasts: 'whileStockLasts',
  everyDayLowPrice: 'everyDayLowPrice',
  greatOffer: 'greatOffer',
  blackFriday: 'blackFriday',
} as const;

/**
 * labels to be shown on a product. up to discussion.
 */
export type ProductLabels = ProductLabelsItem[];

export type ProductPriceUnformattedOneOfCampaign = number | null;

export type ProductPriceUnformattedOneOf = {
  campaign: ProductPriceUnformattedOneOfCampaign;
  /** the unformatted gross price of the product. */
  gross: number;
  /** the unformatted price of a single unit of the product. (optional!) */
  minSingle: number;
  /** the standard unit annotation. */
  unit: string;
};

export type ProductPriceUnformatted = ProductPriceUnformattedOneOf | null;

export type ProductPriceStyle =
  | 'normalprice'
  | 'discountprice'
  | 'bundleofferprice'
  | 'offerprice'
  | 'packageprice'
  | null;

export type ProductPriceRecyclingTaxOneOfRecyclingTaxFee = number | null;

export type ProductPriceRecyclingTaxOneOfRecyclingTaxBasePrice = number | null;

export type ProductPriceRecyclingTaxOneOf = {
  recyclingTaxBasePrice: ProductPriceRecyclingTaxOneOfRecyclingTaxBasePrice;
  recyclingTaxFee: ProductPriceRecyclingTaxOneOfRecyclingTaxFee;
};

export type ProductPriceRecyclingTax = ProductPriceRecyclingTaxOneOf | null;

/**
 * the formatted unit annotation.
 */
export type ProductPriceFormattedOneOfUnit = string | null;

/**
 * the formatted gross price of the product.
 */
export type ProductPriceFormattedOneOfGross = string | null;

export type ProductPriceFormattedOneOf = {
  /** the formatted before price of the product. */
  beforePrice: string;
  /** the formatted before price secondary of the product. */
  beforePriceSecondary: string;
  /** the formatted gross price of the product. */
  gross: ProductPriceFormattedOneOfGross;
  /** the formatted price of the product including unit. */
  grossWithUnit: string;
  /** the formatted net price of the product. */
  netWithUnit: string;
  /** the formatted unit annotation. */
  unit: ProductPriceFormattedOneOfUnit;
};

export type ProductPriceFormatted = ProductPriceFormattedOneOf | null;

export type ProductPriceDiscountSecondaryOneOf = {
  fee: ProductPriceDiscountSecondaryOneOfFee;
  /** if the discount secondary should be visible. */
  isVisible: boolean;
  /**
   * @minimum 0
   * @maximum 100
   */
  percentage: ProductPriceDiscountSecondaryOneOfPercentage;
};

export type ProductPriceDiscountSecondary = ProductPriceDiscountSecondaryOneOf | null;

/**
 * most price related properties, grouped together
 */
export type ProductPrice = {
  additionalPriceInfo: ProductPriceAdditionalPriceInfo;
  baseUnit: ProductPriceBaseUnit;
  couponDiscount: ProductPriceCouponDiscount;
  discount: ProductPriceDiscount;
  discountSecondary: ProductPriceDiscountSecondary;
  formatted: ProductPriceFormatted;
  recyclingTax: ProductPriceRecyclingTax;
  style: ProductPriceStyle;
  unformatted: ProductPriceUnformatted;
};

/**
 * @minimum 0
 * @maximum 100
 */
export type ProductPriceDiscountSecondaryOneOfPercentage = number | null;

export type ProductPriceDiscountSecondaryOneOfFee = number | null;

/**
 * @minimum 0
 * @maximum 100
 */
export type ProductPriceDiscountOneOfPercentage = number | null;

export type ProductPriceDiscountOneOfFee = number | null;

export type ProductPriceDiscountOneOf = {
  fee: ProductPriceDiscountOneOfFee;
  /** if the discount should be visible. this might rather wander into the labels if business agree. */
  isVisible: boolean;
  /**
   * @minimum 0
   * @maximum 100
   */
  percentage: ProductPriceDiscountOneOfPercentage;
};

export type ProductPriceDiscount = ProductPriceDiscountOneOf | null;

export type ProductPriceCouponDiscountOneOf = {
  /** the discount code required. empty means there's no coupon discount to show or apply. */
  code: string;
  /**
   * the discount percentage when using the code.
   * @minimum 0
   * @maximum 100
   */
  percentage: number;
};

export type ProductPriceCouponDiscount = ProductPriceCouponDiscountOneOf | null;

export type ProductPriceBaseUnit = ProductPriceBaseUnitOneOf | null;

export type ProductPriceBaseUnitOneOfPricePerBaseUnit = number | null;

export type ProductPriceBaseUnitOneOfBaseUnitOfMeasure = string | null;

export type ProductPriceBaseUnitOneOf = {
  baseUnitOfMeasure: ProductPriceBaseUnitOneOfBaseUnitOfMeasure;
  pricePerBaseUnit: ProductPriceBaseUnitOneOfPricePerBaseUnit;
};

export type ProductPriceAdditionalPriceInfoOneOfStyle =
  (typeof ProductPriceAdditionalPriceInfoOneOfStyle)[keyof typeof ProductPriceAdditionalPriceInfoOneOfStyle];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductPriceAdditionalPriceInfoOneOfStyle = {
  beforeprice: 'beforeprice',
  singlepieceprice: 'singlepieceprice',
} as const;

/**
 * additional price information to show.
 */
export type ProductPriceAdditionalPriceInfoOneOf = {
  style: ProductPriceAdditionalPriceInfoOneOfStyle;
  text: string;
};

export type ProductPriceAdditionalPriceInfo = ProductPriceAdditionalPriceInfoOneOf | null;

/**
 * resource identifier not originating from Drupal
 */
export type ExternalId = string;

export type TextParagraphAllOfParagraphType =
  (typeof TextParagraphAllOfParagraphType)[keyof typeof TextParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextParagraphAllOfParagraphType = {
  Text: 'Text',
} as const;

/**
 * the background color
 */
export type TextParagraphAllOfBackgroundColor = string | null;

/**
 * The WYSIWYG html output
 */
export type TextParagraphAllOf = {
  /** the background color */
  backgroundColor: TextParagraphAllOfBackgroundColor;
  paragraphType: TextParagraphAllOfParagraphType;
  value: string;
};

export type TextParagraph = ParagraphBase & TextParagraphAllOf;

export type AccordionParagraphAllOfParagraphType =
  (typeof AccordionParagraphAllOfParagraphType)[keyof typeof AccordionParagraphAllOfParagraphType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccordionParagraphAllOfParagraphType = {
  Accordion: 'Accordion',
} as const;

/**
 * html anchor that can also be used for filtering with the getTermsAndConditions endpoint.
 */
export type AccordionParagraphAllOfItemsItemAnchor = string | null;

export type AccordionParagraphAllOfItemsItem = {
  /** html anchor that can also be used for filtering with the getTermsAndConditions endpoint. */
  anchor: AccordionParagraphAllOfItemsItemAnchor;
  content: string;
  title: string;
};

export type AccordionParagraphAllOf = {
  allowOpeningMultiple: boolean;
  items: AccordionParagraphAllOfItemsItem[];
  paragraphType: AccordionParagraphAllOfParagraphType;
};

export type ParagraphBase = {
  config?: ParagraphBaseConfig;
};

export type AccordionParagraph = ParagraphBase & AccordionParagraphAllOf;

export type ParagraphConfigWhitespace = (typeof ParagraphConfigWhitespace)[keyof typeof ParagraphConfigWhitespace];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParagraphConfigWhitespace = {
  none: 'none',
  normal: 'normal',
  medium: 'medium',
  large: 'large',
} as const;

export type ParagraphConfig = {
  whitespace?: ParagraphConfigWhitespace;
};

export type ParagraphBaseConfig = ParagraphConfig | null;

export type ExternalVideoMediaType = (typeof ExternalVideoMediaType)[keyof typeof ExternalVideoMediaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalVideoMediaType = {
  MediaExternalVideo: 'MediaExternalVideo',
} as const;

export type ExternalVideoMedia = {
  type: ExternalVideoMediaType;
  url: Url;
};

/**
 * DateTime normalized as RFC3339
 */
export type DateTime = string;

/**
 * short summary used for some of the teasers on category overviews
 */
export type BlogPostTeaserSummary = string | null;

export type BlogPostTeaserImageTeaser = ImageMedia | null;

/**
 * Type of blog entry
 */
export type BlogPostType = (typeof BlogPostType)[keyof typeof BlogPostType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlogPostType = {
  Inspiration: 'Inspiration',
  ProductChoices: 'ProductChoices',
  Guidance: 'Guidance',
} as const;

export type BlogPostTeaser = {
  blogPostTypes: BlogPostType[];
  id: Uuid;
  imageTeaser: BlogPostTeaserImageTeaser;
  /** short summary used for some of the teasers on category overviews */
  summary: BlogPostTeaserSummary;
  title: string;
  url: Url;
};

export type StoreFullAllOf = {
  country: StoreFullAllOfCountry;
  dataType?: StoreFullAllOfDataType;
  email: StoreFullAllOfEmail;
  fax: StoreFullAllOfFax;
  hasPayAndCollect: boolean;
  imageUrl: StoreFullAllOfImageUrl;
  openingHours: StoreFullAllOfOpeningHours;
  openingHoursNote: StoreFullAllOfOpeningHoursNote;
  salesorg: number;
  storePhone: StoreFullAllOfStorePhone;
  streetSupplement: StoreFullAllOfStreetSupplement;
  telephone: StoreFullAllOfTelephone;
  url: Url;
};

export type StoreFull = StoreBase & StoreFullAllOf;

export type StoreFullAllOfTelephone = string | null;

export type StoreFullAllOfStreetSupplement = string | null;

export type StoreFullAllOfStorePhone = string | null;

export type StoreFullAllOfOpeningHoursNote = string | null;

export type StoreFullAllOfOpeningHoursOneOfItem = {
  /** weekday number, starting with Sunday=0. */
  day: number;
  /** closing time, represented as >= 4 digit integer, 'Hmm' annotation. */
  endHours: number;
  /** opening time, represented as >= 3 digit integer, 'Hmm' annotation. */
  startHours: number;
};

export type StoreFullAllOfOpeningHours = StoreFullAllOfOpeningHoursOneOfItem[] | null;

export type StoreFullAllOfImageUrl = Url | null;

export type StoreFullAllOfFax = string | null;

export type StoreFullAllOfEmail = string | null;

export type StoreFullAllOfDataType = (typeof StoreFullAllOfDataType)[keyof typeof StoreFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreFullAllOfDataType = {
  Store: 'Store',
} as const;

export type StoreFullAllOfCountry = string | null;

/**
 * The timezone for the opening hours.
 */
export type StoreBaseTimezone = string | null;

export type StoreBaseNumber = string | null;

export type StoreBaseNameSupplement = string | null;

export type StoreBase = {
  city: string;
  hasClickAndCollect: boolean;
  hasLendATrailer: boolean;
  /** whether the store has an outlet department or not. */
  hasOutlet: boolean;
  /** Store id */
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  nameSupplement: StoreBaseNameSupplement;
  number: StoreBaseNumber;
  street: string;
  /** The timezone for the opening hours. */
  timezone: StoreBaseTimezone;
  zipCode: string;
};

export type ConfigurationFeatureFlags = {
  /** Enable B2B customer creation */
  enableB2BCustomerCreation: boolean;
  /** Enable B2B customer login and checkout */
  enableB2BCustomerLoginAndCheckout: boolean;
  /** Enable Black Friday theme */
  enableBlackFridayTheme: boolean;
  /** Enable chat */
  enableChat: boolean;
  /** Enable Click & Collect */
  enableClickCollect: boolean;
  /** Enable coupon redeem */
  enableCouponRedeem: boolean;
  /** Enable autocomplete for customer address ZIP code field */
  enableCustomerAddressAutocompleteZipCode: boolean;
  /** Enable file upload on FAQ */
  enableFileUploadOnFAQ: boolean;
  /** Enable giftcard */
  enableGiftcard: boolean;
  /** Enable giftcard redeem */
  enableGiftcardRedeem: boolean;
  /** Enable newsletter */
  enableNewsletter: boolean;
  /** Enable online sales */
  enableOnlineSales: boolean;
  /** Enable optional firstname for newsletter */
  enableOptionalFirstnameNewsletter: boolean;
  /** Enable product customer reviews */
  enableProductCustomerReviews: boolean;
  /** Show agreement checkbox for newsletter */
  showAgreementCheckboxNewsletter: boolean;
  /** Show delivery price terms on products */
  showDeliveryPriceTermsOnProducts: boolean;
  /** Show mandatory terms checkbox for newsletter */
  showMandatoryTermsCheckboxNewsletter: boolean;
  /** Show next ATP date */
  showNextATPDate: boolean;
  /** Show online stock */
  showOnlineStock: boolean;
  /** Show store stock on product cards */
  showStockStatusOnProductCards: boolean;
  /** Show store stock */
  showStoreStock: boolean;
};

export type Configuration = {
  featureFlags: ConfigurationFeatureFlags;
};

export type BlogPostTypeFull = {
  id: string;
  numberOfBlogPosts: number;
};

export type BlogPostCategoryFull = BlogPostCategoryTeaser & BlogPostCategoryFullAllOf;

export type BlogPostCategoryFullAllOfDescription = string | null;

export type BlogPostCategoryFullAllOfDataType =
  (typeof BlogPostCategoryFullAllOfDataType)[keyof typeof BlogPostCategoryFullAllOfDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BlogPostCategoryFullAllOfDataType = {
  BlogPostCategories: 'BlogPostCategories',
} as const;

/**
 * @deprecated
 */
export type BlogPostCategoryFullAllOfCategories = BlogPostCategoryTeaser[] | null;

export type BlogPostCategoryFullAllOf = {
  /** @deprecated */
  categories: BlogPostCategoryFullAllOfCategories;
  dataType: BlogPostCategoryFullAllOfDataType;
  description: BlogPostCategoryFullAllOfDescription;
  image: BlogPostCategoryFullAllOfImage;
  isTopLevelCategory: boolean;
};

export type ImageMediaType = (typeof ImageMediaType)[keyof typeof ImageMediaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageMediaType = {
  Image: 'Image',
} as const;

/**
 * Image Media Entity
 */
export type ImageMedia = {
  alt: string;
  dimensions: ImageMediaDimensions;
  title: string;
  type?: ImageMediaType;
  url: Url;
};

export type BlogPostCategoryFullAllOfImage = ImageMedia | null;

/**
 * the height of an element in px
 */
export type Height = number;

/**
 * the width of an element in px
 */
export type Width = number;

/**
 * dimensions of a (2D) media object in px
 */
export type MediaDimensions = {
  height: Height;
  width: Width;
};

export type ImageMediaDimensions = MediaDimensions | null;

/**
 * Properties for pagination controls.
 */
export type Page = {
  /**
   * Number of elements in current page. Less than or equal to 'size', unless size is 0.
   * @minimum 0
   */
  numberOfElements: number;
  /**
   * Page number, starting with 0.
   * @minimum 0
   */
  pageNumber: number;
  /**
   * Requested max number of elements per page. 0 means all elements.
   * @minimum 0
   */
  pageSize: number;
  /**
   * Total elements matching the query.
   * @minimum 0
   */
  totalElements: number;
  /**
   * Total resulting pages, basically ceil(totalElements/size) if size>0, otherwise 0 or 1.
   * @minimum 0
   */
  totalPages: number;
};

export type BlogPostCategoryTeaserIcon = Icon | null;

export type BlogPostCategoryTeaserChildren = BlogPostCategoryTeaser[] | null;

/**
 * a url following the web standards
 */
export type Url = string;

export type BlogPostCategoryTeaser = {
  children: BlogPostCategoryTeaserChildren;
  icon: BlogPostCategoryTeaserIcon;
  id: Uuid;
  title: string;
  url: Url;
};

/**
 * JYSK icon identifier. There are lots of them, this list is very incomplete. We'd very much like this list to be auto generated from the icon system.
 */
export type Icon = (typeof Icon)[keyof typeof Icon];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Icon = {
  '360-marker': '360-marker',
  'add-store': 'add-store',
  'add-to-basket': 'add-to-basket',
  'add-user': 'add-user',
  add: 'add',
  administration: 'administration',
  approvedwebshop: 'approvedwebshop',
  'arrow-down': 'arrow-down',
  'arrow-left': 'arrow-left',
  'arrow-right': 'arrow-right',
  'arrow-up-round': 'arrow-up-round',
  'arrow-up': 'arrow-up',
  b2b: 'b2b',
  barcode: 'barcode',
  'basket-shopping-bag': 'basket-shopping-bag',
  bathroom: 'bathroom',
  bedroom: 'bedroom',
  bell: 'bell',
  calendar: 'calendar',
  call: 'call',
  'campaign-paper': 'campaign-paper',
  campaign: 'campaign',
  'carry-inside': 'carry-inside',
  'chat-blog': 'chat-blog',
  check: 'check',
  'checkmark-round': 'checkmark-round',
  checkmark: 'checkmark',
  christmas: 'christmas',
  'circle-completed': 'circle-completed',
  'circle-uncompleted': 'circle-uncompleted',
  'clearance-history': 'clearance-history',
  'clearance-list-or-campaign': 'clearance-list-or-campaign',
  'click-and-collect': 'click-and-collect',
  'close-thin': 'close-thin',
  close: 'close',
  complaint: 'complaint',
  'counting-archive': 'counting-archive',
  cross: 'cross',
  'customer-orders': 'customer-orders',
  'customer-service': 'customer-service',
  danish: 'danish',
  delete: 'delete',
  delivery: 'delivery',
  diningroom: 'diningroom',
  'discount-code': 'discount-code',
  'edit-information': 'edit-information',
  'edit-pencil': 'edit-pencil',
  edit: 'edit',
  edlp: 'edlp',
  email: 'email',
  error: 'error',
  'external-link': 'external-link',
  eye: 'eye',
  facebook: 'facebook',
  'file-empty': 'file-empty',
  filter: 'filter',
  'flexible-delivery-options': 'flexible-delivery-options',
  'giftcard-birthday': 'giftcard-birthday',
  globe: 'globe',
  'goods-issue': 'goods-issue',
  'goods-reception-dc': 'goods-reception-dc',
  'goods-reception-history': 'goods-reception-history',
  'goods-reception-other': 'goods-reception-other',
  heart_fill: 'heart_fill',
  heart: 'heart',
  help: 'help',
  home: 'home',
  housewares: 'housewares',
  'in-stock': 'in-stock',
  info: 'info',
  inspiration: 'inspiration',
  instagram: 'instagram',
  'jysk-selection': 'jysk-selection',
  'jysk-splash': 'jysk-splash',
  'kebab-menu': 'kebab-menu',
  linkedin: 'linkedin',
  livingroom: 'livingroom',
  login: 'login',
  marker: 'marker',
  menu: 'menu',
  messenger: 'messenger',
  'minus-list': 'minus-list',
  'no-internet': 'no-internet',
  'no-results': 'no-results',
  nordic: 'nordic',
  office: 'office',
  'on-display': 'on-display',
  'online-banking': 'online-banking',
  oopus: 'oopus',
  'open-orders': 'open-orders',
  'order-history': 'order-history',
  'out-of-stock': 'out-of-stock',
  outdoor: 'outdoor',
  outlet: 'outlet',
  'payment-method': 'payment-method',
  permission: 'permission',
  phone: 'phone',
  pinterest: 'pinterest',
  'play-circle': 'play-circle',
  play: 'play',
  pricematch: 'pricematch',
  print: 'print',
  profile: 'profile',
  'question-circle': 'question-circle',
  receipt: 'receipt',
  return: 'return',
  'safe-webshop': 'safe-webshop',
  'search-error': 'search-error',
  search: 'search',
  'sleep-expert-cn': 'sleep-expert-cn',
  spinner: 'spinner',
  star: 'star',
  stardeals: 'stardeals',
  'stocktaking-archive': 'stocktaking-archive',
  stocktaking: 'stocktaking',
  storage: 'storage',
  stores: 'stores',
  telegram: 'telegram',
  trophy: 'trophy',
  twitter: 'twitter',
  'unlimited-return': 'unlimited-return',
  vk: 'vk',
  'w3-360-marker': 'w3-360-marker',
  'w3-add': 'w3-add',
  'w3-administration': 'w3-administration',
  'w3-arrow-down': 'w3-arrow-down',
  'w3-arrow-left': 'w3-arrow-left',
  'w3-arrow-right': 'w3-arrow-right',
  'w3-arrow-up': 'w3-arrow-up',
  'w3-b2b': 'w3-b2b',
  'w3-bank': 'w3-bank',
  'w3-basket': 'w3-basket',
  'w3-bell': 'w3-bell',
  'w3-calendar': 'w3-calendar',
  'w3-campaign-paper': 'w3-campaign-paper',
  'w3-campaign': 'w3-campaign',
  'w3-chat': 'w3-chat',
  'w3-checkmark': 'w3-checkmark',
  'w3-circle-completed': 'w3-circle-completed',
  'w3-click-and-collect': 'w3-click-and-collect',
  'w3-close': 'w3-close',
  'w3-complaint': 'w3-complaint',
  'w3-customer-service': 'w3-customer-service',
  'w3-delete': 'w3-delete',
  'w3-delivery': 'w3-delivery',
  'w3-download': 'w3-download',
  'w3-edit': 'w3-edit',
  'w3-email': 'w3-email',
  'w3-error': 'w3-error',
  'w3-external-link': 'w3-external-link',
  'w3-file': 'w3-file',
  'w3-filter': 'w3-filter',
  'w3-flag': 'w3-flag',
  'w3-flash-off': 'w3-flash-off',
  'w3-flash-on': 'w3-flash-on',
  'w3-giftcard-birthday': 'w3-giftcard-birthday',
  'w3-globe': 'w3-globe',
  'w3-help': 'w3-help',
  'w3-hide': 'w3-hide',
  'w3-home': 'w3-home',
  'w3-info': 'w3-info',
  'w3-inspiration': 'w3-inspiration',
  'w3-marker': 'w3-marker',
  'w3-menu': 'w3-menu',
  'w3-on-display': 'w3-on-display',
  'w3-order-history': 'w3-order-history',
  'w3-payment-method': 'w3-payment-method',
  'w3-permission': 'w3-permission',
  'w3-phone': 'w3-phone',
  'w3-play': 'w3-play',
  'w3-profile': 'w3-profile',
  'w3-receipt': 'w3-receipt',
  'w3-return': 'w3-return',
  'w3-safe-webshop': 'w3-safe-webshop',
  'w3-search': 'w3-search',
  'w3-show': 'w3-show',
  'w3-star-filled': 'w3-star-filled',
  'w3-star': 'w3-star',
  'w3-stores': 'w3-stores',
  'w3-success': 'w3-success',
  'w3-tag': 'w3-tag',
  'w3-warning': 'w3-warning',
  'w3-warranty': 'w3-warranty',
  'w3-wishlist-filled': 'w3-wishlist-filled',
  'w3-wishlist': 'w3-wishlist',
  'w3-zoom-in': 'w3-zoom-in',
  'w3-zoom-out': 'w3-zoom-out',
  warranty: 'warranty',
  whatsapp: 'whatsapp',
  window: 'window',
  wishlist: 'wishlist',
  youtube: 'youtube',
  yuan: 'yuan',
  'zoom-in': 'zoom-in',
  'zoom-out': 'zoom-out',
} as const;

/**
 * uuid (32 hex digits separated by four hyphens in groups of 8-4-4-4-12)
 */
export type Uuid = string;

export type ErrorResponse = {
  error: string;
};

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get blog post categories
 */
export const getBlogCategories = (
  params?: GetBlogCategoriesParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetBlogCategories200>(
    { url: `/categories/blog-post-categories`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Get single blog post category
 */
export const getBlogCategory = (
  categoryId: string,
  params?: GetBlogCategoryParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetBlogCategory200>(
    { url: `/categories/blog-post-categories/${categoryId}`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Get blog post types
 */
export const getBlogTypes = (params?: GetBlogTypesParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetBlogTypes200>({ url: `/categories/blog-post-types`, method: 'GET', params }, options);
};

/**
 * @summary Return configuration
 */
export const getConfiguration = (options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetConfiguration200>({ url: `/configuration`, method: 'GET' }, options);
};

/**
 * @summary Get list of stores
 */
export const getStores = (params?: GetStoresParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetStores200>({ url: `/content/stores`, method: 'GET', params }, options);
};

/**
 * @summary Get single store
 */
export const getStore = (
  storeId: string,
  params?: GetStoreParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetStore200>({ url: `/content/stores/${storeId}`, method: 'GET', params }, options);
};

/**
 * @summary Get all blog posts
 */
export const getBlogPosts = (params?: GetBlogPostsParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetBlogPosts200>({ url: `/content/blog-posts`, method: 'GET', params }, options);
};

/**
 * @summary Get single blog post
 */
export const getBlogPost = (
  blogPostId: string,
  params?: GetBlogPostParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetBlogPost200>({ url: `/content/blog-posts/${blogPostId}`, method: 'GET', params }, options);
};

/**
 * @summary Get products by filters or param
 */
export const getProducts = (params?: GetProductsParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetProducts200>({ url: `/content/products`, method: 'GET', params }, options);
};

/**
 * @summary Get single product
 */
export const getProduct = (
  productId: string,
  params?: GetProductParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetProduct200>({ url: `/content/products/${productId}`, method: 'GET', params }, options);
};

/**
 * @summary Get embedded contents
 */
export const getEmbeddedContent = (
  params?: GetEmbeddedContentParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetEmbeddedContent200>({ url: `/content/embedded-contents`, method: 'GET', params }, options);
};

/**
 * @summary Get campaign bar
 */
export const getCampaignBar = (params?: GetCampaignBarParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetCampaignBar200>({ url: `/content/info-bars/campaign`, method: 'GET', params }, options);
};

/**
 * @summary Get General notice bar
 */
export const getGeneralNotice = (
  params?: GetGeneralNoticeParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetGeneralNotice200>(
    { url: `/content/info-bars/general-notice`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Get menus by query
 */
export const getNavigationMenus = (
  params?: GetNavigationMenusParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetNavigationMenus200>({ url: `/navigation/menus`, method: 'GET', params }, options);
};

/**
 * @summary Get single menu by query
 */
export const getNavigationMenu = (
  menuName: string,
  params?: GetNavigationMenuParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetNavigationMenu200>(
    { url: `/navigation/menus/${menuName}`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Get header and footer
 */
export const getHeaderFooter = (params?: GetHeaderFooterParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetHeaderFooter200>({ url: `/navigation/header-footer`, method: 'GET', params }, options);
};

/**
 * @summary get coordinates for given address using geocoding service
 */
export const getGeocode = (params: GetGeocodeParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetGeocode200>({ url: `/service/geocoding`, method: 'GET', params }, options);
};

/**
 * @summary Translates an incoming path into a formatted response.
 */
export const getPage = (params?: GetPageParams, options?: SecondParameter<typeof drupalApiClient>) => {
  return drupalApiClient<GetPage200>({ url: `/router/translate-path`, method: 'GET', params }, options);
};

/**
 * @summary Get terms and conditions file
 */
export const getTermsAndConditionsFile = (
  params?: GetTermsAndConditionsFileParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetTermsAndConditionsFile200>(
    { url: `/terms-and-conditions`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Get products export file
 */
export const getProductsExportFile = (
  params?: GetProductsExportFileParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetProductsExportFile200>({ url: `/exports/products`, method: 'GET', params }, options);
};

/**
 * @summary Expose data to be indexed by the search engine.
 */
export const getSearchUpdatedData = (
  type: 'blog_post' | 'product' | 'product_categories' | 'page' | 'store' | 'structural_page' | 'faq',
  params?: GetSearchUpdatedDataParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetSearchUpdatedData200>({ url: `/search/updated/${type}`, method: 'GET', params }, options);
};

/**
 * @summary Expose data to be removed from the index by the search engine.
 */
export const getSearchDeletedData = (
  type: 'blog_post' | 'product' | 'product_categories' | 'page' | 'store' | 'structural_page' | 'faq',
  params?: GetSearchDeletedDataParams,
  options?: SecondParameter<typeof drupalApiClient>,
) => {
  return drupalApiClient<GetSearchDeletedData200>({ url: `/search/deleted/${type}`, method: 'GET', params }, options);
};

export type GetBlogCategoriesResult = NonNullable<Awaited<ReturnType<typeof getBlogCategories>>>;
export type GetBlogCategoryResult = NonNullable<Awaited<ReturnType<typeof getBlogCategory>>>;
export type GetBlogTypesResult = NonNullable<Awaited<ReturnType<typeof getBlogTypes>>>;
export type GetConfigurationResult = NonNullable<Awaited<ReturnType<typeof getConfiguration>>>;
export type GetStoresResult = NonNullable<Awaited<ReturnType<typeof getStores>>>;
export type GetStoreResult = NonNullable<Awaited<ReturnType<typeof getStore>>>;
export type GetBlogPostsResult = NonNullable<Awaited<ReturnType<typeof getBlogPosts>>>;
export type GetBlogPostResult = NonNullable<Awaited<ReturnType<typeof getBlogPost>>>;
export type GetProductsResult = NonNullable<Awaited<ReturnType<typeof getProducts>>>;
export type GetProductResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>;
export type GetEmbeddedContentResult = NonNullable<Awaited<ReturnType<typeof getEmbeddedContent>>>;
export type GetCampaignBarResult = NonNullable<Awaited<ReturnType<typeof getCampaignBar>>>;
export type GetGeneralNoticeResult = NonNullable<Awaited<ReturnType<typeof getGeneralNotice>>>;
export type GetNavigationMenusResult = NonNullable<Awaited<ReturnType<typeof getNavigationMenus>>>;
export type GetNavigationMenuResult = NonNullable<Awaited<ReturnType<typeof getNavigationMenu>>>;
export type GetHeaderFooterResult = NonNullable<Awaited<ReturnType<typeof getHeaderFooter>>>;
export type GetGeocodeResult = NonNullable<Awaited<ReturnType<typeof getGeocode>>>;
export type GetPageResult = NonNullable<Awaited<ReturnType<typeof getPage>>>;
export type GetTermsAndConditionsFileResult = NonNullable<Awaited<ReturnType<typeof getTermsAndConditionsFile>>>;
export type GetProductsExportFileResult = NonNullable<Awaited<ReturnType<typeof getProductsExportFile>>>;
export type GetSearchUpdatedDataResult = NonNullable<Awaited<ReturnType<typeof getSearchUpdatedData>>>;
export type GetSearchDeletedDataResult = NonNullable<Awaited<ReturnType<typeof getSearchDeletedData>>>;
