'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@wla/components/ui/alert';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { Select } from '@wla/components/ui/forms/select';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { TextArea } from '@wla/components/ui/forms/textArea';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useRef, useState } from 'react';
import { useFormState } from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import {
  ParagraphContactFormOrderReturnsAndClaimsSchema,
  ParagraphReturnsAndClaimsData,
} from './paragraph-contact-form-order-returns-and-claims-schema';

const initialState = {
  success: false,
  message: '',
};

export function ParagraphContactFormOrderReturnsAndClaims() {
  const t = useTranslations();
  const { recaptchaKey, defaultLocale } = usePublicConfig();
  const [receivedMyOrder, setReceivedMyOrder] = useState('');
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [messageLength, setMessageLength] = useState(0);
  const language = defaultLocale.substring(3, 5);
  const prepareData = (formData: FormData) => {
    const baseData = {
      toAddress: `cs_${language}_shipment@jysk.com`,
      firstName: formData.get('customerName'),
      lastName: formData.get('customerName'),
      orderNumber: formData.get('orderNumber'),
      email: formData.get('email'),
      phoneNumber: formData.get('phoneNumber'),
      reasonForReturn: formData.get('reasonForReturn'),
      receivedOn: formData.get('receivedOn'),
      receivedMyOrder: formData.get('receivedMyOrder'),
      orderOn: formData.get('orderOn'),
      date: formData.get('date'),
      cancelMyOrder: formData.get('cancelMyOrder'),
      customerName: formData.get('customerName'),
      customerAddress: formData.get('customerAddress'),
      subject: 'Withdrawal Form',
    };

    const message = `
      ${t('forms.claims-message')}:
      \n ${t('forms.message')}: ${formData.get('message')}
      \n ${t('forms.reason-for-return')}: ${formData.get('reasonForReturn')}
      \n ${t('forms.order-number')}: ${formData.get('orderNumber')}
      \n ${t('forms.received-on')}: ${formData.get('receivedOn') || ''}
      ${t('forms.received-my-order') ? `\n ${t('forms.received-my-order')}: ${formData.get('receivedMyOrder')}` : ''}
      \n ${t('forms.ordered-on')}: ${formData.get('orderOn')}
      \n ${t('forms.date')}: ${formData.get('date')}
      ${formData.get('cancelMyOrder') ? `\n ${t('forms.cancel-my-order')}: ${formData.get('cancelMyOrder')}` : ''}
      \n ${t('forms.company.company-customer-name')}: ${formData.get('customerName')}
      \n ${t('forms.company.company-customer-address')}: ${formData.get('customerAddress')}
    `;

    return { ...baseData, message };
  };
  const [result, formAction] = useFormState(async (prevState: any, formData: FormData) => {
    const validateData = prepareData(formData);

    const result = ParagraphContactFormOrderReturnsAndClaimsSchema.safeParse(validateData);

    if (result.success) {
      try {
        const formData = new FormData();
        formData.append('data', JSON.stringify(validateData));
        const response = await fetch('/api/post-sales-force-form', {
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          return { success: true, message: t('customer-service.email-success') };
        }
      } catch (error) {
        return { success: false, message: t('forms.errors.something-went-wrong') };
      }
    }
    return { success: false, message: t('forms.errors.something-went-wrong') };
  }, initialState);

  const form = useForm<ParagraphReturnsAndClaimsData>({
    mode: 'all',
    resolver: zodResolver(ParagraphContactFormOrderReturnsAndClaimsSchema),
  });
  const {
    register,
    formState: { errors },
  } = form;

  function handleOnMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setMessageLength(event.target.value.length);
  }

  return (
    <Form form={form} action={formAction} className={cn('mt-4 flex h-auto flex-col gap-3')}>
      <FormGroup
        label={t('forms.message')}
        inputType={InputTypes.TextArea}
        helpText={t('forms.claims-message')}
        required
        validationError={errors.message?.message ? t('forms.errors.required.message-is-required') : ''}
      >
        <TextArea
          {...register('message')}
          counter={messageLength}
          onChange={handleOnMessageChange}
          maxLength={1000}
          className="h-40"
          resize="none"
        />
      </FormGroup>
      <FormGroup
        inputType={InputTypes.Select}
        required
        validationError={
          errors.reasonForReturn?.message ? t('forms.errors.required.reason-for-return-is-required') : ''
        }
        className="flex-1"
      >
        <Select arrowDirection="down" defaultValue={t('forms.reason-for-return')} {...register('reasonForReturn')}>
          <option>{t('forms.reason-for-return')}</option>
          <option value="A">{t('forms.reason-for-return-a')}</option>
          <option value="B">{t('forms.reason-for-return-b')}</option>
          <option value="C">{t('forms.reason-for-return-c')}</option>
          <option value="D">{t('forms.reason-for-return-d')}</option>
          <option value="E">{t('forms.reason-for-return-e')}</option>
          <option value="F">{t('forms.reason-for-return-f')}</option>
          <option value="G">{t('forms.reason-for-return-g')}</option>
        </Select>
      </FormGroup>
      <FormGroup
        label={t('forms.order-number')}
        required
        inputType={InputTypes.Input}
        validationError={errors.orderNumber?.message ? t('forms.errors.required.order-number-is-required') : ''}
        className="flex-1"
      >
        <Input {...register('orderNumber')} type="number" />
      </FormGroup>
      <FormGroup
        label={t('forms.mobile-number')}
        required
        inputType={InputTypes.Input}
        validationError={errors.phoneNumber?.message ? t('forms.errors.required.phone-number-is-required') : ''}
        className="flex-1"
      >
        <Input {...register('phoneNumber')} />
      </FormGroup>
      <FormGroup
        label={t('forms.ordered-on')}
        required
        inputType={InputTypes.Date}
        validationError={errors.orderOn?.message ? t('forms.errors.required.ordered-on-is-required') : ''}
        className="flex-1"
      >
        <Input type="date" {...register('orderOn')} />
      </FormGroup>
      <FormGroup
        inputType={InputTypes.Select}
        required
        validationError={
          errors.receivedMyOrder?.message ? t('forms.errors.required.received-my-order-is-required') : ''
        }
        className="flex-1"
      >
        <Select
          arrowDirection="down"
          defaultValue={t('forms.received-my-order')}
          {...register('receivedMyOrder')}
          onChange={(e) => setReceivedMyOrder(e.target.value)}
        >
          <option>{t('forms.received-my-order')}</option>
          <option>{t('forms.received-my-order-yes')}</option>
          <option>{t('forms.received-my-order-no')}</option>
        </Select>
      </FormGroup>
      {receivedMyOrder === t('forms.received-my-order-yes') && (
        <FormGroup
          label={t('forms.received-on')}
          required
          inputType={InputTypes.Date}
          validationError={errors.orderOn?.message ? t('forms.errors.required.received-on-is-required') : ''}
          className="flex-1"
        >
          <Input type="date" {...register('receivedOn')} />
        </FormGroup>
      )}
      {receivedMyOrder === t('forms.received-my-order-no') && (
        <FormGroup
          inputType={InputTypes.Select}
          required
          validationError={
            errors.receivedMyOrder?.message ? t('forms.errors.required.cancel-my-order-is-required') : ''
          }
          className="flex-1"
        >
          <Select arrowDirection="down" defaultValue={t('forms.cancel-my-order')} {...register('cancelMyOrder')}>
            <option>{t('forms.cancel-my-order')}</option>
            <option>{t('forms.cancel-my-order-yes')}</option>
            <option>{t('forms.cancel-my-order-no')}</option>
          </Select>
        </FormGroup>
      )}
      <FormGroup
        label={t('forms.company.company-customer-name')}
        required
        inputType={InputTypes.Input}
        validationError={
          errors.customerName?.message ? t('forms.errors.required.company-customer-name-is-required') : ''
        }
        className="flex-1"
      >
        <Input {...register('customerName')} />
      </FormGroup>
      <FormGroup
        label={t('forms.company.company-customer-address')}
        required
        inputType={InputTypes.Input}
        validationError={
          errors.customerAddress?.message ? t('forms.errors.required.company-customer-address-is-required') : ''
        }
        className="flex-1"
      >
        <Input {...register('customerAddress')} />
      </FormGroup>
      <FormGroup
        label={t('forms.date')}
        required
        inputType={InputTypes.Date}
        validationError={errors.date?.message ? t('forms.errors.required.date-is-required') : ''}
        className="flex-1"
      >
        <Input type="date" {...register('date')} />
      </FormGroup>
      <FormGroup
        label={t('forms.email')}
        required
        inputType={InputTypes.Email}
        validationError={errors.email?.message ? t('forms.errors.not-valid.email-not-valid') : ''}
        className="flex-1"
      >
        <Input {...register('email')} />
      </FormGroup>

      <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} size="normal" badge="inline" />
      <SubmitButton translate="no" variant="primary">
        {t('customer-service.send-email-submit')}
      </SubmitButton>
      {result.message && (
        <Alert className="mt-4" type={result.success ? 'success' : 'warning'}>
          {result.message}
        </Alert>
      )}
    </Form>
  );
}
