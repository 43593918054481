'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from '@wla/components/ui/button/button';
import { Dialog } from '@wla/components/ui/dialog';
import { Icon } from '@wla/components/ui/icon';
import { useTranslations } from 'next-intl';

type MaximumQuantityModalProps = {
  leftQuantity: number; // stock minus already in the basket
  atp?: string;
  showButton?: boolean;
  onClick?: (atp: number) => void;
};

export const MaximumQuantityModal = NiceModal.create(
  ({ leftQuantity, atp, showButton, onClick }: MaximumQuantityModalProps) => {
    const t = useTranslations('pdp');
    const modal = useModal();

    function handleClose() {
      modal.resolve(false);
      modal.hide();
    }

    return (
      <Dialog open={modal.visible} onClose={handleClose} isModal>
        <Dialog.Panel>
          <div className="relative mb-1 h-5">
            <Dialog.Close />
          </div>
          <Dialog.Header>
            <Dialog.Title>
              <div className="top-8 mb-4 flex justify-center">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
                  <Icon size="20" src="w3-error" className="font-normal" />
                </div>
              </div>
            </Dialog.Title>
          </Dialog.Header>
          <Dialog.Body className="flex flex-col items-center gap-4">
            <p>{t('max-quantity-warning-left-in-stock', { atp: leftQuantity === 0 ? atp : leftQuantity })}</p>
            {showButton && (
              <Button
                size="medium"
                onClick={() => {
                  onClick?.(leftQuantity);
                  modal.resolve(true);
                  modal.hide();
                }}
              >
                {t('max-quantity-warning-buy-max-quantity-button', { atp: leftQuantity })}
              </Button>
            )}
          </Dialog.Body>
        </Dialog.Panel>
      </Dialog>
    );
  },
);
