'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@wla/components/ui/alert';
import { Drawer } from '@wla/components/ui/drawer';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { useTranslations } from 'next-intl';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import { shareFavouritesAction } from './share-favourites-action';
import { ShareFavouritesFormData, shareFavouritesSchema } from './share-favourites-schema';

const initialState = {
  success: false,
  message: '',
};

export const ShareFavouritesDrawer = NiceModal.create(() => {
  const t = useTranslations();
  const modal = useModal();
  const [result, formAction] = useFormState(shareFavouritesAction, initialState);
  const form = useForm<ShareFavouritesFormData>({
    mode: 'onBlur',
    resolver: zodResolver(shareFavouritesSchema),
  });

  const {
    register,
    formState: { errors },
    reset,
  } = form;

  function handleClose() {
    reset();
    result.message = '';
    result.success = false;
    modal.hide();
  }

  return (
    <Drawer open={modal.visible} onClose={handleClose}>
      <Drawer.Header title={t('customer.favourites.share-favourites')} />
      <Drawer.Body>
        {!result.success ? (
          <>
            <div className="pb-4">{t('customer.favourites.share-modal-body')}</div>
            <Form form={form} action={formAction}>
              <FormGroup
                label={t('forms.email')}
                inputType={InputTypes.Input}
                required
                validationError={errors.email?.message ? t('forms.errors.not-valid.email-not-valid') : ''}
              >
                <Input {...register('email')} className="mb-4" />
              </FormGroup>
              <FormGroup
                label={t('forms.name')}
                inputType={InputTypes.Input}
                required
                validationError={errors.name?.message ? t('forms.errors.required.name-is-required') : ''}
              >
                <Input {...register('name')} className="mb-4" />
              </FormGroup>
              <input type="hidden" name="shareUrl" value={window.location.origin} />
              <SubmitButton loadingMessage={t('common.loading')} className="w-full">
                {t('customer.favourites.share')}
              </SubmitButton>
            </Form>
          </>
        ) : (
          <Alert type={result.success ? 'success' : 'error'}>{result.message}</Alert>
        )}
      </Drawer.Body>
    </Drawer>
  );
});
