'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { createNewsletterSignUpB2BSchema } from '@wla/app/(cms)/newsletter/b2b/create-newsletter-signup-b2b-schema';
import { newsletterSignupB2BAction } from '@wla/app/(cms)/newsletter/b2b/newsletter-signup-b2b-actions';
import { NewsletterTermsAndConditions } from '@wla/app/(cms)/newsletter/terms-and-conditions';
import { Alert } from '@wla/components/ui/alert';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { Wrapper } from '@wla/components/ui/wrapper';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { trackNewsletterSignup } from '@wla/lib/tracking/google-analytics-tracking';
import { useTranslations } from 'next-intl';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import z from 'zod';

type NewsletterSignupB2BProps = {
  source: string;
  excludeXPadding?: boolean;
};

const initialState = {
  success: false,
  message: '',
};

export function NewsletterSignupB2B({ source, excludeXPadding = false }: NewsletterSignupB2BProps) {
  const t = useTranslations();
  const { featureFlags } = usePublicConfig();
  const [result, formAction] = useFormState(newsletterSignupB2BAction, initialState);

  const newsletterSignUpB2BSchema = createNewsletterSignUpB2BSchema(
    featureFlags.enableOptionalFirstnameNewsletter,
    featureFlags.showAgreementCheckboxNewsletter,
  );

  type NewsletterSignUpB2BData = z.infer<typeof newsletterSignUpB2BSchema>;

  const form = useForm<NewsletterSignUpB2BData>({
    mode: 'onSubmit',
    resolver: zodResolver(newsletterSignUpB2BSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  function onSubmit(data: NewsletterSignUpB2BData) {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('company', data.company);
    formData.append('email', data.email);
    formData.append('accept', data.accept);
    formData.append('source', source);
    formData.append('showAgreementCheckbox', featureFlags.enableOptionalFirstnameNewsletter ? 'true' : 'false');
    formData.append('isFirstNameOptional', featureFlags.showAgreementCheckboxNewsletter ? 'true' : 'false');
    formAction(formData);

    if (result.success) {
      trackNewsletterSignup(form.getValues().email, source, 'success');
    } else {
      trackNewsletterSignup(form.getValues().email, source, 'fail');
    }
  }

  if (!featureFlags.enableNewsletter) return;

  return (
    <Wrapper
      className={cn('py-11', {
        'px-0': excludeXPadding,
      })}
    >
      <div className="bg-blue-200 px-4 py-8 md:p-16">
        <div className="mx-auto max-w-3xl">
          <h3 className="mb-2 text-center text-xl font-semibold md:text-4xl">{t('newsletter.b2b.signup-headline')}</h3>
          <p className="mb-8 text-center text-lg">{t('newsletter.b2b.signup-text')}</p>

          {result.success && (
            <Alert className="mb-8" type="success">
              {t('newsletter.b2b.signup-success')}
            </Alert>
          )}

          {!result.success && result.message !== '' && (
            <Alert className="mb-8" type="error">
              {t('newsletter.error-mail-exist')}
            </Alert>
          )}
          {!result.success && (
            <Form form={form} action={formAction} onSubmit={handleSubmit(onSubmit)}>
              <div className="mx-auto mb-4 flex max-w-lg flex-col gap-4">
                <FormGroup
                  label={t('forms.company.company-name')}
                  inputType={InputTypes.Input}
                  required
                  validationError={errors.company?.message ? t('forms.errors.required.company-name-is-required') : ''}
                  className="flex-1"
                >
                  <Input {...register('company')} />
                </FormGroup>
                <FormGroup
                  label={t('forms.first-name')}
                  inputType={InputTypes.Input}
                  required={!featureFlags.enableOptionalFirstnameNewsletter}
                  validationError={errors.name?.message ? t('forms.errors.required.first-name-is-required') : ''}
                  className="flex-1"
                >
                  <Input {...register('name')} />
                </FormGroup>
                <FormGroup
                  label={t('forms.email')}
                  inputType={InputTypes.Input}
                  required
                  validationError={errors.email?.message && t('forms.errors.not-valid.email-not-valid')}
                >
                  <Input {...register('email')} />
                </FormGroup>
                <input type="hidden" name="source" />
                <input type="hidden" name="isFirstNameOptional" />
                <input type="hidden" name="showAgreementCheckbox" />

                {featureFlags.showAgreementCheckboxNewsletter && (
                  <div className="grid grid-flow-col text-sm">
                    <FormGroup
                      validationError={errors.accept?.message && t('common.please-accept-terms-and-conditions')}
                      inputType={InputTypes.Checkbox}
                      required
                    >
                      <Checkbox {...register('accept')} className="items-start">
                        {t.rich('newsletter.b2b.signup-consent', {
                          link1: () => (
                            <NewsletterTermsAndConditions
                              className="cursor-pointer text-blue-500 underline"
                              sectionId="newsletter"
                              triggerText={t('common.terms-and-conditions')}
                              title={t('common.terms-and-conditions')}
                            />
                          ),
                          link2: () => (
                            <NewsletterTermsAndConditions
                              className="cursor-pointer text-blue-500 underline"
                              sectionId="privacy"
                              triggerText={t('common.privacy-policy')}
                              title={t('common.terms-and-conditions')}
                            />
                          ),
                        })}
                      </Checkbox>
                    </FormGroup>
                  </div>
                )}
                <SubmitButton className="max-w-lg" variant="primary">
                  {t('common.sign-up')}
                </SubmitButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
