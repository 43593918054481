import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { StoreFull } from '@jysk/api-types/drupalApi';
import { StoreInfo } from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator';
import logo from '@wla/components/icons/static/jysk-map-pin.svg';
import { CustomOverlay } from '@wla/components/ui/maps/custom-overlay';
import { DEFAULT_ZOOM } from '@wla/components/ui/maps/google-maps';
import { AbstractIntlMessages } from 'next-intl';
import { createRoot } from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import { ClusterMarker } from './cluster-marker';

export let markersArray: google.maps.marker.AdvancedMarkerElement[] = [];
let markerCluster: MarkerClusterer | null = null;
let currentOverlay: { root: ReturnType<typeof createRoot>; element: HTMLDivElement } | null = null;

type GoogleMapsMarkersProps = {
  map: google.maps.Map;
  title: string;
  storeInfo?: StoreInfo[];
  focusedStore?: StoreFull;
  messages: AbstractIntlMessages;
};

export async function GoogleMapsMarkers({ map, title, storeInfo, focusedStore, messages }: GoogleMapsMarkersProps) {
  const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

  if (!storeInfo) {
    return [];
  }

  // Clear existing markers
  if (currentOverlay) {
    const { root, element } = currentOverlay;
    root.unmount();
    if (element.parentNode) {
      element.parentNode.removeChild(element);
    }
    currentOverlay = null;
  }
  markersArray.forEach((marker) => {
    marker.map = null;
  });

  markersArray = [];
  if (markerCluster) {
    markerCluster.clearMarkers();
    markerCluster = null;
  }

  storeInfo.map(({ lat, lng }, index) => {
    const markerImg = document.createElement('img');
    markerImg.src = logo.src;

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat, lng },
      content: markerImg,
      title,
    });

    marker.addListener('click', () => {
      // Close the current overlay if one is open
      if (currentOverlay) {
        const { root, element } = currentOverlay;
        root.unmount();
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
        currentOverlay = null;
      }

      // Calculate the new center to ensure the tooltip is visible on mobile
      const offsetLat = (150 / 2 / (256 * Math.pow(2, map.getZoom() ?? DEFAULT_ZOOM))) * 360;
      const newLat = lat + offsetLat;
      map.panTo({ lat: newLat, lng });

      // Render the CustomOverlay component when a marker is clicked
      const overlayElement = document.createElement('div');
      document.body.appendChild(overlayElement);

      const root = createRoot(overlayElement);
      function handleClose() {
        root.unmount();
        if (overlayElement.parentNode) {
          overlayElement.parentNode.removeChild(overlayElement);
        }
        currentOverlay = null;
      }

      root.render(
        <CustomOverlay
          position={{ lat, lng }}
          map={map}
          storeInfo={storeInfo[index]}
          onClose={handleClose}
          focusedStore={focusedStore}
          messages={messages}
        />,
      );

      currentOverlay = { root, element: overlayElement };
    });

    markersArray.push(marker);
    return marker;
  });

  // If there are multiple locations, cluster them
  if (storeInfo.length > 1) {
    const bounds = new google.maps.LatLngBounds();

    markerCluster = new MarkerClusterer({
      markers: markersArray,
      map,
      renderer: {
        render: (cluster, stats, map) => {
          const count = cluster.markers?.length || 0;
          const position = cluster.bounds?.getCenter();

          // Provide a fallback position if position is undefined
          const fallbackPosition = position || { lat: 0, lng: 0 };
          const clusterElement = ReactDOMServer.renderToString(<ClusterMarker count={count} />);
          const div = document.createElement('div');
          div.innerHTML = clusterElement;

          return new AdvancedMarkerElement({
            position: fallbackPosition,
            content: div,
            map,
          });
        },
      },
    });

    storeInfo.forEach((location) => bounds.extend(location));
    map.fitBounds(bounds);
  }

  // Focus and click the selected store on the details pages
  if (map && focusedStore && markersArray.length) {
    const storeLatLng = { lat: focusedStore.latitude, lng: focusedStore.longitude };
    map.setCenter(storeLatLng);
    map.setZoom(DEFAULT_ZOOM);

    const marker = markersArray.find(
      (m) => m.position?.lat === focusedStore.latitude && m.position.lng === focusedStore.longitude,
    );

    if (marker) {
      google.maps.event.trigger(marker, 'click');
    }
  }

  return markersArray;
}
