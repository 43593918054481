import { z } from 'zod';

export const ParagraphContactFormCustomerAssortmentSuggestionsSchema = z.object({
  email: z.string().email('forms.errors.not-valid.email-not-valid'),
  message: z
    .string()
    .min(1, 'forms.errors.required.message-is-required')
    .max(1000, 'forms.errors.required.message-is-required'),
});

export type ParagraphContactFormCustomerAssortmentSuggestionsData = z.infer<
  typeof ParagraphContactFormCustomerAssortmentSuggestionsSchema
>;
