import { convertHtmlToReact, convertNodeToReactElement } from '@hedgedoc/html-to-react';
import { convertTitleToId, extractText } from '@wla/components/ui/paragraph/paragraph-table-of-content';
import { cn } from '@wla/lib/helpers/cn';
import { Fragment, JSXElementConstructor, ReactElement, ReactNode, useMemo } from 'react';
import { Link } from './link';

function convertKebabToCamelCase(str: string) {
  return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}

function convertStyleStringToObject(str: string): Record<string, string> {
  const styleArray = str.split(';');
  const styleObject: Record<string, string> = styleArray.reduce((obj, style) => {
    if (!style) return obj;

    const [key, value] = style.split(':');
    const camelCaseKey = convertKebabToCamelCase(key.trim());

    return {
      ...obj,
      [camelCaseKey]: value.trim(),
    };
  }, {});
  return styleObject;
}

// @ts-expect-error Cant find the right TS types
function transform(node, index) {
  const foxInTheSnow = 'fox in the snow';
  const style = node.attribs?.style || '';
  const classNames = node.attribs?.class || '';

  // Remove class attribute
  if (classNames) {
    delete node.attribs.class;
  }

  // Replace font-family: 'fox in the snow' with text-fox class
  if (node.type === 'tag' && style.includes('font-family')) {
    // regular expression pattern to match the font-family declarations
    const pattern = /font-family\s*:\s*("[^"]*"|'[^']*'|[^;]*)(?:\s*,\s*("[^"]*"|'[^']*'|[^;]*))*\s*;/gi;

    // Remove font-family declarations from the inline style attributes
    const updStyle = style.replace(pattern, '');

    // Determine if we want to add a new className
    const foxClass = style.toLowerCase().includes(foxInTheSnow) ? 'font-fox' : '';
    // Keep the original tag name
    const Tag = node.name as keyof JSX.IntrinsicElements;

    return (
      <Tag key={index} className={cn(classNames, foxClass)} style={convertStyleStringToObject(updStyle)}>
        {/* @ts-expect-error Cant find the right TS types */}
        {node.children?.map((n, i) => {
          return convertNodeToReactElement(n, i, transform);
        })}
      </Tag>
    );
  }

  // Replace text-align class with text-left, text-center or text-right
  if (node.type === 'tag' && classNames.includes('text-align')) {
    const Tag = node.name as keyof JSX.IntrinsicElements;

    let textAlignClass = 'text-left';
    if (classNames.includes('text-align-center')) {
      textAlignClass = 'text-center';
    } else if (classNames.includes('text-align-right')) {
      textAlignClass = 'text-right';
    }

    return (
      <Tag key={index} className={cn(textAlignClass, classNames)}>
        {/* @ts-expect-error Cant find the right TS types */}
        {node.children?.map((n, i) => {
          return convertNodeToReactElement(n, i, transform);
        })}
      </Tag>
    );
  }

  // Replace "a" tag with Next Link
  if (node.type === 'tag' && node.name === 'a') {
    return (
      <Link key={index} href={node.attribs?.href || ''} className={cn(classNames)} {...node.attribs}>
        {/* @ts-expect-error Cant find the right TS types */}
        {node.children?.map((n, i) => {
          return convertNodeToReactElement(n, i, transform);
        })}
      </Link>
    );
  }

  return undefined;
}

type TextProps = {
  value?: string;
  id?: string;
  className?: string;
  children?: ReactNode;
};

type HeadingElement = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: string | ReactElement<any, string | JSXElementConstructor<any>> | null;
  heading: string;
};

const proseHeader1 =
  'prose-h1:mb-2 prose-h1:mt-4 prose-h1:text-4xl prose-h1:font-semibold prose-h1:lg:text-6xl text-black';
const proseHeader2 =
  'prose-h2:mb-2 prose-h2:mt-3 prose-h2:text-3xl prose-h2:font-semibold prose-h2:lg:text-5xl text-black';
const proseHeader3 =
  'prose-h3:mb-2 prose-h3:mt-3 prose-h3:text-2xl prose-h3:font-semibold prose-h3:lg:text-4xl text-black';
const proseHeader4 =
  'prose-h4:mb-1 prose-h4:mt-2 prose-h4:text-xl prose-h4:font-semibold prose-h4:lg:text-3xl text-black';
const proseHeader5 =
  'prose-h5:mb-1 prose-h5:mt-2 prose-h5:text-lg prose-h5:font-medium prose-h5:lg:text-2xl text-black';
const proseHeader6 =
  'prose-h6:mb-1 prose-h6:mt-2 prose-h6:text-base prose-h6:font-medium prose-h6:lg:text-xl text-black';

export function Text({ value = '', children, className }: TextProps) {
  const text = useMemo<HeadingElement[]>(() => {
    if (!value) return [];

    const reactNodes = convertHtmlToReact(value, { transform });

    return reactNodes
      .map((x) =>
        !x || typeof x === 'string' || x.type !== 'h2'
          ? { element: x, heading: undefined }
          : { element: x, heading: extractText(x) },
      )
      .filter(Boolean) as HeadingElement[];
  }, [value]);

  return (
    <div
      className={cn(
        'prose max-w-none text-base marker:text-black prose-strong:text-inherit prose-em:font-italic',
        proseHeader1,
        proseHeader2,
        proseHeader3,
        proseHeader4,
        proseHeader5,
        proseHeader6,
        className,
      )}
    >
      {text?.map((x, i) =>
        x.heading ? (
          <div key={convertTitleToId(`${x.heading}-${i}`)} id={convertTitleToId(x.heading)}>
            {x.element}
          </div>
        ) : (
          <Fragment key={convertTitleToId(`${x.heading}-${i}`)}>{x.element}</Fragment>
        ),
      )}
      {children}
    </div>
  );
}
