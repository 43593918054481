import { useEffect, useState } from 'react';

export function useHash() {
  const [hash, setHash] = useState<string | null>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.location.hash !== hash) {
        setHash(window.location.hash.slice(1));
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [hash]);

  return hash;
}
