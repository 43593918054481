import NiceModal from '@ebay/nice-modal-react';
import { Icon } from '@wla/components/ui/icon';
import { usePublicConfig } from '@wla/lib/configs';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { Button } from '../button/button';
import { getPreviewSize } from './getPreviewSize';

type GiftcardPreviewProps = {
  getValues: () => any;
};

export function GiftcardPreview({ getValues }: GiftcardPreviewProps) {
  const t = useTranslations('gift-card');
  const { catalog, currency } = usePublicConfig();

  async function showPreview() {
    const values = getValues();

    const props = {
      catalog,
      giftcard: {
        giftcardPriceText: '',
        currency: currency,
        giftcardLayout: 'standard',
        value: values.amount || 100,
        receiverName: values.receiverName || t('receivers-name'),
        buyerName: values.buyerName || t('preview.buyers-name-preview'),
        receiverEmail: values.email || t('preview.receiver-email-preview'),
        giftcardHeader: values.title || t('gift-card-header'),
        giftcardText: [values.message || t('preview.message-preview')],
      },
      ...getPreviewSize(),
    };

    const response = await fetch('/api/get-giftcard-preview', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ props }),
    });

    const blob = await response.blob();

    // Create a URL for the blob
    const imageUrl = URL.createObjectURL(blob);
    NiceModal.show(DRAWER_ID.GIFTCARD_PREVIEW_MODAL, {
      url: imageUrl,
      ...getPreviewSize(),
    });
  }

  return (
    <Button variant="secondary" type="button" onClick={showPreview}>
      <Icon src="w3-preview-giftcard" size="16" className="pr-1" />
      {t('preview.preview-gift-card')}
    </Button>
  );
}
