import { StoreLocator } from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator';
import { NewsletterSignupB2BParagraph } from '@wla/app/(cms)/newsletter/b2b/newsletter-signup-b2b-paragraph';
import { NewsletterSignupB2CParagraph } from '@wla/app/(cms)/newsletter/b2c/newsletter-signup-b2c-paragraph';
import { CookiesOverview } from '@wla/components/ui/cookies-overview';
import { Giftcard } from '@wla/components/ui/giftcard/giftcard';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { ParagraphContactFormB2BContact } from '@wla/components/ui/paragraph/paragraph-contact-form-b2b-contact/paragraph-contact-form-b2b-contact';
import { ParagraphContactFormOrderReturnsAndClaims } from '@wla/components/ui/paragraph/paragraph-contact-form-order-returns-and-claims/paragraph-contact-form-order-returns-and-claims';
import { ParagraphContactFormCustomerAssortmentSuggestions } from '@wla/components/ui/paragraph/paragraph-customer-assortment-suggestions/paragraph-customer-assortment-suggestions';
import { PlaceholderParagraph } from '@jysk/api-types/drupalApi';
import { Press } from '../press';

type ParagraphPlaceholderProps = {
  content: PlaceholderParagraph;
};

export function ParagraphPlaceholder({ content }: ParagraphPlaceholderProps) {
  const { config, id } = content;

  const componentMap: Record<string, JSX.Element> = {
    '404': <div>404</div>,
    B2BCreateForm: <div>B2BCreateForm</div>,
    B2BLandingPage: <div>B2BLandingPage</div>,
    B2BLoginForm: <div>B2BLoginForm</div>,
    B2BNewsletterForm: <NewsletterSignupB2BParagraph />,
    Brands: <div>Brands</div>,
    CampaignPapers: <div>CampaignPapers</div>,
    Campaigns: <div>Campaigns</div>,
    CookiesOverview: <CookiesOverview />,
    CustomerLogin: <div>CustomerLogin</div>,
    CustomerSupport: <div>CustomerSupport</div>,
    EDLP: <div>EDLP</div>,
    GiftCardForm: <Giftcard />,
    Inspiration: <div>Inspiration</div>,
    InspirationUniverse: <div>InspirationUniverse</div>,
    Newsletter: <NewsletterSignupB2CParagraph />,
    Outlet: <div>Outlet</div>,
    Press: <Press />,
    SMSDoc: <div>SMSDoc</div>,
    StoreLocator: <StoreLocator />,
    ContactFormCustomerAssortmentSuggestions: <ParagraphContactFormCustomerAssortmentSuggestions />,
    ContactFormB2BContact: <ParagraphContactFormB2BContact />,
    ContactFormOrderReturnsAndClaims: <ParagraphContactFormOrderReturnsAndClaims />,
    VideoUniverse: <div>VideoUniverse</div>,
  };

  if (id in componentMap) {
    return <ParagraphConfig config={config}>{componentMap[id]}</ParagraphConfig>;
  } else {
    return (
      <ParagraphConfig config={config}>
        <div>Unknown placeholder</div>
      </ParagraphConfig>
    );
  }
}
