import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { FilterAxisItem, FilterAxises, GetFilterAxis200 } from '@jysk/api-types/wssFilterAxisApi/model';
import { Alert } from '@wla/components/ui/alert';
import { Icon } from '@wla/components/ui/icon';
import { Spinner } from '@wla/components/ui/spinner';
import { Switch } from '@wla/components/ui/switch';
import { cn } from '@wla/lib/helpers/cn';
import { filterActions, handleChangeFilter } from '@wla/lib/helpers/filter-change-handler';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { getFilterAxisType, getParentId } from './filters-helpers';
import { FilterTypeBool } from './filters-types-bool';
import { FilterTypeRange } from './filters-types-range';

type FiltersPlpProps = {
  filters: GetFilterAxis200 | null;
  preOpenFilter?: FilterAxises | null;
  isLoading?: boolean;
};

export function findPromotionAxis(filters: GetFilterAxis200 | null) {
  let promotedFilter: FilterAxisItem | undefined;
  const filterOptions: FilterAxises[] = [];

  filters?.filteraxises.map((item) => {
    const foundPromotion = item.filteraxisitems.find((filter) => filter.value === 'PROMOTION');
    if (foundPromotion) {
      promotedFilter = foundPromotion;
    } else {
      filterOptions.push(item);
    }
  });

  return { promotedFilter, filterOptions };
}

export function FiltersPlp({ filters, isLoading, preOpenFilter }: FiltersPlpProps) {
  const t = useTranslations('common');
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryFilters = searchParams.get('filters');

  const { promotedFilter, filterOptions } = findPromotionAxis(filters);

  useEffect(() => {
    if (preOpenFilter) {
      setTimeout(() => {
        filters?.filteraxises.map((filterAxis) => {
          if (filterAxis.handle === preOpenFilter.handle) {
            const element = document.getElementById(filterAxis.handle);
            element?.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }, 200);
    }
  }, [preOpenFilter]);

  if (!filterOptions && isLoading) return <Spinner />;
  if (filterOptions.length === 0) return <Alert className="mt-4">{t('there-are-no-filters-available')}</Alert>;

  return (
    <div data-testid="filters-plp">
      {promotedFilter && (
        <Disclosure as="div" className="border-b" defaultOpen>
          <Switch
            isLeftLabel
            labelClassName="text-md font-semibold mr-auto my-5 h-12 flex items-center"
            key={promotedFilter.value}
            label={promotedFilter?.caption}
            isChecked={promotedFilter.selected}
            onChange={() =>
              handleChangeFilter(
                {
                  id: getParentId(promotedFilter.handle),
                  value: promotedFilter.value,
                  handle: !promotedFilter.selected ? filterActions.ADD : filterActions.REMOVE,
                  caption: promotedFilter.caption,
                },
                { pathname, searchParams, queryFilters },
              )
            }
          />
        </Disclosure>
      )}
      {filterOptions.map((filterAxis) => (
        <Disclosure
          as="div"
          key={filterAxis.handle}
          className="border-b"
          defaultOpen={
            filterAxis.filteraxisitems.some((o) => o.selected) ||
            Boolean(filterAxis.selectedValue) ||
            preOpenFilter?.handle === filterAxis.handle
          }
        >
          {({ open }) => (
            <div className="flow-root">
              <DisclosureButton
                as={filterAxis.alwaysExpand ? 'span' : 'button'}
                id={filterAxis.handle}
                className="my-5 flex h-12 w-full items-center justify-between text-gray-800 hover:text-black"
              >
                <div className="flex w-full items-center justify-between">
                  <span className="text-md font-semibold text-gray-1000">{filterAxis.caption}</span>
                </div>

                {!filterAxis.alwaysExpand && (
                  <span className="ml-6 flex items-center">
                    <Icon
                      src="w3-arrow-down"
                      size="20"
                      className={cn('transform transition-transform', { 'rotate-180': open })}
                    />
                  </span>
                )}
              </DisclosureButton>

              <DisclosurePanel className="pb-6" static={filterAxis.alwaysExpand}>
                <div className="space-y-3">
                  {/* TODO - The filter type (checkbox, range etc) should be a part of the filterAxis */}
                  {getFilterAxisType(filterAxis) === 'bool' ? (
                    <FilterTypeBool
                      id={filterAxis.handle}
                      caption={filterAxis.caption}
                      filterAxises={filterAxis}
                      queryFilters={queryFilters}
                      pathname={pathname}
                      searchParams={searchParams}
                    />
                  ) : (
                    <FilterTypeRange
                      id={filterAxis.handle}
                      queryFilters={queryFilters}
                      pathname={pathname}
                      searchParams={searchParams}
                      caption={filterAxis.caption}
                      filterAxises={filterAxis}
                    />
                  )}
                </div>
              </DisclosurePanel>
            </div>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
