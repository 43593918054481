import { type FaqCategoryListParagraph } from '@jysk/api-types/drupalApi';
import { JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';
import { Button } from '@wla/components/ui/button/button';
import { Icon } from '@wla/components/ui/icon';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';

type ParagraphFaqCategoryListProps = {
  content: FaqCategoryListParagraph;
};

export function ParagraphFaqCategoryList({ content }: ParagraphFaqCategoryListProps) {
  const { config, items } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config}>
      <div className="flex flex-col gap-4">
        {items.map(
          (item, index) =>
            item && (
              <div key={`${item.title}-${index}`}>
                <Button
                  as="a"
                  href={item.url}
                  variant="secondary"
                  iconSize="24"
                  iconPosition="right"
                  iconWeight="font-thin"
                  className="flex h-auto w-auto items-center gap-5 rounded-sm border-gray-300 p-4 font-semibold lg:pr-8"
                >
                  <h3 className="mr-auto text-lg font-semibold lg:text-2xl">{item.title}</h3>
                  <Icon
                    src={item.icon as JyskIconsId}
                    size="24"
                    className="flex min-h-14 min-w-14 items-center justify-center bg-blue-200 lg:hidden lg:h-20 lg:w-20 lg:text-4xl"
                  />
                  <Icon
                    src={item.icon as JyskIconsId}
                    size="32"
                    className="hidden h-14 w-14 items-center justify-center bg-blue-200 lg:flex lg:h-20 lg:w-20 lg:text-4xl"
                  />
                </Button>
              </div>
            ),
        )}
      </div>
    </ParagraphConfig>
  );
}
