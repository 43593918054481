'use client';

import { Button } from '@wla/components/ui/button/button';
import { Link } from '@wla/components/ui/link';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { Text } from '@wla/components/ui/text';
import { cn } from '@wla/lib/helpers/cn';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { ExternalVideoMedia, ImageMedia, MediaParagraph } from '@jysk/api-types/drupalApi';

type ParagraphMediaProps = {
  content: MediaParagraph;
};

export function ParagraphMedia({ content }: ParagraphMediaProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if (!isClient) {
      setIsClient(true);
    }
  }, []);

  const { config, overlay, link, size } = content;
  const HeroItem: React.ElementType = link ? Link : 'div';

  if (!content.media) {
    return null;
  }

  const contentPosition = overlay?.position || 'top_left';
  const media = content.media.type === 'Image' ? (content.media as ImageMedia) : (content.media as ExternalVideoMedia);
  const ctaHref = overlay?.cta?.url;
  const ctaStyle = overlay?.ctaStyle;
  const cta = overlay?.cta;

  return (
    <ParagraphConfig className="h-auto" config={config}>
      <div>
        {media.type && media.type === 'Image' && (
          <div className="relative">
            <HeroItem className="block overflow-hidden" {...(link && { href: link.url, target: link.target })}>
              <Image
                sizes="(max-width: 359px) 383px, (max-width: 430px) 436px, (max-width: 768px) 767px, (max-width: 992px) 963px, (max-width: 1200px) 1372px, 1372px"
                src={media.url}
                alt={media.alt}
                width={media.dimensions?.width}
                height={media.dimensions?.height}
                className={cn({
                  'aspect-square': size === 'square',
                  'aspect-[2/1]': size === 'wide',
                  'aspect-[3/2]': size === 'landscape',
                  'duration-300 hover:scale-110': link,
                })}
              />
            </HeroItem>
            {/* Overlay */}
            <div
              // max and min widths are to make sure the overlay look optimal
              className={cn('pointer-events-none absolute p-4', {
                'min-w-[60%] max-w-[90%] text-center': overlay?.backgroundColor !== null,
                // Fix alignment when there is not overlay bg-color
                'text-left':
                  overlay?.backgroundColor === null &&
                  (contentPosition === 'top_left' ||
                    contentPosition === 'center_left' ||
                    contentPosition === 'bottom_left'),
                'text-center':
                  overlay?.backgroundColor === null &&
                  (contentPosition === 'top_center' ||
                    contentPosition === 'center' ||
                    contentPosition === 'bottom_center'),
                'text-right':
                  overlay?.backgroundColor === null &&
                  (contentPosition === 'top_right' ||
                    contentPosition === 'center_right' ||
                    contentPosition === 'bottom_right'),

                'left-4 top-4': contentPosition === 'top_left',
                'left-2/4 top-4 -translate-x-2/4': contentPosition === 'top_center',
                'right-4 top-4': contentPosition === 'top_right',
                'left-4 top-2/4 -translate-y-2/4': contentPosition === 'center_left',
                'left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4': contentPosition === 'center',
                'right-4 top-2/4 -translate-y-2/4': contentPosition === 'center_right',
                'bottom-4 left-4': contentPosition === 'bottom_left',
                'bottom-4 left-2/4 -translate-x-2/4': contentPosition === 'bottom_center',
                'bottom-4 right-4': contentPosition === 'bottom_right',
              })}
              style={{
                backgroundColor: `${overlay?.backgroundColor}`,
              }}
            >
              {overlay?.text && <Text value={overlay?.text} />}
              {cta && ctaStyle && (
                <Button
                  className="pointer-events-auto mt-2"
                  as="a"
                  href={ctaHref}
                  size="medium"
                  style={
                    ctaStyle.type === 'custom'
                      ? {
                          backgroundColor: ctaStyle.backgroundColor || undefined,
                          color: ctaStyle.fontColor || undefined,
                        }
                      : undefined
                  }
                >
                  {cta.title}
                </Button>
              )}
            </div>
          </div>
        )}
        {media.type && media.type === 'MediaExternalVideo' && isClient && (
          <ReactPlayer controls width="auto" height="auto" className="aspect-video h-fit w-full" url={media.url} />
        )}
      </div>
    </ParagraphConfig>
  );
}
