'use client';

import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { ReactNode, useRef, useState } from 'react';
import { useDebounceValue, useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { TooltipParagraphAllOfTooltipsOneOfItemPosition } from '@jysk/api-types/drupalApi';

type TooltipPopoverProps = {
  type: 'product' | 'link';
  position: TooltipParagraphAllOfTooltipsOneOfItemPosition;
  children: ReactNode;
};

// Calculate center of element (144 is half the width of the popup)
function calculatePosition(el: HTMLDivElement) {
  return el.getBoundingClientRect().right - (el.getBoundingClientRect().right - el.getBoundingClientRect().left) / 2;
}

export function TooltipPopover({ children, position, type }: TooltipPopoverProps) {
  // Convert decimals to percentages
  const x = position.x !== undefined ? Math.round(position.x * 100) : null;
  const y = position.y !== undefined ? Math.round(position.y * 100) : null;

  const [open, setOpen] = useState(false);
  const [iconCenterPosition, setIconCenterPosition] = useState(0);

  const width = useDebounceValue(useWindowSize(), 1000);

  // Adjustment for the popup position (144 is half the width of product card)
  const ALIGNMENT_ADJUSTMENT = type === 'product' ? 144 : 128;

  const customStyle: any = {};

  if (iconCenterPosition + ALIGNMENT_ADJUSTMENT < width[0].width && iconCenterPosition - ALIGNMENT_ADJUSTMENT > 0) {
    customStyle.left = `${x}%`;
  }

  const componentStyle = {
    top: `${y}%`,
    ...customStyle,
  };

  const linkDivRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = () => {
    setOpen(false);
  };

  useOnClickOutside(linkDivRef, handleClickOutside);

  return (
    <>
      <div
        ref={(el) => {
          if (!el) return;
          setIconCenterPosition(calculatePosition(el));
        }}
        onClick={() => (open ? setOpen(false) : setOpen(true))}
        className="z-9 absolute h-8 w-8 transform cursor-pointer rounded-full border border-white/50 transition duration-500 hover:scale-[1.5]"
        style={{
          left: `${x}%`,
          top: `${y}%`,
        }}
      >
        <div className="absolute left-2/4 top-2/4 z-10 min-h-3 min-w-3 -translate-x-2/4 -translate-y-2/4 rounded-full bg-white" />
      </div>

      {type === 'product' && open && (
        <div
          className={cn('absolute z-[10] w-72', {
            '-translate-x-36':
              iconCenterPosition + ALIGNMENT_ADJUSTMENT < width[0].width &&
              iconCenterPosition - ALIGNMENT_ADJUSTMENT > 0, // Center tooltip if it fits
            'right-5': iconCenterPosition + ALIGNMENT_ADJUSTMENT > width[0].width, // Align right if tooltip is outside of screen
            'left-0': iconCenterPosition - ALIGNMENT_ADJUSTMENT < 0, // Align left if tooltip is outside of screen
          })}
          style={componentStyle}
        >
          <div className="bg-white p-4">
            <div
              className="z-9 absolute -right-4 -top-4 flex h-8 w-8 scale-100 cursor-pointer rounded-full bg-white"
              onClick={() => setOpen(false)}
            >
              <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4">
                <Icon src="w3-close" size="20" />
              </div>
            </div>
            {children}
          </div>
        </div>
      )}

      {type === 'link' && open && (
        <div
          className={cn('absolute z-[10] w-[249px]', {
            '-translate-x-36':
              iconCenterPosition + ALIGNMENT_ADJUSTMENT < width[0].width &&
              iconCenterPosition - ALIGNMENT_ADJUSTMENT > 0, // Center tooltip if it fits
            'right-2': iconCenterPosition + ALIGNMENT_ADJUSTMENT > width[0].width, // Align right if tooltip is outside of screen
            'left-2': iconCenterPosition - ALIGNMENT_ADJUSTMENT < 0, // Align left if tooltip is outside of screen
          })}
          ref={linkDivRef}
          style={componentStyle}
        >
          {children}
        </div>
      )}
    </>
  );
}
