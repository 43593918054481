'use client';
import { Button } from '@wla/components/ui/button/button';
import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { useTranslations } from 'next-intl';
import { useEffect, useRef } from 'react';

export function Press() {
  const t = useTranslations('common');
  const ref = useRef<HTMLDivElement>(null);
  const hasConsent = useCookieConsent(CookieTypesEnum.STATISTIC);

  function handleOnButtonClick() {
    try {
      (window as Window).CookieConsent?.show();
    } catch (error) {
      trackError(error);
    }
  }

  useEffect(() => {
    if (hasConsent) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'mnd-script';
      script.innerHTML = `
        var s=document.createElement("script");
        s.type="text/javascript";
        s.async=true;
        s.src="//jysk-dk.mynewsdesk.com/hosted_newsroom.js";
        var i=document.getElementsByTagName('script')[0];
        i.parentNode.insertBefore(s,i);
      `;
      ref.current?.appendChild(script);
    } else {
      const existingScript = document.getElementById('mnd-script');
      if (existingScript) {
        existingScript.remove();
      }

      const iframes = document.querySelector('iframe[src*="mynewsdesk"]');
      if (iframes) {
        iframes.remove();
      }
    }
  }, [hasConsent]);

  return hasConsent ? (
    <div ref={ref} className="h-full w-full" />
  ) : (
    <div>
      <p className="pb-4">{t('please-accept-cookies')}</p>
      <Button variant="primary" onClick={handleOnButtonClick}>
        {t('update-consent')}
      </Button>
    </div>
  );
}
