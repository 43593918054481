import { ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { Button } from '@wla/components/ui/button/button';
import { DropDownButton } from '@wla/components/ui/button/dropdown-button';
import { Drawer } from '@wla/components/ui/drawer';
import { Radiobutton } from '@wla/components/ui/forms/radio';
import { trackSorting } from '@wla/lib/tracking/google-analytics-tracking';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useState } from 'react';

export type SortOptions = (typeof sortOptions)[number];

type ProductListSortingProps = {
  onSortChange?: (sort: SortOptions) => void;
};

enum SortingMap {
  RELEVANCE = '',
  RATING = 'rating',
  REVIEWS = 'score',
  PRICE_ASC = 'priceAscending',
  PRICE_DESC = 'priceDescending',
  NAME = 'alphabetically',
}

const sortOptions = [
  SortingMap.RELEVANCE,
  SortingMap.RATING,
  SortingMap.REVIEWS,
  SortingMap.PRICE_ASC,
  SortingMap.PRICE_DESC,
  SortingMap.NAME,
];
const getPrice = (product: ProductTeaser | PostSearch200ProductsHitsItem) =>
  product.price?.unformatted?.minSingle ?? product.price?.unformatted?.gross ?? 0;

export function sortProducts(products: (ProductTeaser | PostSearch200ProductsHitsItem)[], sort: SortOptions) {
  switch (sort) {
    case SortingMap.RATING:
      return [...products].sort((a, b) => (b.rating ?? 0) - (a.rating ?? 0));

    case SortingMap.PRICE_ASC:
      return [...products].sort((a, b) => getPrice(a) - getPrice(b));

    case SortingMap.PRICE_DESC:
      return [...products].sort((a, b) => getPrice(b) - getPrice(a));

    case SortingMap.REVIEWS:
      return [...products].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

    default: {
      const seriesLowestPrices = products.reduce(
        (acc, product) => {
          const seriesId = product.relations?.series?.seriesId ?? '';
          const price = getPrice(product);
          if (!acc[seriesId] || price < acc[seriesId]) {
            acc[seriesId] = price;
          }
          return acc;
        },
        {} as Record<string, number>,
      );

      return [...products].sort((a, b) => {
        // Sort by isSingleOut first
        const singleOutComparison = Number(b.isSingleOut) - Number(a.isSingleOut);
        if (singleOutComparison !== 0) return singleOutComparison;

        // Calculate lowest price for each seriesId
        const seriesIdA = a.relations?.series?.seriesId ?? '';
        const seriesIdB = b.relations?.series?.seriesId ?? '';
        const lowestPriceA = seriesLowestPrices[seriesIdA];
        const lowestPriceB = seriesLowestPrices[seriesIdB];
        const seriesIdComparison = lowestPriceA - lowestPriceB;

        if (seriesIdComparison !== 0) return seriesIdComparison;

        // Sort by seriesId secondarily
        const seriesIdLocaleCompare = seriesIdA.localeCompare(seriesIdB);
        if (seriesIdLocaleCompare !== 0) return seriesIdLocaleCompare;

        // Sort by price within each seriesId group
        const priceA = getPrice(a);
        const priceB = getPrice(b);
        return priceA - priceB;
      });
    }
  }
}

export function ProductListSorting({ onSortChange }: ProductListSortingProps) {
  const t = useTranslations();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const sort = searchParams.get('sort');
  const [toggleSortingPanel, setToggleSortingPanel] = useState(false);

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  function handleSortChange(sort: SortOptions) {
    history.replaceState({}, '', `${pathname}?${createQueryString('sort', sort)}`);
    onSortChange?.(sort);
    trackSorting(sort);
  }

  const sortOptionTranslationKeys: Record<SortOptions, string> = {
    [SortingMap.RELEVANCE]: t('plp.sort-by-relevance'),
    [SortingMap.RATING]: t('plp.sort-by-rating'),
    [SortingMap.REVIEWS]: t('plp.sort-by-reviews'),
    [SortingMap.PRICE_ASC]: t('plp.sort-by-price-asc'),
    [SortingMap.PRICE_DESC]: t('plp.sort-by-price-desc'),
    [SortingMap.NAME]: t('plp.sort-by-name'),
  };

  return (
    <>
      <DropDownButton
        onClick={() => setToggleSortingPanel(!toggleSortingPanel)}
        className="h-14 w-full rounded-md border-gray-300 px-3 lg:w-auto"
        title={t('plp.sort-by')}
      />
      <Drawer open={toggleSortingPanel} onClose={() => setToggleSortingPanel(false)}>
        <Drawer.Header title={t('plp.sort-by')} />
        <Drawer.Body>
          {sortOptions.map((option) => (
            <RadioGroup.Root
              key={option}
              onValueChange={handleSortChange}
              value={sort ?? SortingMap.RELEVANCE}
              className="my-4 flex flex-row-reverse justify-between py-3"
            >
              <Radiobutton
                value={option}
                id={`sort-button-${option}`}
                labelStyling="ml-0 text-md cursor-pointer w-full"
              >
                {sortOptionTranslationKeys[option]}
              </Radiobutton>
            </RadioGroup.Root>
          ))}
        </Drawer.Body>
        <Drawer.Footer className="mt-auto flex gap-2 border-none px-5">
          <Button onClick={() => setToggleSortingPanel(false)} variant="primary" className="w-full">
            {t('common.apply')}
          </Button>
        </Drawer.Footer>
      </Drawer>
    </>
  );
}
