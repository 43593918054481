'use client';
import { StoreFullAllOfOpeningHoursOneOfItem } from '@jysk/api-types/drupalApi';
import { formatTime } from '@wla/app/(cms)/(favorite-store)/get-store-opening-data';
import { useTranslations } from 'next-intl';

type OpeningHoursTableProps = {
  opening: StoreFullAllOfOpeningHoursOneOfItem[];
};

export function FavoriteStoreOpeningHours({ opening }: OpeningHoursTableProps) {
  const t = useTranslations('common');

  const now = new Date();
  const currentWeekday = now.getDay();

  const days = [
    t('days.sunday'),
    t('days.monday'),
    t('days.tuesday'),
    t('days.wednesday'),
    t('days.thursday'),
    t('days.friday'),
    t('days.saturday'),
  ];

  const sortedDays = [...days.slice(currentWeekday), ...days.slice(0, currentWeekday)];

  return (
    <>
      {opening.map((openingHour, i) => (
        <>
          <p className="">{sortedDays[i % sortedDays.length]}</p>
          <p className="md:col-span-2">
            {openingHour ? `${formatTime(openingHour.startHours)} - ${formatTime(openingHour.endHours)}` : t('closed')}
          </p>
        </>
      ))}
    </>
  );
}
