import { getZipCodeFromOrder } from '@wla/app/checkout/order-states';
import { Search } from '@wla/components/ui/search';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';

type FavoriteStoreSearchProps = {
  setSearchValue: (value: string) => void;
};

export function FavoriteStoreSearch({ setSearchValue }: FavoriteStoreSearchProps) {
  const t = useTranslations('favorite-store');
  const { shoppingSession } = useShoppingSession();
  const zipCode = shoppingSession?.active?.orders && getZipCodeFromOrder(shoppingSession.active.orders);

  return (
    <div className="py-4">
      <Search handleSearch={setSearchValue} placeholder={t('city-adress-post-code')} defaultValue={zipCode || ''} />
    </div>
  );
}
