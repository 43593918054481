import { FavoriteStoreCard } from '@wla/app/(cms)/(favorite-store)/favorite-store-card';
import { AvailabilityStatus } from '@wla/app/(cms)/(favorite-store)/favorite-store-stock';
import { StoreList } from '@wla/app/(cms)/(favorite-store)/store-drawer/store-list';
import { useStores } from '@wla/app/(cms)/(favorite-store)/use-stores';
import { OnlineAtp } from '@wla/app/(cms)/[...slug]/(product-detail-page)/add-to-basket';
import { getClickCollectOrder } from '@wla/app/checkout/order-states';
import { Button } from '@wla/components/ui/button/button';
import { usePublicConfig } from '@wla/lib/configs';
import { STORE_FLY_IN_PAGESIZE } from '@wla/lib/constants';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { ProductFull, ProductTeaser, StoreFull } from '@jysk/api-types/drupalApi';
import { StoreArticleAtp } from '@jysk/api-types/webSapApi/model';

export type StoreType = StoreFull & { atp: Omit<StoreArticleAtp, 'timestamp'> & { timestamp?: string | null } };

type StoreAtpBase = {
  totalNumberStores: number;
  totalNumberStoresWithAtp: number;
  selectedStoreUnit?: number;
  status?: number;
};

export type StoreAtp = StoreAtpBase & {
  [key: string]: StoreArticleAtp;
};

type FavoriteStoreListProps = {
  stores: StoreType[];
  product?: ProductFull | ProductTeaser;
  clickCollectBasketProductIds?: StoreAtp[];
  onClick?: (id: string) => void;
};

export function FavoriteStoreList({ stores, product, clickCollectBasketProductIds, onClick }: FavoriteStoreListProps) {
  const t = useTranslations();
  const { orders, changeSite } = useShoppingSession();
  const { fetchOnlineAtp } = useAtp();
  const { getLastUpdatedStore } = useStores();
  const { favoriteStore, setFavoriteStore } = useFavoriteStore();
  const [selectedStoreId, setSelectedStoreId] = useState(favoriteStore?.id);
  const { defaultLocale, salesOrganisation } = usePublicConfig();
  const clickCollectOrder = getClickCollectOrder(orders);
  const { date, latestUpdatedStore, time, formattedDateTime } = getLastUpdatedStore(stores, defaultLocale);
  const [onlineProductAtp, setOnlineProductAtp] = useState<OnlineAtp | null>(null);

  const [page, setPage] = useState<number>(1);
  const pagedStores = stores.slice(0, page * STORE_FLY_IN_PAGESIZE);

  const handleClick = useCallback(
    async (storeId: string) => {
      const selectedStore = stores.find((store) => store.id === storeId);

      if (!selectedStore) return;

      setFavoriteStore(selectedStore);
      setSelectedStoreId(selectedStore.id);
      onClick?.(storeId);

      if (clickCollectOrder?.siteId !== storeId) {
        changeSite({ siteId: storeId });
      }
    },
    [stores],
  );

  async function getOnlineAtpData(productId: string) {
    const atpResponse = await fetchOnlineAtp(productId, salesOrganisation.toString());
    setOnlineProductAtp(atpResponse);
  }

  useEffect(() => {
    if (!product?.id) return;

    getOnlineAtpData(product.id);
  }, [product?.id]);

  function getClickCollectStatus(storeId: string) {
    if (!clickCollectBasketProductIds?.length) return AvailabilityStatus.InStock;

    const availability = clickCollectBasketProductIds.map((stores) => (stores[storeId]?.units ?? 0) > 0);

    if (availability.every(Boolean)) return AvailabilityStatus.InStock;
    if (availability.some(Boolean)) return AvailabilityStatus.Extended;

    return AvailabilityStatus.OutOfStock;
  }

  return (
    <StoreList>
      {pagedStores?.map((store) => (
        <FavoriteStoreCard
          key={store.id}
          store={store}
          product={product}
          onlineProductAtp={onlineProductAtp}
          isSelected={store.id === selectedStoreId}
          clickCollectBasketAvailability={getClickCollectStatus(store.id)}
          onClick={handleClick}
        />
      ))}
      {latestUpdatedStore && (
        <time className="mb-2 block text-end text-sm text-gray-500" dateTime={formattedDateTime}>
          {t('favorite-store.last-updated', { time, date })}
        </time>
      )}
      {pagedStores.length !== stores.length && (
        <div className="text-center">
          <Button type="button" variant="secondary" onClick={() => setPage((prevPage: number) => prevPage + 1)}>
            {t('favorite-store.show-more-stores')}
          </Button>
        </div>
      )}
    </StoreList>
  );
}
