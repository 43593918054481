'use client';

import { ParagraphFooterContactInformation } from '@wla/components/ui/paragraph/paragraph-footer-contact-information/paragraph-footer-contact-information';
import { ParagraphTableOfContent } from '@wla/components/ui/paragraph/paragraph-table-of-content';
import { ParagraphText } from '@wla/components/ui/paragraph/paragraph-text';
import { ParagraphTooltip } from '@wla/components/ui/paragraph/paragraph-tooltip/paragraph-tooltip';
import { Layout, ParagraphArray } from '@jysk/api-types/drupalApi';
import { ParagraphAccordion } from './paragraph-accordion';
import { ParagraphBackgroundColorWithOverlay } from './paragraph-background-color-with-overlay';
import { ParagraphBrandOverview } from './paragraph-brand-overview';
import { ParagraphBrandSlider } from './paragraph-brand-slider/paragraph-brand-slider';
import { ParagraphButtonList } from './paragraph-button-list';
import { ParagraphCarousel } from './paragraph-carousel';
import { ParagraphCategory } from './paragraph-category';
import { ParagraphContactInfo } from './paragraph-contact-info/paragraph-contact-info';
import { ParagraphFaqSection } from './paragraph-faq';
import { ParagraphFaqCategoryList } from './paragraph-faq-category-list';
import { ParagraphHeroBannerPrimary } from './paragraph-hero-banner-primary';
import { ParagraphHeroBannerSecondary } from './paragraph-hero-banner-secondary';
import { ParagraphIframe } from './paragraph-iframe';
import { ParagraphMedia } from './paragraph-media';
import { ParagraphMenu } from './paragraph-menu';
import { ParagraphMostPopularFaqSection } from './paragraph-most-popular-faq';
import { ParagraphPlaceholder } from './paragraph-placeholder';
import { ParagraphProductListWithFilters } from './paragraph-product-list-with-filters';
import { ParagraphPublicationList } from './paragraph-publication-list';
import { ParagraphSeparator } from './paragraph-separator';
import { ParagraphTable } from './paragraph-table/paragraph-table';
import { ParagraphUspItem } from './paragraph-usp';

type ParagraphItemWrapperProps = {
  paragraphs: ParagraphArray;
  content: Layout;
  type?: string;
};

const componentMapper = {
  Accordion: ParagraphAccordion,
  BackgroundColorWithOverlay: ParagraphBackgroundColorWithOverlay,
  ButtonList: ParagraphButtonList,
  Categories: ParagraphCategory,
  CscContactInformation: ParagraphContactInfo,
  FaqCategoryList: ParagraphFaqCategoryList,
  FaqSection: ParagraphFaqSection,
  FooterContactInformation: ParagraphFooterContactInformation,
  HeroBannerPrimary: ParagraphHeroBannerPrimary,
  HeroBannerSecondary: ParagraphHeroBannerSecondary,
  ProductListWithFilters: ParagraphProductListWithFilters,
  Iframe: ParagraphIframe,
  Media: ParagraphMedia,
  Menu: ParagraphMenu,
  MostPopularFaqQuestion: ParagraphMostPopularFaqSection,
  Placeholder: ParagraphPlaceholder,
  ProductBrandCarousel: ParagraphBrandSlider,
  ProductBrandOverview: ParagraphBrandOverview,
  ProductSlider: ParagraphCarousel,
  PublicationList: ParagraphPublicationList,
  Separator: ParagraphSeparator,
  Table: ParagraphTable,
  TableOfContents: ParagraphTableOfContent,
  Text: ParagraphText,
  Tooltip: ParagraphTooltip,
  Usp: ParagraphUspItem,
};

export function ParagraphItemWrapper({ paragraphs, content, type }: ParagraphItemWrapperProps) {
  return (
    <>
      {paragraphs &&
        paragraphs.map((paragraph, index) => {
          const componentKey = paragraph.paragraphType as keyof typeof componentMapper;
          const ParagraphComponent = componentMapper[componentKey] as React.FC<{
            content: ParagraphArray[number];
            allParagraphs?: ParagraphArray;
            fullContent?: Layout;
            type?: string;
          }>;

          if (ParagraphComponent) {
            return (
              <ParagraphComponent
                key={`${paragraph.paragraphType}_${index}`}
                content={paragraph}
                fullContent={content}
                type={type}
              />
            );
          }

          return (
            <div key={index}>
              <h3>Unknown paragraph</h3>
              <pre>{JSON.stringify(paragraph, null, 2)}</pre>
            </div>
          );
        })}
    </>
  );
}
