import { Breakpoints, breakpoints as breakpointsConfig } from '@wla/tailwind.config';
import { useMediaQuery } from 'react-responsive';

type Breakpoint = {
  query: string;
  valid: boolean;
};

export function useBreakpoint() {
  function getBreakPoint(breakpointKey: keyof Breakpoints): Breakpoint {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const bool = useMediaQuery({
      query: `(min-width: ${breakpointsConfig[breakpointKey]}px)`,
    });

    return {
      query: `(min-width: ${breakpointsConfig[breakpointKey]}px)`,
      valid: bool,
    };
  }

  return { getBreakPoint, breakPoints: breakpointsConfig };
}
