'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Drawer } from '@wla/components/ui/drawer';
import { Icon } from '@wla/components/ui/icon';
import { ProductCard } from '@wla/components/ui/product-card/product-card';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { useProductVisibility } from '@wla/lib/hooks/use-product-visibility';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { ProductRelationsItem, ProductTeaser } from '@jysk/api-types/drupalApi';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';

export type ProductRelationsItemWithProducts = Omit<ProductRelationsItem, 'productIds'> & {
  products: ProductTeaser[];
};

type ProductsDrawerProps = {
  accessory: ProductRelationsItemWithProducts;
  pageType?: string;
};

export const ProductsDrawer = NiceModal.create(({ accessory }: ProductsDrawerProps) => {
  const t = useTranslations('pdp');
  const modal = useModal();
  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const handleOnProductVisible = useProductVisibility('pdpRecommendedAccessories_powerstep');
  const [stockAtp, setStockAtp] = useState<OnlineAtpMap | null>();
  const [isLoading, setIsLoading] = useState(true);

  function handleProductCardClick() {
    modal.hide();
  }

  useEffect(() => {
    async function fetchStockData() {
      if (!accessory.products.length) {
        setIsLoading(false);
        return;
      }

      const productIds = accessory.products.map((product) => product.id);
      const stockData = await fetchOnlineWithStoreStatusAtp(productIds);
      setStockAtp(stockData);
      setIsLoading(false);
    }

    fetchStockData();
  }, []);

  return (
    <Drawer
      open={modal.visible}
      onClose={() => {
        modal.hide();
      }}
    >
      <Drawer.Header title={accessory.name || t('accessories')} />
      <Drawer.Body className="mt-5 md:mt-8">
        <div className="grid grid-cols-2 justify-items-center gap-x-2.5 gap-y-5 md:gap-x-3.5 md:gap-y-8">
          {accessory.products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              refId="productAccessories"
              onProductVisible={handleOnProductVisible}
              onClick={handleProductCardClick}
              stockStatus={stockAtp?.[product.id]}
              isLoadingStock={isLoading}
            />
          ))}
        </div>
      </Drawer.Body>
    </Drawer>
  );
});

export type ProductAccessoryButtonProps = {
  accessory: ProductRelationsItemWithProducts;
};

export function ProductAccessoryButton({ accessory }: ProductAccessoryButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleOnProductVisible = useProductVisibility('pdpRecommendedAccessories');

  function onAccessoryClick(accessory: ProductRelationsItemWithProducts) {
    NiceModal.show(DRAWER_ID.PRODUCTS_DRAWER, { accessory });
  }

  function handleIntersect(entries: IntersectionObserverEntry[]) {
    if (entries[0].isIntersecting) {
      accessory.products.length && handleOnProductVisible(accessory.products[0]);
    }
  }

  useEffect(() => {
    const currentElement = buttonRef.current;
    if (!currentElement) return;

    const observer = new IntersectionObserver(handleIntersect, { threshold: 0.5 });
    observer.observe(currentElement);
    return () => observer.disconnect();
  }, [accessory.name]);

  return (
    <button
      ref={buttonRef}
      key={accessory.name}
      onClick={(e) => {
        e.preventDefault();
        onAccessoryClick(accessory);
      }}
      className="flex cursor-pointer items-center rounded-sm border border-gray-200"
    >
      {accessory.imageUrl && accessory.name && (
        <div className="my-2 ml-2 flex aspect-[3/4] w-14 items-center justify-center bg-gray-100 p-1">
          <Image width={70} height={70} src={accessory.imageUrl} alt={accessory.name} className="p-1" />
        </div>
      )}
      <div className="flex-1 pl-6 text-left">
        <p>{accessory.name}</p>
      </div>
      <div className="p-5">
        <Icon src="w3-arrow-right" size="18" />
      </div>
    </button>
  );
}
