'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from '@wla/components/ui/button/button';
import { Dialog } from '@wla/components/ui/dialog';
import { Icon } from '@wla/components/ui/icon';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';

type CheckoutConvertBasketModalProps = {
  amountOfProducts: number;
  storeName: string;
  siteId: string;
  lineIds: string[];
};

export const CheckoutConvertBasketModal = NiceModal.create(
  ({ amountOfProducts, storeName, siteId, lineIds }: CheckoutConvertBasketModalProps) => {
    const t = useTranslations('checkout');
    const modal = useModal();
    const { moveOrderLine } = useShoppingSession();

    async function convertBasket() {
      const responses = await Promise.all(
        lineIds.map((lineId) =>
          moveOrderLine({
            siteId,
            productId: lineId,
          }),
        ),
      );

      modal.hide();

      if (responses.every((resp) => resp.success)) {
        // We reload to get the new ShoppingSession where there is now only a C&C basket
        window.location.reload();
      }
    }

    return (
      <Dialog
        open={modal.visible}
        onClose={() => {
          modal.reject();
          modal.hide();
        }}
        isModal
      >
        <Dialog.Panel>
          <button
            aria-label="Close"
            onClick={() => {
              modal.resolve();
              modal.hide();
            }}
            className="-mr-0.5 -mt-1.5 ml-auto"
          >
            <Icon src="w3-close" size="24" />
          </button>
          <Dialog.Header>
            <Dialog.Title>
              <div className="text-center text-2xl font-semibold">{t('convert-basket-modal.title')}</div>
            </Dialog.Title>
          </Dialog.Header>
          <Dialog.Body>
            <div className="flex flex-col gap-2 pt-2 text-center">
              <div>
                {t('convert-basket-modal.also-available-as', {
                  amountOfProducts,
                  storeName,
                })}
              </div>
              <div>
                {t('convert-basket-modal.do-you-wish', {
                  amountOfProducts,
                })}
              </div>
              <div className="flex justify-center gap-2 pt-2">
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => {
                    modal.reject();
                    modal.hide();
                  }}
                >
                  {t('convert-basket-modal.no-thanks')}
                </Button>
                <Button variant="primary" size="medium" onClick={convertBasket} className="mr-4">
                  {t('convert-basket-modal.yes-move-products')}
                </Button>
              </div>
            </div>
          </Dialog.Body>
        </Dialog.Panel>
      </Dialog>
    );
  },
);
