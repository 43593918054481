import NiceModal from '@ebay/nice-modal-react';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';
import { DRAWER_ID } from '@wla/lib/modals';
import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';

type StoreLinkProps = {
  product?: ProductFull | ProductTeaser;
  favoriteStoreName?: string;
  className?: string;
};

export function StoreLink({ product, favoriteStoreName, className }: StoreLinkProps) {
  return (
    <Link
      as="button"
      className={cn('text-left', className)}
      onClick={() =>
        NiceModal.show(DRAWER_ID.FAVORITE_STORE_DRAWER, {
          product,
        })
      }
    >
      {favoriteStoreName ?? ''}
    </Link>
  );
}
