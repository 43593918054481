import type { TableParagraphAllOfRowsOneOfItem } from '@jysk/api-types/drupalApi';
import { TableCell } from './paragraph-table-cell';

type TableRowProps = {
  firstColumnSticky: boolean;
  row: TableParagraphAllOfRowsOneOfItem;
  isOddRow: boolean;
};

export function TableRow({ row, firstColumnSticky, isOddRow }: TableRowProps) {
  return (
    <tr className="border-solid text-center align-top even:bg-white">
      {row?.columns?.map((column, columnIndex) => (
        <TableCell
          key={columnIndex}
          column={column}
          firstColumnSticky={firstColumnSticky && columnIndex === 0}
          isOddRow={isOddRow}
        />
      ))}
    </tr>
  );
}
