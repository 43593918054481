'use client';
import NiceModal from '@ebay/nice-modal-react';
import { ProductFullAllOfVariants } from '@jysk/api-types/drupalApi';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';

export enum ProductVariantType {
  Size = 'Size',
  Colour = 'Colour',
}

type ProductSizePickerProps = {
  productId: string;
  variants: ProductFullAllOfVariants;
};

export function ProductSizePicker({ productId, variants }: ProductSizePickerProps) {
  const t = useTranslations('common');

  if (!variants) {
    return null;
  }

  const currentProduct = variants.products.find((product) => product.id === productId);

  // Extract the current product's color
  const currentProductColorOption = currentProduct?.options.find((option) =>
    option.typeId.toLowerCase().includes(ProductVariantType.Colour.toLowerCase()),
  );
  const currentProductColor = currentProductColorOption?.name || '';

  // Filter products to only include those with the same color as the current product
  const filteredProducts = variants.products.filter((product) => {
    const productColorOption = product.options.find((option) =>
      option.typeId.toLowerCase().includes(ProductVariantType.Colour.toLowerCase()),
    );
    const productColor = productColorOption?.name || '';
    return productColor === currentProductColor;
  });

  // Check if there are multiple size options for the current color
  const availableSizes = filteredProducts.map(
    (product) =>
      product.options.find((option) => option.typeId.toLowerCase().includes(ProductVariantType.Size.toLowerCase()))
        ?.name,
  );
  const uniqueSizes = [...new Set(availableSizes)];

  // Only render the size picker if there are multiple unique sizes
  if (uniqueSizes.length <= 1) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <label className="font-bold" htmlFor="size">
        {t('size')}
      </label>
      <div className="group relative inline-block w-full">
        <button
          className={cn(
            'block w-full truncate rounded bg-none px-5 py-4 pr-9 text-start text-base font-semibold',
            'border border-gray-400 leading-normal focus:border-blue-500 focus:outline-none focus:ring-4 focus:ring-gray-300',
          )}
          onClick={() => NiceModal.show(DRAWER_ID.PRODUCT_SIZE_PICKER, { currentProduct, filteredProducts })}
        >
          {
            currentProduct?.options.find((option) =>
              option.typeId.toLowerCase().includes(ProductVariantType.Size.toLowerCase()),
            )?.name
          }
        </button>
        <div className="pointer-events-none absolute inset-y-0 right-5 flex items-center">
          <Icon src="w3-arrow-right" size="24" className="peer-disabled:disabled" />
        </div>
      </div>
    </div>
  );
}
