import { ProductImageFull, ProductMediaVideosOneOfItem } from '@jysk/api-types/drupalApi';

export enum MediaGroupName {
  CutOut = 'cutOut',
  Environment = 'environment',
  Video = 'video',
  Detail = 'detail',
  Function = 'function',
  Fallback = 'fallback',
}

export type AdditionalMediaProps = {
  groupName: MediaGroupName;
  groupSortOrder: number;
  childSortOrder: number;
};

type MediaMapping = {
  [key: string]: AdditionalMediaProps;
};

const cutOutTypeMappings: MediaMapping = {
  'Cut out': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 1 },
  'Cut through': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 2 },
  'Cut out back': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 3 },
  'Cut out front': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 4 },
  'Cut out front right': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 5 },
  'Cut out sets': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 6 },
  'Cut out side': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 7 },
  'Cut out stack': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 8 },
  'Cut out w/model': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 9 },
  'Cut out w/props': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 10 },
  'Cut out w/o shadow': { groupName: MediaGroupName.CutOut, groupSortOrder: 1, childSortOrder: 11 },
};

const environmentTypeMappings: MediaMapping = {
  'Environment web': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 1 },
  'Environment single product': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 2 },
  'Environment multiple products': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 3 },
  'Environment w/model': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 4 },
  'Environment SoMe': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 5 },
  'Environment standard': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 5 },
  'Environment instore': { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 6 },
  Stilleben: { groupName: MediaGroupName.Environment, groupSortOrder: 2, childSortOrder: 7 },
};

const videoTypeMappings: MediaMapping = {
  vimeo: { groupName: MediaGroupName.Video, groupSortOrder: 3, childSortOrder: 1 },
};

const detailTypeMappings: MediaMapping = {
  Detail: { groupName: MediaGroupName.Detail, groupSortOrder: 4, childSortOrder: 1 },
  'Detail #1': { groupName: MediaGroupName.Detail, groupSortOrder: 4, childSortOrder: 2 },
  'Detail #2': { groupName: MediaGroupName.Detail, groupSortOrder: 4, childSortOrder: 3 },
  'Detail #3': { groupName: MediaGroupName.Detail, groupSortOrder: 4, childSortOrder: 4 },
  'Detail w/props': { groupName: MediaGroupName.Detail, groupSortOrder: 4, childSortOrder: 5 },
};

const functionTypeMappings: MediaMapping = {
  Function: { groupName: MediaGroupName.Function, groupSortOrder: 5, childSortOrder: 1 },
  'Line drawing': { groupName: MediaGroupName.Function, groupSortOrder: 5, childSortOrder: 2 },
  'Measurement illustration': { groupName: MediaGroupName.Function, groupSortOrder: 5, childSortOrder: 3 },
  'Packaging w/model': { groupName: MediaGroupName.Function, groupSortOrder: 5, childSortOrder: 4 },
};

const fallbackTypeMappings: MediaMapping = {
  fallback: { groupName: MediaGroupName.Fallback, groupSortOrder: 6, childSortOrder: 1 },
};

// "groupSortOrder" represents the sorting logic mentioned in ProductImages component. "childSortOrder" sorts the items within each group list.
export const typeMappings: MediaMapping = {
  ...cutOutTypeMappings,
  ...environmentTypeMappings,
  ...videoTypeMappings,
  ...detailTypeMappings,
  ...functionTypeMappings,
  ...fallbackTypeMappings,
};

export function applyExtraProperties(
  item: ProductImageFull | ProductMediaVideosOneOfItem,
): (ProductImageFull & AdditionalMediaProps) | (ProductMediaVideosOneOfItem & AdditionalMediaProps) {
  const mappings = typeMappings;
  const { groupName, groupSortOrder, childSortOrder } = mappings[item.type] || mappings.fallback;
  return Object.assign(item, { groupName, groupSortOrder, childSortOrder });
}
