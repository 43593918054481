import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { TextParagraph } from '@jysk/api-types/drupalApi';
import { Text } from '../text';

type ParagraphTextProps = {
  content: TextParagraph;
};

export function ParagraphText({ content }: ParagraphTextProps) {
  const { value, config, backgroundColor } = content;

  if (!value) return null;

  return (
    <ParagraphConfig config={config} backgroundColor={backgroundColor}>
      <Text value={value} />
    </ParagraphConfig>
  );
}
