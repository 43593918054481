'use client';
import { Wrapper } from '@googlemaps/react-wrapper';
import { StoreFull, StoreFullAllOfOpeningHours } from '@jysk/api-types/drupalApi';
import { StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { useStoreLocation } from '@wla/app/(cms)/(favorite-store)/use-store-location';
import {
  FilterTypes,
  StoreLocatorSearch,
  useFilterTypes,
} from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator-search';
import { DEFAULT_ZOOM } from '@wla/components/ui/maps/google-maps';
import { GoogleMapsMarkers } from '@wla/components/ui/maps/google-maps-markers';
import { Spinner } from '@wla/components/ui/spinner';
import { usePublicConfig } from '@wla/lib/configs';
import { usePrevious } from '@wla/lib/hooks/use-previous';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { useMessages } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';

export type StoreInfo = {
  id: string;
  name: string;
  address: string;
  lat: number;
  lng: number;
  openingHours: StoreFullAllOfOpeningHours;
  url: string;
  distance?: number;
  lendATrailer?: boolean;
  outletArea?: boolean;
};

type StoreLocatorProps = {
  focusedStore?: StoreFull;
};

export function StoreLocator({ focusedStore }: StoreLocatorProps) {
  const messages = useMessages();
  const { googleMaps } = usePublicConfig();
  const getFilterTypes = useFilterTypes();
  const mapElmRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const viewportBound = useRef<google.maps.LatLngBounds | null>();
  const isInitialLoad = useRef(true);

  const searchParams = useSearchParams();
  const filtersFromUrl = searchParams.get('filters');
  const previousValue = usePrevious(filtersFromUrl);
  const activeFilters = filtersFromUrl?.split(',');

  const [stores, setStores] = useState<StoreType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { location, viewPort, permission, fetchLocation, setSearchValue } = useStoreLocation();

  useEffect(() => {
    async function getStores() {
      setIsLoading(true);
      try {
        const response = await fetch(`/checkout/api/get-stores`, {
          method: 'POST',
          body: JSON.stringify({
            latitude: undefined,
            longitude: undefined,
          }),
        });

        const { data } = await response.json();
        setStores(data);
      } catch (error) {
        trackError(error);
      } finally {
        setIsLoading(false);
      }
    }

    getStores();
  }, []);

  const storeInfo = stores.map((store) => ({
    id: store.id,
    name: store.name,
    address: `${store.street} ${store.number}, ${store.zipCode} ${store.city}`,
    lat: store.latitude,
    lng: store.longitude,
    url: store.url,
    openingHours: store.openingHours,
    lendATrailer: store.hasLendATrailer,
    outletArea: store.hasOutlet,
  }));

  let storeMarkers = storeInfo;
  if (activeFilters?.includes(getFilterTypes[FilterTypes.OPENNOW])) {
    const date = new Date();
    const currentTime = date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

    storeMarkers = storeMarkers.filter((store) => {
      if (!store.openingHours) return false;

      return (
        parseInt(currentTime) >= store.openingHours[0].startHours &&
        parseInt(currentTime) <= store.openingHours[0].endHours
      );
    });
  }

  if (activeFilters?.includes(getFilterTypes[FilterTypes.FREETAILER])) {
    storeMarkers = storeMarkers.filter((store) => store.lendATrailer);
  }

  if (activeFilters?.includes(getFilterTypes[FilterTypes.OUTLET])) {
    storeMarkers = storeMarkers.filter((store) => store.outletArea);
  }

  const initializeMap = useCallback(() => {
    if (mapElmRef.current && !mapRef.current) {
      mapRef.current = new window.google.maps.Map(mapElmRef.current, {
        center: { lat: 0, lng: 0 },
        zoom: DEFAULT_ZOOM,
        mapId: 'store-locator-map',
      });
    }

    if (mapRef.current && (isInitialLoad.current || previousValue !== filtersFromUrl)) {
      GoogleMapsMarkers({
        map: mapRef.current,
        title: '',
        storeInfo: storeMarkers,
        focusedStore,
        messages,
      });
      isInitialLoad.current = false;
    }
  }, [storeMarkers, filtersFromUrl, previousValue]);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  function fitViewportBounds() {
    if (mapRef.current && viewportBound.current) {
      mapRef.current.fitBounds(viewportBound.current);
    }
  }

  return (
    <>
      {!focusedStore && (
        <StoreLocatorSearch
          mapRef={mapRef}
          stores={storeMarkers}
          location={location}
          viewPort={viewPort}
          permission={permission}
          onSearch={setSearchValue}
          fitViewportBounds={fitViewportBounds}
          onViewportBoundsChange={(newBounds) => {
            viewportBound.current = newBounds;
          }}
          fetchLocation={fetchLocation}
        />
      )}
      <div className="h-[440px] w-full bg-blue-200 lg:h-[700px]">
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="lg" />
          </div>
        ) : (
          <Wrapper apiKey={googleMaps.key}>
            <div
              className="h-full w-full"
              ref={(el) => {
                mapElmRef.current = el;
                if (el) initializeMap();
              }}
            />
          </Wrapper>
        )}
      </div>
    </>
  );
}
