'use client';
import { MostPopularFaqQuestionParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { useTranslations } from 'next-intl';
import { Link } from '../link';

type FaqSectionProps = {
  content: MostPopularFaqQuestionParagraph;
};

export function ParagraphMostPopularFaqSection({ content }: FaqSectionProps) {
  const t = useTranslations('customer-service');
  const { config, items } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config} className="mb-8 flex flex-col gap-4">
      <h2 className="text-3xl font-semibold md:text-5xl">{t('most-popular-questions')}</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {items.map((item) => (
          <Link key={item.title} href={item.url} className="w-fit text-blue-500 underline">
            {item.title}
          </Link>
        ))}
      </div>
    </ParagraphConfig>
  );
}
