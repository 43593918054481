'use client';

import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { Icon } from '@wla/components/ui/icon';
import { CustomerFavouritesContext } from '@wla/lib/hooks/favourites/customer-favourites-context';
import { useContext, useEffect, useState } from 'react';

type FavouriteButtonProps = {
  product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem;
  className?: string;
};

export function FavouriteButton({ product, className }: FavouriteButtonProps) {
  const { isFavouriteProduct, handleFavouriteProductToggle } = useContext(CustomerFavouritesContext);
  const [isClient, setIsClient] = useState(false);

  // needed, otherwise the icon will not be filled in when the page is reloaded and it IS a favourite product
  // solution from https://nextjs.org/docs/messages/react-hydration-error
  // Jira ticket for refactoring this: https://jysk.atlassian.net/browse/CG-26210
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <button onClick={() => handleFavouriteProductToggle(product)} className={className}>
      {isClient && (
        <Icon
          src={isFavouriteProduct(product?.id) ? 'w3-wishlist-filled' : 'w3-wishlist'}
          className="h-5 w-5 cursor-pointer"
          size="20"
        />
      )}
    </button>
  );
}
