'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Drawer } from '@wla/components/ui/drawer';
import PayoneCVCCVV from '@wla/public/payone-cvc-cvv.png';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

export const PaymentPayoneDrawer = NiceModal.create(() => {
  const t = useTranslations('checkout');
  const { visible, hide } = useModal();

  return (
    <Drawer open={visible} onClose={hide} position="right">
      <Drawer.Header title={t('payment.cvc-or-cvv')} className="mb-8" />
      <Drawer.Body className="mt-0">
        <Image src={PayoneCVCCVV} alt={t('payment.cvc-or-cvv')} width={300} height={300} className="w-full" />
      </Drawer.Body>
    </Drawer>
  );
});
