import * as Slider from '@radix-ui/react-slider';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useEffect, useState } from 'react';
import { Input } from './forms/input';

/**
 * @description Use the RangeSlider to pick two numbers in a range
 *
 * @param { Number } min value of the range
 * @param { Number } max value of the range
 * @param { Number } thumbMin selected value of the left handle
 * @param { Number } thumbMax selected value of the right handle
 *
 * @returns { Number[] } the selected range as a tuplet
 */
type RangeSliderProps = {
  min?: number;
  max?: number;
  thumbMin?: number;
  thumbMax?: number;
  onChange?: (value: number[]) => void;
};

function clamp(value: number, min: number, max: number) {
  return Math.min(max, Math.max(value, min));
}

export function RangeSlider({ min = 0, max = 100, thumbMin, thumbMax, onChange }: RangeSliderProps) {
  const t = useTranslations('plp');
  const [minValue, setMinValue] = useState<number>(min);
  const [maxValue, setMaxValue] = useState<number>(max);

  const thumbClassName = `cursor-pointer z-50 block w-6 h-6 \
    font-bold bg-white border-[9px] \
    rounded-full shadow-xl outline-none \
    border-spacing-[20px] border-brand-500 \
    ring-indigo-400 focus:ring-4`;

  function handleMinChange(event: ChangeEvent<HTMLInputElement>) {
    if (Number(event.target.value) < min) {
      const value = event.target.value.replace(',', '.');
      return setMinValue(parseFloat(value));
    }
    const newValue = clamp(Number(event.target.value), min, max);
    setMinValue(newValue);
    onChange && onChange([newValue, maxValue]);
  }

  function handleMaxChange(event: ChangeEvent<HTMLInputElement>) {
    if (Number(event.target.value) > max || Number(event.target.value) < minValue) {
      const value = event.target.value.replace(',', '.');
      return setMaxValue(parseFloat(value));
    }
    const newValue = clamp(Number(event.target.value), min, max);
    setMaxValue(newValue);
    onChange?.([minValue, newValue]);
  }

  function handleValueChange([newMin, newMax]: number[]) {
    setMinValue(newMin);
    setMaxValue(newMax);
  }

  useEffect(() => {
    // Update the values if the props change, e.g. when the user resets the filters
    setMinValue(thumbMin || min);
    setMaxValue(thumbMax || max);
  }, [thumbMin, thumbMax]);

  return (
    <div>
      <Slider.Root
        className="relative flex h-6 w-full select-none items-center"
        value={[minValue, maxValue]}
        onValueChange={handleValueChange}
        min={min}
        max={max}
        minStepsBetweenThumbs={0}
        onValueCommit={onChange}
        data-testid="rangeslider-root"
      >
        <Slider.Track
          data-testid="rangeslider-track"
          className="relative h-1 flex-grow rounded-full bg-gray-300 outline-none"
        >
          <Slider.Range
            data-testid="rangeslider-range"
            className="absolute h-full rounded-full bg-brand-500 outline-none"
          />
        </Slider.Track>
        <Slider.Thumb className={thumbClassName} />
        <Slider.Thumb className={thumbClassName} />
      </Slider.Root>
      <div className="mt-6 grid grid-cols-2 justify-between gap-4">
        <FormGroup
          label={t('min')}
          inputType={InputTypes.Input}
          validationError={minValue < min ? t('min-validation') : ''}
          className="max-w-28"
        >
          <Input className="md:w-[118px]" name="minPrice" type="number" value={minValue} onChange={handleMinChange} />
        </FormGroup>
        <FormGroup
          className="ml-auto max-w-28"
          label={t('max')}
          inputType={InputTypes.Input}
          validationError={maxValue > max || maxValue < minValue ? t('max-validation') : ''}
        >
          <Input className="w-[118px]" name="maxPrice" type="number" value={maxValue} onChange={handleMaxChange} />
        </FormGroup>
      </div>
    </div>
  );
}
