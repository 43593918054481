import { FavoriteStoreDetails } from '@wla/app/(cms)/(favorite-store)/favorite-store-details';
import { StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { AvailabilityStatus, FavoriteStoreStockBasket } from '@wla/app/(cms)/(favorite-store)/favorite-store-stock';
import { StoreCard } from '@wla/app/(cms)/(favorite-store)/store-drawer/store-card';
import { OnlineAtp } from '@wla/app/(cms)/[...slug]/(product-detail-page)/add-to-basket';
import { StatusIndicatorStatus } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { isProductFullTypeGuard } from '@wla/lib/helpers/product';
import { useProductAtp } from '@wla/lib/hooks/product-atp/use-product-atp';
import { ProductStatusCodes } from '@wla/types/product';
import { useTranslations } from 'next-intl';
import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';

type FavoriteStoreCardProps = {
  store: StoreType;
  onlineProductAtp: OnlineAtp | null;
  isSelected?: boolean;
  product?: ProductFull | ProductTeaser;
  clickCollectBasketAvailability: AvailabilityStatus;
  onClick?: (storeId: string) => void;
};

export function FavoriteStoreCard({
  store,
  product,
  onlineProductAtp,
  isSelected,
  clickCollectBasketAvailability,
  onClick,
}: FavoriteStoreCardProps) {
  const t = useTranslations();
  const { getStoreStockStatus } = useProductAtp();

  function findAvailability(): AvailabilityStatus {
    const storeStockStatus = getStoreStockStatus({
      product,
      storesWithAtp: store?.atp?.units || 0,
      onlineAtp: onlineProductAtp?.qty || 0,
      selectedStoreUnit: store?.atp?.units,
      storeName: store.name,
    });

    if (storeStockStatus.status === StatusIndicatorStatus.Success) return AvailabilityStatus.InStock;
    if (storeStockStatus.status === StatusIndicatorStatus.Warning) return AvailabilityStatus.Extended;
    if (storeStockStatus.status === StatusIndicatorStatus.Error) return AvailabilityStatus.OutOfStock;

    return AvailabilityStatus.InStock;
  }

  const isClickCollectAvailable = clickCollectBasketAvailability === AvailabilityStatus.InStock;
  const isProductUnavailable =
    product && (!store.atp?.units || store.atp?.units <= 0 || findAvailability() !== AvailabilityStatus.InStock);

  const onDisplayText = store.atp?.onDisplay
    ? t('favorite-store.shop-with-product-on-display')
    : isProductFullTypeGuard(product) && product.statusCode === ProductStatusCodes.PROCURED_ARTICLES
      ? t('favorite-store.shop-with-a-comparable-product')
      : '';

  return (
    <StoreCard
      title={t('common.jysk-store', {
        storeName: store.name,
      })}
      headerSlot={product?.id && <p className="text-xs text-gray-500">{onDisplayText}</p>}
      isSelected={isSelected}
      isDisabled={!isClickCollectAvailable || isProductUnavailable}
      onClick={() => onClick?.(store.id)}
    >
      <FavoriteStoreDetails store={store} product={product} onlineProductAtp={onlineProductAtp} />
      <FavoriteStoreStockBasket availability={clickCollectBasketAvailability} />
    </StoreCard>
  );
}
