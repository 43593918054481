import NiceModal from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { NotifyBackInStockSchema, NotifyFormData } from '@wla/app/(cms)/(back-in-stock-drawer)/back-in-stock-schema';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { Link } from '@wla/components/ui/link';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';

type BackInStockFormProps = {
  articleId: string;
  onSubmit: (data: NotifyFormData) => void;
};

export function BackInStockForm({ articleId, onSubmit }: BackInStockFormProps) {
  const t = useTranslations();

  const form = useForm<NotifyFormData>({
    mode: 'onBlur',
    resolver: zodResolver(NotifyBackInStockSchema),
    defaultValues: {
      articleId,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  return (
    <Form form={form} onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
      <Input type="hidden" {...register('articleId')} />
      <FormGroup
        label={t('forms.email')}
        inputType={InputTypes.Input}
        required
        validationError={errors.email?.message && t('forms.errors.not-valid.email-not-valid')}
      >
        <Input {...register('email')} type="email" />
      </FormGroup>
      <FormGroup
        validationError={errors.acceptTermsAndConditions?.message && t('common.please-accept-terms-and-conditions')}
        inputType={InputTypes.Checkbox}
        required
      >
        <Checkbox className="my-0.5 mr-2 flex items-start" {...register('acceptTermsAndConditions')}>
          {t.rich('pdp.back-in-stock.terms-and-conditions', {
            link: () => (
              <Link
                as="button"
                onClick={() => {
                  NiceModal.show(DRAWER_ID.TERMS_AND_CONDITIONS_DRAWER);
                }}
              >
                {t('forms.terms-and-conditions')}
              </Link>
            ),
          })}
        </Checkbox>
      </FormGroup>
      <SubmitButton className="w-full">{t('pdp.back-in-stock.notify-me')}</SubmitButton>
    </Form>
  );
}
