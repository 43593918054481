import { Link } from '@wla/components/ui/link';
import { ReactNode } from 'react';

type ContactLineProps = {
  href: string;
  children?: ReactNode;
};
export function ContactLink({ children, href }: ContactLineProps) {
  return (
    <Link className="text-base text-blue-500 underline" href={href}>
      {children}
    </Link>
  );
}
