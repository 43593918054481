import { cn } from '@wla/lib/helpers/cn';
import { ReactNode } from 'react';
import { ParagraphConfig as ParagraphConfigType, ParagraphConfigWhitespace } from '@jysk/api-types/drupalApi';

type ParagraphConfigProps = {
  config?: ParagraphConfigType | null;
  backgroundColor?: string | null;
  className?: string;
  children: ReactNode;
};

export function ParagraphConfig({ config, backgroundColor, children, className }: ParagraphConfigProps) {
  return (
    <div
      className={cn(className !== undefined ? className : '', {
        'mb-0': config?.whitespace === ParagraphConfigWhitespace.none,
        'mb-4': config?.whitespace === ParagraphConfigWhitespace.normal,
        'mb-10': config?.whitespace === ParagraphConfigWhitespace.medium,
        'mb-20': config?.whitespace === ParagraphConfigWhitespace.large,
        'p-4': backgroundColor && backgroundColor !== '#FFFFFF',
      })}
      style={{ backgroundColor: backgroundColor ?? undefined }}
    >
      {children}
    </div>
  );
}
