import { ProductCarouselParagraph } from '@jysk/api-types/drupalApi';
import { ProductCardCarousel } from '../product-card-carousel';
import { ParagraphConfig } from './paragraph-config';

type ParagraphCarouselProps = {
  content: ProductCarouselParagraph;
};

export function ParagraphCarousel({ content }: ParagraphCarouselProps) {
  const { config, items } = content;
  const products = items;

  if (!products) return null;

  return (
    <ParagraphConfig config={config}>
      <div className="py-8">
        <ProductCardCarousel products={products} />
      </div>
    </ParagraphConfig>
  );
}
