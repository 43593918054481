import { PostSearch200ProductsFacets } from '@jysk/api-types/searchApi/model';
import { LatLngLiteral, ViewPort } from '@wla/app/(cms)/(favorite-store)/use-store-location';
import { FilterSearchDrawer } from '@wla/app/(cms)/[...slug]/(filters)/filter-search-drawer';
import { RemoveFilterButtons } from '@wla/app/(cms)/[...slug]/(filters)/remove-filter-buttons';
import { getMapPointsDistance } from '@wla/app/(cms)/[...slug]/(store-locator)/get-map-points-distance';
import { StoreInfo } from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator';
import { AllFiltersButton } from '@wla/components/ui/button/all-filters-button';
import { Icon } from '@wla/components/ui/icon';
import { markersArray } from '@wla/components/ui/maps/google-maps-markers';
import { Search } from '@wla/components/ui/search';
import { useTranslations } from 'next-intl';
import { MutableRefObject, useEffect, useState } from 'react';

type StoreLocatorSearchProps = {
  mapRef: MutableRefObject<google.maps.Map | null>;
  stores: StoreInfo[];
  location?: LatLngLiteral;
  viewPort?: ViewPort;
  permission?: boolean;
  onSearch: (value: string) => void;
  fitViewportBounds: () => void;
  onViewportBoundsChange: (bounds: google.maps.LatLngBounds) => void;
  fetchLocation: () => void;
};

export enum FilterTypes {
  OPENNOW = 'open-now',
  FREETAILER = 'free-trailer',
  OUTLET = 'outlet',
}

export function useFilterTypes() {
  const t = useTranslations();
  return {
    [FilterTypes.OPENNOW]: t('store-locator.open-now'),
    [FilterTypes.FREETAILER]: t('store-locator.book-a-free-trailer'),
    [FilterTypes.OUTLET]: t('store-locator.outlet-area'),
  };
}

export function StoreLocatorSearch({
  mapRef,
  stores,
  location,
  viewPort,
  permission,
  onSearch,
  fitViewportBounds,
  onViewportBoundsChange,
  fetchLocation,
}: StoreLocatorSearchProps) {
  const t = useTranslations();
  const getFilterTypes = useFilterTypes();
  const [toggleFilterDrawer, setToggleFilterDrawer] = useState(false);

  function storeToLatLng(store: StoreInfo) {
    return { lat: store.lat, lng: store.lng };
  }

  async function displayStores(stores: StoreInfo[], geoLocation: google.maps.LatLngLiteral) {
    const boundsInstance = new google.maps.LatLngBounds();
    if (stores.length === 1) {
      const store = stores[0];
      const storeLatLng = storeToLatLng(store);
      boundsInstance.extend(storeLatLng);

      // Zoom out a bit to show the single store
      const zoomOutDistance = 0.01;
      boundsInstance.extend({
        lat: storeLatLng.lat + zoomOutDistance,
        lng: storeLatLng.lng + zoomOutDistance,
      });
      boundsInstance.extend({
        lat: storeLatLng.lat - zoomOutDistance,
        lng: storeLatLng.lng - zoomOutDistance,
      });

      onViewportBoundsChange(boundsInstance);
      fitViewportBounds();

      if (!mapRef.current) return;

      // Find the marker for the single store
      const marker = markersArray.find((m) => m.position?.lat === store.lat && m.position.lng === store.lng);
      if (marker) {
        google.maps.event.trigger(marker, 'click');
      }
    } else {
      boundsInstance.extend(geoLocation);
      stores.forEach((store) => boundsInstance.extend(storeToLatLng(store)));
      onViewportBoundsChange(boundsInstance);
      fitViewportBounds();
    }
  }
  function displayStoresFromPosition(
    geoLocation: google.maps.LatLngLiteral,
    viewPortBounds = new google.maps.LatLngBounds(),
  ) {
    const storesWithinBounds = stores.filter((store) => viewPortBounds.contains(storeToLatLng(store)));

    if (storesWithinBounds.length >= 1) {
      displayStores(storesWithinBounds, geoLocation);
      return;
    }

    // No stores within viewport bounds, expand search
    const shopsSortedByDistance = stores.map((store) => {
      store.distance = getMapPointsDistance(geoLocation, store);
      return store;
    });
    shopsSortedByDistance.sort((a, b) => ((a.distance ?? 0) < (b.distance ?? 0) ? -1 : 1));

    // Filter out stores that are too far away from the closest shop
    const minimumDistance = 500;
    const closestDistance =
      (shopsSortedByDistance[0].distance ?? 0) > minimumDistance
        ? shopsSortedByDistance[0].distance ?? 0
        : minimumDistance;

    // Add stores within 25% of the closest distance
    const storesWithinRange = shopsSortedByDistance.filter((store) => {
      const factor = (store.distance ?? 0) / closestDistance;
      if (factor < 1.25) {
        return store;
      }
    });

    displayStores(storesWithinRange, geoLocation);
  }

  const filters: PostSearch200ProductsFacets = {
    sorted: Object.values(getFilterTypes).map((key) => ({
      name: key,
      type: 'boolean',
      isSelected: false,
      numberOfDocuments: 1, // If 0, the filter will be disbaled
    })),
  };

  useEffect(() => {
    if (!location || !viewPort) return;

    const geoLocation = {
      lat: location.latitude,
      lng: location.longitude,
    };

    const viewPortBounds = new google.maps.LatLngBounds(
      { lat: viewPort.southwest.latitude, lng: viewPort.southwest.longitude },
      { lat: viewPort.northeast.latitude, lng: viewPort.northeast.longitude },
    );

    displayStoresFromPosition(geoLocation, viewPortBounds);
  }, [location]);

  return (
    <>
      <FilterSearchDrawer
        toggleFilterDrawer={toggleFilterDrawer}
        filters={filters}
        onFilterDrawerToggle={() => setToggleFilterDrawer(!toggleFilterDrawer)}
        productCount={filters.sorted?.length || 0}
      />
      <div className="flex flex-col gap-4 py-4">
        <div className="flex gap-3">
          <Search
            className="placeholder:truncate lg:max-w-[33.25rem]"
            handleSearch={onSearch}
            placeholder={t('store-locator.post-code-town-or-street')}
          />
          <AllFiltersButton
            title={t('common.filter')}
            className="whitespace-nowrap rounded border-gray-300 pl-2.5 pr-4"
            onClick={() => setToggleFilterDrawer(!toggleFilterDrawer)}
            size="medium"
          />
        </div>
        {permission && (
          <button className="flex items-center gap-2" onClick={fetchLocation}>
            <Icon src="w3-store-locator" size="20" />
            <p className="text-gray-500 hover:underline">{t('store-locator.use-my-current-location')}</p>
          </button>
        )}
        <RemoveFilterButtons />
      </div>
    </>
  );
}
