'use client';

import { NavigationButton } from '@wla/components/ui/carousel/navigation-button';
import { cn } from '@wla/lib/helpers/cn';
import { useBreakpoint } from '@wla/lib/hooks/use-breakpoint';
import { Children, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

type CarouselProps = {
  disableNaivagtionButtons?: boolean;
  className?: string;
  parentClass?: string;
  showPagination?: boolean;
  paginationClass?: string;
  buttonOverflow?: boolean;
  previewNextSlide?: boolean;
  visibleItems?: number;
  initialSlide?: number;
  visibleItemsMd?: number;
  visibleItemsSm?: number;
};

export function Carousel({
  children,
  disableNaivagtionButtons,
  className,
  parentClass,
  paginationClass,
  buttonOverflow,
  showPagination = false,
  previewNextSlide = true,
  initialSlide = 0,
  visibleItems = 4,
  visibleItemsMd,
  visibleItemsSm,
}: PropsWithChildren<CarouselProps>) {
  const { getBreakPoint } = useBreakpoint();
  const carouselRef = useRef<HTMLDivElement>(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [activeIndex, setActiveIndex] = useState(initialSlide);
  const [columnWidth, setColumnWidth] = useState(100 / visibleItems);
  const nextChildAmount = previewNextSlide ? 0.5 : 0;

  const { valid: isSm } = getBreakPoint('sm');
  const { valid: isMd } = getBreakPoint('md');
  const { valid: isLg } = getBreakPoint('lg');

  const updateScrollButtons = useCallback(() => {
    if (!carouselRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;

    setActiveIndex(Math.round(scrollLeft / clientWidth));
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  }, [disableNaivagtionButtons]);

  const scrollLeft = useCallback(() => {
    carouselRef?.current?.scrollBy({
      left: -carouselRef.current.offsetWidth,
      behavior: 'smooth',
    });
  }, []);

  const scrollRight = useCallback(() => {
    carouselRef?.current?.scrollBy({
      left: carouselRef.current.offsetWidth,
      behavior: 'smooth',
    });
  }, []);

  function handleGoToImage(index: number) {
    carouselRef?.current?.scrollBy({
      left: (index - activeIndex) * carouselRef.current.offsetWidth,
      behavior: 'smooth',
    });
    setActiveIndex(index);
  }

  function getColumnWidth() {
    const items = visibleItems > 6 ? 6 : visibleItems;

    if (isLg) {
      return 100 / items;
    }

    if (isMd) {
      return 100 / ((visibleItemsMd || items - 1) + nextChildAmount); // Show nextChildAmount of the next child
    }

    if (isSm) {
      return 100 / ((visibleItemsSm || items - 2) + nextChildAmount); // Show nextChildAmount of the next child
    }

    return 100 / ((visibleItemsSm || 1) + nextChildAmount); // Show nextChildAmount of the next child
  }

  useEffect(() => {
    if (disableNaivagtionButtons) return;

    updateScrollButtons();
    const handleScroll = () => updateScrollButtons();
    const currentCarouselRef = carouselRef.current;

    if (currentCarouselRef) {
      currentCarouselRef.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll); // Update buttons on window resize
    }

    return () => {
      if (!currentCarouselRef) return;

      currentCarouselRef.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [updateScrollButtons, disableNaivagtionButtons]);

  useEffect(() => {
    const columnWidth = getColumnWidth();

    setColumnWidth(columnWidth);
  }, [visibleItems, isSm, isMd, isLg]);

  useEffect(() => {
    if (!carouselRef.current) return;

    const newScrollPosition = initialSlide * carouselRef.current.offsetWidth;
    carouselRef.current.scrollLeft = newScrollPosition;
    setActiveIndex(initialSlide);
  }, [initialSlide]);

  return (
    <div className={cn('relative', parentClass)}>
      {!disableNaivagtionButtons && canScrollLeft && (
        <NavigationButton aria-label="Previous" buttonOverflow={buttonOverflow} onClick={scrollLeft} isPrev />
      )}
      <div
        ref={carouselRef}
        // Tailwind grid classes does not support grid-template-columns with percentages
        style={{
          gridAutoColumns: `minmax(${columnWidth}%, ${columnWidth}%)`,
        }}
        className={cn(
          'scrollbar-hide grid snap-x snap-mandatory grid-flow-col overflow-x-auto overflow-y-hidden',
          className,
        )}
      >
        {children}
      </div>
      {showPagination && (
        <div
          className={cn(
            'absolute bottom-6 right-6 grid h-2 w-full grid-flow-col items-center justify-end gap-2',
            paginationClass,
          )}
        >
          {Children.map(children, (_, index) => (
            <button
              className={cn(
                'h-2 w-2 rounded-full bg-gray-300 transition-all duration-300 hover:h-2 hover:w-2',
                // The farther away from the activeIndex, the smaller the dot
                activeIndex === index && 'bg-gray-600',
                Math.abs(activeIndex - index) > 1 && 'h-1.5 w-1.5',
                Math.abs(activeIndex - index) > 2 && 'hidden',
              )}
              onClick={() => handleGoToImage(index)}
              key={index}
            />
          ))}
        </div>
      )}
      {!disableNaivagtionButtons && canScrollRight && (
        <NavigationButton aria-label="Next" buttonOverflow={buttonOverflow} onClick={scrollRight} />
      )}
    </div>
  );
}

type CarouselItemProps = {
  className?: string;
};

export function CarouselItem({ children, className }: PropsWithChildren<CarouselItemProps>) {
  return <div className={cn('snap-start pr-4 only:px-0 md:pr-8', className)}>{children}</div>;
}
