import { DeliveryGroup, Order, ShoppingSession } from '@jysk/api-types/orderManagementApi/model';

export function countOrderLinesInGroup(group: DeliveryGroup) {
  return group?.orderLines?.reduce((acc, line) => acc + (line.quantity || 0), 0) ?? 0;
}

export function countOrderLinesInOrder(order: Order) {
  return order?.deliveryGroups?.reduce((acc, group) => acc + countOrderLinesInGroup(group), 0) ?? 0;
}

export function countOrderLinesInShoppingSession(shoppingSession: ShoppingSession | undefined | null) {
  return shoppingSession?.active?.orders?.reduce((acc, order) => acc + countOrderLinesInOrder(order), 0) ?? 0;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
