import { Icon, IconName } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { Url } from 'next/dist/shared/lib/router/router';
import { ElementType } from 'react';

type ContactLineProps = {
  icon: IconName;
  href?: Url;
  header: string;
  subText?: string;
};
export function ContactLine({ header, icon, subText, href }: ContactLineProps) {
  const LinkElm: ElementType = href ? Link : 'div';
  return (
    <LinkElm {...(href && { href: href })} className="flex flex-col gap-4 pb-4">
      <hr className="h-0 w-full border-gray-300" />
      <div className="flex min-h-12 items-center gap-4">
        <Icon src={icon} size="24" className="row-span-2 block lg:hidden" />
        <Icon src={icon} size="32" className="row-span-2 hidden lg:block" />
        <div className="flex flex-col gap-1 align-middle">
          <p className="text-left text-blue-500 underline md:text-lg">{header}</p>
          <p className="text-sm text-gray-500">{subText}</p>
        </div>
      </div>
    </LinkElm>
  );
}
