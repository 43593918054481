import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Alert } from '@wla/components/ui/alert';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { Dialog } from '../dialog';
import { Spinner } from '../spinner';

type GiftcardPreviewModalProps = {
  loading?: boolean;
  url: string;
  error?: string;
  maxWidth: number;
  maxHeight: number;
};

export const GiftcardPreviewModal = NiceModal.create(
  ({ url, loading, error, maxWidth, maxHeight }: GiftcardPreviewModalProps) => {
    const t = useTranslations('gift-card');
    const modal = useModal();

    if (error) {
      return <Alert type="error">{t('errors.preview-gift-card')}</Alert>;
    }

    return (
      <Dialog open={modal.visible} onClose={modal.hide} isModal>
        <Dialog.Panel>
          <Dialog.Header>
            <Dialog.Close />
          </Dialog.Header>
          <Dialog.Body>
            {loading && <Spinner />}
            {url && <Image src={url} alt={t('preview.gift-card-preview-image')} width={maxWidth} height={maxHeight} />}
          </Dialog.Body>
        </Dialog.Panel>
      </Dialog>
    );
  },
);
