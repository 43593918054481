import { IframeParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { Iframe } from '../iframe';

type ParagraphIframeProps = {
  content: IframeParagraph;
};

export function ParagraphIframe({ content }: ParagraphIframeProps) {
  const { config, url } = content;

  if (!url) return null;

  return (
    <ParagraphConfig config={config}>
      <Iframe url={url} />
    </ParagraphConfig>
  );
}
