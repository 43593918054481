'use client';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PageAnalytics } from '@wla/app/(cms)/[...slug]/page-analytics';
import { Alert } from '@wla/components/ui/alert';
import { Drawer } from '@wla/components/ui/drawer';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { TextArea } from '@wla/components/ui/forms/textArea';
import { Link } from '@wla/components/ui/link';
import { Rating } from '@wla/components/ui/rating/rating';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { MouseEvent, useEffect, useState } from 'react';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import { createReview } from './create-review-action';
import { CreateReviewFormData, createReviewSchema } from './create-review-schema';

type CreateReviewDrawerProps = {
  productId: string;
};

const initialState = {
  success: false,
  messageTitle: '',
  messageBody: '',
};

export const CreateReviewDrawer = NiceModal.create(({ productId }: CreateReviewDrawerProps) => {
  const t = useTranslations();
  const { visible, hide } = useModal();
  const [rating, setRating] = useState<number>(0);
  const [result, formAction] = useFormState(createReview, initialState);
  const form = useForm<CreateReviewFormData>({
    mode: 'onBlur',
    resolver: zodResolver(createReviewSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = form;

  function handleSecondDrawerOpen(drawerId: DRAWER_ID, e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    hide();
    NiceModal.show(drawerId, {
      reviewData: getValues(),
      onClose: (reviewData: CreateReviewFormData) => {
        setValue('title', reviewData.title);
        setValue('description', reviewData.description);
        setValue('name', reviewData.name);
        setValue('email', reviewData.email);
        setValue('acceptTermsAndConditions', reviewData.acceptTermsAndConditions);
        NiceModal.show(DRAWER_ID.CREATE_REVIEW_DRAWER, { productId });
      },
    });
  }

  function onSubmit(data: CreateReviewFormData) {
    const formData = new FormData();
    formData.append('rating', rating.toString());
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('acceptTermsAndConditions', data.acceptTermsAndConditions);
    formData.append('productId', productId);
    formAction(formData);
  }

  function handleClose() {
    hide();
    setRating(0);
    result.success = false;
    result.messageTitle = '';
    result.messageBody = '';
    reset();
  }

  useEffect(() => {
    if (rating > 0) {
      setValue('rating', rating, { shouldValidate: true });
    }
  }, [rating]);

  return (
    <Drawer open={visible} onClose={handleClose}>
      <PageAnalytics pageType="reviewPage" />
      <Drawer.Header title={t('forms.write-a-review')} />
      <Drawer.Body className="flex flex-col gap-4 pb-32 sm:pb-0">
        {result.messageTitle ? (
          <Alert type={result.success ? 'success' : 'error'} className="flex flex-col text-sm">
            <p>{result.messageTitle}</p>
            <p>{result.messageBody}</p>
          </Alert>
        ) : (
          <Form form={form} action={formAction} onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="productId" value={productId} />
            <input type="hidden" value={rating.toString()} {...register('rating')} />
            <div className="mb-4">
              <p className="mb-2 font-semibold">{t('pdp.review.reviews-star')} *</p>
              <Rating onChange={(newRating) => setRating(newRating)} rating={rating} iconSize="2xl" />
              {errors.rating && <div className="mt-2 text-xs text-red-500">{t(errors.rating.message)}</div>}
            </div>
            <FormGroup
              label={t('forms.headline')}
              inputType={InputTypes.Input}
              required
              validationError={errors.title && t(errors.title.message)}
              className="mb-3"
            >
              <Input {...register('title')} />
            </FormGroup>
            <FormGroup
              label={t('pdp.review.review')}
              inputType={InputTypes.TextArea}
              required
              validationError={errors.description && t(errors.description.message)}
              className="mb-3"
            >
              <TextArea {...register('description')} className="h-40" rows={3} />
            </FormGroup>
            <FormGroup
              label={t('forms.first-name')}
              inputType={InputTypes.Input}
              required
              validationError={errors.name && t(errors.name.message)}
              className="mb-3"
            >
              <Input {...register('name')} />
            </FormGroup>
            <FormGroup
              label={t('forms.email')}
              inputType={InputTypes.Input}
              required
              validationError={errors.email && t(errors.email.message)}
              className="mb-4"
            >
              <Input {...register('email')} />
            </FormGroup>
            <FormGroup
              validationError={errors.acceptTermsAndConditions && t(errors.acceptTermsAndConditions.message)}
              inputType={InputTypes.Checkbox}
              required
            >
              <Checkbox {...register('acceptTermsAndConditions')}>
                {t.rich('forms.yes-i-have-read-terms-and-conditions', {
                  link1: () => (
                    <Link
                      as="button"
                      onClick={(e: MouseEvent<HTMLButtonElement>) =>
                        handleSecondDrawerOpen(DRAWER_ID.REVIEW_GUIDELINES_DRAWER, e)
                      }
                    >
                      {t('forms.guidelines-link-button')}
                    </Link>
                  ),
                  link2: () => (
                    <Link
                      as="button"
                      onClick={(e: MouseEvent<HTMLButtonElement>) =>
                        handleSecondDrawerOpen(DRAWER_ID.TERMS_AND_CONDITIONS_DRAWER, e)
                      }
                    >
                      {t('forms.terms-and-conditions')}
                    </Link>
                  ),
                })}
              </Checkbox>
            </FormGroup>
            <SubmitButton loadingMessage={t('common.loading')} className="mt-4 w-full">
              {t('forms.submit-review')}
            </SubmitButton>
          </Form>
        )}
      </Drawer.Body>
    </Drawer>
  );
});
