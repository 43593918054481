'use client';

import NiceModal from '@ebay/nice-modal-react';
import { PageContext } from '@wla/app/(cms)/[...slug]/page-provider';
import { usePublicConfig } from '@wla/lib/configs';
import { DRAWER_ID } from '@wla/lib/modals';
import { trackAddToFavouritesList } from '@wla/lib/tracking/google-analytics-tracking';
import Cookies from 'js-cookie';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { ProductFull, ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { WishListItem } from '@jysk/api-types/wssCustomerApi/model';
import { FAVOURITE_IDS } from '../../constants';
import { useCustomerSession } from '../customer-session/use-customer-session';
import { addFavourites, deleteFavourite } from './favourite-product-actions';
import { FavouritesAction } from './favourites-error-modal';

export const FAVOURITES_GUEST = 'favouritesGuest';

type CustomerFavouritesContextType = {
  loading: boolean;
  favouriteIds: string[];
  isFavouriteProduct: (productId: string) => boolean;
  handleFavouriteProductToggle: (product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem) => void;
  handleRemoveFromFavourites: (id: string) => void;
};

export const CustomerFavouritesContext = createContext<CustomerFavouritesContextType>(
  {} as CustomerFavouritesContextType,
);

type ContextProviderProps = {
  children: ReactNode;
};

export const CustomerFavouritesProvider = ({ children }: ContextProviderProps) => {
  const { currency } = usePublicConfig();
  const [loggedInFavouriteIds, setLoggedInFavouriteIds] = useState<string[]>([]);
  const cookieFavouriteIds = Cookies.get(FAVOURITE_IDS);
  const [loggedOutFavouriteIds, setLoggedOutFavouriteIds] = useState<string[]>(
    cookieFavouriteIds ? (JSON.parse(cookieFavouriteIds) as string[]) : [],
  );
  const { customerSession, isB2C } = useCustomerSession();
  const [isLoadingAddFavourite, setIsLoadingAddFavourite] = useState(false);
  const [isLoadingDeleteFavourite, setIsLoadingDeleteFavourite] = useState(false);
  const { pageType } = useContext(PageContext);

  const isFavouriteProduct = (productId: string) => {
    return loggedInFavouriteIds.includes(productId) || loggedOutFavouriteIds.includes(productId);
  };

  useEffect(() => {
    async function addToFavourites() {
      if (isB2C) {
        // if user logs in, add missing items from loggedOutFavourites to loggedInFavourites and remove all loggedOutFavourites
        const itemsToBeAdded = loggedOutFavouriteIds.filter((id) => !loggedInFavouriteIds.includes(id));
        if (itemsToBeAdded.length > 0) {
          const response = await fetch('/favourites/api/add-favourites', {
            method: 'POST',
            body: JSON.stringify({ query: itemsToBeAdded }),
          });
          const { success } = await response.json();
          if (!success)
            return NiceModal.show(DRAWER_ID.FAVOURITES_ERROR_MODAL, {
              productIds: itemsToBeAdded,
              actionType: FavouritesAction.Add,
            });
          Cookies.set(FAVOURITE_IDS, JSON.stringify([]));
          setLoggedOutFavouriteIds([]);
        }

        // get all favouriteIds (needed here to update isFavouriteProduct accordingly)
        const res = await fetch('/favourites/api/get-favourites-list');
        const json = await res.json();
        if (json.success) {
          const favouritesList = json.data[0].wishListItems as WishListItem[];
          const ids = favouritesList
            .map((favourite) => favourite.articleId)
            .filter((id) => id !== undefined) as string[];
          setLoggedInFavouriteIds(ids);
        }
      }
    }
    addToFavourites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isB2C]);

  async function addToFavourites(id: string, product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem) {
    setIsLoadingAddFavourite(true);
    try {
      addFavourites({ articleIds: [id] });
      trackAddToFavouritesList({ product, currency, pageType });
      setLoggedInFavouriteIds([...loggedInFavouriteIds, id]);
    } catch (err) {
      return NiceModal.show(DRAWER_ID.FAVOURITES_ERROR_MODAL, { productIds: [id], actionType: FavouritesAction.Add });
    } finally {
      setIsLoadingAddFavourite(false);
    }
  }

  async function addToLoggedOutFavourites(
    id: string,
    product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem,
  ) {
    const updatedFavourites = [...loggedOutFavouriteIds, id];
    trackAddToFavouritesList({ product, currency, pageType });
    Cookies.set(FAVOURITE_IDS, JSON.stringify(updatedFavourites));
    setLoggedOutFavouriteIds(updatedFavourites);
  }

  async function removeFromFavourites(id: string) {
    setIsLoadingDeleteFavourite(true);
    try {
      deleteFavourite({ id });
      const index = loggedInFavouriteIds.findIndex((favId) => favId === id);
      if (index > -1) {
        setLoggedInFavouriteIds([...loggedInFavouriteIds.slice(0, index), ...loggedInFavouriteIds.slice(index + 1)]);
      }
    } catch (error) {
      return NiceModal.show(DRAWER_ID.FAVOURITES_ERROR_MODAL, { productId: id, actionType: FavouritesAction.Delete });
    } finally {
      setIsLoadingDeleteFavourite(false);
    }
  }

  async function removeFromLoggedOutFavourites(id: string) {
    const index = loggedOutFavouriteIds.findIndex((favId) => favId === id);
    if (index > -1) {
      const updatedFavourites = [...loggedOutFavouriteIds.slice(0, index), ...loggedOutFavouriteIds.slice(index + 1)];
      Cookies.set(FAVOURITE_IDS, JSON.stringify(updatedFavourites));
      setLoggedOutFavouriteIds(updatedFavourites);
    }
  }

  async function handleFavouriteProductToggle(
    product: ProductFull | ProductTeaser | PostSearch200ProductsHitsItem,
  ): Promise<void> {
    const { id } = product;
    const isFavourite = isFavouriteProduct(id);
    const isUserLoggedIn = Boolean(customerSession);

    const actionState = [
      // If the user is not logged in, toggle the product in favourites
      [addToLoggedOutFavourites, removeFromLoggedOutFavourites],
      // If the user is logged in, toggle the product in favourites
      [addToFavourites, removeFromFavourites],
    ];

    const handleFavouriteState = actionState[Number(isUserLoggedIn)][Number(isFavourite)];
    await handleFavouriteState(id, product);
  }

  function handleRemoveFromFavourites(id: string) {
    const isUserLoggedIn = Boolean(customerSession);
    isUserLoggedIn ? removeFromFavourites(id) : removeFromLoggedOutFavourites(id);
  }

  return (
    <CustomerFavouritesContext.Provider
      value={{
        loading: isLoadingAddFavourite || isLoadingDeleteFavourite,
        favouriteIds: customerSession ? loggedInFavouriteIds : loggedOutFavouriteIds,
        isFavouriteProduct,
        handleFavouriteProductToggle,
        handleRemoveFromFavourites,
      }}
    >
      {children}
    </CustomerFavouritesContext.Provider>
  );
};
