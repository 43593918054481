'use client';

import { cn } from '@wla/lib/helpers/cn';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { useTranslations } from 'next-intl';
import { ProductPriceDiscount, ProductPriceDiscountSecondary } from '@jysk/api-types/drupalApi';

type ProductPriceProps = {
  netWithUnit?: string | null;
  price?: string | null;
  unit?: string | null;
  size?: 'small' | 'default';
  additionalPriceInfo?: {
    style?: 'singlepieceprice' | 'beforeprice';
    text?: string;
  } | null;
  shouldShowCheapestPriceNotice: boolean;
  beforePriceSecondary?: string;
  beforePrice?: string;
  discountSecondary: ProductPriceDiscountSecondary;
  discount?: ProductPriceDiscount;
  isProductCard?: boolean;
  className?: string;
  productDiscountSticker?: string;
};

export function ProductPrice({
  netWithUnit,
  price,
  unit,
  additionalPriceInfo,
  shouldShowCheapestPriceNotice,
  beforePriceSecondary,
  beforePrice,
  discountSecondary,
  discount,
  isProductCard = false,
  className,
  size = 'default',
  productDiscountSticker,
}: ProductPriceProps) {
  const t = useTranslations();
  const { isB2B } = useCustomerSession();

  return (
    <div className="text-gray-800">
      <div className="flex items-end justify-between">
        <div className="font-bold" aria-label={`Product price - ${price} ${unit}`}>
          <span
            className={cn(
              {
                'text-4xl': size === 'default',
                'text-base': size === 'small',
                'text-red-500': additionalPriceInfo && productDiscountSticker,
              },
              className,
            )}
          >
            {price}
          </span>
          {unit && (
            <span
              className={cn('pl-0.5 uppercase', {
                'text-sm': size === 'default',
                'text-xs': size === 'small',
                'text-red-500': additionalPriceInfo && productDiscountSticker,
              })}
            >
              {unit}
            </span>
          )}
        </div>
      </div>
      {isB2B && netWithUnit && (
        <div aria-label={`Product net price - ${netWithUnit}`} className={cn('font-bold')}>
          {t('pdp.product.excl-vat', {
            price: netWithUnit,
          })}
        </div>
      )}
      {additionalPriceInfo && (
        <div
          aria-label={`crossed out price - ${additionalPriceInfo.text}`}
          className={cn({
            'font-bold text-gray-400 line-through': additionalPriceInfo.style === 'beforeprice',
          })}
        >
          {additionalPriceInfo.text}
        </div>
      )}
      {shouldShowCheapestPriceNotice && (
        <>
          <div className={cn('mt-1 text-sm', { 'mt-0.5 s:mt-1': isProductCard })}>
            {t('pdp.product.lowest-price', {
              discountPrice: beforePrice,
              percentage:
                discount?.isVisible &&
                `(${discount?.percentage && discount?.percentage < 0 ? `+${Math.abs(discount?.percentage)}` : `-${discount?.percentage ?? 0}`}%)`,
            })}
          </div>
          <div className="mb-1 text-sm">
            {t('pdp.product.normal-price', {
              beforePrice: beforePriceSecondary,
              percentage:
                discountSecondary?.isVisible &&
                `(${discountSecondary?.percentage && discountSecondary?.percentage < 0 ? `+${Math.abs(discountSecondary?.percentage)}` : `-${discountSecondary?.percentage}`}%)`,
            })}
          </div>
        </>
      )}
    </div>
  );
}
