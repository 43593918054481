'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { EmbeddedContentType } from '@jysk/api-types/drupalApi';
import { B2bRequestResetPasswordForm } from '@wla/app/(cms)/(customer)/b2b/login/(reset-password)/b2b-request-reset-form';
import { CustomerB2bLoginFormData, b2bLoginFormSchema } from '@wla/app/(cms)/(customer)/b2b/login/b2b-auth-schemas';
import { loginB2bCustomerActions } from '@wla/app/(cms)/(customer)/b2b/login/login-actions';
import { Alert } from '@wla/components/ui/alert';
import { Button } from '@wla/components/ui/button/button';
import { EmbeddedContent } from '@wla/components/ui/embedded-content/embedded-content';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { Password } from '@wla/components/ui/forms/password';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { Link } from '@wla/components/ui/link';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type B2bLoginFormProps = {
  onSubmitSuccess?: () => void;
  navigateToB2b?: string;
  buttonStyling?: boolean;
};

export function B2bLoginForm({ onSubmitSuccess, navigateToB2b, buttonStyling }: B2bLoginFormProps) {
  const t = useTranslations();
  const { countryISOCode } = usePublicConfig();
  const [loading, setLoading] = useState(false);
  const [resetPasswordClicked, setResetPasswordClicked] = useState(false);
  const [loginStatusCode, setLoginStatusCode] = useState<number | null>(null);
  const router = useRouter();

  const form = useForm<CustomerB2bLoginFormData>({
    mode: 'all',
    resolver: zodResolver(b2bLoginFormSchema),
    defaultValues: {
      domainCode: countryISOCode,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = form;

  async function onSubmit(data: CustomerB2bLoginFormData) {
    setLoading(true);
    const response = await loginB2bCustomerActions(data);

    if (response.success && response.status === 200) {
      if (response.auth === 'CHANGE') {
        console.log('reponse auth?', response);
        setLoading(false);
        onSubmitSuccess?.();
        router.push(`/b2b/change-password?customerNo=${data.customerNo}`);
        return;
      }
      onSubmitSuccess?.();
      setTimeout(() => {
        navigateToB2b && router.push(navigateToB2b);
      }, 50);
    } else if (response.status) {
      setLoginStatusCode(response.status);
    }
    setLoading(false);
  }

  return (
    <div className="my-4">
      {!resetPasswordClicked ? (
        <div className="w-full">
          {loginStatusCode === 400 && <Alert className="mb-3">{t('forms.errors.login-failed')}</Alert>}

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3 lg:gap-4">
            <FormGroup
              label={t('forms.customer-id-or-email')}
              inputType={InputTypes.Input}
              required
              validationError={errors.customerNo?.message ? t(errors.customerNo?.message) : ''}
            >
              <Input {...register('customerNo')} />
            </FormGroup>
            <FormGroup
              label={t('forms.password')}
              inputType={InputTypes.Input}
              required
              validationError={errors.password?.message ? t(errors.password?.message) : ''}
            >
              <Password type="password" {...register('password')} />
            </FormGroup>
            <SubmitButton
              loadingMessage={t('forms.submitting')}
              loading={loading}
              className={cn('w-full', {
                'sm:w-fit': buttonStyling,
              })}
            >
              {t('forms.login')}
            </SubmitButton>
          </form>
        </div>
      ) : (
        <>
          <Alert type="info" className="mb-4">
            {t('forms.reset-password-text')}
          </Alert>
          <B2bRequestResetPasswordForm />
        </>
      )}
      <Link
        as="button"
        className="w-min whitespace-nowrap pt-3 text-left text-base lg:pt-4"
        onClick={() => {
          setResetPasswordClicked(!resetPasswordClicked);
        }}
      >
        {!resetPasswordClicked ? t('common.forgot-password') : t('customer.login.back')}
      </Link>
      <div className="my-10">
        <h1 className="text-lg font-semibold"> {t('customer.b2b.advantages-of-b2b')}</h1>
        <EmbeddedContent contentTypes={EmbeddedContentType.CustomerLoginCreateAccountTextB2B} excludeWrapper />
        <div className="py-2">
          <Button as="a" href="/b2b/create" variant="secondary">
            {t('common.create-account')}
          </Button>
        </div>
      </div>
    </div>
  );
}
