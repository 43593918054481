import { Link as LinkProps } from '@jysk/api-types/drupalApi';
import { Link } from '@wla/components/ui/link';
import { useTranslations } from 'next-intl';

export function LinkTooltip({ title, url }: LinkProps) {
  const t = useTranslations('common');

  return (
    <div className="flex w-64 flex-col items-center justify-center gap-2 rounded-lg bg-white px-4 py-3.5 shadow-[0px_0px_20px_rgba(0,0,0,0.10)]">
      {title && <div className="font-bold">{title}</div>}

      {url && (
        <Link className="text-sm text-blue-500 underline" href={url}>
          {t('link')}
        </Link>
      )}
    </div>
  );
}
