import { ProductBrandTeaser } from '@jysk/api-types/drupalApi';
import Image from '@wla/components/ui/image';
import { Link } from '@wla/components/ui/link';

type BrandCardProps = Omit<ProductBrandTeaser, 'id' | 'brandLogoUrl'>;

export function BrandCard({ brandLogoImage, name, url }: BrandCardProps) {
  return (
    <Link
      href={url}
      className="group relative flex aspect-[320/210] max-h-44 w-full items-center justify-center bg-gray-100 p-2.5 md:max-h-full lg:p-8"
    >
      <Image
        src={brandLogoImage?.url ?? ''}
        alt={name}
        title={name}
        fill
        className="-order-1 object-contain p-2.5 transition-transform sm:order-none sm:group-hover:scale-110 lg:p-8"
        data-org={brandLogoImage?.url ?? ''}
      />
    </Link>
  );
}
