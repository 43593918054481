'use client';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';

// this is a function to get the size for the giftcard preview
export function getPreviewSize() {
  const isPortrait = window.innerHeight > window.innerWidth;

  let maxWidth;

  if (isMobileOnly && isPortrait) {
    maxWidth = 345;
  } else if (isTablet && !isPortrait) {
    maxWidth = 860;
  } else if (isMobile) {
    maxWidth = 782;
  } else {
    maxWidth = 860;
  }

  return {
    maxWidth,
    maxHeight: Math.max(1200, 2 * maxWidth),
  };
}
