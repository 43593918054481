import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CreateReviewFormData } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/create-review-schema';
import { PageAnalytics } from '@wla/app/(cms)/[...slug]/page-analytics';
import { ContentLayout } from '@wla/components/ui/content-layout';
import { Drawer } from '@wla/components/ui/drawer';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { GetPage200, StructuralPageFull } from '@jysk/api-types/drupalApi';

type TermsAndConditionsDrawerProps = {
  reviewData?: CreateReviewFormData;
  onClose?: (reviewData?: CreateReviewFormData) => void;
};

export const TermsAndConditionsDrawer = NiceModal.create(({ reviewData, onClose }: TermsAndConditionsDrawerProps) => {
  const t = useTranslations('common');
  const modal = useModal();
  const [pageContent, setPageContent] = useState<GetPage200>();

  useEffect(() => {
    async function getPageContent() {
      const response = await fetch(`/content/get-page?path=/structural-page/TermsConditions?redirect=false`);
      const { data } = await response.json();
      setPageContent(data);
    }

    getPageContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    modal.hide();
    onClose?.(reviewData);
  }

  if (!pageContent?.entity) {
    return null;
  }

  return (
    <Drawer open={modal.visible} onClose={handleClose}>
      <Drawer.Header title={t('terms-and-conditions')} />
      <Drawer.Body>
        <PageAnalytics pageType={(pageContent.entity.data as StructuralPageFull).structuralPageType} />
        <ContentLayout content={(pageContent.entity.data as StructuralPageFull).content} />
      </Drawer.Body>
    </Drawer>
  );
});
