'use client';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Drawer } from '@wla/components/ui/drawer';
import { Spinner } from '@wla/components/ui/spinner';

type SideDrawerProps = {
  title: string;
  bodySlot: React.ReactNode | null;
};

export const PDPSideDrawer = NiceModal.create(({ bodySlot, title }: SideDrawerProps) => {
  const { visible, hide } = useModal();

  return (
    <Drawer open={visible} onClose={hide} position="right">
      <Drawer.Header title={title} className="mb-8" />
      <Drawer.Body className="mt-0">
        {!bodySlot ? (
          <div className="flex items-center justify-center">
            <Spinner size="lg" />
          </div>
        ) : (
          bodySlot
        )}
      </Drawer.Body>
    </Drawer>
  );
});
