import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

type SearchProps = {
  handleSearch: (query: string) => void;
  placeholder: string;
  defaultValue?: string;
  className?: string;
};

export function Search({ handleSearch, placeholder, defaultValue, className }: SearchProps) {
  const t = useTranslations('search');
  const [inputValue, setInputValue] = useState(defaultValue || '');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
    handleSearch(event.target.value);
  }

  function handleReset() {
    setInputValue('');
    handleSearch('');
  }

  return (
    <div className={cn('pt-1" relative flex h-full w-full items-center', className)}>
      <form
        className="block w-full"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <label htmlFor="default-search" className="sr-only mb-2 text-sm font-medium text-gray-900">
          {t('search')}
        </label>
        <div className="grid w-full grid-flow-col auto-rows-min grid-cols-1 gap-x-2.5 rounded bg-gray-100">
          <input
            autoComplete="off"
            type="text"
            id="default-search"
            className="peer block h-10 w-full truncate rounded border-none bg-gray-100 px-5 py-2.5 pr-11 text-lg text-gray-900 placeholder:overflow-hidden placeholder:text-ellipsis placeholder:whitespace-nowrap focus:outline-none focus:ring-0 lg:h-11 lg:text-base"
            placeholder={placeholder}
            required
            onChange={handleChange}
            value={inputValue}
            inputMode="search"
          />
          {inputValue && (
            <button
              type="reset"
              className="absolute bottom-0.5 right-12 top-1 z-10 p-1 text-sm text-gray-600 underline"
              onClick={handleReset}
            >
              {t('clear')}
            </button>
          )}
          <button type="submit" className="mr-5 h-10 cursor-pointer">
            <Icon src="w3-search" size="24" />
          </button>
        </div>
      </form>
    </div>
  );
}
