import { FilterAxises, GetFilterAxis200 } from '@jysk/api-types/wssFilterAxisApi/model';
import { FiltersPlp } from '@wla/app/(cms)/[...slug]/(filters)/filters-plp';

type FiltersProps = {
  filters: GetFilterAxis200 | null;
  preOpenFilter?: FilterAxises | null;
  isLoading?: boolean;
};

export function Filters({ filters, isLoading, preOpenFilter }: FiltersProps) {
  return (() => <FiltersPlp filters={filters} preOpenFilter={preOpenFilter} isLoading={isLoading} />)();
}
