import { cn } from '@wla/lib/helpers/cn';
import { ReactNode } from 'react';

type TabListProps = {
  children: ReactNode;
  className?: string;
};

export function TabList({ children, className }: TabListProps) {
  return <div className={cn('flex gap-2 border-b-4', className)}>{children}</div>;
}
