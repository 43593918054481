import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { EmbeddedContentType } from '@jysk/api-types/drupalApi';
import { CreateReviewFormData } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/create-review-schema';
import { Drawer } from '@wla/components/ui/drawer';
import { EmbeddedContent } from '@wla/components/ui/embedded-content/embedded-content';
import { useTranslations } from 'next-intl';

type ReviewGuidelinesDrawerProps = {
  reviewData: CreateReviewFormData;
  onClose: (reviewData?: CreateReviewFormData) => void;
};

export const ReviewGuidelinesDrawer = NiceModal.create(({ reviewData, onClose }: ReviewGuidelinesDrawerProps) => {
  const t = useTranslations();
  const modal = useModal();

  function handleClose() {
    modal.hide();
    onClose?.(reviewData);
  }

  return (
    <Drawer open={modal.visible} onClose={handleClose} position="right">
      <Drawer.Header title={t('forms.write-a-review')} />
      <Drawer.Body>
        <EmbeddedContent contentTypes={EmbeddedContentType.PdpProductReviewTermsAndCondition} excludeWrapper />
      </Drawer.Body>
    </Drawer>
  );
});
