import { ProductCard } from '@wla/components/ui/product-card/product-card';
import { cn } from '@wla/lib/helpers/cn';
import { UserAction, useNewRelic } from '@wla/lib/hooks/tracking/use-new-relic';
import { useProductVisibility } from '@wla/lib/hooks/use-product-visibility';
import { isMobile } from 'react-device-detect';
import { ProductTeaser } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsHitsItem } from '@jysk/api-types/searchApi/model';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';

type ProductListingProps = {
  products: (ProductTeaser | PostSearch200ProductsHitsItem)[];
  isFirst: boolean;
  title?: string;
  stockStatus?: OnlineAtpMap | null;
  isLoadingStock?: boolean;
  onProductClickTrackingUserAction?: UserAction;
};

export function ProductList({
  title,
  isFirst,
  products,
  stockStatus,
  isLoadingStock,
  onProductClickTrackingUserAction,
}: ProductListingProps) {
  const handleOnProductVisible = useProductVisibility('productListPage');
  const { recordUserAction } = useNewRelic();

  return (
    <section className="flex w-full flex-col">
      <h2
        className={cn('flex py-5 text-3xl font-semibold md:py-12 md:text-5xl', {
          'py-5 md:pb-7 md:pt-5': isFirst,
        })}
      >
        {title}
      </h2>
      <div className="relative grid auto-rows-fr grid-cols-1 gap-2 xs:grid-cols-2 sm:gap-8 md:grid-cols-3 lg:grid-cols-4 lg:pt-2.5">
        {products?.map((product, index) => (
          <ProductCard
            onProductVisible={() => handleOnProductVisible(product, index)}
            key={product.id}
            product={product}
            isAboveTheFold={isMobile ? index < 2 : index < 4}
            stockStatus={stockStatus?.[product.id]}
            isLoadingStock={isLoadingStock}
            onProductCardClick={() => {
              if (onProductClickTrackingUserAction) {
                recordUserAction({ action: onProductClickTrackingUserAction, target: product.id });
              }
            }}
          />
        ))}
      </div>
    </section>
  );
}
