'use client';

import { RECENTLY_SEARCHED_COOKIE_KEY, SEARCH_ITEMS_PR_PAGE } from '@wla/lib/constants';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import Cookies from 'js-cookie';
import { PostSearch200, PostSearchBody } from '@jysk/api-types/searchApi/model';
import { GetQuickSearch200 } from '@jysk/api-types/searchApi/model/getQuickSearch200';

type JsonResponseQuickSearch = {
  success: boolean;
  response: GetQuickSearch200;
};

export function useSearch() {
  async function quickSearch(query: string, options: RequestInit = {}) {
    const params = new URLSearchParams({
      query,
    });

    try {
      const response = await fetch(`/api/quick-search?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        ...options,
      });

      const { response: jsonResponse }: JsonResponseQuickSearch = await response.json();

      return jsonResponse;
    } catch (error) {
      trackError(error, { query, options });
    }
  }

  async function setRecentlySearchedQuery(searchQuery: string, url?: string) {
    await fetch(`/api/set-recently-search`, {
      method: 'POST',
      body: JSON.stringify({ searchQuery, url }),
    });
  }

  function getRecentlySearchQueries() {
    const cookie = Cookies.get(RECENTLY_SEARCHED_COOKIE_KEY);
    const cookieQueries = JSON.parse(cookie ?? '[]');
    return cookieQueries;
  }

  async function clearRecentlySearchQueries() {
    const res = await fetch(`/api/set-recently-search`, {
      method: 'DELETE',
    });

    const { success } = await res.json();
    return success;
  }

  async function postFullSearch({
    q: query,
    type,
    filters,
    from,
    staticFilters,
    showTotalHitsForTypes,
    size = SEARCH_ITEMS_PR_PAGE,
    sort,
  }: Omit<PostSearchBody, 'locale'>) {
    const searchResponse = await fetch('/api/get-search', {
      method: 'POST',
      body: JSON.stringify({ query, type, filters, from, showTotalHitsForTypes, size, sort, staticFilters }),
    });

    const searchData = await searchResponse.json();
    return searchData.data as PostSearch200;
  }

  return {
    quickSearch,
    setRecentlySearchedQuery,
    getRecentlySearchQueries,
    clearRecentlySearchQueries,
    postFullSearch,
  };
}
