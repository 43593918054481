// NOTE: See comment in libs/deployment/hook-container/terraform/locals.tf
const IGNORE_ROUTES_IN_CACHE = [
  '^/customer/.*', // Matches any route starting with /customer/
  '^/favourites', // Matches any route starting with /favourites/
  '^/b2b/.*', // Matches any route starting with /b2b/
  '^/checkout$', // Matches route with /checkout
  '^/checkout/.*', // Matches any route starting with /checkout/
  '.*/api/.*', // Matches any route containing /api/
];

export function isPathCacheable(path: string) {
  return !IGNORE_ROUTES_IN_CACHE.some((pattern) => new RegExp(pattern).test(path));
}

export async function sendToWCH(requestHeaders: Record<string, string>, responseHeaders: Headers, drupalUrl: string) {
  // Send cache tags from drupal to cache handler
  if (responseHeaders && responseHeaders.get('cache-tags')) {
    const cacheHandlerUrl = process.env['CACHE_HANDLER_API_URL'];
    const cacheTags = responseHeaders.get('cache-tags');
    const cacheControl = responseHeaders.get('cache-control');
    const path = requestHeaders['x-path'];
    const cacheDomain = requestHeaders['x-cache-domain'];
    const cacheKey = requestHeaders['x-cache-key'];
    if (cacheTags && cacheHandlerUrl && path && cacheDomain && cacheKey !== '' && isPathCacheable(path)) {
      // The "await" here is to make sure that the cache handler gets some cache-control headers before it has
      // to send the response to cloud front.
      try {
        await fetch(`${cacheHandlerUrl}/v1/tags-from-wla`, {
          method: 'POST',
          headers: [['Content-Type', 'application/json']],
          body: JSON.stringify({
            cacheTags: cacheTags.split(','),
            cacheControl,
            domain: cacheDomain,
            cacheKey,
            drupalUrl,
          }),
        });
      } catch (error) {
        console.log(`🔴 Error sending tags to wch url ${cacheHandlerUrl}/v1/tags-from-wla`, error);
        throw error;
      }
    }
  }
}
