import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { FavoriteStoreSearch } from '@wla/app/(cms)/(favorite-store)/favorite-store-search';
import { StoreCard } from '@wla/app/(cms)/(favorite-store)/store-drawer/store-card';
import { StoreDrawer } from '@wla/app/(cms)/(favorite-store)/store-drawer/store-drawer';
import { StoreList } from '@wla/app/(cms)/(favorite-store)/store-drawer/store-list';
import { useStoreLocation } from '@wla/app/(cms)/(favorite-store)/use-store-location';
import { useStores } from '@wla/app/(cms)/(favorite-store)/use-stores';
import { getZipCodeFromOrder } from '@wla/app/checkout/order-states';
import { Alert } from '@wla/components/ui/alert';
import { Spinner } from '@wla/components/ui/spinner';
import { usePublicConfig } from '@wla/lib/configs';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';
import { Droppoint } from '@jysk/api-types/wssApi/model';

type CarrierDrawerProps = {
  title: string;
  carrierId: string;
  isDroppoint?: boolean;
  initialSelectedId?: string;
  zipCode?: string;
};

export const CarrierDrawer = NiceModal.create(
  ({ title, carrierId, isDroppoint = false, initialSelectedId, zipCode }: CarrierDrawerProps) => {
    const t = useTranslations();
    const { countryISOCode } = usePublicConfig();
    const modal = useModal();
    const { orders, updateUnscheduledDelivery } = useShoppingSession();
    const { setSearchValue, hasSearched, location } = useStoreLocation(countryISOCode === 'PT' ? 3000 : 500);
    const { setFavoriteStore } = useFavoriteStore();
    const { getDroppoints, getStoresWithLocation, isLoading } = useStores();
    const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(initialSelectedId);

    const [carrierData, setCarrierData] = useState<Droppoint[] | StoreType[]>([]);

    function isDroppointType(store: Droppoint | StoreType): store is Droppoint {
      return 'parcelShopId' in store;
    }

    function isStoreType(store: Droppoint | StoreType): store is StoreType {
      return 'id' in store;
    }

    useEffect(() => {
      async function fetchCarrierData() {
        if (isDroppoint) {
          const droppoints = await getDroppoints({ carrierId, location, zipCode: getZipCodeFromOrder(orders) });
          setCarrierData(droppoints || []);
        } else {
          const stores = await getStoresWithLocation({ location: location || zipCode || '' });
          setCarrierData(stores);
        }
      }
      fetchCarrierData();
    }, [isDroppoint, carrierId, location, zipCode]);

    function getStoreName({ street, number, zipCode, city }: StoreType | Droppoint) {
      return `${street}${!isDroppoint && number ? ` ${number}` : ''}, ${zipCode} ${city}`;
    }

    const handleSelectDeliveryDestination = useCallback(
      async (storeId: string) => {
        const selectedStore = carrierData.find((store) =>
          isDroppointType(store) ? store.parcelShopId === storeId : store.id === storeId,
        );

        if (!selectedStore) return;

        if (carrierId) {
          await updateUnscheduledDelivery({
            carrierId,
            answer: [
              isDroppointType(selectedStore)
                ? { id: 'parcelShopId', value: selectedStore?.parcelShopId ?? '' }
                : { id: 'shopId', value: selectedStore?.id ?? '' },
              { id: 'name', value: selectedStore?.name ?? '' },
              { id: 'street', value: selectedStore?.street ?? '' },
              { id: 'number', value: selectedStore.number as string },
              { id: 'zipCode', value: selectedStore?.zipCode ?? '' },
              { id: 'city', value: selectedStore?.city ?? '' },
            ],
          });
        }

        const selectedId = isDroppointType(selectedStore) ? selectedStore.parcelShopId : selectedStore.id;
        setSelectedStoreId(selectedId);

        if (!isDroppointType(selectedStore)) setFavoriteStore(selectedStore);

        setSearchValue('');
        modal.resolve(selectedStore);
        modal.hide();
      },
      [carrierId, carrierData, updateUnscheduledDelivery],
    );

    return (
      <StoreDrawer
        isOpen={modal.visible}
        onClose={() => {
          modal.resolve({});
          modal.hide();
        }}
        title={title}
      >
        {(hasSearched || carrierData?.length > 5) && <FavoriteStoreSearch setSearchValue={setSearchValue} />}

        {isLoading && (
          <div className="flex w-full justify-center pt-10">
            <Spinner size="lg" />
          </div>
        )}

        {!isLoading && carrierData.length > 0 && (
          <StoreList>
            {carrierData.map((store) => {
              const storeId = isDroppointType(store) ? store.parcelShopId ?? '' : store.id;
              return (
                <StoreCard
                  key={storeId}
                  isDisabled={isStoreType(store) && !store.hasPayAndCollect}
                  title={
                    isDroppoint
                      ? store.name ?? ''
                      : t('common.jysk-store', {
                          storeName: store.name,
                        })
                  }
                  isSelected={storeId === selectedStoreId}
                  headerSlot={<div>{getStoreName(store)}</div>}
                  onClick={() => handleSelectDeliveryDestination(storeId)}
                />
              );
            })}
          </StoreList>
        )}

        {!isLoading && !carrierData.length && (
          <Alert type="error" className="text-base">
            {t('favorite-store.errors.an-error-occurred-when-finding-store')}
          </Alert>
        )}
      </StoreDrawer>
    );
  },
);
