import NiceModal from '@ebay/nice-modal-react';
import { PaginatedReviews } from '@jysk/api-types/wssReviewsApi/model';
import { getTotalRating } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/get-total-rating';
import { Review } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/review';
import { Alert } from '@wla/components/ui/alert';
import { Button } from '@wla/components/ui/button/button';
import { Link } from '@wla/components/ui/link';
import { Rating } from '@wla/components/ui/rating/rating';
import { RatingList } from '@wla/components/ui/rating/rating-list';
import { Spinner } from '@wla/components/ui/spinner';
import { cn } from '@wla/lib/helpers/cn';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { useRef, useState } from 'react';

type ReviewBlockProps = {
  reviews: PaginatedReviews;
  productId: string;
  onOpenCreateReviewDrawer?: () => void;
};

export function ReviewBlock({ reviews, productId, onOpenCreateReviewDrawer }: ReviewBlockProps) {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reviewList, setReviewList] = useState<Omit<PaginatedReviews, 'page'> & { page: number }>({
    ...reviews,
    page: reviews.page || 0,
  });
  const ratingRef = useRef<number | null>(null);

  const totalRating = getTotalRating(reviews.avg_approved_rating);

  function handleOpenCreateReviewDrawer() {
    NiceModal.show(DRAWER_ID.CREATE_REVIEW_DRAWER, { productId });
    onOpenCreateReviewDrawer?.();
  }

  async function handlePageChange(selected: number, filterRating: boolean) {
    setError(false);
    setIsLoading(true);
    const response = await fetch(
      `/api/get-review-list?productId=${productId}&page=${selected}&rating=${ratingRef.current}`,
    );
    const { success, data } = await response.json();
    if (success) {
      const updatedReviewList = filterRating
        ? data
        : { ...data, results: reviewList.results ? [...reviewList.results, ...data.results] : data.results };
      setReviewList(updatedReviewList);
    } else {
      setError(true);
    }
    setIsLoading(false);
  }

  async function filterRatingList(rating: number | null) {
    setError(false);
    ratingRef.current = rating;

    if (rating === null) {
      setReviewList({ ...reviews, page: 0 });
    } else {
      handlePageChange(0, true);
    }
  }

  return (
    <section data-scroll-id="reviews" className="relative flex w-full flex-col">
      <div className="flex flex-col items-center gap-2">
        <div className="pt-8 text-6xl font-bold">{totalRating}</div>
        <Rating size="xs" rating={totalRating} className="" />
        <div className="flex gap-1">
          <div>{reviewList.total_results}</div>
          <div className="lowercase">
            {reviewList.total_results === 1 ? t('pdp.review.review') : t('pdp.review.reviews')}
          </div>
        </div>
        <Link as="button" onClick={handleOpenCreateReviewDrawer}>
          {t('forms.write-a-review')}
        </Link>
        <div className="mb-9 border-b pb-9 pt-2.5 text-center">{t('pdp.review.review-information')}</div>
      </div>
      <div className="pb-9">
        <RatingList summary={reviewList.rating_count} onRatingClick={filterRatingList} />
      </div>
      {totalRating > 0 && (
        <>
          {reviewList.results?.map((review, index) => (
            <Review key={`${review.id}-${index}`} productId={review.product_id} review={review} />
          ))}
          {error && <Alert type="error">{t('pdp.errors.loading-review-error')}</Alert>}
          <Button
            disabled={isLoading}
            variant="secondary"
            className={cn('mb-8 mt-4 self-center', { hidden: reviewList.approved === reviewList.results?.length })}
            onClick={() => handlePageChange(reviewList.page >= 0 ? reviewList.page + 1 : 0, false)}
          >
            {isLoading ? <Spinner size="md" /> : t('pdp.review.show-more-reviews')}
          </Button>
        </>
      )}
    </section>
  );
}
