import { Disclosure } from '@headlessui/react';
import {
  PostSearch200ProductsFacets,
  PostSearch200ProductsFacetsSortedItem,
  PostSearch200ProductsFacetsSortedItemOneOf,
  PostSearch200ProductsFacetsSortedItemOneOfEight,
  PostSearch200ProductsFacetsSortedItemOneOfFour,
} from '@jysk/api-types/searchApi/model';
import { CheckboxFacet } from '@wla/app/(cms)/[...slug]/(filters)/facets/checkbox-facet';
import { RangeFacet } from '@wla/app/(cms)/[...slug]/(filters)/facets/range-facet';
import { SwitchFacet } from '@wla/app/(cms)/[...slug]/(filters)/facets/switch-facet';
import { Alert } from '@wla/components/ui/alert';
import { Spinner } from '@wla/components/ui/spinner';
import { Switch } from '@wla/components/ui/switch';
import { handleChangeSeachFilter } from '@wla/lib/helpers/filter-change-handler';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';
import { Fragment } from 'react';

type FiltersSearchProps = {
  filters: PostSearch200ProductsFacets;
  preOpenFilter?: PostSearch200ProductsFacetsSortedItem | null;
  isLoading?: boolean;
};

export type FacetProps = {
  facet: PostSearch200ProductsFacetsSortedItem;
  queryFilters: string | null;
  isOpen: boolean;
  handleChangeSeachFilter: typeof handleChangeSeachFilter;
  pathname: string;
  searchParams: URLSearchParams;
};

export function FiltersSearch({ filters, isLoading, preOpenFilter }: FiltersSearchProps) {
  const t = useTranslations('common');
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryFilters = searchParams.get('filters');
  const facets = filters.sorted;
  const promotedFilter = filters.promotion;
  function isRangeFacet(
    facet: PostSearch200ProductsFacetsSortedItem,
  ): facet is PostSearch200ProductsFacetsSortedItemOneOfFour {
    return facet.type === 'number';
  }
  function isSwitchFacet(
    facet: PostSearch200ProductsFacetsSortedItem,
  ): facet is PostSearch200ProductsFacetsSortedItemOneOfEight {
    return facet.type === 'boolean';
  }
  function isCheckboxFacet(
    facet: PostSearch200ProductsFacetsSortedItem,
  ): facet is PostSearch200ProductsFacetsSortedItemOneOf {
    return facet.type === 'keyword';
  }

  if (!facets && isLoading) return <Spinner />;
  if (facets?.length === 0) return <Alert className="mt-4">{t('there-are-no-filters-available')}</Alert>;

  return (
    <div data-testid="filters-search">
      {promotedFilter && (
        <Disclosure as="div" className="border-b" defaultOpen>
          <Switch
            isLeftLabel
            labelClassName="text-md font-semibold mr-auto my-5 h-12 flex items-center"
            label={promotedFilter?.name}
            isChecked={promotedFilter.isSelected}
            onChange={() =>
              handleChangeSeachFilter(
                {
                  key: filters.promotion?.name,
                  value: filters.promotion?.name,
                },
                { pathname, searchParams, queryFilters },
              )
            }
          />
        </Disclosure>
      )}
      {facets?.map((facet) => (
        <Fragment key={facet.name}>
          {isSwitchFacet(facet) && (
            <SwitchFacet
              facet={facet}
              queryFilters={queryFilters}
              isOpen={preOpenFilter?.name === facet.name}
              handleChangeSeachFilter={handleChangeSeachFilter}
              pathname={pathname}
              searchParams={searchParams}
            />
          )}
          {isCheckboxFacet(facet) && (
            <CheckboxFacet
              facet={facet}
              queryFilters={queryFilters}
              isOpen={preOpenFilter?.name === facet.name}
              handleChangeSeachFilter={handleChangeSeachFilter}
              pathname={pathname}
              searchParams={searchParams}
            />
          )}
          {isRangeFacet(facet) && (
            <RangeFacet
              facet={facet}
              queryFilters={queryFilters}
              isOpen={preOpenFilter?.name === facet.name}
              handleChangeSeachFilter={handleChangeSeachFilter}
              pathname={pathname}
              searchParams={searchParams}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
}
