import { StoreFull } from '@jysk/api-types/drupalApi';
import { StoreInfo } from '@wla/app/(cms)/[...slug]/(store-locator)/store-locator';
import IntlProvider from '@wla/app/intl-provider';
import { MarkerTooltip } from '@wla/components/ui/maps/marker-tooltip';
import { AbstractIntlMessages } from 'next-intl';
import { useEffect, useRef } from 'react';
import { Root, createRoot } from 'react-dom/client';

type CustomOverlayProps = {
  position: google.maps.LatLngLiteral;
  map: google.maps.Map;
  storeInfo: StoreInfo;
  onClose: () => void;
  focusedStore?: StoreFull;
  messages: AbstractIntlMessages;
};

export function CustomOverlay({ position, map, storeInfo, onClose, focusedStore, messages }: CustomOverlayProps) {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const rootRef = useRef<Root | null>(null);

  useEffect(() => {
    if (!overlayRef.current) {
      overlayRef.current = document.createElement('div');
      overlayRef.current.style.position = 'absolute';
    }

    const overlay = new google.maps.OverlayView();

    overlay.onAdd = () => {
      overlay.getPanes()?.overlayMouseTarget.appendChild(overlayRef.current!);
      overlayRef.current!.addEventListener('mouseenter', () => {
        map.setOptions({ draggable: false });
      });
      overlayRef.current!.addEventListener('mouseleave', () => {
        map.setOptions({ draggable: true });
      });
    };

    overlay.onRemove = () => {
      if (overlayRef.current?.parentNode) {
        overlayRef.current.parentNode.removeChild(overlayRef.current);
      }
      if (rootRef.current) {
        // A fix for not attempting to synchronously unmount a root while React is already rendering.
        setTimeout(() => {
          rootRef.current?.unmount();
          rootRef.current = null;
          map.setOptions({ draggable: true });
        }, 0);
      }
    };

    overlay.draw = () => {
      const projection = overlay.getProjection();
      const pos = new google.maps.LatLng(position.lat, position.lng);
      const point = projection.fromLatLngToDivPixel(pos);

      if (point && overlayRef.current) {
        overlayRef.current.style.left = `${point.x}px`;
        overlayRef.current.style.top = `${point.y}px`;
        overlayRef.current.style.transform = 'translate(-50%, -130%)';
        overlayRef.current.style.fontFamily = 'var(--font-open-sans)';
        overlayRef.current.style.zIndex = '1000';

        if (!rootRef.current) {
          rootRef.current = createRoot(overlayRef.current);
        }

        rootRef.current.render(
          <IntlProvider messages={messages}>
            <MarkerTooltip storeInfo={storeInfo} onClose={onClose} focusedStore={focusedStore} />
          </IntlProvider>,
        );
      }
    };

    overlay.setMap(map);

    return () => {
      overlay.setMap(null);
      map.setOptions({ draggable: true });
    };
  }, [map, position, storeInfo, onClose]);

  return null;
}
