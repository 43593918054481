import { TooltipParagraph } from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { LinkTooltip } from '@wla/components/ui/paragraph/paragraph-tooltip/link-tooltip';
import { TooltipDirectLink } from '@wla/components/ui/paragraph/paragraph-tooltip/tooltip-direct-link';
import { TooltipPopover } from '@wla/components/ui/paragraph/paragraph-tooltip/tooltip-popover';
import { ProductCard } from '@wla/components/ui/product-card/product-card';
import { cn } from '@wla/lib/helpers/cn';
import Image from 'next/image';
import { Fragment } from 'react';

type ParagraphTooltipProps = {
  content: TooltipParagraph;
};

export function ParagraphTooltip({ content }: ParagraphTooltipProps) {
  const { config } = content;

  return (
    <ParagraphConfig config={config}>
      <div
        className="relative"
        style={{
          maxWidth: content.image?.dimensions?.width,
        }}
      >
        <Image
          src={content.image?.url}
          alt={content.image?.alt}
          width={content.image?.dimensions?.width}
          height={content.image?.dimensions?.height}
          className={cn('w-full object-fill', {
            'aspect-square': content.imageSize === 'square',
            'aspect-[2/1]': content.imageSize === 'wide',
            'aspect-[3/2]': content.imageSize === 'landscape',
          })}
        />
        {content.tooltips?.map((tooltip, index) => (
          <Fragment key={index}>
            {/* Link with no title but has url, links directly from tooltip icon */}
            {tooltip.link && !tooltip.link.title && tooltip.link.url ? (
              <TooltipDirectLink
                key={`${tooltip.position.x}_${tooltip.position.y}`}
                position={tooltip.position}
                url={tooltip.link.url}
              />
            ) : (
              <TooltipPopover
                type={tooltip.product ? 'product' : 'link'}
                key={tooltip.position.x + ' ' + tooltip.position.y}
                position={tooltip.position}
              >
                {tooltip.product && <ProductCard key={tooltip.product.id} product={tooltip.product} refId="tooltip" />}
                {tooltip.link && (
                  <LinkTooltip
                    key={tooltip.link.title}
                    url={tooltip.link.url}
                    title={tooltip.link.title}
                    target={tooltip.link.target}
                  />
                )}
              </TooltipPopover>
            )}
          </Fragment>
        ))}
      </div>
    </ParagraphConfig>
  );
}
