import { TabsProvider } from '@wla/components/ui/tabs/tabs-context';
import { ReactNode, useEffect, useRef, useState } from 'react';

type TabsProps<T> = {
  defaultTab?: T;
  selectedTab?: T;
  onTabChange?: (value: T) => void;
  children: ReactNode;
};

export function Tabs({ defaultTab, selectedTab, onTabChange, children }: TabsProps<any>) {
  const [internalSelectedTab, setInternalSelectedTab] = useState(selectedTab ?? defaultTab);
  const tabListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedTab) {
      setInternalSelectedTab(selectedTab);
    } else if (defaultTab) {
      setInternalSelectedTab(defaultTab);
    }
  }, [selectedTab, defaultTab]);

  useEffect(() => {
    if (tabListRef.current) {
      const firstTab = tabListRef.current.querySelector('button');

      if (firstTab && !selectedTab) {
        handleTabChange(firstTab.getAttribute('data-value'));
        setInternalSelectedTab(firstTab.getAttribute('data-value'));
      }
    }
  }, []);

  function handleTabChange(value: any) {
    setInternalSelectedTab(value);
    if (onTabChange) {
      onTabChange(value);
    }
  }

  return (
    <TabsProvider defaultTab={defaultTab} selectedTab={internalSelectedTab} onTabChange={handleTabChange}>
      <div className="tabs" ref={tabListRef}>
        {children}
      </div>
    </TabsProvider>
  );
}
