import z from 'zod';

export function createNewsletterSignUpB2CSchema(isFirstNameOptional: boolean, showAgreementCheckbox: boolean) {
  const firstNameOptional = isFirstNameOptional
    ? z.string().optional()
    : z
        .string()
        .min(1, 'forms.errors.required.first-name-is-required')
        .max(35, 'forms.errors.required.first-name-is-required');
  const showCheckbox = !showAgreementCheckbox
    ? z.any().optional()
    : z
        .any()
        .optional()
        .refine(
          (value) => value === true || value === 'true' || value === 'on',
          'common.please-accept-terms-and-conditions',
        );

  return z.object({
    email: z.string().email('forms.errors.not-valid.email-not-valid'),
    source: z.string().optional(),
    name: firstNameOptional,
    accept: showCheckbox,
    isFirstNameOptional: z.string().optional(),
    showAgreementCheckbox: z.string().optional(),
  });
}
