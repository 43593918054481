'use client';

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { COOKIE_INFORMATION } from '../constants';
import { trackError } from '../tracking/errors/error-tracking';

export enum CookieTypesEnum {
  FUNCTIONAL = 'cookie_cat_functional',
  STATISTIC = 'cookie_cat_statistic',
  MARKETING = 'cookie_cat_marketing',
  NECESSARY = 'cookie_cat_necessary',
}

export function useCookieConsent(contentType: CookieTypesEnum) {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  function updateCookieConsent() {
    if (typeof window.CookieInformation === 'undefined') {
      return trackError(new Error('CookieInformation is not defined'));
    }

    const consentGiven = window.CookieInformation.getConsentGivenFor(contentType);
    setCookiesAccepted(consentGiven);
  }

  useEffect(() => {
    try {
      const cookie = Cookies.get(COOKIE_INFORMATION);
      if (!cookie) return;

      const parsedCookie = JSON.parse(cookie);
      setCookiesAccepted(parsedCookie.consents_approved.includes(contentType));
    } catch (error) {
      setCookiesAccepted(false);
    }

    window.addEventListener('CookieInformationConsentGiven', updateCookieConsent);

    return () => window.removeEventListener('CookieInformationConsentGiven', updateCookieConsent);
  }, [contentType]);

  return cookiesAccepted;
}
