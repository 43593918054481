'use client';
import * as TooltipRadix from '@radix-ui/react-tooltip';
import { Icon } from '@wla/components/ui/icon';
import { ReactNode, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

type TooltipProps = {
  children: ReactNode;
};

export function Tooltip({ children }: TooltipProps) {
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  function handleToggle(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setOpen((prev) => !prev);
  }

  useOnClickOutside(tooltipRef, () => setOpen(false));

  return (
    <TooltipRadix.Provider delayDuration={200}>
      <TooltipRadix.Root open={open}>
        <TooltipRadix.Trigger asChild>
          <span className="hover:cursor-pointer" onClick={handleToggle}>
            <Icon src="w3-help" size="20" />
          </span>
        </TooltipRadix.Trigger>
        {open && (
          <TooltipRadix.Portal>
            <TooltipRadix.Content
              ref={tooltipRef}
              className="z-[1001] rounded-lg border border-gray-200 bg-white p-4 shadow-lg"
              sideOffset={20}
              align="center"
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </TooltipRadix.Content>
          </TooltipRadix.Portal>
        )}
      </TooltipRadix.Root>
    </TooltipRadix.Provider>
  );
}
