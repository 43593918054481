'use client';
import { GiftCardConfig } from '@jysk/api-types/orderManagementGiftcardApi/model';
import { GiftcardForm } from '@wla/components/ui/giftcard/giftcard-form';
import { Spinner } from '@wla/components/ui/spinner';
import { usePublicConfig } from '@wla/lib/configs';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export function Giftcard() {
  const t = useTranslations('gift-card');
  const { featureFlags } = usePublicConfig();
  const [config, setConfig] = useState<GiftCardConfig | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    async function getConfig() {
      const response = await fetch(`/api/get-config`);
      const data = await response.json();
      setConfig(data.config);
      setIsLoading(false);
    }

    if (featureFlags.enableGiftcard) {
      getConfig();
    } else {
      router.push('/');
    }
  }, [featureFlags.enableGiftcard, router]);

  if (isLoading) {
    return <Spinner className="m-auto flex" />;
  }

  if (!config?.giftCardRedeemEnabled && featureFlags.enableGiftcard) {
    return <>{t('not-available')}</>;
  }

  return <GiftcardForm />;
}
