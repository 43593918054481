'use client';

import { StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { LatLngLiteral } from '@wla/app/(cms)/(favorite-store)/use-store-location';
import { usePublicConfig } from '@wla/lib/configs';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { useRef, useState } from 'react';
import { Droppoint } from '@jysk/api-types/wssApi/model/droppoint';

export type GetDroppointProps = {
  location?: { latitude: number; longitude: number };
  carrierId?: string;
  zipCode?: string;
};

type GetStoresProps = {
  location?: { latitude: number; longitude: number };
  productId?: string;
};

export function useStores() {
  const { favoriteStore } = useFavoriteStore();
  const { countryISOCode } = usePublicConfig();
  const [isLoading, setIsLoading] = useState(false);

  const retryRef = useRef<NodeJS.Timeout | null>(null);
  const isJPT = countryISOCode === 'PT';
  let retryCount = 0;

  async function getStores({ location, productId }: GetStoresProps) {
    setIsLoading(true);
    try {
      const response = await fetch(`/checkout/api/get-stores`, {
        method: 'POST',
        body: JSON.stringify({
          latitude: location?.latitude ?? favoriteStore?.latitude ?? undefined,
          longitude: location?.longitude ?? favoriteStore?.longitude ?? undefined,
          productId: productId ?? null,
        }),
      });

      const { data } = await response.json();

      return data;
    } catch (error) {
      trackError(error, { from: 'getStores()', favoriteStore, location, productId });
    } finally {
      setIsLoading(false);
    }
  }

  async function getStoresWithLocation({
    location = '',
    productId,
  }: {
    location?: LatLngLiteral | string;
    productId?: string;
  }) {
    setIsLoading(true);
    try {
      const response = await fetch(`/checkout/api/stores-with-location`, {
        method: 'POST',
        body: JSON.stringify({
          location,
          productId,
        }),
      });

      const { data } = await response.json();

      return data;
    } catch (error) {
      trackError(error, { from: 'getStoresWithLocation()', favoriteStore, location });
    } finally {
      setIsLoading(false);
    }
  }

  async function getDroppoints({ carrierId, location, zipCode }: GetDroppointProps) {
    setIsLoading(true);
    try {
      const response = await fetch(`/checkout/api/get-droppoints`, {
        method: 'POST',
        body: JSON.stringify({
          carrierId,
          latitude: location?.latitude ?? undefined,
          longitude: location?.longitude ?? undefined,
          zipCode,
        }),
      });

      const { data } = (await response.json()) as { data: Droppoint[] };
      if (data.length === 0 && isJPT && retryCount < 3) {
        retryCount = retryCount + 1;
        if (!retryRef.current) {
          retryRef.current = setTimeout(() => {
            retryRef.current = null;
            return getDroppoints({ carrierId, location, zipCode });
          }, 3500);
        }
      } else {
        clearRetry();
      }

      return data;
    } catch (error) {
      trackError(error, { from: 'getDroppoint()', favoriteStore, location, carrierId, zipCode });
    } finally {
      setIsLoading(false);
    }
  }

  function clearRetry() {
    if (retryRef.current && isJPT) {
      clearTimeout(retryRef.current);
      retryRef.current = null;
    }
    retryCount = 0;
    setIsLoading(false);
  }

  function getLastUpdatedStore(stores: StoreType[], defaultLocale: string) {
    const latestUpdatedStore = stores.reduce<StoreType | null>(
      (latest, store) =>
        !latest || new Date(store.atp?.timestamp ?? 0) > new Date(latest.atp?.timestamp ?? 0) ? store : latest,
      null,
    );

    const lastUpdatedFormatted = new Date(latestUpdatedStore?.atp?.timestamp ?? Date.now());

    const timeFormatter = new Intl.DateTimeFormat(defaultLocale, { hour: 'numeric', minute: 'numeric' });
    const dateFormatter = new Intl.DateTimeFormat(defaultLocale, { year: 'numeric', month: 'numeric', day: 'numeric' });

    return {
      latestUpdatedStore,
      formattedDateTime: lastUpdatedFormatted.toString(),
      time: timeFormatter.format(lastUpdatedFormatted),
      date: dateFormatter.format(lastUpdatedFormatted),
    };
  }

  return { getStores, getDroppoints, getLastUpdatedStore, isLoading, getStoresWithLocation };
}
