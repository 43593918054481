import {
  formatFiltersToObject,
  formatFiltersToString,
  hasSelectedFilter,
} from '@wla/app/(cms)/[...slug]/(filters)/filters-helpers';
import { trackFilter } from '@wla/lib/tracking/google-analytics-tracking';

export enum filterActions {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  OVERRIDE = 'OVERRIDE',
  RESET = 'RESET',
}

type HandleChangeFilterProps = {
  id: string;
  value: string;
  handle: keyof typeof filterActions;
  caption?: string;
};

type UpdateFilterProps = HandleChangeFilterProps & {
  filters: string | null;
  searchParams: string;
  min?: number;
  max?: number;
};

type HandleChangeFilterUrlProps = {
  pathname: string;
  searchParams: URLSearchParams;
  queryFilters: string | null;
};

function updateFilters({ id, value, handle, filters, searchParams, min, max }: UpdateFilterProps) {
  const params = new URLSearchParams(searchParams);
  const existingFilters = formatFiltersToObject(filters);

  const filterMapper = {
    [filterActions.ADD]: () => {
      // only add if it's not already in the array
      existingFilters[id] = Array.from(new Set([...(existingFilters[id] || []), value]));
    },
    [filterActions.REMOVE]: () => {
      if (existingFilters[id]?.length === 1) {
        delete existingFilters[id];
      } else if (existingFilters[id]?.length > 1) {
        existingFilters[id] = existingFilters[id].filter((v) => v !== value);
      }
    },
    [filterActions.OVERRIDE]: () => {
      // Used for range slider, that always updates the current value
      existingFilters[id] = [value];
    },
    [filterActions.RESET]: () => {
      // Reset all filters
      Object.keys(existingFilters).forEach((key) => {
        delete existingFilters[key];
      });
    },
  };

  filterMapper[handle]?.();

  if (Object.keys(existingFilters).length) {
    params.set('filters', formatFiltersToString(existingFilters));
  } else {
    params.delete('filters');
  }

  return params.toString();
}

export function handleChangeFilter(
  { id, value, handle, caption }: HandleChangeFilterProps,
  { pathname, searchParams, queryFilters }: HandleChangeFilterUrlProps,
) {
  // Generate the updated filters string
  const updatedFilter = updateFilters({
    id,
    value,
    handle,
    filters: queryFilters,
    searchParams: searchParams.toString(),
  });

  // Update filters from WssFilterAxis API by saving in URL
  history.replaceState({}, '', `${pathname}?${updatedFilter}`);
  // Track the filter change
  trackFilter({ label: caption ?? '', value, action: handle });
}

export function handleChangeSeachFilter(
  { key, value, handle }: { key: string | any; value?: string | any; handle?: filterActions },
  { pathname, searchParams, queryFilters }: HandleChangeFilterUrlProps,
) {
  const toggleHandler = hasSelectedFilter(value, queryFilters) ? filterActions.REMOVE : filterActions.ADD;
  const updatedFilter = updateFilters({
    id: key,
    value,
    handle: handle || toggleHandler,
    filters: queryFilters,
    searchParams: searchParams.toString(),
  });

  history.replaceState({}, '', `${pathname}?${updatedFilter}`);
  trackFilter({ label: key, value, action: toggleHandler });
}
