import { OpeningInfo } from '@wla/app/(cms)/(favorite-store)/get-store-opening-data';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';

type FavoriteStoreOpeningStatusProps = {
  openingInfo: OpeningInfo;
  readonly?: boolean;
  className?: string;
};

export function FavoriteStoreOpeningStatus({ openingInfo, readonly, className }: FavoriteStoreOpeningStatusProps) {
  const t = useTranslations();

  return (
    <div
      className={cn(
        'flex items-end gap-3 whitespace-nowrap font-semibold',
        {
          group: !readonly,
          'cursor-default': readonly,
        },
        className,
      )}
    >
      {openingInfo.open ? (
        <span className="flex h-5 items-center gap-1 text-sm group-hover:underline">
          <span className="text-green-700">{t('common.open')}</span>
          <span>&bull;</span>
          <span>{t('favorite-store.close-time', { time: openingInfo.closesToday })}</span>
        </span>
      ) : (
        <div className="flex h-5 items-center gap-1 text-sm group-hover:underline">
          <span className="text-gray-700">{t('common.closed')}</span>
          <span>&bull;</span>
          <span>{t('favorite-store.opens-time', { time: openingInfo.opensAgain })}</span>
        </div>
      )}
      {!readonly && <Icon src="w3-arrow-down" size="16" className="-mb-0.5 -ml-2 text-gray-500" />}
    </div>
  );
}
