import {
  ButtonListParagraph,
  ButtonListParagraphAllOfAlignment,
  ButtonListParagraphAllOfItemsItemStyleType,
  ButtonListParagraphAllOfPosition,
} from '@jysk/api-types/drupalApi';
import { ParagraphConfig } from '@wla/components/ui/paragraph/paragraph-config';
import { cn } from '@wla/lib/helpers/cn';
import { Button } from '../button/button';

type ParagraphButtonListProps = {
  content: ButtonListParagraph;
};

export function ParagraphButtonList({ content }: ParagraphButtonListProps) {
  const { config, items, alignment, position } = content;

  if (!items) return null;

  return (
    <ParagraphConfig config={config}>
      <div
        className={cn('flex w-full flex-wrap gap-4', {
          'flex-row': position === ButtonListParagraphAllOfPosition.inline,
          'flex-col': position === ButtonListParagraphAllOfPosition.stacked,
          'items-start justify-start': alignment === ButtonListParagraphAllOfAlignment.left,
          'items-center justify-center': alignment === ButtonListParagraphAllOfAlignment.center,
          'items-end justify-end': alignment === ButtonListParagraphAllOfAlignment.right,
        })}
      >
        {items.map((button, index) => (
          <Button
            key={`${index}${button.link.url}`}
            as="a"
            href={button.link.url}
            variant={button.style.type === 'secondary' || button.style.type === 'primary' ? button.style.type : null}
            style={
              button.style.type === ButtonListParagraphAllOfItemsItemStyleType.custom
                ? {
                    backgroundColor: button.style.backgroundColor || undefined,
                    color: button.style.fontColor || undefined,
                  }
                : undefined
            }
          >
            {button.link.title}
          </Button>
        ))}
      </div>
    </ParagraphConfig>
  );
}
