import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';

type NavigationButtonProps = {
  isPrev?: boolean;
  buttonOverflow?: boolean;
  onClick: () => void;
};

export function NavigationButton({ isPrev, buttonOverflow = true, onClick, ...rest }: NavigationButtonProps) {
  return (
    <button
      {...rest}
      onClick={onClick}
      className={cn('absolute top-1/2 z-10 hidden h-10 w-10 rounded-full bg-white shadow lg:block', {
        'right-2 -translate-y-1/2 translate-x-1/2 md:right-8': !isPrev,
        '-translate-x-1/2 -translate-y-1/2': isPrev,
        'translate-x-0 shadow-none': !buttonOverflow && !isPrev,
        'left-2 -translate-x-0 shadow-none md:left-8': !buttonOverflow && isPrev,
      })}
    >
      <Icon src={isPrev ? 'w3-arrow-left' : 'w3-arrow-right'} size="20" />
    </button>
  );
}
